
import { ActivatedRoute, Router } from '@angular/router';

import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import { AccountType } from '../constants/account-types';
import { HttpClient } from '@angular/common/http';
import { WhitelabelService } from '../domain/whitelabel.service';
import Sockette from 'sockette';
// import moment from 'moment';
import * as moment from 'moment-timezone';
import {HostListener, Injectable, RendererFactory2} from '@angular/core';

import {BehaviorSubject, fromEventPattern, Observable, Subject} from 'rxjs';
import {API_ADDRESS_PROD, API_ADDRESS_QC1, API_ADDRESS_QC3, API_ADDRESS_XQC1, API_ADDRESS_LOCAL} from './api-addr/l';
import { UserIdleModule, UserIdleService } from 'angular-user-idle';
import { AssessmentType } from '../bc-assessments/bc-assessments.service';
import { District, School, AcceptableDomainsBC } from '../bc-accounts/bc-accounts.service';

const auth = require('@feathersjs/authentication-client');

export const F_ERR_MSG__FAILED_TO_FETCH = 'Failed to fetch'; // if feather is unable to connect to the server
export const F_ERR_MSG__INVALID_LOGIN = 'Invalid login';
export const F_ERR_MSG__REAUTH_NO_TOKEN = 'No accessToken found in storage';
export const F_ERR_MSG__RELOGIN = 'jwt expired';

const TOKEN_REFRESH_INTERVAL = 10 * 60 * 1000;
interface IAuthRes {
  accessToken: string,
  user?: IUserInfoCore,
  authentication?: {
    payload: IUserInfoCore
  }
}
interface IApiFindQuery {
  query?: {
    $limit?: number,
    [key: string]: any;
  }
  [key: string]: any;
}
interface IUploadResponse {
  success: boolean,
  filePath?: string,
  url?: string,
  urls? : string[]
}

export interface AccountTypeRecord {
  display_order   : number,
  route_template  : string,
  caption         : string,
  color           : string,
  group_id        : string,
  account_type    : string,
  s_name?         : string,
  s_foreign_id?   : string,
  sd_name?        : string,
  sd_foreign_id?  : string,
  route?          : string,
}
export interface IUserInfoCore {
  email: string,
  uid: number,
  accountType: AccountType,
  accountTypes: AccountTypeRecord[],
  accountId: number,
  accountInfo: {
    institutionId?: number // test admins only
  },
  // roles?: string[],
  firstName: string,
  lastName: string,
  imgURL?: string
  showComments?: boolean;
  timezone?: string,
  contact_email?: string
}

export interface IUserInfo extends IUserInfoCore {
  accessToken: string,
  lang?: string,
  dashboardType?: string
}

export const DB_TIMEZONE = 'Z';

export const getFrontendDomain = () => {
  return window.location.origin + '/';
}



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private api = <any>feathers();
  private restClient = <any>rest(this.whitelabel.getApiAddress());
  private _user: IUserInfo;
  public userSub: BehaviorSubject<IUserInfo> = new BehaviorSubject(null);
  private reauthCompletedSub: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private apiNetFail: BehaviorSubject<boolean> = new BehaviorSubject(false); // gets overridden upon registration
  private apiAuthExpire: Subject<boolean> = new Subject(); // if you want to use BehaviorSubject, need to be diligent about setting this to false whenever the user is re-authenticated
  public isLoggingIn: boolean;

  public activeKioskPassword: string; // unclean
  public earlyAccessCode:string;
  public isAccomm:boolean;

  private rolesByMarkingSession: any = {};
  private activeMarkingRole: string;

  authWebSocket: any;
  private readonly authWebSocketURI: string = 'wss://mnomt58qw8.execute-api.ca-central-1.amazonaws.com/production';

  private onLoginCallback: CallableFunction = undefined;
  private onLogoutCallback: CallableFunction = undefined;
  private hideLoginGuardPopUpOnLogOut: boolean = false;

  public onMove$: Observable<Event>;
  public onPress$: Observable<Event>;

  idleTimer = 0;
  isIdle = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private whitelabel: WhitelabelService,
    private userIdle:UserIdleService,
    private rendererFactory:RendererFactory2
  ) { 
    this.api.configure(this.restClient.fetch(window['fetch']));
    this.api.configure(auth({ storage: window['localStorage'] }));
    this.reauth();

    // setInterval(this.refreshToken, TOKEN_REFRESH_INTERVAL);
    // this.clearLocalSession()
  }


  ngOnDestroy() {
    if (this.authWebSocket) this.authWebSocket.close();
  }

  public user() {
    return this.userSub;
  }

  getDashboardRoute(lang: string, schoolAdminOverride?: boolean, SACurrentlyGrad?: boolean | null) {
    if (this._user && this._user.accountType) 
    {
      // console.log(schoolAdminOverride, SACurrentlyGrad);
      
      // if Grad
      if (schoolAdminOverride && SACurrentlyGrad)
      {
        return `/${lang}/school-admin/bc-grad/dashboard`;
      }

      // if FSA
      else if (schoolAdminOverride && SACurrentlyGrad != null && !SACurrentlyGrad)
      {
        return `/${lang}/school-admin/bc-fsa/dashboard`;
      }
      // else schoolAdminOverride = false or SACurrentlyGrad is null; in either case, use account type
      // to get dashboard URL like normal

      if (this._user.accountType === 'educator') {
        return `/${lang}/${this._user.accountType}/classrooms`
      }
      if (this._user.accountType === AccountType.MINISTRY_ADMIN || this._user.accountType === AccountType.BC_FSA_MINISTRY_ADMIN) {
        return `/${lang}/ministry-admin/bc-fsa/dashboard`;
      }
      if ([AccountType.BC_GRAD_MINISTRY_ADMIN, AccountType.BC_GRAD_MINISTRY_ADMIN_SPECIAL_CASE].includes(this._user.accountType))  {
        return `/${lang}/ministry-admin/bc-grad/dashboard`;
      }
      if (this._user.accountType === AccountType.BC_GRAD_SCHOOL_ADMIN) {
        return `/${lang}/school-admin/bc-grad/dashboard`;
      }
      if (this._user.accountType === AccountType.BC_FSA_SCHOOL_ADMIN) {
        return `/${lang}/school-admin/bc-fsa/dashboard`;
      }

      if (this._user.accountType === AccountType.BC_FSA_DIST_ADMIN) {
        return `/${lang}/dist-admin/bc-fsa/dashboard`;
      }
      if (this._user.accountType === AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY) {
        return `/${lang}/school-admin/bc-fsa/dashboard`;
      }
      if(this.myActiveMarkingRole()) {
        console.log("GOT IT");
        return `/${lang}/${this.myActiveMarkingRole(null, true)}/dashboard`;
      }
      return `/${lang}/${this._user.accountType}/dashboard`
    }

    return `/`;
  }

  public setHideLoginGuardPopUpOnLogOut(hideLoginGuardPopUpOnLogOut: boolean)
  {
    this.hideLoginGuardPopUpOnLogOut = hideLoginGuardPopUpOnLogOut;
  }

  public clearLocalStorage()
  {
    localStorage.clear();
  }

  public getDBFromHostname(currDBConnected?: AcceptableDomainsBC): AcceptableDomainsBC
  {
    // do not remove, important 
    // by Shardul

    const prodDBDomains = ["bced.vretta.com", "bced-qc.vretta.com", "bced-ltp.vretta.com"];
    const mirrorDBDomains = ["bced-testing.vretta.com", "bced-mirror-qc.vretta.com"];
    const UATDBDomains = ["bced-uat.vretta.com"];

    const hostName = window.location.hostname;
    if ([prodDBDomains.includes(hostName)])
    {
      return AcceptableDomainsBC.live;
    }

    else if ([UATDBDomains.includes(hostName)])
    {
      return AcceptableDomainsBC.uat;
    }

    else if ([mirrorDBDomains.includes(hostName)])
    {
      return AcceptableDomainsBC.mirror;
    }

    else if (hostName !== "localhost")
    {
      // error for users

      // to be safe, just throw an appropriate error on the page, even if not localhost, if we get to this block, rather than asssuming a database
      alert(`There was an error in generating the .LIN files on this page. Please contact Vretta to resolve this issue.`);
      throw new Error(`Function "getDBFromHostname": This indicates a non localhost domain has been reached, and that this domain does not 
      have a database identified as being pointed to. Please include it in the lists above this block to fix this issue.`);
    }
    
    else if (hostName === "localhost" && currDBConnected == null)
    {
      // error for developers

      alert(`Function "getDBFromHostname": The developer must specify the current DB currently pointing to locally, and it must be in the list of acceptableDomains.`);
      throw new Error(`Function "getDBFromHostname": The developer must specify the current DB currently pointing to locally, and it must be in the list of acceptableDomains.`);
    }

    else
    {
      return currDBConnected;
    }
  }

  getTimezone(): string {
    if (this._user.timezone == null)
    {
      return "America/Vancouver";
    }

    // console.log(this._user, 'timezone');
    return this._user.timezone;
  }

  public userIsStudent() {
    return this._user?.accountType === AccountType.STUDENT;
  }

  public checkUserAccountType(accountType: AccountType): boolean {
    if (!this._user) return false;
    return this._user.accountType === accountType;
  }

  public getDomain() {
    return window.location.origin;
  }

  public getReauthCompletedSub() {
    return this.reauthCompletedSub;
  }

  getApiAuthExpire() {
    this.apiAuthExpire;
  }

  public isLoggedIn() {
    return this.userSub.value ? true : false;
  }

  public isQcBranch() {
    return (this.whitelabel.getApiAddress() === API_ADDRESS_QC1)
  }

  public setKioskPassword(kioskPassword: string) {
    this.activeKioskPassword = kioskPassword;
  }

  public registerNoApiNetSub(apiNetFail: BehaviorSubject<boolean>) {
    apiNetFail.next(this.apiNetFail.getValue()); // usually this first one will only be around for a fraction of a second. in any case, it is private so nothing outside of this calss should be subscribing to it becaus it will be wiped out
    this.apiNetFail = apiNetFail;
  }
  private clearNetworkError = (res: any) => {
    this.apiNetFail.next(false);
    return res;
  }
  private catchNetworkError = (e) => {
    // console.log('catch net error', e.message)

    if (e.code === 500) {
      this.apiNetFail.next(true);
    }
    else if (e.message === F_ERR_MSG__RELOGIN) {
      this.apiAuthExpire.next(true); // not being used at the moment
      this.clearUser();
    }
    else if (e.message === F_ERR_MSG__FAILED_TO_FETCH) {
      this.apiNetFail.next(true);
    }
    else if (e.message === F_ERR_MSG__FAILED_TO_FETCH) {
      this.apiNetFail.next(true);
    }
    throw e;
  }

  private clearLocalSession() {

    this.api.authentication.removeAccessToken();
  }

  private refreshUserInfo = async (res: IAuthRes, isSilent: boolean = false) => {
    this._user = {
      ...res.user || res.authentication.payload,
      accessToken: res.accessToken,
    }

    if (!isSilent) {
      await this.initMyActiveMarkingRole();
      this.userSub.next(this._user)
    }

    return res;
  }

  // temp: wrong place, just rrushing
  isStudentIntervalInitialized
  initStudentInterval() {
    if (!this.isStudentIntervalInitialized) {
      this.isStudentIntervalInitialized = true;
      setInterval(this.refreshStudentData, 5 * 1000);
      this.refreshStudentData();
    }
  }
  refreshStudentData = () => {
    return this.apiCreate('public/educator/students', { type: 'STUDENT_INTERVAL' }).then()
  }

  private getToken = (res: IAuthRes) => {
    return res.accessToken;
  }

  getDisplayName() {
    if (this._user) {
      return this._user.firstName + ' ' + this._user.lastName
    }
    return 'Not Logged In';
  }

  getUid() {
    return this._user?.uid || -1;
  }

  getAccessToken() {
    return this._user?.accessToken || '';
  }

  myAccountType() {
    if(this._user) {
      if((this.router.url.includes('mrkg-coord') || this.router.url.includes('mrkg-lead')) && (this._user.accountType == 'test-ctrl-adm-bcgrad-ministry' || this._user.accountType == AccountType.BC_FSA_MINISTRY_ADMIN || this._user.accountType == 'ministry-admin')) {
        return 'mrkg-coord';
      }
      return this._user.accountType;
    }
    else{
      return '';
    }
  }

  myActiveMarkingRole(markingSessionID?, replaceUnderscores=false) {

    if(markingSessionID) {
      let role = this.rolesByMarkingSession[markingSessionID];
      if(!role) {
        return '';
      }

      if(replaceUnderscores) {
        return role.replace('_', '-');
      }

      return role;
      
    }
    else {
      if(!this.activeMarkingRole) return '';
    
      if(replaceUnderscores) {
        return this.activeMarkingRole.replace('_', '-');
      }

      return this.activeMarkingRole;
    }
  }

  async initMyActiveMarkingRole() {
    let role = await this.apiFind('public/mrkg-coord/marking-roles', {
      query: {
        getActiveMarkingRole: true
      }
    });
    if(role.length == 0) {
      this.activeMarkingRole = '';
    }
    else {
      this.activeMarkingRole = role;
    }

    let roles = await this.apiFind('public/mrkg-coord/marking-roles', {
      query: {
        getAllMarkingRoles: true
      }
    });

    this.rolesByMarkingSession = roles;
  

  }

  isCoordinator() {
    if(this._user) {
      return this._user.accountType == 'mrkg-coord' || this._user.accountType == 'test-ctrl-adm-bcgrad-ministry'  || this._user.accountType == AccountType.BC_FSA_MINISTRY_ADMIN || this._user.accountType == 'ministry-admin';
    }
    else{
      return false;
    }
  }

  superLeaderSessions;
  isSuperLeader(msid) {
    if(!this._user) {
      return false;
    }

    if(this._user.accountType != 'mrkg-lead') {
      return false;
    }
    if(!this.superLeaderSessions) return false;
    return this.superLeaderSessions.includes(msid);
  }
  


  uploadFile(file: File | Blob, filename: string, purpose: string = '_general', isPermaLink: boolean = false): Promise<IUploadResponse> {
    const formData: FormData = new FormData();
    const uid = this._user?.uid || -1;
    const jwt = this._user?.accessToken || '';
    formData.append('form_upload', file, filename);
    formData.append('uid', '' + uid);
    console.log("started uploading file with user id", uid);
    formData.append('purpose', purpose);
    formData.append('isPermaLink', isPermaLink ? '1' : '0');
    formData.append('jwt', jwt);
    return this.httpClient
      .post(this.whitelabel.getApiAddress() + '/upload', formData)
      .toPromise()
      .then((res) => { console.log(res); return <IUploadResponse>res });
  }

  excelToJson(file: File) {
    const formData: FormData = new FormData();
    const uid = this._user?.uid || -1;
    const jwt = this._user?.accessToken || '';

    formData.append('form_upload', file);
    formData.append('uid', '' + uid);
    formData.append('jwt', jwt);
    return this.httpClient
      .post(this.whitelabel.getApiAddress() + '/convert-xlsx-to-json', formData)
      .toPromise()
  }


  public refreshToken = (): Promise<any> => {
    return this.api
      .reAuthenticate(true)
      .then(userInfo => this.refreshUserInfo(userInfo, true))
      .then(function (result) {
        console.log('reAuth success', result)
        return result;
      }).catch(function (err) {
        console.log('reAuth err', err);
        throw err;
      })
  }

  reportFilePath(path: string, raw: string, filename: string) 
  {
    // Documentation: - twin relevant API file:
    // src/services/download-data-frame/download.listener.ts
    // It is not actively called anymore, but serves as a listener for exporting requests (such as a spreadsheet)

    return this.whitelabel.getApiAddress() + '/' + encodeURIComponent(filename) + '.xlsx?uid=' + this._user?.uid + '&path=' + path + '&rawParams=' + encodeURIComponent(raw) + '&jwt=' + this._user?.accessToken;
  }

  textFilePath(path: string, raw: string, filename: string, fileExtension: string) {
    return this.whitelabel.getApiAddress() + '/' + encodeURIComponent(filename) + '.' + fileExtension + '?uid=' + this._user?.uid + '&path=' + path + '&rawParams=' + encodeURIComponent(raw) + '&jwt=' + this._user?.accessToken;
  }

  dataFilePath(path: string, options: { [key: string]: string | number }) {
    let optionStr = '';
    if (options) {
      Object.keys(options).forEach(param => optionStr += '&' + param + '=' + options[param]);
    }
    return this.whitelabel.getApiAddress() + '/data-frame.xlsx?uid=' + this._user?.uid + '&path=' + path + optionStr + '&jwt=' + this._user?.accessToken;
  }

  jsonToExcel(records: any[], fileName) {
    const formData: FormData = new FormData();
    const uid = this._user?.uid || -1;
    const jwt = this._user?.accessToken || '';

    formData.append('uid', '' + uid);
    formData.append('jwt', jwt);
    const jsonRecords = JSON.stringify(records);
    const recordsBlob = new Blob([jsonRecords], { type: 'application/json' });
    formData.append('form_upload', recordsBlob);
    formData.append('fileName', fileName)
    return this.httpClient.post(this.whitelabel.getApiAddress() + '/upload-json-as-xlsx', formData).toPromise();
  }

  

  async checkCredentialingAccepted(uid?: number) {
    const userUid = uid || this._user.uid;
    await this.apiPatch('public/mrkg-coord/applicant-info', null, [userUid], {
      query: {
        invite_status: 2,
      }
    });
  }

  // public refreshToken = () =>{
  // if (this._user && !this.isLoggingIn){
  //   this.apiFind('/rest/auth/refresh-token', {})
  //     .then(refreshed => {
  //       this.api.authentication.setAccessToken(refreshed[0]);
  //       // setTimeout(()=>{
  //       //   console.log(['refreshToken', refreshed[0], this.api.authentication.getAccessToken()])
  //       // }, 1000)
  //     })
  //     .catch(()=>{
  //       console.log('soft fail')
  //       return this.api
  //         .reAuthenticate(true)
  //         .then(res => this.refreshUserInfo(res, true))
  //     })
  //     .catch(e =>{
  //       console.log('hard fail')
  //       return this.clearUser(e);
  //     }
  // }
  // }

  clearUser = (e?: any) => {
    if (!this.hideLoginGuardPopUpOnLogOut)
    {
      this.userSub.next(null);
    }

    else 
    {
      this.userSub = new BehaviorSubject(null);
    }

    this._user = null;
  }

  public reauth(): Promise<any> {
    return this.api
      .reAuthenticate()
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then( res => { this.reauthCompletedSub.next(true); return res })
      .then( () => {
        if(this._user.accountType == 'mrkg-lead') {
          this.apiFind('public/mrkg-lead/super-leaders', {
            query:{
              uid:this._user.uid,
              $limit:1000
            }
          }).then((res) => {
            this.superLeaderSessions = res.data.map(row => row.marking_session_id);
          });

        }
      })
      .then( () => {
        this.initMyActiveMarkingRole();

      })
      // .then( () => { this.initAuthWebSocket(); })
      // .then( () => { this.checkCredentialingAccepted() })
      .catch( e => { this.reauthCompletedSub.next(true); return e })
      .catch(this.catchNetworkError)
      .catch(e => { if (e.message !== F_ERR_MSG__REAUTH_NO_TOKEN) { throw e; } }) // no token is not really an error since we are not checking for it in the first place
  }

  public loginWithKey(key: string): Promise<any> {
    this.isLoggingIn = true;

    if (this.onLoginCallback) this.onLoginCallback();

    return this.api.authenticate( {
      strategy: 'loginMarkerKey',
      secret_key: key
    }).then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then(() => {
        this.isLoggingIn = false;
        // this.initAuthWebSocket();
      })
      .catch(this.catchNetworkError)
      .catch(err => { this.isLoggingIn = false; throw err; })
  }

  public login(email: string, password: string): Promise<any> {
    this.isLoggingIn = true;

    if (this.onLoginCallback) this.onLoginCallback();

    return this.api
      .authenticate({
        strategy: 'local',
        email,
        password,
      })
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then(() => {
        this.isLoggingIn = false;
        // this.initAuthWebSocket();
      })
      .catch(this.catchNetworkError)
      .catch(err => { this.isLoggingIn = false; throw err; })
  }

  public loginStudentGrad(stage: 1 | 2, studentNumber: string, accessCode: string, district?: number, school?: number, lang?:string): Promise<any> {
    this.isLoggingIn = true;

    if (stage === 1) {
      return this.apiCreate('public/student/name-initial', {
        studentNumber,
        accessCode,
        district,
        school,
        assessmentType: 'grad',
        earlyAccessCode: this.earlyAccessCode,
        kioskPassword: this.activeKioskPassword, // STAGE 1 GRAD
        isAccomm: this.isAccomm
      }, {
        query: {
          action: 'get last name',
        }
      }).then(data => {
        return data;
      });
    } else {
      return this.api
        .authenticate({
          strategy: 'loginSessionKey',
          stage,
          studentNumber,
          accessCode,
          district,
          school,
          assessmentType: 'grad',
          lang,
          earlyAccessCode: this.earlyAccessCode,
          kioskPassword: this.activeKioskPassword, // STAGE 2 GRAD
          isAccomm: this.isAccomm
        })
        .then(this.clearNetworkError)
        .then(this.refreshUserInfo)
        .catch(this.catchNetworkError)
        .catch(err => { this.isLoggingIn = false; throw err; })
    }

  }

  public loginStudentFsa(stage: 1 | 2, studentNumber: string, accessCode: string, district?: number, school?: number, lang?: string): Promise<any> {
    this.isLoggingIn = true;

    if (stage === 1) {
      return this.apiCreate('public/student/name-initial', {
        studentNumber,
        accessCode,
        district,
        school,
        assessmentType: 'fsa',
        earlyAccessCode: this.earlyAccessCode,
        kioskPassword: this.activeKioskPassword, // STAGE 1 FSA
        isAccomm: this.isAccomm
      }, {
        query: {
          action: 'get names',
        }
      }).then(data => {
        return data;
      });
    } else {
      return this.api
        .authenticate({
          strategy: 'loginSessionKey',
          stage,
          studentNumber,
          accessCode,
          district,
          school,
          assessmentType: 'fsa',
          lang,
          earlyAccessCode: this.earlyAccessCode,
          kioskPassword: this.activeKioskPassword, // STAGE 2 FSA
          isAccomm: this.isAccomm
        })
        .then(this.clearNetworkError)
        .then(this.refreshUserInfo)
        .catch(this.catchNetworkError)
        .catch(err => { this.isLoggingIn = false; throw err; })
    }

  }

  public loginEqaoStudent(studentNumber:string, accessCode:string) : Promise<any> {
    this.isLoggingIn = true;
    // cherry-pick //  this.hasAutoLoggedOut = false;

    return this.api
      .authenticate({
        strategy: 'loginKey',
        studentNumber,
        accessCode
      })
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      // .then(() => this.isLoggedIn = true)
      .then(() => this.isLoggingIn = false)
      .catch(this.catchNetworkError)
      .catch( err => { this.isLoggingIn = false; throw err; })
  }

  public loginAlias(id:string, secret:string) : Promise<any> {
    this.isLoggingIn = true;
    return this.api
      .authenticate({
        strategy: 'alias',
        id,
        secret
      })
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      // .then(() => this.isLoggedIn = true)
      .then(() => this.isLoggingIn = false)
      .catch(this.catchNetworkError)
      .catch( err => { this.isLoggingIn = false; throw err; })
  }

  public loginFieldTest(studentNumber: string, accessCode: string, asmtSlug: string, selectedSchoolId: string, selectedDistrictId: string): Promise<any> {
    this.isLoggingIn = true;
    return this.api
      .authenticate({
        strategy: 'loginFieldTest',
        studentNumber,
        accessCode,
        asmtSlug,
        selectedSchoolId,
        selectedDistrictId,
      })
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .catch(err => { this.isLoggingIn = false; throw err; })
  }

  private persistPresence(userInfo) {

    return userInfo
  }

  public getJWT(email: string, password: string): Promise<any> {
    return this.api
      .authenticate({
        strategy: 'local',
        email,
        password,
      })
      .then(this.getToken)
      .catch(err => { this.isLoggingIn = false; throw err; })
  }

  public logout(): Promise<any> {

    if (this.authWebSocket) this.authWebSocket.close();

    if (this.onLogoutCallback) this.onLogoutCallback();

    return this.api
      .logout()
      .then(this.clearLocalStorage())
      .then(this.clearNetworkError)
      .then(this.clearUser())
      .catch(e => { this.clearLocalSession(); throw e; })
      .catch(this.catchNetworkError)
  }

  public subscribeToLogin(cb: CallableFunction) {
    this.onLoginCallback = cb;
  }

  public subscribeToLogout(cb: CallableFunction) {
    this.onLogoutCallback = cb;
  }

  public apiGet(route:string, id:string | number,  params?:any) : Promise<any> {
    return this.api
      .service(route)
      .get(id, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }

  public apiFind(route: string, params?: any): Promise<any> {
    return this.api
      .service(route)
      .find(params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }
  public apiCreate(route: string, data: any, params?: any): Promise<any> {
    return this.api
      .service(route)
      .create(data, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }

  public apiPatch(route: string, id: string | number, data: any, params?: any): Promise<any> {
    return this.api
      .service(route)
      .patch(id, data, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }
  public apiUpdate(route: string, id: string | number, data: any, params?: any): Promise<any> {
    return this.api
      .service(route)
      .update(id, data, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }

  public apiRemove(route: string, id: string | number, params?: any): Promise<any> {
    return this.api
      .service(route)
      .remove(id, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }


  // isWebSocketInited:boolean;
  // pingInterval;
  // initAuthWebSocket() {
  //   if (this.isWebSocketInited){
  //     this.authWebSocket.close()
  //     // return
  //   }
  //   this.isWebSocketInited = true;
  //   this.authWebSocket = new Sockette(this.authWebSocketURI, {
  //     timeout: 10e3,
  //     maxAttempts: 10,
  //     onopen: e => {
  //       this.onOpenAuthWS(e);
  //     },
  //     onmessage: e => {
  //       // console.log("Received message: ", e);
  //       this.onMessageAuthWS(e);
  //     },
  //     onreconnect: e => console.log('Reconnecting...', e),
  //     onmaximum: e => console.log('Stop Attempting!', e),
  //     onclose: e => this.onCloseAuthWS(e),
  //     onerror: e => console.log('Error:', e)
  //   });

  //   this.pingInterval = setInterval(() => {
  //     this.authWebSocket.json({action:'ping', data:"Ping!"});
  //   }, 30000);
  // }

  // onOpenAuthWS(e) {
  //   if(!this.getCookie('veaSession')) {
  //     this.setCookie('veaSession', this.generateToken(), 1);
  //   }
  //   console.log("connecting with " + this.getCookie('veaSession'));
  //   this.authWebSocket.json({action: "authConnect", data:{uid: this.user().value.uid, veaSession:this.getCookie('veaSession')}});
  // }

  // onCloseAuthWS(e) {
  //   this.authWebSocket.json({action: "authDisconnect"});
  //   this.isWebSocketInited = false;
  // }

  private onKickCallback: CallableFunction;

  setOnKickCallback(cb: CallableFunction) {
    this.onKickCallback = cb;
  }

  onMessageAuthWS(e) {
    let data;
    try {
      data = JSON.parse(e.data);
    } catch (e) {
    }

    // if(!data && e.data == "KICK") {
    //   alert("Someone else logged into this account. You will now be logged out.");
    //   this.logout();
    //   if (this.onKickCallback) this.onKickCallback();
    // }
  }

  setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  } 
  getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
  }

  isSchoolAdmin(accountType?: AccountType): boolean {
    accountType = this.ensureAccountType(accountType);
    return [
      AccountType.BC_FSA_SCHOOL_ADMIN,
      AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY,
      AccountType.BC_GRAD_SCHOOL_ADMIN,
      AccountType.SCHOOL_ADMIN,
    ].includes(accountType);
  }

  isScanningAdmin(accountType?: AccountType): boolean {
    accountType = this.ensureAccountType(accountType);
    return [
      AccountType.SCANNING_COORD
    ].includes(accountType);
  }

  isFsaSchoolAdmin(accountType?: AccountType): boolean {
    return [
      AccountType.BC_FSA_SCHOOL_ADMIN,
      AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY,
    ].includes(this.ensureAccountType(accountType));
  }

  isFsaAdmin(): boolean {
    return this.isFsaSchoolAdmin() || this.isFsaDistrictAdmin() || this.isFsaMinistryAdmin();
  }

  isGradAdmin(): boolean {
    return this.isGradSchoolAdmin() || this.isGradMinistryAdmin();
  }

  getAssessmentType(): AssessmentType {
    if (this.isFsaAdmin()) {
      return AssessmentType.FSA;
    }

    if (this.isGradAdmin()) {
      return AssessmentType.GRAD;
    }
  }

  isGradSchoolAdmin(accountType?: AccountType): boolean {
    accountType = this.ensureAccountType(accountType);
    return [
      AccountType.BC_GRAD_SCHOOL_ADMIN,
    ].includes(this.ensureAccountType(accountType));
  }

  isScoreEntrySchoolAdmin(accountType?: AccountType): boolean {
    accountType = this.ensureAccountType(accountType);
    return accountType === AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY;
  }

  isScoreEntryDistrictAdmin(accountType?: AccountType): boolean {
    accountType = this.ensureAccountType(accountType);
    return accountType === AccountType.BC_FSA_DIST_ADMIN_SCORE_ENTRY;
  }

  isDistrictAdmin(accountType?: AccountType): boolean {
    accountType = this.ensureAccountType(accountType);
    return [
      AccountType.DIST_ADMIN,
      AccountType.BC_FSA_DIST_ADMIN,
      AccountType.BC_FSA_DIST_ADMIN_SCORE_ENTRY,
      AccountType.BC_GRAD_DIST_ADMIN,
    ].includes(accountType);
  }

  isFsaDistrictAdmin(accountType?: AccountType): boolean {
    return [
      AccountType.BC_FSA_DIST_ADMIN,
      AccountType.BC_FSA_DIST_ADMIN_SCORE_ENTRY,
    ].includes(this.ensureAccountType(accountType));
  }

  isGradMinistryAdmin(accountType?: AccountType): boolean 
  {
    accountType = this.ensureAccountType(accountType);
    return [
      AccountType.BC_GRAD_MINISTRY_ADMIN,
    ].includes(accountType);
  }

  isFsaMinistryAdmin(accountType?: AccountType): boolean {
    return [
      AccountType.BC_FSA_MINISTRY_ADMIN,
    ].includes(this.ensureAccountType(accountType));
  }

  isFsaEaoAdmin(accountType?: AccountType): boolean {
    return [
      AccountType.BC_FSA_ADMIN_EAO,
    ].includes(this.ensureAccountType(accountType));
  }

  isMinistryAdmin(accountType?: AccountType): boolean {
    accountType = this.ensureAccountType(accountType);
    return [
      AccountType.MINISTRY_ADMIN,
      AccountType.BC_GRAD_MINISTRY_ADMIN,
      AccountType.BC_FSA_MINISTRY_ADMIN,
    ].includes(accountType);
  }

  ensureAccountType(accountType?: AccountType) {
    if (accountType) {
      return accountType;
    }
    if (this._user && this._user.accountType) {
      return this._user.accountType;
    }
  }

  formatDate(dateString: string): string {
    if (!dateString) return 'N/A';
    const m = moment.utc(dateString).tz(this.getTimezone());
    return m.format('D/M/YYYY') + m.format(' @ h:mm a z');
  }

  public generateToken(): string {
    let token = `${this.S4()}${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}${this.S4()}${this.S4()}`;
    return token;
  }

  private S4(): string {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  async ECASToUsers(file: File, marking_session_id:number, lang='en', account_type='mrkg_mrkr') {
    const formData: FormData = new FormData();
    const uid = this._user?.uid || -1;
    const jwt = this._user?.accessToken || '';

    formData.append('form_upload', file);
    formData.append('uid', ''+uid);
    formData.append('jwt', jwt);
    let resp = await <any> this.httpClient
      .post(this.whitelabel.getApiAddress()+'/convert-xlsx-to-json', formData)
      .toPromise()

    resp.marking_session_id = marking_session_id;
    resp.role_type = account_type;
    resp.lang = lang;

    return await this.apiCreate('public/mrkg-lead/ecas-import-export', resp);
  }



  isCurrentUserAllowToEditStudentPen() {
    return this.isFsaSchoolAdmin() || this.isGradSchoolAdmin() || this.isFsaMinistryAdmin();
  };

  isCurrentUserAbleToEditExemption() {
    return this.isFsaMinistryAdmin();
  }

  async getDistrictsPublic(payload?: { 
    excludeOOP: boolean,
    isSample?: boolean
    isForLogin?:boolean,
  }): Promise<District[]> {
    const { excludeOOP, isSample, isForLogin } = payload || {};

    const rawDistricts: any = await this.apiFind('public/districts', {
      query: {
        exclude_oop: excludeOOP ? 1 : 0,
        participation: isSample ? 1 : 0,
        is_for_login: isForLogin ? 1 : 0,
      }
    });

    if (!(rawDistricts?.length > 0)) return [];

    return rawDistricts.map((rawDistrict: any) => ({
      ...rawDistrict,
      isSample: rawDistrict?.isSample === 1
    }))
  }

  async getIndependentSchoolsPublic(isSample: boolean = false): Promise<School[]> {
    return this.apiFind('public/schools', {query: {participation: isSample? 1 : 0}});
  }
}
