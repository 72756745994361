export enum UserRoles {
    ALL = 'all',
    CERT_BODY_DATA_RETR = 'cert_body_data_retr',
    DEBUG = 'debug',
    MPT_ACCOMM_APPLICANT = 'mpt_accomm_applicant',
    MPT_APPLICANT = 'mpt_applicant',
    MPT_BOOKED_APPLICANT = 'mpt_booked_applicant',
    MPT_SYS_ADMIN = 'mpt_sys_admin',
    MPT_TEST_ADMIN_ACCOMM_COORD = 'mpt_test_admin_accomm_coord',
    MPT_TEST_ADMIN_INST_MNGR = 'mpt_test_admin_inst_mngr',
    MPT_TEST_ADMIN_INVIG = 'mpt_test_admin_invig',
    MPT_WAITING_LIST_APPLICANT = 'mpt_waiting_list_applicant',
    TEST_CTRL_DATA_RETR = 'test_ctrl_data_retr',
    TEST_CTRL_ISSUE_TRACKER = 'test_ctrl_issue_tracker',
    TEST_CTRL_LIAS_CERT_BODY = 'test_ctrl_lias_cert_body',
    TEST_CTRL_LIAS_INTERNAL = 'test_ctrl_lias_internal',
    TEST_CTRL_LIAS_TEST_ADMIN = 'test_ctrl_lias_test_admin',
    TEST_CTRL_META_REG = 'test_ctrl_meta_reg',
    TEST_CTRL_SCORE_VALID = 'test_ctrl_score_valid',
    TEST_CTRL_WINDOW_MONITOR = 'test_ctrl_window_monitor',
    TEST_ITEM_AUTHOR = 'test_item_author',
    TEST_ITEM_AUTHOR_REV = 'test_item_author_rev',
    TEST_ITEM_AUTHOR_SUPER = 'test_item_author_super',
    TRANSLATOR = 'translator',
    TRANSLATOR_SUPER = 'translator_super',
    BC_AUTH_COORD = 'bc_auth_coord',
    BC_AUTH_CHAIR = 'bc_auth_chair',
    BC_AUTH_MINISTRY_REV = 'bc_auth_ministry_rev',
    BC_AUTH_PSYCHO = 'bc_auth_psycho',
    BC_AUTH_TRANS_COORD = 'bc_auth_trans_coord',
    BC_AUTH_SOC_SPEC = 'bc_auth_soc_spec',
    BC_AUTH_EXT_REV = 'bc_auth_ext_rev',
    BC_AUTH_TRIAL_WRITER = 'bc_auth_trial_writer',
    BC_AUTH_TRANS = 'bc_auth_trans',
    BC_AUTH_LING_REV = 'bc_auth_ling_rev',
    BC_AUTH_ACC_SPEC = 'bc_auth_acc_spec',
    BC_AUTH_STANDARD_SPEC = 'bc_auth_standard_spec',
    BC_AUTH_BASE = 'bc_auth_base'

}