import { CustomInteractionType, getElementWeight, ElementType, QuestionState, intializeDefault, mapToObject, objectToMap, IContentElementGridPaint, IEntryStateGridPaint } from "../../models";
import { QuestionPubSub } from "../../question-runner/pubsub/question-pubsub";
import { CustomInteractionCtrl } from "./custom-interaction";
import * as PIXI from "pixi.js-legacy";
import { Direction, ScoringTypes } from "../../models";
import { SpriteLoader } from "./sprite-loader";
import { AXIS, IGraphConfig, pixiGraph, PIXI_GRAPH_DEFAULTS } from "./util/pixi-graph";
import { drawRectangle } from './util/drawShapes';
import * as _ from 'lodash';

interface ICellCoords {
    xStart: number;
    yStart: number;
    xEnd: number;
    yEnd: number;
}
export class GridPaint extends CustomInteractionCtrl {
    element: IContentElementGridPaint;
    container: PIXI.Container;
    grid: pixiGraph;
    paintContainer: PIXI.Container;
    spriteLoader: SpriteLoader;
    paintContainerData: Map<string, ICellCoords>;
    isResponded: boolean;
    isDragging: boolean;
    lastCell: string // stores last location when dragging to avoid flickering
    // Adjacent Coords ex: for AXIS: X, MAP = key => x axis edge, value => y axis coords
    adjacentCoords: Map<AXIS, Map<string, Set<number>>>


    constructor(element: IContentElementGridPaint, questionState: QuestionState, questionPubSub: QuestionPubSub, addGraphic, render, zoom, isLocked, removeGraphic, textToSpeech){
        super(questionState, questionPubSub, addGraphic, render, zoom, isLocked, textToSpeech);

        this.element = element;
        this._initializeDefault();
        this._initGraph();
        this.loadAssets().then(({resources}) => this._init())
    }

    private _init(){
        this.render();
    }

    private _getCoordsForStartEndPoints = (coord, axis:AXIS) => {
        let data = axis === AXIS.X ? this.grid.xAxisData : this.grid.yAxisData;
        return {
            coord,
            displayCoord: data.get(coord).displayCoord
        }
    }

    private _initGraph(){
        const {canvasHeight} = this.element;
        let config = { ...PIXI_GRAPH_DEFAULTS, render: this.render, canvasHeight, lineColor: this.getColor(), isHiContrast: this.isHCMode() };
        for (let conf of Object.keys(config)){
            if(conf in this.element){
                config[conf] = this.element[conf];
            }
        }
        if (this.isHCMode()) {
            config["backgroundColor"]="#000000"
        }
        this.grid = new pixiGraph(<IGraphConfig>config);
        this.grid.interactive = true;
        this.grid.zIndex = 2;
        this.registerMouseEvents(this.grid);
        this.container.addChild(this.grid);
        
        this.paintContainer = new PIXI.Container();
        this.grid.addChild(this.paintContainer);
    }
    
    private _initializeDefault() {
        let defaults = {
            ...PIXI_GRAPH_DEFAULTS,
            xStartDefault: 1,
            yStartDefault: 1,
            xEndDefault: 2,
            yEndDefault: 2,
            defaultFillColor: '#00ff00',
            defaultFillColorHC: '#00ff00',
            defaultFillColorAlpha: 0.5
        }
        
        intializeDefault(defaults, this.element);
        this.paintContainerData = new Map();
        this.spriteLoader = new SpriteLoader();
        this.container = new PIXI.Container();
        this.container.zIndex = 2;
        if(this.element.isAdjacentSelection){
            this.adjacentCoords = new Map();
            this.adjacentCoords.set(AXIS.X, new Map())
            this.adjacentCoords.set(AXIS.Y, new Map())
        } 
        this.addGraphic(this.container);
    }

    getUpdatedState() :Partial<IEntryStateGridPaint> {
        const weight = getElementWeight(this.element);
        // const {allCorrect, totalCorrect} = this._isCorrect();
        return {
            type: ElementType.CUSTOM_INTERACTION,
            subtype: CustomInteractionType.GRID_SCATTER_PLOT,
            paintContainerData: mapToObject(this.paintContainerData),
            isStarted: this.paintContainerData !== undefined,
            isFilled: this.paintContainerData.size > 0,
            isCorrect: this._isCorrect(), //allCorrect, 
            isResponded: !!this.isResponded,
            score: this._isCorrect() ? weight : 0,
            weight: weight,
            scoring_type: ScoringTypes.AUTO
        };
    }

    private _isCorrect(){
        return this.element.value === this.paintContainerData.size
    }

    handleNewState(): void {
        const qsValue: Map<string, ICellCoords> = objectToMap(this.questionState[this.element.entryId].paintContainerData);
        this.isResponded = this.questionState[this.element.entryId].isResponded;
        if(qsValue.size || this.isResponded) {
            this.paintContainerData = qsValue;
            qsValue.forEach((value, key) => {
                if(this.paintContainer){
                    const { xStart, xEnd, yStart, yEnd} = value
                    this._paintWithCoords(false, [xStart, xEnd, yStart, yEnd])
                }
            });
        } else {
            if(this.grid  && this.paintContainer) this._paintWithCoords(true)
        }
        
    }

    loadAssets(): Promise<PIXI.Loader> {
        this.spriteLoader.addSpritestoLoader([]);
        return this.spriteLoader.loadSprites();
    }
    
    registerMouseEvents(obj) {
        obj.on("mousedown", $event => this.activateMouseDown($event, obj));
        obj.on("mouseup", $event => this.deactivateMouseDown($event));
        obj.on("mousemove", $event => this.changeLocation($event, obj));
        obj.on("mouseupoutside", $event => this.deactivateMouseDown($event));
        obj.on("touchstart", $event => this.activateMouseDown($event, obj));
        obj.on("touchend", $event => this.deactivateMouseDown($event));
        obj.on("touchmove", $event => this.changeLocation($event, obj));
        obj.on("touchendoutside", $event => this.deactivateMouseDown($event));
    }

    activateMouseDown(event, obj: any) {
        if(this.isLocked) return; 
        this._paint(event, true);
        this.isDragging = true;
    }

    deactivateMouseDown($event) {
        this.isDragging = false;
    }
    changeLocation($event, obj: any) {
        if(this.isDragging && !this.isLocked){
            this._paint($event);
        }
    }

    

    // private paint = _.throttle(($event) => {
    //     this._paint($event)
    // }, 0)

    private _paint = (event, isSelection?: boolean) => {
        const mousePos = event.data.getLocalPosition(this.container);
            
        const gridCell = this.getGridCell(mousePos);
        const { paintStartPoint, paintEndPoint } = this.getStartEndPoints(gridCell);

        // if(!this._validatePosition(x.displayCoord, y.displayCoord)) return;
        if(this.element.isAdjacentSelection){
            if(!this.checkAdjacentCoords(paintStartPoint, paintEndPoint)) return;
        }
    
        this.isResponded = true;
        let extractedPoints = this._getPaintCoords(paintStartPoint, paintEndPoint);
        let name = this._getName(extractedPoints);
        if(this.lastCell === name && !isSelection) return;
        if(this.paintContainerData.has(name)){
            this._removeGraphics(extractedPoints);
        } else {
            this._addGraphics(paintStartPoint, paintEndPoint);
        }
        this.lastCell = name

        this.updateState();
        
    }

    private _paintWithCoords = (defaultConfig: boolean = false, coords?: any[]) => {
        let xStart, xEnd, yStart, yEnd;
        if(defaultConfig) {
            xStart = this.element.xStartDefault;
            xEnd = this.element.xEndDefault;
            yStart = this.element.yStartDefault;
            yEnd = this.element.yEndDefault;
        } else {
            [xStart, xEnd, yStart, yEnd] = coords
        }

        let x1 = this._getCoordsForStartEndPoints(xStart, AXIS.X)
        let x2 = this._getCoordsForStartEndPoints(xEnd, AXIS.X)
        let y1 = this._getCoordsForStartEndPoints(yStart, AXIS.Y)
        let y2 = this._getCoordsForStartEndPoints(yEnd, AXIS.Y)
        const gridCell = {x1, x2, y1, y2};
        const { paintStartPoint, paintEndPoint} = this.getStartEndPoints(gridCell)
        this._addGraphics(paintStartPoint, paintEndPoint)
    }

    private _addGraphics(paintStartPoint, paintEndPoint){
        let {defaultFillColor, defaultFillColorHC, defaultFillColorAlpha } = this.element

        
        let paint = drawRectangle(paintStartPoint.x.displayCoord, paintStartPoint.y.displayCoord, this.grid.gridW, this.grid.gridH,{
            color: this.isHCMode() ? defaultFillColorHC : defaultFillColor,
            alpha: defaultFillColorAlpha
        })
        
        let points = this._getPaintCoords(paintStartPoint, paintEndPoint);
        paint.name = this._getName(points);
        this.paintContainer.addChild(paint);
        
        this.paintContainerData.set(paint.name, points);
        // Check if current coord is adjacent of any previous selections:
        if(this.element.isAdjacentSelection) this._setAdjacentCoords(paintStartPoint, paintEndPoint);
        this.render();
    }

    private _removeGraphics = (extractedPoints) => {
        let paint = this.paintContainer.getChildByName(this._getName(extractedPoints)); 
        this.paintContainer.removeChild(paint);
        this.paintContainerData.delete(this._getName(extractedPoints));
        this.render();
    }

    private _getEdgeAndCoords = (axis:AXIS, paintStartPoint, paintEndPoint) => {
        let edge , coords;
        switch(axis){
            case AXIS.X: 
                edge = `${paintStartPoint.x.coord}${paintEndPoint.x.coord}`
                coords = [paintStartPoint.y.coord , paintEndPoint.y.coord];
                return {edge, coords}
            case AXIS.Y:
                edge = `${paintStartPoint.y.coord}${paintEndPoint.y.coord}`
                coords = [paintStartPoint.x.coord , paintEndPoint.x.coord];
                return {edge, coords}
        }
    }

    private _setAdjacentCoords (paintStartPoint, paintEndPoint) {

        const updateEdgeCoords = (axis:AXIS): Map<string, Set<number>> => {
            const { edge, coords } = this._getEdgeAndCoords(axis, paintStartPoint, paintEndPoint);
            let edgeCoords = this.adjacentCoords.get(axis);
            
            if(edgeCoords.has(edge)){
                coords.forEach(edgeCoords.get(edge).add, edgeCoords.get(edge));
            } else {
                edgeCoords.set(edge, new Set(coords));
            }

            return edgeCoords
        }

        this.adjacentCoords.set(AXIS.X, updateEdgeCoords(AXIS.X));
        this.adjacentCoords.set(AXIS.Y, updateEdgeCoords(AXIS.Y));
        // console.log("adjacent",this.adjacentCoords)
    }

    private checkAdjacentCoords = (paintStartPoint, paintEndPoint) => {

        // Needs refactoring
        const {edge: xEdge, coords: yCoords} = this._getEdgeAndCoords(AXIS.X, paintStartPoint, paintEndPoint)
        const {edge: yEdge, coords: xCoords} = this._getEdgeAndCoords(AXIS.Y, paintStartPoint, paintEndPoint)
        let xData = this.adjacentCoords.get(AXIS.X).get(xEdge);
        let yData = this.adjacentCoords.get(AXIS.Y).get(yEdge);
        let isAdjacentX, isAdjacentY
        if(xData) isAdjacentX = xData.has(yCoords[0]) || xData.has(yCoords[1]);  
        if(yData) isAdjacentY = yData.has(xCoords[0]) || yData.has(xCoords[1]);
    
        return !!isAdjacentX || !!isAdjacentY
        
    }

    getStartEndPoints = (gridCell) => {     
        // Starting point: max Y & min X coords
        // Ending point: min Y and Max X
        let paintStartPoint:any = {};
        let paintEndPoint:any = {}

        // For x:
        if(gridCell.x1.coord > gridCell.x2.coord){
            paintStartPoint['x'] = gridCell.x2; 
            paintEndPoint['x'] = gridCell.x1;
        } else {
            paintStartPoint['x'] = gridCell.x1; 
            paintEndPoint['x'] = gridCell.x2;
        }

        // For y:
        if(gridCell.y1.coord > gridCell.y2.coord){
            paintStartPoint['y'] = gridCell.y1;
            paintEndPoint['y'] = gridCell.y2;
        } else {
            paintStartPoint['y'] = gridCell.y2;
            paintEndPoint['y'] = gridCell.y1;
        }

        return { paintStartPoint, paintEndPoint }
    }

    private getGridCell = (mousePos) => {
        //get the grid 
        const { xAxisData } = this.grid;
        const { yAxisData } = this.grid;
        return this.grid.getGridCell(mousePos, {xAxisData, yAxisData})
    }

    private _validatePosition(x,y){
        let isInsideGrid = x >= this.grid.xStart && x <= this.grid.xEnd && y <= this.grid.yStart && y >= this.grid.yEnd;
        return isInsideGrid;
    }

    private _getPaintCoords = (paintStartPoint, paintEndPoint) => {
        return {
            xStart: paintStartPoint.x.coord, // start
            yStart: paintStartPoint.y.coord,
            xEnd: paintEndPoint.x.coord, //end
            yEnd: paintEndPoint.y.coord
        }
    }

    private _getName({xStart, yStart, xEnd, yEnd}){
        return `(${xStart},${yStart})-(${xEnd},${yEnd})`;
    }
    
}

