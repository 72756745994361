export enum SaFinalResultsViews {
    INDIVIDUAL_STUDENT_RESULTS_REPORT = 'individual_student_results',
    SCHOOL_LEVEL_DATA_REPORT = 'school_level_data_report',
    SELF_REFLECTION_REPORT = 'self_reflection_report',
    STUDENT_RESULTS = 'student_results',
    ASSESSMENT_KEYS = 'assessment_keys',
    PROVINCIAL_RESPONSE_TRACKER = 'provincial_resposne_tracker',
    DISCREPANCY_REPORT = 'discrepancy_report',
    MONITORING_REPORT  = 'monitoring_report',
}

export const SA_FINAL_RESULTS_VIEWS = [
    {
        id: SaFinalResultsViews.INDIVIDUAL_STUDENT_RESULTS_REPORT,
        caption: 'sa_fr_individual_srr_title',
        disabled: false,
    },
    {
        id: SaFinalResultsViews.SCHOOL_LEVEL_DATA_REPORT,
        caption: 'sc_sldr_title',
        disabled: false,
    },
    {
        id: SaFinalResultsViews.DISCREPANCY_REPORT,
        caption: 'Provincial Discrepancy Report',
        disabled: false,
    },
    {
        id: SaFinalResultsViews.MONITORING_REPORT,
        caption: 'District Monitoring Report',
        disabled: false,
    },
]

export const getSelectedViewDisplay = (view: SaFinalResultsViews): string => {
    if (view) {
        switch (view) {
            case SaFinalResultsViews.SCHOOL_LEVEL_DATA_REPORT:
                return 'School Level Data Report';
            case SaFinalResultsViews.INDIVIDUAL_STUDENT_RESULTS_REPORT:
                return 'Individual Student Results Report';
            case SaFinalResultsViews.SELF_REFLECTION_REPORT:
                return 'Self Reflections';
            case SaFinalResultsViews.STUDENT_RESULTS:
                return 'Student Analysis Codes and Suspense Errors';
            case SaFinalResultsViews.ASSESSMENT_KEYS:
                return 'Assessment Keys';
            case SaFinalResultsViews.PROVINCIAL_RESPONSE_TRACKER:
                return 'Provincial Response Tracker';
            case SaFinalResultsViews.DISCREPANCY_REPORT:
                return 'Provincial Discrepancy Report';
            case SaFinalResultsViews.MONITORING_REPORT:
                return 'Provincial Monitoring Report';
        }
    }

    return '...'
}
