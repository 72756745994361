import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { IContentElementText, TextParagraphStyle, QuestionState, TextParagraph, ElementType } from '../models';
import { Subject } from 'rxjs';
import { QuestionPubSub, IQPubSubPayload } from '../question-runner/pubsub/question-pubsub';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { TextToSpeechService } from '../text-to-speech.service';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PubSubTypes } from '../element-render-frame/pubsub/types';
@Component({
  selector: 'element-render-text',
  templateUrl: './element-render-text.component.html',
  styleUrls: ['./element-render-text.component.scss']
})
export class ElementRenderTextComponent implements OnInit {

  @Input() element:IContentElementText;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() allowTranscripts?: boolean = false;
  @ViewChild('textDiv', { static: false }) textDiv:ElementRef<HTMLElement>;


  constructor(
    private whitelabel: WhitelabelService,
    private text2Speech: TextToSpeechService,
    private route: ActivatedRoute
  ) { }

  thisText = '';
  observer: MutationObserver;
  showTranscript=true;
  textInput = new FormControl('');
  isAccommodation;
  entryIdList=new Map()
  showhowNumberInputOrMCQResponseFormat = ''


  ngOnInit() {
    this.isInitDynamicText()
    this.isInitTranscriptText();
    this.thisText = this.getElementCaption()
    this.startObservingKURZWEIL();
    this.isAccommodation =  this.route.snapshot.queryParamMap.get('isAccomm')
  }
  
  ngOnChange(){
    this.isInitDynamicText()
  }

  ngOnDestroy(){
    if (this.observer) this.observer.disconnect();
  }

  initElementSubState(){
    this.questionPubSub.allSub().subscribe(payload => {
      if (payload.entryId !== this.element.fromEntryId || !this.element.isShowDynamicText) return;
      const state = this.questionState[payload.entryId]
      switch (state.type) {
        case ElementType.MCQ:
          if (!state.selections.length) return ;
          const selection = state.selections[0]
          let selectionStr = selection.content
          if (selection.paragraphStyle == TextParagraphStyle.ADVANCED_INLINE && selection.advancedList) {
            selectionStr = ''
            selection.advancedList.forEach((el)=>{
              if (el.elementType==ElementType.TEXT) {
                selectionStr += el.caption
              }
            })
          }
          //this.element.caption = selection.toString();
          this.thisText = selectionStr
          return;
        default:
          return;
      }
    });
  }

  initElementSubState_NumberInputAndMCQ(){
    this.questionPubSub.allSub().subscribe(payload => {
      if (!this.entryIdList.has(payload.entryId) || !this.element.isShowNumberInputOrMCQResponse) return;
      const state = this.questionState[payload.entryId]
      switch (state.type) {
        case ElementType.MCQ:
          if (!state.selections.length){
            if (this.element.isShowNumberInputOrMCQResponse){
              this.updateNumberInputOrMQCResponseString(payload, '')
            }
            return ;
          } 
          const selection = state.selections[0]
          let selectionStr = selection.content
          if (selection.paragraphStyle == TextParagraphStyle.ADVANCED_INLINE && selection.advancedList) {
            selectionStr = ''
            selection.advancedList.forEach((el)=>{
              if (el.elementType==ElementType.TEXT) {
                selectionStr += el.caption
              }
            })
          }
          if (this.element.isShowNumberInputOrMCQResponse){
            this.updateNumberInputOrMQCResponseString(payload, selectionStr)
          }
          return;
        case ElementType.INPUT_NUMBER:
          if (this.element.isShowNumberInputOrMCQResponse){
            this.updateNumberInputOrMQCResponseString(payload, state.value)
          }
          return
        default:
          return;
      }
    });
  }

  initElementSub() {
    const elementSub = this.questionPubSub.initElementSub(this.element.entryId);
    elementSub.subscribe(payload => {
      if (payload.type in PubSubTypes){
        switch(payload.type){
          case PubSubTypes.TRANSCRIPT:     return this.initTranscriptBox(payload);
        }
      }
    });
  }


  initTranscriptBox (payload: IQPubSubPayload) {
    const { data } = payload;
    this.textInput.setValue(data)
  }

  ngDoCheck() {
    if(!this.element.isShowDynamicText && !this.element.isShowNumberInputOrMCQResponse) {
      this.thisText = this.getElementCaption()
    }
  }

  isInitDynamicText(){
    if(this.element.isShowDynamicText){
      this.element.caption = ''
      this.initElementSubState();
    }
    if(this.element.isShowNumberInputOrMCQResponse && this.element.showhowNumberInputOrMCQResponseFormat){
      this.getEntryIdPramsFromFormat()
      this.showhowNumberInputOrMCQResponseFormat = this.element.showhowNumberInputOrMCQResponseFormat
      this.initElementSubState_NumberInputAndMCQ();
    }
  }

  getEntryIdPramsFromFormat(){
    const regex = /{{\d+}}/g;
    const entryIdStrings = this.element.showhowNumberInputOrMCQResponseFormat.match(regex)
    entryIdStrings.forEach(entryIdString => {
      this.entryIdList.set(Number(entryIdString.replace('{{', '').replace('}}', '')), '')
    })
  }

  updateNumberInputOrMQCResponseString(payload, value){
    if(!value || !payload.entryId){
      this.thisText = ''
      return;
    }
    this.entryIdList.set(payload.entryId, value)
    let payloadIdString = '{{' + payload.entryId + '}}'
    const regex = /{{\d+}}/g;
    if(!Array.from(this.entryIdList.values()).includes('')){
      let resultStr = this.element.showhowNumberInputOrMCQResponseFormat
      this.entryIdList.forEach((value, key)=>{
        let idString = '{{' + key + '}}'
        resultStr = resultStr.replace(idString, value)
      })
      this.thisText = resultStr
    }
  }

  isInitTranscriptText() {
    if(this.element.isTranscript && this.isAccommadation()) {
      this.initElementSub();
    }
  }

  isParagraphs(){
    return this.element.paragraphStyle === TextParagraphStyle.PARAGRAPHS;
  }
  isHeading(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE;
  }
  isHeadingSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE_SMALL;
  }
  isBody(){
    return this.element.paragraphStyle === TextParagraphStyle.REGULAR;
  }
  isSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.SMALL;
  }
  isBulletList(){
    return this.element.paragraphStyle === TextParagraphStyle.BULLET;
  }
  isNumberedList(){
    return this.element.paragraphStyle === TextParagraphStyle.NUMBERED;
  }

  /* The white-space does not work with the 'text-content: justify' css prop
     in FireFox, so replace the newline character with the line break tag and 
     remove the white-space setting for FireFox
  */
  isFireFox(){
    let userAgent = navigator.userAgent;
    if(userAgent.match(/firefox|fxios/i))
      return true;
    return false;
  }
  getElementCaption(){
    if(this.isFireFox()){
      return this.element.caption.replace(/\n/g, '<br>')
    } else {
      return this.element.caption
    }
  }

  isSimpleText(){
    if (this.element.paragraphStyle === TextParagraphStyle.HEADLINE){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.HEADLINE_SMALL){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.REGULAR){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.SMALL){ return true }
    return false;
  }

  isLink(){
    return this.element.paragraphStyle === TextParagraphStyle.LINK;
  }

  isListText(){
    if (this.element.paragraphStyle === TextParagraphStyle.BULLET){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.NUMBERED){ return true }
    return false;
  }

  isAdvancedInlineText(){
    if (this.element.paragraphStyle === TextParagraphStyle.ADVANCED_INLINE){ return true }
    return false;
  }

  isAnnotation() {
    return this.element.paragraphStyle === TextParagraphStyle.ANNOTATION;
  }

  getFontFamily() {
    if (!this.whitelabel.getSiteFlag('IS_EQAO')){
      return this.element.font;
    }
    return 'inherit';
  }

  

  getRotation() {
    if (this.element.rotation) return "rotate("+this.element.rotation+"deg)";
    return '';
  }

  getFontSize(){
    if (this.element.fontSize){
      return `${this.element.fontSize}em`;
    }
    return 'inherit';
  }

  getLineHeight() {
    if (this.element.lineHeight){
      return `${this.element.lineHeight}em`;
    }
    return '1.3em';
  }

  renderParaNum(index:number, paragraph:TextParagraph){
    if (paragraph.numberingOverride){
      return paragraph.numberingOverride;
    }
    let start = this.element.paragraphNumberingStart || 1;
    return (+start) + index;
  }

  renderParaNumPadding(){
    return (1-(this.element.paragraphNumberingScale || 1)) / 4;
  }

  getTextStyle() {
    if (this.element.colour === '#000000' ) this.element.colour = undefined;

    // console.log(this.textDiv.nativeElement?.style.color)
    let colour = this.element.colour

    if (!this.element.colour){
      // set a default color of black if is not in HC, this is mainly for iPad where the default color is blue
      if (!this.text2Speech.isHiContrast) colour = '#000000';

      // For HC mode, we want to preserve the HC color override from parent divs where the color is set to white.
      // but we want to make sure the default color stays black if the text is does not have color set from an 
      // ancestor class
      else if (this.textDiv && this.textDiv.nativeElement && window.getComputedStyle(this.textDiv?.nativeElement).color){
        // (0, 122, 255) is the blue color for default text in iPad
        if (window.getComputedStyle(this.textDiv?.nativeElement).color === 'rgb(0, 122, 255)') {
          colour = '#000000'
        }
      }
    }
    
    const style:any = {}
    style["font-family"]=this.getFontFamily();
    style["text-align"]=this.element.alignment;
    style["font-size"]=this.getFontSize();
    style["transform"]=this.getRotation();
    style["color"]= colour;
    //if (!this.isAdvancedInlineText()){
    style["line-height"]= this.getLineHeight()
    if (this.element.isNoInvertOnHiContrast && this.text2Speech.isHiContrast) {
      style["filter"]="invert(1)";
    }
    //}
    // style["user-select"]='none';
    return style;
  }

  getChildStyle() {
    const style: any = {}
    if (this.getTextStyle()["line-height"]) {
      style['line-height']='inherit'
    }
    return style;
  }

  minWidth = 10;
  getWidth() {
    if(this.isParagraphs()){
      return this.element.paragraphWidth
    }
    if (this.element.advancedInlineWidth && this.element.advancedInlineWidth >= this.minWidth) {
      return this.element.advancedInlineWidth;
    }
    return 'initial';
  }

  parseTextFormat(text:string) {
    if (!text) {
      text = ''
    }
    let regEx = /{{([0-9]+)\.(.+)}}/
    let match = text.match(regEx);
    if (match) {
      const id = match[1];
      const field = match[2];
      if (id && this.questionState[id] && this.questionState[id][field]!=undefined) {
        const replaceWith = this.questionState[id][field]
        const newText = text.replace(regEx, replaceWith)
        return newText;
      }
    }
    if(this.isParagraphs()){
      text = text.replace(/\n/g, '<br>')
    }
    return text;
  }

  // For KURZWEIL 3000 screen reader: this reader does not change
  // the text color when it is reading a sentence and highlighting
  // it, since we're setting the invisible text to be transparent,
  // we need to manually set the color to black for the hi-lighted 
  // sentence. 
  startObservingKURZWEIL(){
    this.observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
          for (var i = 0; i < mutation.addedNodes.length; i++) {
              let a = mutation.addedNodes[i] as HTMLElement
              if (a.className == 'rtw-highlight-sentence'){         // the "rtw-highlight-sentence" class is used by KURZWEIL 3000 to highlight the text it's currently reading (through css prop)
                a.style.color = 'black'                             // supply the color prop for the highlighted text so they are not inheriting the transparent color from invisible text.
              }
          }
      });
    });
    this.observer.observe(document, { childList: true, subtree: true });
  }

  getTranscriptBoxFixedHeight() {
    return this.element.isFixedHeight && this.element.fixedHeight ? this.element.fixedHeight : ''
  }
  isAccommadation(){
    return this.route.snapshot.queryParamMap.get('isAccomm')
  }
}