import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {
  @Input() scale = 1;
  @Input() color = '#4e8ff1';
  constructor() { }

  ngOnInit(): void {
  }

}
