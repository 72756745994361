<fieldset [disabled]="isReadOnly()">
    <div>
        <button class="button" [class.is-info]="element.isDragBetweenWords" (click)="element.isDragBetweenWords = !element.isDragBetweenWords; updateChangeCounter()">
            <tra slug="auth_invisible_target"></tra> ({{ element.isDragBetweenWords ? lang.tra('auth_on') : lang.tra('auth_off') }})
        </button>
    </div>
</fieldset>
<br>
<div class="element-header">
    <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
    <tra slug="auth_draggable_elements"></tra>
</div>
<br>
    <fieldset [disabled]="isReadOnly()">
        <label style="display:flex;align-items:center;" *ngIf="!element.isDragBetweenWords">
            <input type="checkbox" [(ngModel)]="this.element.isRepeatableOptions" />
            &nbsp;<tra slug="auth_draggable_options"></tra>
        </label>
    </fieldset>
    <br>
    <div 
        cdkDropList
        (cdkDropListDropped)="drop(element.draggables, $event);"
    >
    <div 
        cdkDrag 
        *ngFor="let draggableEl of element.draggables"
    >
        <div class="draggable-row">
            <a 
                cdkDragHandle 
                class="button is-handle" 
                [class.is-info]="isSelectedPosRef(draggableEl)"
                [class.is-disabled]="isReadOnly()"
                (mousedown)="selectPosElByRef(draggableEl)"
            >
                <i class="fas fa-arrows-alt" aria-hidden="true"></i>
            </a>
            <div>
                <capture-dnd-style 
                    [element]="draggableEl" 
                    (change)="throttledUpdate()"
                    (idClick)="selectPosElByRef(draggableEl)"
                    isHidePos="true"
                ></capture-dnd-style>
                <element-config [contentElement]="draggableEl.element"></element-config>
            </div>
            
            <a class="button is-expanded" [class.is-disabled]="isReadOnly()" (click)="removeElement(element.draggables, draggableEl)">
                <i class="fas fa-trash" aria-hidden="true"></i>
            </a>
        </div>
        <element-config-mcq-option-info *ngIf="this.element.isShowAdvancedOptions" [mcqOption]="draggableEl"></element-config-mcq-option-info>        
    </div>
    
    <fieldset [disabled]="isReadOnly()">
        <button class="is-primary button" (click)="insertDraggableElement()">
            <tra slug="auth_add_bloc"></tra>
        </button>
    </fieldset>
</div>
<hr/>
<div class="element-header">
    <i class="fa fa-cross-hairs" aria-hidden="true"></i>
    <tra slug="auth_target_elements"></tra>
</div>
<div 
      cdkDropList 
      [cdkDropListDisabled]="isReadOnly()"
      (cdkDropListDropped)="drop(element.textBlocks, $event);"
    >
    <div 
    cdkDrag 
    *ngFor="let draggableEl of element.textBlocks"
    >
        <div class="draggable-row" >
            <a 
                cdkDragHandle 
                class="button is-handle" 
                [class.is-disabled]="isReadOnly()"
                [class.is-info]="isSelectedPosRef(draggableEl)"
                (mousedown)="selectPosElByRef(draggableEl)"
            >
                <i class="fas fa-arrows-alt" aria-hidden="true"></i>
            </a>
            <div class="row-content">
                <capture-dnd-style 
                    *ngIf="draggableEl.element.elementType=='text'"
                    [element]="draggableEl" 
                    (change)="throttledUpdate()"
                    (idClick)="selectPosElByRef(draggableEl)"
                    isHidePos="true"
                    isHideId="true"
                ></capture-dnd-style>
                <capture-dnd-style 
                    *ngIf="draggableEl.element.elementType==''"
                    [element]="draggableEl" 
                    (change)="throttledUpdate()"
                    (idClick)="selectPosElByRef(draggableEl)"
                    isHidePos="true"
                ></capture-dnd-style>
                <element-config-text *ngIf="draggableEl.element.elementType == 'text'" [element]="draggableEl.element" (simpleTextChange)="onInnerTextChange()"></element-config-text>
                <div *ngIf="draggableEl.element.elementType == ''">
                    <element-config [contentElement]="draggableEl.element"></element-config>
                    <label class="element-header">Blank</label>
                </div>
            </div>
            <a [class.is-disabled]="isReadOnly()" class="button is-expanded" (click)="removeElement(element.textBlocks, draggableEl)">
                <i class="fas fa-trash" aria-hidden="true"></i>
            </a>
        </div>
        <!--<element-config-text-info *ngIf="this.element.isShowAdvancedOptions" [textElement]="draggableEl.element"></element-config-text-info>-->        
    </div>
</div>
<!-- <button class="is-primary button" (click)="insertTextBlock()">
    Add Text Block
</button>
<button class="is-primary button" (click)="insertBlank()">
    Add Blank
</button>
<br>
<br>
<div *ngIf="!this.element.isDragBetweenWords">
    <label>
        <input type="number" min="1" [style.width]="'4em'" [(ngModel)]="this.element.blankTargetSize"/>
        Set minimum width of blanks
    </label>
</div>
<div *ngIf="this.element.isShowAdvancedOptions">
    <label class="checkbox">
        <input type="checkbox" [(ngModel)]="this.element.enableProportionalScoring">
            Enable Proportional Scoring
    </label> <br>
    <label *ngIf="this.element.enableProportionalScoring" type="checkbox">Score Weight <input  type="number" [(ngModel)]="this.element.scoreWeight" min="1" [style.width.em]="4"></label>
</div>
<br>
<div>
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="this.element.isShowInstructions">
        Show Instructions
    </label>
</div>
<div>
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="this.element.isTargetsAbove">
        Targets placed above 
    </label>
</div> -->
<fieldset [disabled]="isReadOnly()">
    <button class="is-primary button" (click)="insertTextBlock()">
        <tra slug="auth_add_text_block"></tra>
    </button>
    <button class="is-primary button" (click)="insertBlank()">
        <tra slug="auth_add_blank"></tra>
    </button>
    <br>
    <br>
    <div *ngIf="!this.element.isDragBetweenWords">
        <label>
            <input type="number" min="1" [style.width]="'4em'" [(ngModel)]="this.element.blankTargetSize"/>
            <tra slug="auth_midth_blanks"></tra>
        </label>
    </div>
    <div *ngIf="this.element.isShowAdvancedOptions">
        <label class="checkbox">
            <input type="checkbox" [(ngModel)]="this.element.enableProportionalScoring">
                <tra slug="auth_proportional_scoring_nqm"></tra>
        </label> <br>
        <label *ngIf="this.element.enableProportionalScoring" type="checkbox"><tra slug="auth_score_weight"></tra> <input  type="number" [(ngModel)]="this.element.scoreWeight" min="1" [style.width.em]="4"></label> <br>
        <label class="checkbox">
            <input type="checkbox" [(ngModel)]="this.element.isDragContainerFixedWidth">
                <tra slug="auth_set_w_drag_cont"></tra>
        </label> <br>
        <label *ngIf="this.element.isDragContainerFixedWidth" type="checkbox"><tra slug="auth_weight"></tra> <input  type="number" [(ngModel)]="this.element.widthOfDraggables" min="1" [style.width.em]="4"></label>
    </div>
    <br>
    <div>
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="this.element.isShowInstructions">
            <tra slug="auth_show_instructions"></tra>
        </label>
    </div>
    <div>
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="this.element.isTargetsAbove">
            <tra slug="auth_target_above"></tra> 
        </label>
    </div>
</fieldset>
<div>
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="this.element.isShowAdvancedOptions">
        <tra slug="auth_show_advanced_options"></tra>
    </label>
</div>