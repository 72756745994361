<audio id="CallAudio"></audio>
<!--TEMPRARILY NOT ALLOWING DRAG FOR FIELD TEST-->
<div cdkDrag [cdkDragDisabled]="isUsingInput " [cdkDragFreeDragPosition]="draggedPosition" (cdkDragEnded)="settingDraggedPosition($event)" class="chat-widget-container" (click)="isDragging = false"(cdkDragStarted)="dragStart($event)" cdkDragBoundary="body">
    <!-- <div cdkDragHandle style="position:absolute; top:0px; left:0px; right:0px; bottom:0px;"></div> -->
    <!-- <div class="chat-widget-container"> -->
    <!-- List of Channels -->
    <div *ngIf="isOpen && isLoaded && !openChat" class="chat-view">
        <div cdkDragHandle class="chat-header">
            <i (click)="$event.stopPropagation(); toggleOpen()" class="fas fa-window-minimize close-button"></i>
            <div class="chat-window-title"><tra slug="mrkg_chat"></tra></div>
            <div class="field search-bar-container">
                <p class="control has-icons-left">
                    <input (input)="searchChange()" [formControl]="searchField" class="input is-fullwidth search-icon" type="text" (focus)="isUsingInput = true"
                    (focusout)="isUsingInput = false" [placeholder]="getChatPlaceholder()">
                    <span class="icon is-small is-left">
                        <i class="fa fa-search"></i>
                    </span>
                </p>
            </div>
            <button (click)="newGroup()" class="new-chat" *ngIf="accountType != 'mrkg_mrkr'"><i class="fas fa-pencil-alt"></i></button>
            <!-- <i (click)="$event.stopPropagation(); toggleOpen()" class="fa fa-angle-down fa-2x close-button"></i> -->
            <!-- <i (click)="toggleSupport()" class="fa fa-question-circle fa-2x help-button"></i> -->
        </div>

        

        <div class="error-screen" *ngIf="chat.errorLoading">
            <i (click)="chat.initSocket()" id="retryIcon" class="fa fa-refresh fa-4x" aria-hidden="true"></i>
            <p><tra slug="mrkg_cht_error"></tra></p>
            <p (click)="chat.initSocket()" id="tryAgain"><tra slug="mrkg_cht_try_again"></tra></p>
        </div>
        <div *ngIf="!chat.errorLoading" class="chat-body">
            <div class="chat-row-header">
                <p><tra slug="mrkg_cht_recent"></tra></p>
            </div>
            <ng-container *ngIf="chat.isLoadingRecent">
                <loading-indicator></loading-indicator>
            </ng-container>
            <ng-container *ngIf="!chat.isLoadingRecent && (!searchField.value || (searchField.value && searchField.value.length == 0))">
                <div (click)="selectRow(row)" *ngFor="let row of getRecent()" class="chat-row recent">

                    <ng-container *ngIf="row.chatType == 'PRIVATE'">
                        <img [ngClass]="{'active':row.active}" [src]="row.imgURL ? row.imgURL : 'assets/profile-default.jpg'">
                    </ng-container>
                    <ng-container *ngIf="row.chatType == 'GROUP'">
                        <i style="color:white;" class="fas fa-user-friends"></i>
                    </ng-container>    
                 
                    <p class="row-title"><span class="row-main">{{ getName(row) }}</span> <span [class]="'row-sub ' + getSubtitle(row)">{{getSubtitleDisplay(row)}}</span></p>
                    <span class="active-call-indicator" *ngIf="row.chatId == callService.activeCallId && callService.callState != CallState.INACTIVE"><i class="fas fa-phone-volume"></i></span>
                    <div *ngIf="!!row.unreadCount" class="notification-circle push-right">{{ row.unreadCount }}</div>
                </div>
                <div *ngIf="chat.recent.length > SECTION_SOFT_MAX" class="chat-row show-more" (click)="toggleShowMoreRecent()">
                    <span *ngIf="!showMoreRecent"><tra-md slug="mrkg_cht_show_more" [props]="{amount: chat.recent.length - SECTION_SOFT_MAX}"></tra-md></span>
                    <span *ngIf="showMoreRecent"><tra slug="mrkg_cht_show_less"></tra></span>
                </div>
            </ng-container>
            <ng-container *ngIf="!chat.isLoadingRecent && searchField.value && searchField.value.length > 0">
                <div (click)="selectRow(row)" *ngFor="let row of searchRecent" class="chat-row recent">
                    <img [ngClass]="{'active':row.active}" [src]="row.imgURL ? row.imgURL : 'assets/profile-default.jpg'">
                    <p class="row-title"><span class="row-main">{{ getName(row) }}</span> <span [class]="'row-sub ' + getSubtitle(row)">{{getSubtitleDisplay(row)}}</span></p>
                    <span class="active-call-indicator" *ngIf="row.chatId == callService.activeCallId && callService.callState != CallState.INACTIVE"><i class="fas fa-phone-volume"></i><tra slug="mrkg_cht_active_call"></tra></span>
                    <div *ngIf="!!row.unreadCount" class="notification-circle push-right">{{ row.unreadCount }}</div>
                </div>
            </ng-container>
            

            <div *ngIf="chat.userType != 'mrkg_mrkr'" class="chat-row-header">
                <p><tra slug="mrkg_cht_broadcast"></tra></p>
            </div>
            <ng-container *ngIf="chat.isLoadingContacts && chat.userType != 'mrkg_mrkr'">
                <loading-indicator></loading-indicator>
            </ng-container>
            <ng-container *ngIf="!chat.isLoadingContacts && chat.userType != 'mrkg_mrkr' && (!searchField.value || (searchField.value && searchField.value.length == 0))">
                <div (click)="selectRow(group)" *ngFor="let group of getVisibleBroadcasts()" class="chat-row sessions">
                    <i class="fas fa-bullhorn broadcast-icon" [class.active]="!!group.activeCount"></i>
                    <p class="row-title"><span class="row-main">{{ getName(group) }}</span></p>
                    <div *ngIf="!!group.unreadCount" class="notification-circle push-right">{{ group.unreadCount }}</div>
                </div>
                <div *ngIf="chat.broadcasts.length > SECTION_SOFT_MAX" class="chat-row show-more" (click)="toggleShowMoreBroadcasts()">
                    <span *ngIf="!showMoreBroadcasts"><tra-md slug="mrkg_cht_show_more" [props]="{amount: chat.broadcasts.length - SECTION_SOFT_MAX}"></tra-md></span>
                    <span *ngIf="showMoreBroadcasts"><tra slug="mrkg_cht_show_less"></tra></span>
                </div>
            </ng-container>
            <ng-container *ngIf="!chat.isLoadingContacts && searchField.value && searchField.value.length > 0">
                <div (click)="selectRow(group)" *ngFor="let group of searchBroadcasts" class="chat-row sessions">
                    <i class="fas fa-bullhorn broadcast-icon" [class.active]="!!group.activeCount"></i>
                    <p class="row-title"><span class="row-main">{{ getName(group) }}</span></p>
                    <div *ngIf="!!group.unreadCount" class="notification-circle push-right">{{ group.unreadCount }}</div>
                </div>
            </ng-container>
            <!-- <ng-container *ngIf="!chat.isLoadingContacts && chat.userType != 'mrkg_mrkr' && searchField.value && searchField.value.length > 0">
                <div (click)="selectRow(group)" *ngFor="let group of searchGroups" class="chat-row sessions">
                    <i class="fas fa-users broadcast-icon" [class.active]="!!group.activeCount"></i>
                    <p class="row-title">
                        <span class="row-main">{{ getName(group) }}</span> 
                        <span [class]="'row-sub ' + getSubtitle(group)">{{getSubtitle(group)}}</span>
                    </p>
                    <div *ngIf="!!group.unreadCount" class="notification-circle push-right">{{ group.unreadCount }}</div>
                </div>
            </ng-container> -->
           

            <div class="chat-row-header">
                <p><tra slug="mrkg_cht_contacts"></tra></p>
            </div>
            <ng-container *ngIf="chat.isLoadingContacts">
                <loading-indicator></loading-indicator>
            </ng-container>
            <ng-container *ngIf="!chat.isLoadingContacts && (!searchField.value || (searchField.value && searchField.value.length == 0))">
                <div (click)="selectRow(row)" *ngFor="let row of getVisiblePeople()" class="chat-row people">
                    <img [ngClass]="{'active':row.active}" [src]="row.imgURL ? row.imgURL : 'assets/profile-default.jpg'">
                    <p class="row-title"><span class="row-main">{{ getName(row) }}</span> <span [class]="'row-sub ' + getSubtitle(row)">{{getSubtitleDisplay(row)}}</span></p>
                    <!-- <div *ngIf="!!row.unreadCount" class="notification-circle push-right">{{ row.unreadCount }}</div> -->
                </div>
                <div *ngIf="chat.contacts.length > SECTION_SOFT_MAX" class="chat-row show-more" (click)="toggleShowMorePeople()">
                    <span *ngIf="!showMorePeople"><tra-md slug="mrkg_cht_show_more" [props]="{amount: chat.contacts.length - SECTION_SOFT_MAX}"></tra-md></span>
                    <span *ngIf="showMorePeople"><tra slug="mrkg_cht_show_less"></tra></span>
                </div>
            </ng-container>
            <ng-container *ngIf="!chat.isLoadingContacts && searchField.value && searchField.value.length > 0">
                <div (click)="selectRow(row)" *ngFor="let row of searchContacts" class="chat-row people">
                    <img [ngClass]="{'active':row.active}" [src]="row.imgURL ? row.imgURL : 'assets/profile-default.jpg'">
                    <p class="row-title"><span class="row-main">{{ getName(row) }}</span> <span [class]="'row-sub ' + getSubtitle(row)">{{getSubtitleDisplay(row)}}</span></p>
                    <!-- <div *ngIf="!!row.unreadCount" class="notification-circle push-right">{{ row.unreadCount }}</div> -->
                </div>
            </ng-container>

        </div>

    </div>

    <!-- Conversation view -->
    <div *ngIf="isOpen && isLoaded && openChat" class="chat-messages-view">
        <div cdkDragHandle class="chat-messages-header">
            <div>
                <div class="messages-header-title">
                   
                    <i (click)="closeRow()" class="fa fa-angle-left fa-2x back-button" style="margin-right:0.5em;"></i>
                    <div *ngIf="chat.getTotalUnread() > 0" class="notification-circle closed-notification beside-back-button">
                        {{ chat.getTotalUnread() }}
                    </div>
                    <ng-container *ngIf="openChat.chatType == 'BROADCAST'">
                        <!-- <i class="fas fa-bullhorn"></i> -->
                    </ng-container>
                    <ng-container *ngIf="openChat.chatType == 'PRIVATE' || openChat.chatType == 'GROUP'">
                        <ng-container *ngIf="!openChat.participants || openChat.participants.length == 2">
                            <img [ngClass]="{'active':openChat.active}" [src]="openChat.imgURL ? openChat.imgURL : 'assets/profile-default.jpg'">
                        </ng-container>
                        <ng-container *ngIf="openChat.participants?.length > 2">
                            <div class="pic-array">
                                <div *ngFor="let p of participantPhotos(openChat)" class="img-container"> <img [src]="p ? p : 'assets/profile-default.jpg'"></div>
                            </div>
                        </ng-container>
                    </ng-container>
                   
                    <div class="name">
                        <div style="line-height: 15px;margin-top: 4px;width: 191px;">
                            <span>{{ getName(openChat) }}</span>
                            <input #AddUsersToChat class="add-users-to-chat" [placeholder]="getSearchNamePlaceholder()" *ngIf="openChat.chatType == 'GROUP' && openChat.chat.length == 0" [(ngModel)]="addUsersSearchQuery" (ngModelChange)="addUsersSearchQueryChanged()">
                        </div>
                        <div *ngIf="!openChat.participants" style="font-size: 0.8em;" class="sub">{{ getSubtitleDisplay(openChat) }}</div> 
                    </div>
                    
                </div>
            </div>
            <!-- Disabling until further notice -->
            <div *ngIf="false" class="chat-buttons">
                <button [disabled]="callService.callState != CallState.INACTIVE || (!openChat.active && !openChat.participants)" (click)="createCall()" id="call"><i class="fas fa-phone"></i></button>
                
            </div>
        </div>
        <div class="add-users-results-container" *ngIf="addUsersSearchQuery">
            <div *ngFor="let user of addUsersSearchResults" class="search-row" (click)="addUserToParticipants(user)">
                <img [src]="user.imgURL ? user.imgURL : 'assets/profile-default.jpg'"> {{user.firstName}} {{user.lastName}}
            </div>
        </div>
        <hr/>
        <div *ngIf="callService.activeCallId == openChat.chatId && callService.callState == CallState.CONNECTING" class="chat-main-container">
            <div class="connecting-container" >
                <div class="ball-scale-multiple">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <img src="../../../assets/profile-default.jpg" />
                <div class="status"><tra slug="mrkg_cht_connecting"></tra></div>
                <div class="name">{{getName(openChat)}}</div>
            </div>
            <form *ngIf="callService.viewChangeDevices" id="deviceSelectionForm">
                <p><tra slug="mrkg_cht_output_device"></tra></p>
                
                <select [(ngModel)]="deviceForm.audioOutputDevice"  name="audioOutputDevicesOther">
                    <ng-container *ngFor="let device of callService.audioOutputDevices; let i = index" >
                        <option [value]="callService.audioOutputDevices[i].deviceId" [attr.selected]="i==0 ? true : null">{{device.label}}</option>
                    </ng-container>  
                </select>

                <p><tra slug="mrkg_cht_input_device"></tra></p>
                
                <select [(ngModel)]="deviceForm.audioInputDevice"   name="audioInputDevicesOther">
                    <ng-container *ngFor="let device of callService.audioInputDevices; let i = index" >
                        <option [value]="callService.audioInputDevices[i].deviceId" [attr.selected]="i==0 ? true : null">{{device.label}}</option>
                    </ng-container>
                    
                </select>

                <button class="join" (click)="submitDeviceForm(deviceForm)"><tra slug="save_btn"></tra></button>
            </form>
            <div class="controls-container">
                <button (click)="toggleViewChangeDevices()" [disabled]="false" class="settings"><i class="fas fa-cog"></i></button>
                <button (click)="callService.endCall()" class="end"><i class="fas fa-phone"></i></button>  
                <button (disabled)="!this.callService.meetingSession" class="unmute" *ngIf="callService.isMuted()" (click)="callService.toggleMute()">
                    <i class="fas fa-microphone-slash"></i>
                </button>
                <button (disabled)="!this.callService.meetingSession" class="mute" *ngIf="!callService.isMuted()" (click)="callService.toggleMute()">
                    <i class="fas fa-microphone"></i>
                </button>
            </div>
            
        </div>

        <div *ngIf="callService.activeCallId == openChat.chatId && callService.callState == CallState.CONNECTED" class="chat-main-container">
            <form *ngIf="callService.viewChangeDevices" id="deviceSelectionForm">
                <p><tra slug="mrkg_cht_output_device"></tra></p>
                
                <select [(ngModel)]="deviceForm.audioOutputDevice"  name="audioOutputDevicesOther">
                    <ng-container *ngFor="let device of callService.audioOutputDevices; let i = index" >
                        <option [value]="callService.audioOutputDevices[i].deviceId" [attr.selected]="i==0 ? true : null">{{device.label}}</option>
                    </ng-container>  
                </select>

                <p><tra slug="mrkg_cht_input_device"></tra></p>
                
                <select [(ngModel)]="deviceForm.audioInputDevice"   name="audioInputDevicesOther">
                    <ng-container *ngFor="let device of callService.audioInputDevices; let i = index" >
                        <option [value]="callService.audioInputDevices[i].deviceId" [attr.selected]="i==0 ? true : null">{{device.label}}</option>
                    </ng-container>
                    
                </select>
                <button class="join" (click)="submitDeviceForm(deviceForm)"><tra slug="btn_save"></tra></button>
            </form>
            <div class="left" *ngIf="Object.keys(callService.presentAttendeeData).length == 1">
                <tra-md slug="mrkg_cht_has_left" [props]="{name: getName(openChat)}"></tra-md>
            </div>
            <div class="connected-container" >
                <ng-container *ngFor="let attendeeId of Object.keys(callService.presentAttendeeData); let i = index">
                    <div class="attendee" *ngIf="attendeeId != callService.me">
                        <div *ngIf="!callService.presentAttendeeData[attendeeId].muted" class="volume" [style.width.px]="125 + callService.presentAttendeeData[attendeeId].volume * 50" [style.height.px]="125 + callService.presentAttendeeData[attendeeId].volume * 50">
                        </div>
                        <img src="../../../assets/profile-default.jpg" />
                        <div class="status">{{callService.callTimeString}}</div>
                        <div class="name">{{getName(openChat)}}</div>
                    </div>
                </ng-container>
                
            </div>
            
            <div class="controls-container">
                <button (click)="toggleViewChangeDevices()" (disabled)="!this.callService.meetingSession" class="settings"><i class="fas fa-cog"></i></button>
                <button (click)="callService.endCall()" class="end"><i class="fas fa-phone"></i></button>
                <button (disabled)="!this.callService.meetingSession" class="unmute" *ngIf="callService.isMuted()" (click)="callService.toggleMute()">
                    <i class="fas fa-microphone-slash"></i>
                </button>
                <button (disabled)="!this.callService.meetingSession" class="mute" *ngIf="!callService.isMuted()" (click)="callService.toggleMute()">
                    <i class="fas fa-microphone"></i>
                </button>
            </div>      
        </div>
       
            


        <div *ngIf="callService.activeCallId != openChat.chatId || callService.callState == CallState.INACTIVE" class="chat-main-container" #chatHistory [style.overflow]="isEmojiPickerShowing ? 'hidden' : 'auto'">
            <button class="chat-with-pair" *ngIf="getSubtitle(openChat) =='Marker' && accountType != 'mrkg_mrkr'" (click)="chatWithPair(openChat.uid)"><tra slug="mrkg_cht_chat_pair"></tra></button>
            
            <div class="chat-messages">
                <span *ngIf="invalidGroup() && accountType != 'mrkg_mrkr'" class="group-chat-error"><tra slug="mrkg_chat_group_rule"></tra></span>
                <div *ngFor="let message of openChat.chat" class="chat-message-row">
                    <chat-message [name]="getFirstName(openChat, message)" [message]="message"></chat-message>
                    <button *ngIf="message.message.includes('meetingId')" (click)="joinCall(JSON.parse(message.message).meetingId)" class="join-meeting" [ngClass]="{'right':message.senderUid == getMyUid(), 'left':message.senderUid != getMyUid()}"><tra slug="mrkg_cht_join"></tra></button>
                </div>
            </div>
        </div>
        <div *ngIf="isEmojiPickerShowing" class="emoji-picker-container">
            <!--<chat-emoji-picker (insertHandler)="emojiHandler($event)"></chat-emoji-picker>-->
            <div class="emoji-container">
                <span (click)="pickEmoji('🙂')" class="smile">🙂</span>
                <span (click)="pickEmoji('🤔')" class="thinking">🤔</span>
                <span (click)="pickEmoji('😂')" class="laugh">😂</span>
                <span (click)="pickEmoji('😎')" class="cool">😎</span>
                <span (click)="pickEmoji('😮')" class="surprise">😮</span>
                <span (click)="pickEmoji('🙁')" class="sad">🙁</span>
                <span (click)="pickEmoji('👍')" class="thumbsUp">👍</span>
                <span (click)="pickEmoji('👎')" class="thumbsDown">👎</span>
            </div>
        </div>
        <div class="chat-lower-container" *ngIf="callService.activeCallId != openChat.chatId || callService.callState == CallState.INACTIVE">
            <div *ngIf="chatField.value?.length > (MAX_CHARACTERS - 100)" class="char-count">{{chatField.value?.length || 0}} / {{MAX_CHARACTERS}}</div>
            <div class="chat-send-message">
                <div (click)="toggleEmojis()" class="emoji-button">
                    <i class="fa fa-laugh fa-2x"></i>
                </div>
                <div class="message-input-container">
                    <div class="input-container">
                        <textarea 
                                  class="message-text-area" [placeholder]="getMessagePlaceholder()" (focus)="isUsingInput = true"
                                  (focusout)="isUsingInput = false"
                                  [formControl]="chatField" (keyup)="textAreaKeyUp($event)"
                                  (input)="inputHandler()" (change)="changeHandler()"
                        ></textarea><!-- (keyup.enter)="sendMessage()" -->
                    </div>
                </div>
                <div (click)="sendMessage()" class="send-button" [class.disable-send-button]="invalidGroup()">
                    <i class="fa fa-chevron-right"></i>
                </div>
            </div>
        </div>
    </div>

    <!-- Closed "Scoring Chat" button -->
    <div *ngIf="!isOpen" (click)="toggleOpen()" class="open-chat-button">
        <p><tra slug="mrkg_marking_chat"></tra></p>
        <div *ngIf="chat.getTotalUnread() > 0" class="notification-circle closed-notification">
            {{ chat.getTotalUnread() }}
        </div>
    </div>
</div>
