import { Injectable } from '@angular/core';
import {
  generateLeaderMarkingSessions, generateLeaderScoresOverview,
  getLeaderSessionItems, getS2LeaderSessionItems, getS3LeaderSessionItems, IItemScore,
  ILeaderSelectionItem,
  IMarkingSession, ItemType
} from './data/data';
import * as moment from 'moment-timezone';
import {AuthService} from '../api/auth.service';
import Sockette from 'sockette';
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import { WhitelabelService } from '../domain/whitelabel.service';
import { BehaviorSubject } from 'rxjs';
import { formatDate } from '@angular/common';
import { UrlLoaderService } from '../ui-testrunner/url-loader.service';
import { ThrowStmt } from '@angular/compiler';
import { LangService } from '../core/lang.service';
import { AssignmentService } from './assignment.service';
import { CommentDisplayComponent } from '../ui-item-maker/comment-display/comment-display.component';

export enum AssessmentView {
  ACTIVE = 'ACTIVE',
  PAST = 'PAST',
}
export enum DiscrepancyView {
  RECENT = 'RECENT',
  RESOLVED = 'RESOLVED',
}
export enum TaskView {
  UPCOMING = 'UPCOMING',
  PREVIOUS = 'PREVIOUS',
}

export enum SessionView {
  ITEMS = 'ITEMS',
  MARKERS = 'MARKERS',
  REPORTS = 'REPORTS',
}

@Injectable({
  providedIn: 'root'
})
export class LeaderService {
  ws: any;
  private readonly wsTrainingURI: string = 'wss://9hvbopupyk.execute-api.ca-central-1.amazonaws.com/production';
  private readonly wsESURI: string = 'wss://c3h10zjtfg.execute-api.ca-central-1.amazonaws.com/production';
  private readonly wsItemInspectionURI: string = 'wss://4akbgwqdhl.execute-api.ca-central-1.amazonaws.com/production';
  private activeMarkingSessions: IMarkingSession[] = [];
  private pastMarkingSessions: IMarkingSession[] = [];
  private leaderItems: ILeaderSelectionItem[] = [];
  private S2leaderItems: ILeaderSelectionItem[] = [];
  private S3leaderItems: ILeaderSelectionItem[] = [];

  connectedUsersControls: BehaviorSubject<any> = new BehaviorSubject([]);
  getTrainingControls: BehaviorSubject<any> = new BehaviorSubject({});
  newMarksSubmitted: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  gotItemInspectionConnectedUsers:BehaviorSubject<any> = new BehaviorSubject([]);
  gotItemInspectionLeaderAssignment:BehaviorSubject<any> = new BehaviorSubject({});
  
  gotExemplarSelectionConnectedUsers:BehaviorSubject<any> = new BehaviorSubject([]);
  gotExemplarSelectionLeaderAssignment:BehaviorSubject<any> = new BehaviorSubject({});

  requestControls:BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  // private refreshNamesInterval: any = null;

  private itemScores: IItemScore[] = [];

  realScores: any = [];
  connectedUsers: any = [];

  trainingControls: any;

  trainingItem:any;

  exemplarSelectionData = null;
  
  constructor(
      private auth: AuthService,
      private lang:LangService,
      private assignmentService:AssignmentService
  ) {
   }

  // tab persistence
  // dashboard
  selectedMarkingSessionView = AssessmentView.ACTIVE;
  selectedDiscrepancyView = DiscrepancyView.RECENT;
  selectedTaskView = TaskView.UPCOMING;

  // session overview
  selectedSessionView = SessionView.ITEMS;

  ngOnDestroy() {
    if(this.ws) {
      this.ws.close();
    }
    
  }

  connectToTraining(setId) {
      this.initTrainingSocket(setId);
  }

  async getMarkingSession(id) {
    if (id){
      return await this.auth.apiGet('public/mrkg-lead/assigned-marking-session', id);
    }
    else {
      console.error('Cannot `getMarkingSession`. Undefined `id`')
    }
  }

  exemplarSelectionStatus: {
    itemId:0,
    responseId:0
  }

  updateExemplarSelectionStatus(itemId, responseId) {
    this.exemplarSelectionStatus = {
      itemId,
      responseId
    }

    this.ws.json({action: "esConnect", data:{itemId, responseID:responseId, uid: this.auth.user().value.uid}});
  }

  connectToExemplarSelection(itemId, respnseId) {
      this.initExemplarSelectionSocket(itemId, respnseId);
      this.resetCommunicationTimeout();
  }


  initExemplarSelectionSocket(itemID, responseID) {
    this.ws = new Sockette(this.wsESURI, {
      timeout: 10e3,
      maxAttempts: 10,
      onopen: e => {
        this.onExemplarSelectionOpen(e, itemID, responseID);
      },
      onmessage: e => {
       this.onExemplarSelectionMessage(e);
      },
      onreconnect: e => console.log('Reconnecting...', e),
      onmaximum: e => console.log('Stop Attempting!', e),
      onclose: e => this.onExemplarSelectionClose(e),
      onerror: e => console.log('Error:', e)
    });
  }

  initTrainingSocket(setId) {
    this.ws = new Sockette(this.wsTrainingURI, {
      timeout: 10e3,
      maxAttempts: 10,
      onopen: e => {
        this.onTrainingOpen(e, setId);
      },
      onmessage: e => {
       this.onTrainingMessage(e);
      },
      onreconnect: e => console.log('Reconnecting...', e),
      onmaximum: e => console.log('Stop Attempting!', e),
      onclose: e => this.onTrainingClose(e),
      onerror: e => console.log('Error:', e)
    });
    this.pingInterval = setInterval(() => {
      this.ws.json({action: "ping", data:"Ping!"});
   }, 30000);

  }
  pingInterval;

  onExemplarSelectionOpen(e, itemID, responseID) {
    this.ws.json({action: "esConnect", data:{itemId:itemID, responseID:responseID, uid: this.auth.user().value.uid}});
  }

  onExemplarSelectionClose(e?) {
    this.ws.json({action: "disconnect"});
    this.ws = undefined;
    clearInterval(this.pingInterval);
    clearTimeout(this.communicationTimeout);
  }

  async getTrainingSetControls(marking_session_id, set_id) {
    return await this.auth.apiFind('/public/mrkg-lead/training-set-controls', {
      query: {
        marking_session_id,
        set_id
      }
    });
  }

  //DEPRICATED
  // async getTrainingItemControls(marking_session_id, item_id) {
  //   return await this.auth.apiFind('/public/mrkg-lead/training-set-controls', {
  //     query: {
  //       marking_session_id,
  //       item_id
  //     }
  //   });
  // }

  onTrainingOpen(e, setId) {
    this.ws.json({action: "trainingConnect", data:{userType: 'leader', setId:setId, uid: this.auth.user().value.uid}});
  }

  onTrainingClose(e?) {
    this.ws.json({action: "disconnect"});
    clearInterval(this.pingInterval);
  }

  async onTrainingMessage(e) {
    console.log(e);
    let data;
    try{
      data = JSON.parse(e.data);
    } catch(e){
      return;
    }
    if(data && data.newTrainingState) {
      this.getTrainingControls.next(data);
      return;
    }
    else if(data && data.newMarks) {
      //new mark data
      this.realScores = data;
      this.newMarksSubmitted.next(true);
    }
    else if(data.length == 0) {
      this.connectedUsers = data;
    }
    else if(data && data[0] && data[0].connectionId) {
      //online users data

      const uids = data.map(user => user.uid);
      const markers = await this.auth.apiFind('/public/mrkg-lead/user', {
        query: { uids: JSON.stringify(uids) }
      });
      
      markers.map(marker => {
        marker.name = marker.firstName + ' ' + marker.lastName[0];
      });

      this.connectedUsers = [...markers];
    

      this.connectedUsersControls.next(this.connectedUsers);

      //update the new users about our position
      this.requestControls.next(true);
      
    }

  }


  connectToItemInspection(itemID, responseID) {
    setTimeout(() => {
      this.initItemInspectionSocket(itemID, responseID);
    }, 300);
  }

  initItemInspectionSocket(itemID, responseID) {
    this.ws = new Sockette(this.wsItemInspectionURI, {
      timeout: 10e3,
      maxAttempts: 10,
      onopen: e => {
        this.onItemInspectionOpen(e, itemID, responseID);
      },
      onmessage: e => {
       this.onItemInspectionMessaage(e);
      },
      onreconnect: e => console.log('Reconnecting...', e),
      onmaximum: e => console.log('Stop Attempting!', e),
      onclose: e => this.onItemInspectionClose(e),
      onerror: e => console.log('Error:', e)
    });
    this.pingInterval = setInterval(() => {
      this.ws.json({action: "ping", data:"Ping!"});
   }, 30000);

  }

  updateItemInspectionLeaderAssignment(itemID, responseID, score) {
    this.ws.json({action:"itemInspectionUpdateLeaderAssignment", data:{uid:this.auth.getUid(), itemId:itemID, responseID, score}});
  }

  updateItemInspectionPosition(itemID, responseID) {
    this.ws.json({action: "itemInspectionConnect", data:{itemId:itemID, responseID:responseID, uid: this.auth.user().value.uid}});
  }

  onItemInspectionOpen(e, itemID, responseID) {
    this.ws.json({action: "itemInspectionConnect", data:{itemId:itemID, responseID:responseID, uid: this.auth.user().value.uid}});
  }

  onItemInspectionClose(e?) {
    this.ws.json({action: "disconnect"});
    this.ws = undefined;
    clearInterval(this.pingInterval);
    clearTimeout(this.communicationTimeout);
  }

  onItemInspectionMessaage(e) {
    let data;
    try{
      data = JSON.parse(e.data);
    } catch(e){
      return;
    }
    //this handles connects and positions
    if(data.connectedUsers) {
      this.gotItemInspectionConnectedUsers.next(data.connectedUsers);
      return;
    }
    else if(data.leaderAssignment) {
      this.gotItemInspectionLeaderAssignment.next(data.leaderAssignment);
    }

  }

  //NICK we need to send a eventType: "PING" connectedUsers: [] every 5 sec

  onExemplarSelectionMessage(e) {
    this.resetCommunicationTimeout();
    let data;
    try{
      data = JSON.parse(e.data);
    } catch(e){
      return;
    }
    //this handles connects and positions
    if(data.connectedUsers) {
      this.gotExemplarSelectionConnectedUsers.next(data.connectedUsers);
      return;
    }
    else if(data.leaderAssignment) {
      this.gotExemplarSelectionLeaderAssignment.next(data.leaderAssignment);
    }
  }

  communicationTimeout;

  resetCommunicationTimeout() {
    if(this.communicationTimeout) {
      clearTimeout(this.communicationTimeout);
    }
    this.communicationTimeout = setTimeout(this.didLoseCommunication, 10000); //comminication is considered lost if no message in 10 seconds.
  }

  didLoseCommunication = () => {
    //If we have not heard from the WS server in 65 seconds, reconnect.
    this.ws.json({action: "esConnect", data:{
      itemId:this.exemplarSelectionStatus.itemId, 
      responseID:this.exemplarSelectionStatus.responseId, 
      uid: this.auth.user().value.uid
    }});
    this.resetCommunicationTimeout();
  }

  updateExemplarSelectionLeaderAssignment(itemID, responseID, score) {
    this.ws.json({action:"esUpdateLeaderAssignment", data:{uid:this.auth.getUid(), itemId:itemID, responseID, score}});
  }

  updateExemplarSelectionPosition(itemID, responseID) {
    this.updateExemplarSelectionStatus(itemID, responseID);
  }


  async updateLeaderControls(leaderControls, markingSessionID, setID) {
    console.log(leaderControls);
    this.trainingControls = leaderControls;
    await this.updateTrainingSetControls(leaderControls, markingSessionID, setID);
    this.ws.json({action: "leaderControls", data:{setID:setID, leaderControls: leaderControls}});
  }

  async toggleShowAssignedMark(resp, flag?) {
    if (flag != undefined) {
      return await this.auth.apiPatch('public/mrkg-mrkr/training-response', resp.mesrID, {
        show_correct_mark:flag
      });
    } else {
      return await this.auth.apiPatch('public/mrkg-mrkr/training-response', resp.mesrID, {
        show_correct_mark:!resp.show_correct_mark
      });
    }
  }


 

  async updateTrainingSetControls(leaderControls, marking_session_id, set_id) {
    let res = await this.auth.apiPatch('/public/mrkg-lead/training-set-controls', null, leaderControls, {
      query: {
        marking_session_id,
        set_id
      }
    });
    return res;
  }

  changeReleaseRateMode(set_id, automatic_release_rate) {
    return this.auth.apiPatch('/public/mrkg-lead/marking-exemplar-sets', set_id, {
      automatic_release_rate
    });
  }

  async getReliabilityRate(set_id) {

    return (await this.auth.apiGet('/public/mrkg-lead/marking-exemplar-sets', set_id)).releases_per_hour;

  }


  setReliabilitySettings(set_id, releases_per_hour, day, time) {
    let release_start = new Date(day);
    let hours = Math.floor(time);
    let minutesFraction = time % 1;
    let minutes = minutesFraction * 60;

    release_start.setHours(hours, minutes, 0, 0);
    return this.auth.apiPatch('/public/mrkg-lead/marking-exemplar-sets', set_id, {
      releases_per_hour,
      release_start
    });
  }


  async getActiveMarkingSessions() {
    let data = await this.auth.apiFind('/public/mrkg-lead/assigned-marking-session', {
      query: {
        uid: this.auth.getUid(),
        type:await this.auth.myActiveMarkingRole()
      }
    });

    return data.data;
  }

  async updateResponseExemplarSelection(response_id, newData) {

    if (newData.setType == 2) {

      const newReliabilityResponse = {
        taqr_id: response_id,
        setId: newData.setId, 
        set_order: newData.set_order,
        include:newData.include
      };

      
      let res = await this.auth.apiPatch('public/mrkg-lead/marking-reliability-set-responses', null, newReliabilityResponse, {
        query: {
          taqr_id: response_id,
          set_id: newData.setId,
        } 
      });

      await this.auth.apiPatch('/public/mrkg-lead/exemplarSelectionResponse', response_id, newData );
      
      return res;
    }
    else {

      return this.auth.apiPatch('/public/mrkg-lead/exemplarSelectionResponse', response_id, newData );
      
    }
   
    
  }
  //NEW

  getItemSets(itemId, markingSessionId) {
    return this.auth.apiFind('public/mrkg-lead/marking-exemplar-sets', {
      query: {
        item_id: itemId,
        marking_session_id: markingSessionId,
      }
    })
  }

  async getSet(setId) {
    return await this.auth.apiGet('public/mrkg-lead/marking-exemplar-sets', setId);
  }
  

  getMarkerWithStats(markingSessionID, itemID, userID, days) {
    return new Promise((resolve, reject) => {
      this.auth.apiFind('/public/mrkg-lead/user', {
        query: {
          uid:userID
        }
      }).then(marker => { 
        marker = marker.data[0];
        
        this.auth.apiFind('/public/mrkg-mrkr/response', {
          query: {
            marking_session_id: markingSessionID,
            item_id:itemID,
            isRawExcluded: true,
          }
        }).then(responses => {
          responses = responses.data;
          let marksByMarker = [];
        
          //sort through the responses and find the marked ones
          responses.forEach(resp => {
            if(!resp.assigned_markers.includes(marker.uid)) {
              return;
            }
            let scores = JSON.parse(resp.scores);
            
            scores.forEach(score => {
              if(score.uid == marker.uid) {
                marksByMarker.push({
                  response_id:resp.id,
                  score:score.score,
                  timestamp:new Date(score.timestamp)
                });    
              }
            });
          });

          //sort them by timestamp
          marksByMarker.sort(function(a, b) {
            if (a.timestamp < b.timestamp) {
              return -1;
            }
            if (a.timestamp > b.timestamp) {
              return 1;
            }
            return 0;
          });


          //USE DAYS TO TRANSFORM marksbymarker into proper format
          let markingByDayDict = [];
          days.forEach(day => {
            markingByDayDict.push(this.hoursInDay(day));
          });


          let totalMark = 0;

          let avgMark = 0;
          let marks = 0;
          let timeSeconds = 0;
          let marksPerHr = 0; //    marks / (timeSeconds/60)
          let marksPerHrDiff = 0;
          
          let lastTime = null;
          marksByMarker.forEach(mark => {
            if(mark.score != 'S2L') {
              totalMark += (parseInt(mark.score) || 0);
              marks++;
            }
            
            if(this.isValidDate(mark.timestamp)) {
              if(lastTime) {
                let elapsed = this.elapsedSeconds(lastTime, mark.timestamp);
                if(elapsed < 600) { //10 minutes
                  timeSeconds += elapsed;
                }       
              }
              lastTime = mark.timestamp;

              days.forEach((day, index) => {
                if(this.isSameDate(new Date(day.startTime), new Date(mark.timestamp))) {
                  markingByDayDict[index][mark.timestamp.getHours().toString()]++;
                }
              });

            }
          });


          if(marks > 0) {
            avgMark = this.twoDecimal(totalMark / marks);
          }
          if(timeSeconds > 0) {
            marksPerHr = this.twoDecimal(marks / (timeSeconds / 3600));
          }

          let timeFormatted = this.formatTime(timeSeconds);
          let status = "Checkpoint #" + (Math.floor(marks / 20) + 1);
          
          let markingByDay = [];
          markingByDayDict.forEach(dayDict => {
            markingByDay.push([this.dayDictToMorningValues(dayDict), this.dayDictToAfternoonValues(dayDict)]);
          });


          resolve({
            name:marker.firstName + " " + marker.lastName[0],
            imgURL:marker.imgURL,
            uid:marker.uid,
            avgMark,
            marks,
            timeSeconds,
            status,
            timeFormatted,
            marksPerHr,
            marksPerHrDiff:0,
            markingByDay
          });
        });


        
      });
    });
  }

  twoDecimal(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  dayDictToMorningValues(dayDict) {
    let keys = Object.keys(dayDict);

    let morningValues = [];
    keys.forEach(key => {
      if(parseInt(key) < 12) {
        morningValues.push(dayDict[key]);
      }
    });

    return morningValues;
  }

  dayDictToAfternoonValues(dayDict) {
    let keys = Object.keys(dayDict);

    let afternoonValues = [];
    keys.forEach(key => {
      if(parseInt(key) >= 12) {
        afternoonValues.push(dayDict[key]);
      }
    });

    return afternoonValues;
  }

  isSameDate(dateA, dateB) {
    return dateA.getFullYear() == dateB.getFullYear()
    && dateA.getMonth() == dateB.getMonth()
    && dateA.getDate() == dateB.getDate();
  }

  hasMorning(day) {
    let startTime = new Date(day.startTime);

    return startTime.getHours() < 12;
  }

  hasAfternoon(day) {
    let endTime = new Date(day.endTime);

    return endTime.getHours() >= 12;
  }

  getNumHoursInDay(day) {
    let endTime = new Date(day.endTime);
    let startTime = new Date(day.startTime);
    return endTime.getHours() - startTime.getHours();
  }

  getNumHoursInMorning(day) {
    let startTime = new Date(day.startTime);
    if(this.hasMorning(day)) {
      return 12 - startTime.getHours();
    }
    else {
      return 0;
    }
  }

  getNumHoursInAfternoon(day) {
    let endTime = new Date(day.endTime);
    if(this.hasAfternoon(day)) {
      return endTime.getHours() - 12;
    }
    else {
      return 0;
    }
  }


  hoursInDay(day) {
    let hours = {};

    let startTime = new Date(day.startTime);
    let numHours = this.getNumHoursInDay(day);
    let firstHour = startTime.getHours();
    for(let i = firstHour; i < firstHour + numHours; i++) {
      hours[i.toString()] = 0;
    }
    return hours;

  }

  hoursInMorning(day){
    let startTime = new Date(day.startTime);
    let hours = [];
    if(this.hasMorning(day)) {
      let numHours = this.getNumHoursInMorning(day);
      let firstHour = startTime.getHours();
      
      for(let i = firstHour; i < firstHour + numHours; i++) {
        hours.push(i);
      }
    }
  
    return hours;
  }

  hoursInAfternoon(day) {
    let startTime = new Date(day.startTime);
    let hours = [];
    if(this.hasAfternoon(day)) {
      let numHours = this.getNumHoursInAfternoon(day);

      let firstHour;
      if(startTime.getHours() < 12) {
        firstHour = 12;
      }
      else {
        firstHour = startTime.getHours();
      }
      
      for(let i = firstHour; i < firstHour + numHours; i++) {
        if(i > 12){
          hours.push(i - 12) 
        }
        else {
          hours.push(i);
        }
      }
    }
    return hours;
  }

  isValidDate(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {  // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  elapsedSeconds(start,end) {
    var timeDiff = end - start; //in ms
    // strip the ms
    timeDiff /= 1000;
  
    // get seconds 
    return Math.round(timeDiff);
  }

  inspection_allResponses = {};
  async getAllResponses(itemId) {
    if(this.inspection_allResponses[itemId]) {
      //delete them after fetching once. solves fetching twice.
      let responses = [...this.inspection_allResponses[itemId]];
      this.inspection_allResponses[itemId] = null;
      return responses;
    }
    let allResponses = await this.auth.apiFind('/public/mrkg-lead/all-item-responses', {
      query:{
        item_id:itemId
      }
    });
    this.inspection_allResponses[itemId] = allResponses;
    return allResponses;
  }

  getMarkingResponses = async (itemId: number, ids: number[]) => {
    return this.auth.apiFind('/public/mrkg-lead/all-item-responses', {
      query:{
        item_id:itemId,
        mr_ids: ids,
      }
    })
  }
  async getResponsesFromPEN(marking_session_id, pen) {

    return await this.auth.apiFind('/public/mrkg-lead/responses-by-pen', {
      query:{
        marking_session_id,
        pen
      }
    });

  }

  async getResponsesFromPENandTestWindow(test_window_id, pen) {

    return await this.auth.apiFind('/public/mrkg-lead/responses-by-pen', {
      query:{
        test_window_id,
        pen,
      }
    });

  }

  inspection_markedResponses = {};
  async getMarkedResponses(id, itemID) {

    if(this.inspection_markedResponses[itemID]) {
      return this.inspection_markedResponses[itemID];
    }

    let markedResponses = await this.auth.apiFind('/public/mrkg-lead/markedResponsesOfItems', {
      query: {
        itemID:itemID,
        marking_session_id: id,
      }
    });
    this.inspection_markedResponses[itemID] = markedResponses;
    return markedResponses;

  }

  getResponsesOfMarkingSession(id, itemIDs) {
    return new Promise((resolve, reject) => {
      let responses = {};
      if(itemIDs.length == 0) {
        resolve(null);
      }

      /*OLD*/
      itemIDs.forEach((itemID) => {
        this.auth.apiFind('/public/mrkg-mrkr/response', {
          query: {
            marking_session_id: id,
            item_id:itemID,
            isRawExcluded: true,
          }
        }).then(data => {
          let rawResponses = data.data
          responses[itemID.toString()] = rawResponses.filter(resp => resp.setTags == '[]');
          if(Object.keys(responses).length == itemIDs.length) {
            resolve(responses);
          }
          
        });
      });

    });
  }

  async getMarkingSessionName(id) {
    let ms = await this.auth.apiFind('/public/mrkg-lead/assigned-marking-session', {
      query: {
        marking_session_id: id,
      }
    });
    if(ms.data.length != 1) return '';
    return ms.data[0].title;
  }



async loadMarkerPairNames(markingSessionID) {
  let res = await this.auth.apiFind('/public/mrkg-lead/pairs', {
    query: {
      marking_session_id:markingSessionID
    }
  });
  return res;
}


  loadDocuments(docsRef) {

    return new Promise((resolve, reject) => {
      let documents = [];
      for(let i = 0; i < docsRef.length; i++) {
        let doc_id = docsRef[i];
        this.auth.apiFind('/public/mrkg-lead/document', {
          query: {
            id: doc_id
          }
        }).then(res => { 
          let doc = res.data[0];

          documents.push(doc);
          

          if(docsRef.length == documents.length) {
            resolve(documents);
          }
        });
      }
    });
  }

  
  loadMarkers(markersRef) {
    return new Promise((resolve, reject) => {
      let markers = [];
      if(markersRef.length == 0) {
        resolve(markers);
      }
      for(let i = 0; i < markersRef.length; i++) {
        let id = markersRef[i];
        this.auth.apiFind('/public/mrkg-lead/user', {
          query: {
            uid: id
          }
        }).then(res => { 
          let marker = res.data[0];

          markers.push(marker);
          

          if(markersRef.length == markers.length) {
            resolve(markers);
          }
        });
      }
    });
  }


  async amIAssignedTheItem(item) {
    let leaders = await this.auth.apiGet('public/mrkg-lead/marking-item-leaders', item.id);
    return leaders.includes(this.auth.getUid());
  }
  

  getName(user) {
    if(!user) return '';
    return user.firstName + " " + user.lastName[0] + '.';
  }

  loadMarkerPairs(markerPairsRef) {
    return new Promise((resolve, reject) => {

      let markerPairs = [];
      if(markerPairsRef.length == 0) {
        resolve(markerPairs);
      }
      markerPairsRef.forEach(markerPairRef => {
        let markerA, markerB;
        let markerAPromise, markerBPromise;
        if(markerPairRef[0]) {
          markerAPromise = new Promise((resolve, reject) => {
            this.auth.apiFind('/public/mrkg-lead/user', {
              query: {
                uid: markerPairRef[0]
              }
            }).then(res => { 
              resolve(res.data[0]);
            });
          });
        }
        else {
          markerAPromise = new Promise((resolve, reject) => {
            resolve(false);
          });
        }
       
        if(markerPairRef[1]) {
          markerBPromise = new Promise((resolve, reject) => {
            this.auth.apiFind('/public/mrkg-lead/user', {
              query: {
                uid: markerPairRef[1]
              }
            }).then(res => { 
              resolve(res.data[0]);
            });
          });
        }
        else {
          markerBPromise = new Promise((resolve, reject) => {
            resolve(false);
          });
        }
        

        Promise.all([markerAPromise, markerBPromise]).then((markers) => {
          markerPairs.push([
            this.getName(markers[0]), this.getName(markers[1])
          ]);

          if(markerPairs.length == markerPairsRef.length) {
            resolve(markerPairs);
          }
        });

      });
      
    });
  }
  
  

  getTrainingReliabilityResponses(set_id) {
    return this.auth.apiFind('public/mrkg-lead/marking-reliability-set-responses', { query: { set_id }});
  }

  getMarkersAssignedToItem(item_id) {
    return this.auth.apiFind('public/mrkg-lead/markers-assigned-item', { query: { item_id }});
  }


  getItemData(itemId) {
    return this.auth.apiFind('/public/mrkg-lead/item', {
      query: {
        item_id:itemId
      }
    });
  }


  exemplarSelectionItems = {}

  didFetchLessThan10SecondsAgo(itemID) {
    if(!this.exemplarSelectionItems[itemID]) return false;

    let now = +new Date();

    return ((now - this.exemplarSelectionItems[itemID].timestamp) < 10000);
  }

  async getExemplarSelectionRationalUpdate(esSelResID){
    let resData = await this.auth.apiGet('/public/mrkg-lead/exemplarSelectionResponse', esSelResID, {
      query: {
        exemSelectionRes: true
      }
    });
    return resData[0];
  }

  async getExemplarSelectionItem(itemId, stage=null, setId=null, only_from_set=undefined) {
    // Incorrectly caching exemplar selection data. we dont want to do this, 
    // We were sometimes fetching the wrong exemplar set, since this data structure does not track the set_id. (needed on line 1062)
    // We want the most recent exemplar selection data anyway & the fetch is lighter than it used to be.

    // if(this.didFetchLessThan10SecondsAgo(itemId)) {
    //   return this.exemplarSelectionItems[itemId].data;
    // }

    let itemData = await this.auth.apiFind('/public/mrkg-lead/item', {
      query: {
        item_id:itemId
      }
    });

    let resData = await this.auth.apiFind('/public/mrkg-lead/exemplarSelectionResponse', {
      query: {
        item_id:itemId,
        stage,
        set_id:setId,
        only_from_set
      }
    });


    resData.forEach((res, index) => {
      res.taqrId = index + 1;
    });
    
    let item = {
      item:{
        id: itemData.data[0].id,
        name: itemData.data[0].name,
        type: resData[0]?.response_type,
        item_type: itemData.data[0].item_type,
        marking_type: itemData.data[0].marking_type,
        response_count: resData.length,
        responses_expected: resData.length,
        tq_config:itemData.data[0].tq_config,
        marked_count: resData.length - itemData.data[0].markingLeftover
      },
      isSelectedDone:itemData.data[0].finishedSelected,
      isScoredDone:itemData.data[0].finishedMarked,
      isReliabilitySet:itemData.data[0].finishedReliability,
      isOnSiteSet:itemData.data[0].finishedOnSiteTraining,
      isOffSiteSet:itemData.data[0].finishedOffSiteTraining,
      currentMarkingOptions: JSON.parse(itemData.data[0].markingOptions),
      responses:resData,
      isOralItem: itemData.data[0].isOralItem,
    };

    this.exemplarSelectionItems[itemId] = {
      timestamp:+new Date(),
      data:item
    };

    return item;
  }

  async getTrainingResponsesBySet(markingSessionId: number, setId: number) {
    let data = await this.auth.apiFind('/public/mrkg-mrkr/training-response', {
      query: {
        marking_session_id: markingSessionId,
        set_id: setId
      }
    });
    data.sort((a,b) => {
      return a.set_order - b.set_order;
    });
    let filteredRes = [ ...data ];
      filteredRes.forEach((resp, index) => {
        resp.taqrId = index+1;
      //  resp.scores = JSON.parse(resp.scores);
    });
    return filteredRes;
  }

  async getTrainingItemBySet(markingSessionId: number, setId: number, forceRefresh?:boolean): Promise<any> {
    if(this.trainingItem && !forceRefresh) {
      return this.trainingItem;
    }
    else {
      let itemData = {
        item:null,
        responses:[],
        rubric:null,
        documents:null
      };
      let setRecord = await this.auth.apiGet('/public/mrkg-lead/marking-exemplar-sets', setId);
      let itemRecord = await this.auth.apiFind('/public/mrkg-lead/item', {
        query:{
          item_id:setRecord.item_id
        }
      });


      itemData.item = itemRecord.data[0];

      itemData.item.markerPairsRef = await this.auth.apiFind('public/mrkg-lead/marking-assignment-groups', {
        query: {
          item_id:itemRecord.data[0].id
        }
      });
      itemData.item.exemplar_set_type_id = setRecord.exemplar_set_type_id
      itemData.rubric = itemRecord.data[0].rubric;
      itemData.documents = itemRecord.data[0].documents;
      if(itemRecord.data[0].markingOptions) {
        itemData.item.markingOptions = JSON.parse(itemRecord.data[0].markingOptions);
      }
      let data = await this.auth.apiFind('/public/mrkg-mrkr/training-response', {
        query: {
          marking_session_id: markingSessionId,
          set_id: setId
        }
      });

      data.sort((a,b) => {
        return a.set_order - b.set_order;
      });
      let filteredRes = [ ...data ];
        filteredRes.forEach((resp, index) => {
          resp.taqrId = index+1;
        //  resp.scores = JSON.parse(resp.scores);
        });
      itemData.responses = filteredRes;
      itemData.item.response_count = filteredRes.length;
      this.trainingItem = itemData;
      return itemData;
        
    }
  }

  


  // tab persistence
  // dashboard
  selectMarkingSessionView(id: AssessmentView) {
    this.selectedMarkingSessionView = id;
  }
  selectDiscrepancyView(id: DiscrepancyView) {
    this.selectedDiscrepancyView = id;
  }
  selectTaskView(id: TaskView) {
    this.selectedTaskView = id;
  }

  // session overview
  selectSessionView(id: SessionView) {
    this.selectedSessionView = id;
  }

  getDateString(date){
    return formatDate(date, 'EEE, MMM d', 'en-US');
  }

  formatTime(sec) {
    return new Date(sec * 1000).toISOString().substr(11, 8);
  }


  updateSetOrder(responseID, order, type, setID) {

    this.auth.apiPatch('/public/mrkg-lead/exemplarSelectionResponse', responseID, {
      order, 
      setId:setID
    });
    if(type == 2) {
      this.auth.apiPatch('/public/mrkg-lead/marking-reliability-set-responses', null, {set_order:order,include:1}, {
        query:{
          set_id:setID,
          taqr_id:responseID,
        }
      })
    }
    
    
    return;
  }

  async getResponseMarks(responseID) {
    const response = await this.auth.apiFind('/public/mrkg-mrkr/response-scores', {
      query:{
        taqr_id:responseID
      }
    });

    return response.data;
  }

  assignMarkerToSet(uid, markingWindowId, markingWindowItemId, markingExemplarSet, is_credentialing_session = false) {
    const query = {
      uid,
      marking_window_id: markingWindowId,
      marking_window_item_id: markingWindowItemId,
      marking_exemplar_set: markingExemplarSet,
      is_credentialing_session
    }
    this.auth.apiCreate('public/mrkg-lead/markers-assigned-item', {}, { query });
  }

  unassignMarkerFromSet(uid, markingExemplarSet) {
    const query = {
      uid,
      marking_exemplar_set: markingExemplarSet,
    }
    this.auth.apiRemove('public/mrkg-lead/markers-assigned-item', '', { query });
  }

  exemplarSelectionTableData = {};

  async getExemplarSelectionTableData(markingSessionId): Promise<any> {
    let returnData = await this.auth.apiFind('public/mrkg-lead/exemplar-selection-table', {
      query:{
        marking_session_id:markingSessionId,
        $limit:1000
      }
    })
    
    this.exemplarSelectionTableData[markingSessionId] = returnData;
    return returnData;
  }
  
  inspectionTableData = {};
  
  async getInspectionTableData(markingSession, options, getRole=false): Promise<any> {
    let returnData = await this.auth.apiFind('public/mrkg-lead/inspection-overview', {
      query:{
        marking_session_id:markingSession.id,
        options
      }
    });

    this.inspectionTableData[markingSession.id] = returnData.summary;

    if(getRole) {
      return returnData;
    }
    else {
      return returnData.summary;
    }
  }

  async getTrainingReliabilityTableData(markingSessionId, is_credentialing_session = false): Promise<any> {

    let items = await this.auth.apiFind('public/mrkg-lead/item', {
      query: {
        marking_session_id:markingSessionId
      }
    });

    items = items.data;

    let trSets = [];

    for(let item of items) {
      
      let sets = await this.auth.apiFind('/public/mrkg-lead/marking-exemplar-sets', {
        query: {
          marking_session_id:markingSessionId,
          item_id:item.id,
          countResponses: true
        }
      });


      if(!sets) continue;
      for(let set of sets.data) {
        let link;

        if(is_credentialing_session) {

          link = '/'+ this.lang.c() +'/'+this.auth.myActiveMarkingRole(markingSessionId, true)+'/credentialing/'+ markingSessionId + '/' + set.item_id + '/'+set.id+'/1';
        }
        else {
          link = '/'+ this.lang.c() +'/'+this.auth.myActiveMarkingRole(markingSessionId, true)+'/training/'+ markingSessionId + '/' + set.item_id + '/'+set.id+'/1';
        }

        let resps = await this.getTrainingReliabilityResponses(set.id);
        //reverse sort
        resps = resps.sort((a, b) => {
          return b.set_order - a.set_order;
        });

        let status: string;
        let type: string;

        //if the last response has a release end && it has already past
        if(!!+new Date(resps[0]?.release_end) && (+new Date(resps[0]?.release_end) < +new Date())) {
          status = 'Complete'
        }
        else if (set.training_complete) {
          status = 'Complete';
        } else if (set.assignedMarkers.length > 0) {
          if (set.exemplar_set_type_id == 1 || set.exemplar_set_type_id == 3) {
            status = 'In Progress'
          } 

          if (set.exemplar_set_type_id == 2) {
            status = 'Ongoing'
          }
        } else {
          status = 'Unassigned'
        }

        if (set.exemplar_set_type_id == 1) type = 'training';
        if (set.exemplar_set_type_id == 2) type = 'reliability';
        if (set.exemplar_set_type_id == 3) type = 'assessment';

        // const progressString = (set.exemplar_set_type_id === 1 || set.exemplar_set_type_id === 1) ? (set.synchronous_mode ? 'In Progress (Sync)' : 'In Progress (Async)') : 'Ongoing';
        // const setStatus = set.training_complete ? 'Complete' : 
        // (set.assignedMarkers.length > 0 ? progressString : 'Unassigned');

        trSets.push({
          id: set.id,
          name:set.caption,
          item:set.itemName,
          item_id:set.item_id,
          sort_order:set.item_sort_order,
          exemplar_set_type_id:set.exemplar_set_type_id,
          type,
          assignedMarkers: set.assignedMarkers,
          numComplete:set.numComplete,
          numResponses: set.numResponses,
          createdOn: this.formatSQLDate(set.created_on),
          status,
          currentResponse: set.training_complete ? set.numResponses : set.current_index,
          link,
          releases_per_hour: set.releases_per_hour,
          automatic_release_rate:set.automatic_release_rate,
          releases_soft_limit: set.releases_soft_limit,
          synchronous_mode: set.synchronous_mode
        });
      }
    }

    return { items, trSets };
  }


  formatSQLDate(sqlDate){
    sqlDate = sqlDate.replace(' ', 'T');
    return formatDate(sqlDate, 'longDate', 'en-US');
  }


  allMarkers = {};
  async getAllMarkers(marking_session_id, forceReload = false, allow_revoked: boolean = false) {
    if(this.allMarkers[marking_session_id] && !forceReload) return [... this.allMarkers[marking_session_id]];

    let allMarkers = await this.auth.apiFind('/public/mrkg-lead/manage-markers', {
      query: {
        marking_session_id,
        allow_revoked: allow_revoked ? 1 : 0,
      }
    });

    this.allMarkers[marking_session_id] = [...allMarkers];
    return [...allMarkers];
  }

  allLeaders = {};
  async getAllLeaders(marking_session_id, forceReload = false) {
    if(this.allLeaders[marking_session_id] && !forceReload) return [... this.allLeaders[marking_session_id]];

    let allLeaders = await this.auth.apiFind('/public/mrkg-lead/manage-leaders', {
      query: {
        marking_session_id,
      }
    });

    this.allLeaders[marking_session_id] = [...allLeaders];
    return [...allLeaders];
  }

  activeItems = {};
  async getActiveItems(marking_session_id, forceRefresh=false) {

    if(!forceRefresh && this.activeItems[marking_session_id]) return this.activeItems[marking_session_id];

    let items = (await this.auth.apiFind('/public/mrkg-lead/item', {
      query: {
        marking_session_id,
      }
    })).data;

    // if(is_credentialing_session){ 
    //   items = items.filter(i => i.credentialing_item_category != 'training');
    // }

    this.activeItems[marking_session_id] = items;
    return items;


  }

  assignmentJSON = {};
  async getAssignment(markingSession) {
    //if(this.assignmentJSON[markingSession.id]) return this.assignmentJSON[markingSession.id];

    //WE SHOULD PARSE ASSIGNMENTS
   
    let assignmentJSONResp = await this.auth.apiFind('/public/mrkg-lead/marking-session-assignment-json', {
      query: {
        marking_session_id: markingSession.id,
      }
    });

    if(assignmentJSONResp.data[0]) {
      assignmentJSONResp.data[0].current_assignments_json = await this.assignmentService.getMarkerAssignments(markingSession.id);
    }

    this.assignmentJSON[markingSession.id] = assignmentJSONResp.data;
    return assignmentJSONResp.data;
  }
  


  async patchUpcomingAssignmentJSON(markingSession, upcoming_assignments_json) {
    
    let res = await this.auth.apiPatch('/public/mrkg-lead/marking-session-assignment-json', markingSession.id, {
      upcoming_assignments_json:JSON.stringify(upcoming_assignments_json)
    });
    this.assignmentJSON = res;
  }

  async patchUpcomingAssignmentTimestamp(markingSession, upcoming_timeslot) {
    let res = await this.auth.apiPatch('/public/mrkg-lead/marking-session-assignment-json', markingSession.id, {
      upcoming_timeslot
    });
  }

  async patchLeaderUpcomingAssignmentJSON(markingSession, upcoming_assignments_json) {
    let res = await this.auth.apiPatch('/public/mrkg-lead/marking-session-assignment-json', markingSession.id, {
      upcoming_leader_assignments_json:JSON.stringify(upcoming_assignments_json)
    });
    this.assignmentJSON = res;
  }

  statsByItem = {};
  async getMarkerStatistics(item_id) {
    if(this.statsByItem[item_id]) return this.statsByItem[item_id];

    let stats = await this.auth.apiFind('public/mrkg-mrkr/marker-stats', {
      query: {
        item_id
      }
    });
    this.statsByItem[item_id] = stats.data;
    return stats.data;
  }

  thresholdsByItem = {};
  async getItemThresholds(item_id) {
    if(this.thresholdsByItem[item_id]) return this.thresholdsByItem[item_id];

    let thresholds = await this.auth.apiFind('public/mrkg-lead/item-thresholds', {
      query: {
        item_id
      }
    });
    this.thresholdsByItem[item_id] = thresholds;

    return thresholds;
  }

  async sendLoginLinks(uids, is_credentialing_session=false) {
    return await this.auth.apiUpdate('public/mrkg-lead/marker-send-access-keys', null, {
      lang: this.lang.c(),
      uids:JSON.stringify(uids),
      is_credentialing_session
    });
  }

  async getLoginLink(uid) {
    return await this.auth.apiGet('public/mrkg-lead/marker-send-access-keys', uid, { query: {lang: this.lang.c() }});
  }

  async sendInvitationEmails(uids, is_credentialing_session = false) {
    return await this.auth.apiUpdate('public/mrkg-lead/leader-send-invitation-email', null, {
      lang: this.lang.c(),
      uids:JSON.stringify(uids),
      is_credentialing_session
    });
  }

  async pauseItem(item_id) {
    return await this.auth.apiPatch('public/mrkg-lead/inspection-overview', item_id, {
      isPaused:1
    });
  }

  async playItem(item_id) {
    return await this.auth.apiPatch('public/mrkg-lead/inspection-overview', item_id, {
      isPaused:0
    });
  }

  async getSetPDF(set_id) {
    let pdfData = await this.auth.apiGet('public/mrkg-lead/exemplar-set-pdf', set_id);
    // for(let resp of pdfData.responses) {
    //   if(resp.response_type == 'SCAN' && resp.raw) {
    //     resp.raw = JSON.parse(resp.raw);
    //     let now = new Date();
    //     if(!resp.expiry || resp.expiry < new Date()) {
    //       let expireSeconds = 600; //10 min
    //       resp.expiry = +now + (1000*expireSeconds);
    //       resp.img_urls = await this.auth.apiGet('public/mrkg-mrkr/generate-response-urls', resp.id);
    //     }
    //   }
      
    // }

    let set = await this.auth.apiGet('public/mrkg-lead/marking-exemplar-sets', set_id);
    let item = await this.auth.apiGet('public/mrkg-lead/item', set.item_id);
    try{
      pdfData.tq_config = JSON.parse(item[0].tq_config)
    } catch(e) {
      console.log(e);
    }

    return pdfData;
  }

  resetReliabilitySet(set_id) {
    this.auth.apiPatch('public/mrkg-lead/marking-reliability-set-responses', null, {}, {
      query: {
        set_id,
        reset:1
      }
    });
  }


  async getReliabilityResponses(marking_session_id, set_id) {
    let responses =  await this.auth.apiFind('public/mrkg-lead/reliability-response-details', {
      query: {
        set_id
      }
    });
    responses.sort((a,b) => {
      return a.set_order - b.set_order;
    });

    return responses;
  }
  
  async getItemName(item_id) {
    return (await this.auth.apiGet('public/mrkg-lead/item', item_id)).name;
  }


  async exportFieldTestData() {
    let data = await this.auth.apiFind('public/mrkg-lead/export-field-test-data');
  }

  async patchResponse(id, data) {
    return await this.auth.apiPatch('public/mrkg-mrkr/response', id, data);
  }


  async getDiscrepancyReportData(marking_session_id, page?) {
    if(page) {
      return await this.auth.apiFind("public/mrkg-lead/fsa-discrepancy-report", {
        query: {
          marking_session_id,
          page
        }
      });
    }
    else {
      return await this.auth.apiFind("public/mrkg-lead/fsa-discrepancy-report", {
        query: {
          marking_session_id,
        }
      });
    }
    
  } 

  async getFSAScanRequestsData(marking_session_id) {
    return await this.auth.apiFind("public/mrkg-lead/fsa-scan-requests", {
      query: {
        marking_session_id,
      }
    });
  }
  
  async getPENs(marking_session_id) {
    return await this.auth.apiFind("public/mrkg-lead/marking-session-pens", {query:{
      marking_session_id,
      $limit:100000
    }});
  }

  async getHistoricalPacing(uids) {
    return await this.auth.apiFind('public/mrkg-mrkr/marker-stats', {
      query:{
        historical:1,
        uids:JSON.stringify(uids)
      }
    })
  }

  async isFSAMonitoringSession(markingSessionId) {
    const ms = await this.auth.apiFind('/public/mrkg-lead/assigned-marking-session', {
      query: {
        marking_session_id: markingSessionId,
      }
    });

    return (ms.data[0].is_fsa_monitoring_session == 1);
  }

  async getResponseById(responseID){
    const response = await this.auth.apiFind('/public/mrkg-mrkr/response', {
      query:{
        id:responseID
      }
    });

    return response.data;
  }

}
