<div class="school-participation">
    <div class="view-header">
        <div class="assessment-container">
            <label><tra slug="sa_aa_assessment_session"></tra></label>
            <select [(ngModel)]="selectedTestWindowWithoutTestSession" (change)="onSelectedTestWindowChange()">
                <option *ngFor="let testWindow of testWindows" [ngValue]="testWindow">
                    {{getTestWindowTitle(testWindow)}}
                </option>
            </select>
        </div>

        <div style="display: flex; flex-direction: row;">
            <button class="add-account" [disabled]="isLoading || !selectedTestWindowWithoutTestSession" (click)="openAddEditModal()">
                <i class="fa fa-plus-square"></i>
                <span class="button-title"> Add Aegrotat or Disqualification</span>
            </button>

            <button class="add-account" [disabled]="isLoading || !selectedTestWindowWithoutTestSession" (click)="openRemoveEditModal()">
                <i class="fa fa-minus"></i>
                <span class="button-title"> Remove Aegrotat or Disqualification</span>
            </button>
        </div>

        <div style="display: flex; flex-direction: row; margin-top: 1rem;">
            <button class="add-account" [disabled]="isLoading || !selectedTestWindowWithoutTestSession" (click)="openSpecialFormatModal('add')">
                <i class="fa fa-plus-square"></i>
                <span class="button-title"> Add Special Format</span>
            </button>

            <button class="add-account" [disabled]="isLoading || !selectedTestWindowWithoutTestSession" (click)="openSpecialFormatModal('remove')">
                <i class="fa fa-minus"></i>
                <span class="button-title"> Remove Special Format</span>
            </button>
        </div>
    </div>

</div>

<div style="margin-top:3em; margin-bottom: 1em;">
    <div style="display: flex; justify-content: space-between; align-items: center;">
        <div style="display: flex; align-items: center;">
            <button 
                *ngFor="let view of viewTabs"
                class="button"
                [class.is-info]="isViewTabSelected(view.tab)"
                (click)="selectViewTab(view.tab)"
            > {{view.caption}}
            </button>
        </div>
    </div>
</div>

<div class="custom-modal" *ngIf="showAddEditModal">
    <div class="modal-contents" style="width:700px">
        <strong>{{ getTestWindowTitle(selectedTestWindowWithoutTestSession) }}</strong>
        <div class="modal-title">Add Aegrotat or Disqualification:</div>

        <table>
            <tr>
                <th>
                    <label style="margin-right: 10px;">PEN:</label>
                    <input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/^([\d]{0,9}).*$/g, '$1');"
                        [(ngModel)]="addOrRemoveCasePen">
                    <button (click)="lookupPen2()" [disabled]="isLoading || addOrRemoveCaseLoading || isStudentAssessmentInfoLoading" style="margin-left: 10px;">Lookup</button>
                </th>
                <!-- <th>
                    <mat-slide-toggle [(ngModel)]="strictPenValidation" color="primary">Use strict PEN validation
                    </mat-slide-toggle>
                </th> -->
            </tr>
        </table>

        <div class="alert-message" *ngIf="addOrRemoveCaseErrors.pen">
            <i class="fa fa-info-circle"></i>
            <div>
                {{addOrRemoveCaseErrors.pen}}
            </div>
        </div>

        <div *ngIf="isNextStepEnabled()">
            <div style="display: flex; flex-direction: row;">
                <input class="radio-button-label" 
                type="radio" value="aegrotat" 
                selected [formControl]="selectedCaseType"
                (change)="onSelectedCaseTypeChange('aegrotat', true)"
                [disabled]="isStudentAssessmentInfoLoading"/>
                <label class="radio-button-label" for="aegrotat"> Aegrotat</label>

                <input class="radio-button-label" 
                type="radio" 
                value="disqualification" 
                [formControl]="selectedCaseType"
                (change)="onSelectedCaseTypeChange('disqualification', false)"
                [disabled]="isStudentAssessmentInfoLoading"/>
                <label class="radio-button-label" for="disqualification">Disqualification</label>
            </div>

            <br>

            <ng-container *ngIf="isAssessmentsReady()">
                <label>Assessment:</label>
                <select 
                    class="assessment-select" 
                    [(ngModel)]="selectedAssessment" 
                    (change)="onSelectedAssessmentChange($event)"
                    [disabled]="isLoading || isStudentAssessmentInfoLoading"
                >
                    <option *ngFor="let assessment of getEnrolledAssessments()" [ngValue]="assessment">
                        {{ getAssessmentDisplayInSelect(assessment) }}
                    </option>
                </select>
            </ng-container>

            <div style="margin-top: 1rem; font-weight: bold;" *ngIf="selectedAssessment">
                <div>{{ enrollmentStatus }}</div>
                <div>{{ enrolledSchoolCode }}</div>
                <div>{{ enrolledSchoolName }}</div>
            </div>

            <div style="margin-top: 1rem;" 
            class="table is-bordered"
            *ngIf="studentTestAttempts.length !== 0 
            && selectedAssessment != null
            && isSelectedCaseTypeAegrotat()">
            
                <div style="font-weight: bold; margin-bottom: 0.5rem">
                    Student Assessment Submission Status:
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Assessment & Component
                            </th>
                            <th>
                                Started On
                            </th>
                            <th>
                                Submission Status
                            </th>
                            <th>
                                Submitted On
                            </th>
                            <th>
                                School of Test Attempt
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let testAttempt of studentTestAttempts">
                            <td>
                                {{testAttempt.assessment}}
                            </td>
                            <td>
                                {{formatDate(testAttempt.createdOn)}}
                            </td>
                            <td>
                                {{formatSubmissionStatus(testAttempt.isSubmitted)}}
                            </td>
                            <td>
                                {{formatDate(testAttempt.submittedOn)}}
                            </td>
                            <td>
                                {{formatSchoolDisplay(testAttempt.schoolName, testAttempt.schoolCode)}}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div style="margin-top: 0.5em;">
                    <span class="error-message">
                        {{aegrotatWarningMessage}}
                    </span>
                </div>
            </div>

            <div style="margin-top: 1rem; font-weight: bold;" 
            *ngIf="studentTestAttempts.length === 0
            && selectedAssessment != null
            && isSelectedCaseTypeAegrotat()">
                <span class="ok-message">
                {{aegrotatWarningMessage}}
                </span>
            </div>
            
            <table style="margin-top: 2rem;">
                <tr *ngIf="isPenNotExistCase()">
                    <td>* First Name</td>
                    <td><input [(ngModel)]="addOrRemoveCaseFirstName"/></td>
                    <td>{{ errorMessages.firstName }}</td>
                </tr>

                <tr *ngIf="isPenNotExistCase()">
                    <td>* Last Name</td>
                    <td><input [(ngModel)]="addOrRemoveCaseLastName"/></td>
                    <td style="border: none;">{{ errorMessages.lastName }}</td>
                </tr>

                <tr *ngIf="ifSchoolSelectionRequired()">
                    <td>
                        <label>Districts</label>
                    </td>
                    <td>
                        <select 
                            class="assessment-select" 
                            [(ngModel)]="selectedDistrict"
                            (change)="onSelectedDistrictChange()"
                            [disabled]="isLoading || isStudentAssessmentInfoLoading"
                        >
                            <option *ngFor="let district of allDistricts" [ngValue]="district">
                                {{getDisplayDistrict(district)}}
                            </option>
                        </select>
                    </td>
                </tr>

                <tr *ngIf="ifSchoolSelectionRequired()">
                    <td>* Schools</td>
                    <td>
                        <select 
                            class="assessment-select" 
                            [(ngModel)]="selectedSchool"
                            [disabled]="isLoading || isStudentAssessmentInfoLoading"
                        >
                            <option *ngFor="let school of filteredSchools" [ngValue]="school">
                                {{getDisplaySchool(school)}}
                            </option>
                        </select>
                    </td>
                    <td style="border: none;">{{ errorMessages.school }}</td>
                </tr>
                <tr *ngIf="ifSchoolSelectionRequired() && isAllSchoolsSelected()">
                    <td colspan="2">
                        <span class="error-message">
                            Please select a school to proceed.
                        </span>
                    </td>
                </tr>
            </table>
            
        </div>
        <div class="error-message" *ngIf="errorInSavingAddModal">
            <br>
            {{aegrotatOrDisqualificationError}}
        </div>

        <div *ngIf="isLoading"
        class="notification is-warning enlarge-mini"
        style="margin-bottom: -1em; margin-top: 1em;">
            <tra slug="loading_caption"></tra>
        </div> 

        <div class="actions">
            <button class="cancel-button" (click)="closeAddEditModal()" [disabled]="isLoading || isStudentAssessmentInfoLoading">Cancel</button>
            <button class="save-button" (click)="saveAddEditModal()" [disabled]="isSaveBtnDisabled()">Save</button>

        </div>
    </div>
</div>

<div class="custom-modal" *ngIf="showRemoveEditModal">
    <div class="modal-contents" style="width:700px">
        <strong>{{getTestWindowTitle(selectedTestWindowWithoutTestSession)}}</strong>
        <div class="modal-title">Remove Aegrotat or Disqualification:</div>

        <table>
            <tr>
                <th>
                    <label style="margin-right: 10px;">PEN:</label>
                    <input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/^([\d]{0,9}).*$/g, '$1');"
                        [(ngModel)]="addOrRemoveCasePen">
                    <button (click)="lookupPen2()" [disabled]="isLoading || addOrRemoveCaseLoading || isStudentAssessmentInfoLoading" style="margin-left: 10px;">Lookup</button>
                </th>
                <!-- <th>
                    <mat-slide-toggle [(ngModel)]="strictPenValidation" color="primary">Use strict PEN validation
                    </mat-slide-toggle>
                </th> -->
            </tr>
        </table>
        <div class="alert-message" *ngIf="addOrRemoveCaseErrors.pen">
            <i class="fa fa-info-circle"></i>
            <div>
                {{addOrRemoveCaseErrors.pen}}
            </div>
        </div>

        <!-- <div class="modal-question">Is the school participating?</div> -->
        <!-- <select [(ngModel)]="editIsParticipating" (change)="onSelectedParticipatingChange()"> -->
        <!-- <option [ngValue]="true">Yes</option> -->
        <!-- <option [ngValue]="false">No</option> -->
        <!-- </select> -->
        <div *ngIf="isNextStepEnabled()">
            <div style="display: flex; flex-direction: row;">
                <input class="radio-button-label" type="radio" value="aegrotat" selected [formControl]="selectedCaseType"
                (change)="onSelectedCaseTypeChange('aegrotat', false)"/>
                <label class="radio-button-label" for="aegrotat"> Aegrotat</label>
                <input class="radio-button-label" type="radio" value="disqualification" [formControl]="selectedCaseType"
                (change)="onSelectedCaseTypeChange('disqualification', false)"/>
                <label class="radio-button-label" for="disqualification">Disqualification</label>
            </div>
            <br>
            <label>Assessment:</label>
            <ng-container *ngIf="selectedTestWindow">
                <select 
                    class="assessment-select" 
                    [(ngModel)]="selectedAssessment" 
                    (change)="onSelectedAssessmentChange($event)"
                    [disabled]="isLoading"
                >
                    <option *ngFor="let assessment of selectedTestWindow.assessments" [ngValue]="assessment">
                        {{ getAssessmentDisplayInSelect(assessment) }}
                    </option>
                </select>
            </ng-container>
        </div>
        <div class="error-message" *ngIf="errorInSavingRemoveModal">
            <br>
            {{noExistingAegrotatOrDisqualificationError}}
        </div>

        <div *ngIf="isLoading"
        class="notification is-warning enlarge-mini"
        style="margin-bottom: -1em; margin-top: 1em;">
            <tra slug="loading_caption"></tra>
        </div> 

        <div class="actions">
            <button class="cancel-button" (click)="closeRemoveEditModal()" [disabled]="isLoading">Cancel</button>
            <button class="save-button" (click)="saveRemoveEditModal()" [disabled]="!addOrRemoveCaseUid || !selectedCaseType.value || !selectedAssessment || isLoading">Save</button>
        </div>
    </div>
</div>

<div class="custom-modal" *ngIf="showExitModal">
    <div class="modal-contents" style="width:700px; margin-bottom: -2em; height: 15em;">
        <h2>
            Do you wish to continue {{currentAction}} more aegrotats or disqualifications for this student?
        </h2>
        
        <div class="actions">
            <button class="cancel-button" (click)="closeAllModals()">No</button>
            <button class="save-button" (click)="closeExitModalAndKeepAddOrRemoveModal()" [disabled]="false">Yes
            </button>
        </div>
    </div>
</div>

<div class="custom-modal" *ngIf="showSpecialFormatModal">
    <div class="modal-contents" style="width:700px">
        <strong>{{getTestWindowTitle(selectedTestWindowWithoutTestSession)}}</strong>
        <div class="modal-title">
            <span *ngIf="specialFormatAction === 'add'">Add</span>
            <span *ngIf="specialFormatAction === 'remove'">Remove</span>
            Special Format:
        </div>

        <table>
            <tr>
                <th>
                    <label style="margin-right: 10px;">PEN:</label>
                    <input 
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/^([\d]{0,9}).*$/g, '$1');"
                        [(ngModel)]="addOrRemoveCasePen"
                    >
                    <button 
                        style="margin-left: 10px;"
                        (click)="lookupPenForSpecialFormat()" 
                        [disabled]="isLoading || addOrRemoveCaseLoading" 
                    >
                        Lookup
                    </button>
                </th>
            </tr>
        </table>

        <div class="alert-message" *ngIf="addOrRemoveCaseErrors.pen">
            <i class="fa fa-info-circle"></i>
            <div>
                {{addOrRemoveCaseErrors.pen}}
            </div>
        </div>

        <div *ngIf="isNextStepEnabledForAddSpecialFormat()">
            <div style="display: grid; grid-template-columns: 100px 1fr;grid-gap: 16px;margin-top: 1rem;">
                <label for="special-format-assessment" style="grid-column: 1 / 2;">Assessment:</label>
                <select 
                    id="special-format-assessment"
                    style="grid-column: 2 / 3; margin: 0;"
                    class="assessment-select" 
                    [(ngModel)]="selectedAssessment" 
                    (change)="onSelectedAssessmentChange($event)"
                    [disabled]="isLoading"
                >
                    <option *ngFor="let assessment of selectedTestWindow.assessments" [ngValue]="assessment">
                        {{ getAssessmentDisplayInSelect(assessment) }}
                    </option>
                </select>
                <label style="grid-column: 1 / 2;"></label>
                <div style="padding: 8px; grid-column: 2 / 3;">  
                    Please indicate
                    <ol>
                        <li>whether these student requires a Braille or Large Print</li>
                        <li>the font size that the student requires</li>
                        <li>the font family that the student requires (Arial or Verdana)</li>
                        <li>formatting options: Normal or bold</li>
                        <li>and any other notes</li>
                    </ol>
                </div>
                <label for="special-format-content" style="grid-column: 1 / 2;">Request:</label>
                <textarea 
                    placeholder="Enter your request here"
                    id="special-format-content" 
                    rows="4" 
                    [(ngModel)]="specialFormatContent" 
                    style="padding: 8px; grid-column: 2 / 3;"
                ></textarea>
            </div>
        </div>

        <div *ngIf="isNextStepEnabledForRemoveSpecialFormat()">
            <table style="margin-top: 2rem;">
                <tr>
                    <td>PEN</td>
                    <td>First Name</td>
                    <td>Last Name</td>
                    <td>Content</td>
                    <td>Assessment</td>
                </tr>
                <tr *ngFor="let specialFormat of specialFormats">
                    <td>{{ specialFormat.pen }}</td>
                    <td>{{ specialFormat.first_name }}</td>
                    <td>{{ specialFormat.last_name }}</td>
                    <td>{{ specialFormat.content }}</td>
                    <td>{{ specialFormat.assessment }}</td>
                    <td style="border: none;">
                        <button (click)="revokeSpecialFormat(specialFormat)">Remove</button>
                    </td>
                </tr>
            </table>
        </div>

        <div class="actions">
            <button class="cancel-button" (click)="closeSpecialFormatModal()">Cancel</button>
            <button class="save-button" (click)="saveSpecialFormat()" [disabled]="isSaveSpecialFormatDisabled()">Save</button>
        </div>
    </div>
</div>

<div class="custom-modal" *ngIf="showEditSpecialFormatModal">
    <div class="modal-contents" style="width:700px">
        <strong>{{ getTestWindowTitle(selectedTestWindowWithoutTestSession)}}</strong>
        <div class="modal-title">Edit Special Format:</div>

        <div style="display: grid; grid-template-columns: 100px 1fr;grid-gap: 16px;margin-top: 1rem;">
                <label for="special-format-assessment" style="grid-column: 1 / 2;">Assessment:</label>
                <select 
                    id="special-format-assessment"
                    style="grid-column: 2 / 3; margin: 0;"
                    class="assessment-select" 
                    [(ngModel)]="selectedAssessment" 
                    [disabled]="isLoading"
                    [value]="specialFormatRowToEdit.fullAssessment"
                >
                    <option *ngFor="let assessment of selectedTestWindow.assessments" [ngValue]="assessment">
                        {{ getAssessmentDisplayInSelect(assessment) }}
                    </option>
                </select>
                <label style="grid-column: 1 / 2;"></label>
                <div style="padding: 8px; grid-column: 2 / 3;">  
                    Please indicate
                    <ol>
                        <li>whether these student requires a Braille or Large Print</li>
                        <li>the font size that the student requires</li>
                        <li>the font family that the student requires (Arial or Verdana)</li>
                        <li>formatting options: Normal or bold</li>
                        <li>and any other notes</li>
                    </ol>
                </div>
                <label for="special-format-content" style="grid-column: 1 / 2;">Request:</label>
                <textarea 
                    placeholder="Enter your request here"
                    id="special-format-content" 
                    rows="4" 
                    [(ngModel)]="specialFormatContent" 
                    style="padding: 8px; grid-column: 2 / 3;"
                    [disabled]="isLoading"
                    ></textarea>
            </div>

        <div class="actions">
            <button class="cancel-button" 
            (click)="closeEditSpecialFormatModal()"
            [disabled]="isLoading"
            >Cancel</button>
            <button class="save-button" 
            (click)="saveEditSpecialFormatModal()"
            [disabled]="isLoading || shouldEditSaveButtonBeDisabled() ">Save</button>
        </div>
    </div>
</div>

<div class="rows" [ngSwitch]="selectedViewTab">
    <div class="row" *ngSwitchCase="specialCases.AEGROTAT">
        <div class="header-container" style="width: 100%">
            <label class="header">Aegrotat</label>
        </div>
        <ng-container *ngIf="!isLoading">
            <ag-grid-angular style="width: 100%; height: 600px; margin-top: 2rem;" class="ag-theme-alpine"
                [gridOptions]="gridOptionsAegrotat">
            </ag-grid-angular>

            <button class="button has-icon" (click)="downloadAsCsv(gridOptionsAegrotat)">
                <span class="icon">
                    <i class="fas fa-file-export"></i>
                </span>
                <span> Export CSV </span>
            </button>
        </ng-container>

        <div *ngIf="isLoading" class="notification is-warning enlarge-mini">
            <tra slug="loading_caption">
            </tra>
        </div>
    </div>
    <div class="row" *ngSwitchCase="specialCases.DISQUALIFICATION">
        <div class="header-container" style="width: 100%">
            <label class="header">Disqualification</label>
        </div>
        
        <ng-container *ngIf="!isLoading">
            <ag-grid-angular style="width: 100%; height: 600px; margin-top: 2rem;" class="ag-theme-alpine"
                [gridOptions]="gridOptionsDisqualification">
            </ag-grid-angular>

            <button class="button has-icon" (click)="downloadAsCsv(gridOptionsDisqualification)">
                <span class="icon">
                    <i class="fas fa-file-export"></i>
                </span>
                <span> Export CSV </span>
            </button>
        </ng-container>

        <div *ngIf="isLoading" class="notification is-warning enlarge-mini">
            <tra slug="loading_caption">
            </tra>
        </div>
        
    </div>
    <div class="row" *ngSwitchCase="specialCases.SPECIALFORMAT">
        <div class="header-container" style="width: 100%">
            <label class="header">Special Formats</label>
        </div>
        
        <ng-container *ngIf="!isLoading">
            <ag-grid-angular style="width: 100%; height: 600px; margin-top: 2rem;" class="ag-theme-alpine"
                [gridOptions]="gridOptionsSpecialFormats">
            </ag-grid-angular>

            <button class="button has-icon" (click)="downloadAsCsv(gridOptionsSpecialFormats)">
                <span class="icon">
                    <i class="fas fa-file-export"></i>
                </span>
                <span> Export CSV </span>
            </button>
        </ng-container>

        <div *ngIf="isLoading" class="notification is-warning enlarge-mini">
            <tra slug="loading_caption">
            </tra>
        </div>
    </div>
    <div class="row" *ngSwitchCase="specialCases.SPECIALFORMATSTRACKER">
        <div class="header-container" style="width: 100%">
            <label class="header">Special Formats Tracker</label>
        </div>
        <lp-tracker [mode]="'readonly'" [selectedTestWindow]="selectedTestWindow" [isForSupportPanel]="false"
            [triggerDataRefresh]="triggerDataRefreshInLPTracker"> </lp-tracker>
    </div>
</div>