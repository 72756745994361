<div class="test-window-picker-wrapper">
    <label>
        {{ labelText }}:
    </label>

    <select [(ngModel)]="selectedTestWindow" (change)="onSelectedTestWindowChange()" [disabled]="isLoading">
        <option [ngValue]="null">
            {{ defaultOptionText }}
        </option>

        <option *ngFor="let testWindow of testWindows" [ngValue]="testWindow">
            {{ getTestWindowTitle(testWindow) }}
        </option>
    </select>
</div>