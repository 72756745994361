<ng-container class="score-entry-individual">
    <h1 style="font-size: 2em;"><strong>{{displayStudentInfo()}}</strong></h1>

    <div id="header-bar">
        <div class="left">
            <button class="caret" 
            (click)="changePage(true)" [disabled]="isPageChangeDisabled(true)"
            style="margin-right: 0.25em;">
                <i class="fa fa-caret-left" aria-hidden="true"></i>
            </button>

            <button class="caret" (click)="changePage(false)" [disabled]="isPageChangeDisabled(false)">
                <i class="fa fa-caret-right" aria-hidden="true"></i>
            </button>

            {{createHeaderBarText()}}
        </div>

        <div class="right">
            <button
            (click)="markAsNotYetScored()"
            class="button"
            [disabled]="disableMarkNotYetScoredBtn()"
            style="margin-right: 1em;"> 
                <tra slug="sa_se_notYetScored"></tra>
            </button>

            <button 
            class="button"
            (click)="fakeSave()">
                <tra slug="btn_save"></tra>
            </button>
        </div>

    </div>

    <div *ngIf="!isScoreEntryOpen" style="margin-bottom: 1rem; color: red;">
        <p>
            Note: Score Entry is currently closed. At this stage, all scores are required for this student and 
            <strong style="color: red;">
                the Clear Selection button has been disabled. 
            </strong>
        </p>
    </div>

    <div class="scoring-panel">
        <div class="panel-title">
            Literacy
        </div>

        <div class="buttons has-addons">
            <span class="question-title">Theme Selection</span>

            <div class="field has-addons" *ngFor="let themeOption of selectedScoreEntryRowV2.literacy.themeOptions">
                <button 
                    *ngIf="isThemeSelected(themeOption)"
                    class="button is-select is-info"
                    [disabled]="isLoading"
                >
                    <ng-container >Theme {{themeOption}}</ng-container>
                </button>

                <button 
                    *ngIf="!isThemeSelected(themeOption)"
                    class="button"
                    [disabled]="isLoading"
                    (click)="changeTheme(themeOption)"
                >
                    <ng-container >Theme {{themeOption}}</ng-container>
                </button>
            </div>

            <button 
                style="margin-left: 4.25em;"
                class="button has-addons clear-section" 
                [disabled]="isLoading || !isScoreEntryOpen"
                (click)="changeTheme(ScoreEntryThemes.NO_THEME)"
            >
                <tra slug="sa_se_clear_selection"></tra>
            </button>
        </div>

        <div class="buttons has-addons" *ngFor="let questionOption of selectedScoreEntryRowV2.literacy.questionOptions">
            <span class="question-title">Question {{ questionOption }}</span>

            <div class="field has-addons" *ngFor="let scoreOption of scoreOptions">
                <button 
                *ngIf="isScoreSelected(questionOption, scoreOption, 'literacy')"
                class="button is-select is-info"
                [disabled]="isLoading"
                >
                    {{ scoreOption }}
                </button>

                <button 
                *ngIf="!isScoreSelected(questionOption, scoreOption, 'literacy')"
                class="button"
                [disabled]="isLoading"
                (click)="saveScore('literacy', scoreOption, questionOption)"
                >
                    {{ scoreOption }}
                </button>
            </div>

            <button 
            class="button has-addons clear-section" 
            [disabled]="isLoading || !isScoreEntryOpen"
            (click)="saveScore('literacy', '', questionOption)"
            >
                <tra slug="sa_se_clear_selection"></tra>
            </button>
        </div>
    </div>

    <div class="scoring-panel" >
        <div class="panel-title"
            >Numeracy
        </div>

        <div class="buttons has-addons" *ngFor="let questionOption of selectedScoreEntryRowV2.numeracy.questionOptions">
            <span class="question-title">Question {{ questionOption }}</span>

            <div class="field has-addons" *ngFor="let scoreOption of scoreOptions">
                <button 
                *ngIf="isScoreSelected(questionOption, scoreOption, 'numeracy')"
                class="button is-select is-info"
                [disabled]="isLoading"
                >
                    {{ scoreOption }}
                </button>

                <button 
                *ngIf="!isScoreSelected(questionOption, scoreOption, 'numeracy')"
                class="button"
                [disabled]="isLoading"
                (click)="saveScore('numeracy', scoreOption, questionOption)"
                >
                    {{ scoreOption }}
                </button>
            </div>

            <button 
                class="button has-addons clear-section" 
                [disabled]="isLoading || !isScoreEntryOpen"
                (click)="saveScore('numeracy', '', questionOption)"
                >
                <tra slug="sa_se_clear_selection"></tra>
            </button>
        </div>
    </div>
    <!-- <score-entry-documents *ngIf="currentRow" [grade]="currentRow.grade" [large]="documentsLargeText">
    </score-entry-documents>

    

    <ng-container *ngIf="currentRow">

        <div class="student-info">
            <b>{{currentRow.last_name}}, {{currentRow.first_name}} ({{currentRow.pen}}) - <tra slug="sa_grade"></tra>:
                {{currentRow.grade}}</b>
            <span class="score-entry-status tag" [style.background-color]="getColorForStatus(currentRow)">
                {{getStatus(currentRow)}}
            </span>
        </div>
    </ng-container>

    <div class="action-bar" *ngIf="allPens && pagination">
        <bc-paginator [paginator]="pagination" [showCount]="paginatorShowCount" [getCountDisplay]="getCountDisplay"
            [disabled]="isLoading" (refresh)="onPaginationChange()" (willRefresh)="onPaginationWillChange($event)">
        </bc-paginator>

        <div style="flex-grow: 1;"></div>

        <button class="button not-scored-button" (click)="markAsNotYetScoredClicked()"
            [disabled]="shouldDisableMarkAsNotYetScoredButton()">
            <tra *ngIf="currentRow && !currentRow.is_marked_not_yet_scored" slug="sa_se_notYetScored"></tra>
            <tra *ngIf="currentRow && currentRow.is_marked_not_yet_scored" slug="sa_se_mark_submitted"></tra>
        </button>

        <button class="button save-button" (click)="save()" [disabled]="isLoading">
            <tra slug="sa_aa_save"></tra>
        </button>
    </div>

    <ng-container *ngIf="themeProfile && scoreProfile && currentRow">

        <div class="scoring-panel" *ngFor="let panel of scoringPanels">
            <div class="panel-title">{{panel.component}}</div>

            <div class="buttons has-addons" *ngFor="let question of panel.questions">
                <span class="question-title">{{question.title}}</span>

                <div class="field has-addons" *ngFor="let option of question.profile.options">
                    <button *ngIf="currentRow[question.scoreEntryField] == option.slug" class="button is-select is-info"
                        [disabled]="isLoading">
                        <ng-container *ngIf="question.getDisplay">{{question.getDisplay(option.slug)}}</ng-container>
                        <ng-container *ngIf="!question.getDisplay">{{option.slug}}</ng-container>
                    </button>

                    <button *ngIf="currentRow[question.scoreEntryField] != option.slug" class="button"
                        [disabled]="isLoading || (question.disableOption && question.disableOption(option.slug))"
                        (click)="question.onChange(question.scoreEntryField, option.slug)">
                        <ng-container *ngIf="question.getDisplay">{{question.getDisplay(option.slug)}}</ng-container>
                        <ng-container *ngIf="!question.getDisplay">{{option.slug}}</ng-container>
                    </button>
                </div>

                <button class="button has-addons clear-section" (click)="question.onChange(question.scoreEntryField)"
                    [disabled]="isLoading">
                    <tra slug="sa_se_clear_selection"></tra>
                </button>
            </div>
        </div>

    </ng-container> -->


</ng-container>