import { Component, OnInit } from '@angular/core';
import { AegrotatTestAttempt, ALL_SCHOOLS, BcAccountsService, District, Filter, FilterCondition, LookupResult, Pagination, School, SchoolParticipationRow } from 'src/app/bc-accounts/bc-accounts.service';
import { BcAssessmentsService, AssessmentType, AssessmentComponent, TestWindow, TestWindowTabular } from "src/app/bc-assessments/bc-assessments.service";
import { AccountType } from '../../../app/constants/account-types';
import { FormControl } from '@angular/forms';
import moment from 'moment';
import { is } from 'cypress/types/bluebird';
import { LanguageService } from 'typescript';
import { LangService } from 'src/app/core/lang.service';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';

const headingToSortBy = [
  { heading: 'District', sortBy: 'district_code' },
  { heading: 'School', sortBy: 'school_name' },
  { heading: 'School Code', sortBy: 'school_code' },
  { heading: 'School Type', sortBy: 'school_type' },
  { heading: 'Participation Status', sortBy: 'is_participating', isSelect: true, options: [{ label: 'Any', value: '' }, { label: 'Yes', value: 1 }, { label: 'No', value: 0 }] },
  { heading: 'Is Uploaded', sortBy: 'has_students', isSelect: true, options: [{ label: 'Any', value: '' }, { label: 'Yes', value: 1 }, { label: 'No', value: 0 }] },
  { heading: 'Print Materials', sortBy: 'no_print_order', isSelect: true, options: [{ label: 'Any', value: '' }, { label: 'Yes', value: 0 }, { label: 'No', value: 1 }] },
]

export enum SpecialCases {
  AEGROTAT = 'aegrotat',
  DISQUALIFICATION = 'disqualification',
  SPECIALFORMAT = 'special-format',
  SPECIALFORMATSTRACKER = 'special-formats-tracker',
}

export interface aegrotatStudentRow {
  pen: string,
  first_name: string,
  last_name: string,
  assessment: string,
}

export interface disqualificationStudentRow {
  pen: string,
  first_name: string,
  last_name: string,
  assessment: string,
}

export interface SpecialFormat {
  uid: number,
  pen: string,
  firstName: string,
  lastName: string,
  assessmentCode: string,
  content: string,
}

@Component({
  selector: 'bcg-aegrotat-disqualification',
  templateUrl: './bcg-aegrotat-disqualification.component.html',
  styleUrls: ['./bcg-aegrotat-disqualification.component.scss']
})
export class BcgAegrotatDisqualificationComponent implements OnInit {

  triggerDataRefreshInLPTracker: boolean = false;
  isLoading = true;
  isStudentAssessmentInfoLoading = false;

  showAddEditModal: boolean = false;
  showExitModal: boolean = false;
  isExitModalForAddModal: boolean = false;
  showRemoveEditModal: boolean = false;
  showSpecialFormatModal: boolean = false;
  showEditSpecialFormatModal: boolean = false;
  currentAction = "";

  testWindows: TestWindow[] = [];
  selectedTestWindow: TestWindow | null = null;
  selectedTestWindowWithoutTestSession: TestWindow;
  testWindow: TestWindow | null = null;
  todaysTabular: TestWindowTabular = [];
  selectedAssessment: AssessmentComponent;
  specialCaseType: SpecialCases;

  // newStudentLookupResult: LookupResult;
  addOrRemoveCaseLoading: boolean = false;
  errorInSavingAddModal: boolean = false;
  errorInSavingRemoveModal: boolean = false;
  aegrotatOrDisqualificationError: string = ""; // error when using add modal
  noExistingAegrotatOrDisqualificationError: string = ""; //error when using remove modal
  addCaseLookupResult: LookupResult;
  addOrRemoveCaseUid: number;
  addOrRemoveCasePen: string = null;
  addOrRemoveCaseErrors: Record<string, string> = {};
  addOrRemoveCaseFirstName: string;
  addOrRemoveCaseLastName: string;
  specialFormatRowToEdit: any;

  studentTestAttempts: AegrotatTestAttempt[] = [];
  aegrotatWarningMessage: string = "";
  isAnyTestAttemptSubmitted: boolean = false;

  penEntered: boolean = false;
  selectOption: string = "aegrotat";

  aegrotatRows: any[] = [];
  disqualificationRows: any[] = [];
  specialFormatRows: any[] = [];

  selectedCaseType = new FormControl();

  currentAegrotatSort;
  aegrotatSortingDirection: "ASC" | "DESC" = "ASC";

  currentDisqualSort;
  disqualSortingDirection: "ASC" | "DESC" = "ASC";

  currentSpecialCasesSort;
  specialCasesSortingDirection: "ASC" | "DESC" = "ASC";

  specialCases = SpecialCases;
  selectedViewTab = SpecialCases.AEGROTAT;
  viewTabs = [{
    tab: SpecialCases.AEGROTAT,
    caption: 'Aegrotat'}, 
    {tab: SpecialCases.DISQUALIFICATION,
    caption: 'Disqualification'}, 
    {tab: SpecialCases.SPECIALFORMAT,
    caption: 'Special Formats'}, 
    {tab: SpecialCases.SPECIALFORMATSTRACKER,
      caption: 'Special Formats Tracker'}, 
  ];
  filteredAegrotatsRows: any[] = [];
  filteredDisqualificationRows: any[] = [];
  specialFormats: any[];

  aegrotatFilters: any[] = [
    {by: 'pen', activated: false, search: ""},
    {by: 'last_name', activated: false, search: ""},
    {by: 'first_name', activated: false, search: ""},
    {by: 'assessment', activated: false, search: ""},
  ]
  disqualFilters: any[] = [
    {by: 'pen', activated: false, search: ""},
    {by: 'last_name', activated: false, search: ""},
    {by: 'first_name', activated: false, search: ""},
    {by: 'assessment', activated: false, search: ""},
  ];
  fontsFilters: any[] = [
    {by: 'pen', activated: false, search: ""},
    {by: 'last_name', activated: false, search: ""},
    {by: 'first_name', activated: false, search: ""},
    {by: 'font', activated: false, search: ""},
    {by: 'assessment', activated: false, search: ""},
  ]; 

  allDistricts: District[];
  allSchools: School[];
  filteredSchools: School[];
  selectedDistrict: District;
  selectedSchool: School;
  enrolledAssessments: any[];
  errorMessages: {
    firstName: string,
    lastName: string,
    school: string,
  }
  
  gridOptionsAegrotat: GridOptions;
  gridOptionsDisqualification: GridOptions;
  gridOptionsSpecialFormats: GridOptions;

  enrollmentStatus: string;
  enrolledSchoolCode: string;
  enrolledSchoolName: string;

  specialFormatContent: string;
  specialFormatAction: string;
  constructor(
    private bcAccounts: BcAccountsService,
    private bcAssessments: BcAssessmentsService,
    private lang: LangService,
    private loginGuard: LoginGuardService
  ) {
    this.enrolledAssessments = [];
  }

  ngOnInit(): void 
  {
    this.resetErrorMessages();
    this.initGridOptions();

    Promise.all([
      this.bcAssessments.findAvailableTestWindowsByUserRole({ assessment: AssessmentType.GRAD }),
      this.bcAssessments.findActiveTestWindow(AccountType.MINISTRY_ADMIN, AssessmentType.GRAD, false),
    ]).then(async ([allTestWindows, activeTestWindow]) => 
    {
      this.testWindows = allTestWindows.sort((a, b) => moment(a.dateStart).isSameOrBefore(moment(b.dateStart)) ? -1 : 1);

      if (activeTestWindow) 
      {
        this.selectedTestWindowWithoutTestSession = this.testWindows[this.testWindows.findIndex(a => a.id == activeTestWindow.id)];
      } 
      
      else if (this.testWindows.length > 0) 
      {
        this.selectedTestWindowWithoutTestSession = this.testWindows[0];
      }

      await this.onSelectedTestWindowChange();
      this.isLoading = false;
    });
  }

  editSpecialCase(specialFormatRow: any)
  {
    // console.log(specialFormatRow);
    this.specialFormatRowToEdit = specialFormatRow;
    this.specialFormatRowToEdit.fullAssessment = this.findAssessmentFromCodeAndName(specialFormatRow.assessment);
    this.selectedAssessment = this.specialFormatRowToEdit.fullAssessment;
    // console.log(this.specialFormatRowToEdit);
    this.specialFormatContent = specialFormatRow.content;
    this.showEditSpecialFormatModal = true;
  }

  shouldEditSaveButtonBeDisabled(): boolean
  {
    return this.specialFormatRowToEdit.fullAssessment === this.selectedAssessment &&
    this.specialFormatRowToEdit.content === this.specialFormatContent;
  }

  closeEditSpecialFormatModal()
  {
    this.showEditSpecialFormatModal = false;
    this.specialFormatRowToEdit = null;
    this.selectedAssessment = this.selectedTestWindow.assessments[0];
    this.specialFormatContent = "";
  }

  async saveEditSpecialFormatModal()
  {
    this.isLoading = true;
    try 
    {
      await this.bcAccounts.updateSpecialCase(
      this.specialFormatRowToEdit.id,
      this.selectedAssessment.code,
      this.specialFormatContent);
      this.specialFormatRows = await this.bcAccounts.getSpecialFormats(this.selectedTestWindowWithoutTestSession);
    }

    catch(e)
    {
      console.error(e);
      alert("There was an error. Please try again or contact Vretta Support.");
    }

    this.closeEditSpecialFormatModal();
    this.isLoading = false;
  }

  updateFilter(by, aegrotatDisqual){
    if(aegrotatDisqual == 0){
      this.filteredAegrotatsRows = this.aegrotatRows;


      this.aegrotatFilters.map(filter =>{
        if(filter.activated && filter.search != ""){
          if (filter.by == 'pen')
            this.filteredAegrotatsRows = this.filteredAegrotatsRows.filter(item => item.pen.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase()));
          if (filter.by == 'last_name')
            this.filteredAegrotatsRows = this.filteredAegrotatsRows.filter(item => item.last_name.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase()));
          if (filter.by == 'first_name')
            this.filteredAegrotatsRows = this.filteredAegrotatsRows.filter(item => item.first_name.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase()));
          if (filter.by == 'assessment')
            this.filteredAegrotatsRows = this.filteredAegrotatsRows.filter(item => item.assessment.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase()));
        }
      })
    }
    else{
      this.filteredDisqualificationRows = this.disqualificationRows
      this.disqualFilters.map(filter =>{
        if(filter.activated && filter.search != ""){
          if (filter.by == 'pen')
            this.filteredDisqualificationRows = this.filteredDisqualificationRows.filter(item => item.pen.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase()));
          if (filter.by == 'last_name')
            this.filteredDisqualificationRows = this.filteredDisqualificationRows.filter(item => item.last_name.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase()));
          if (filter.by == 'first_name')
            this.filteredDisqualificationRows = this.filteredDisqualificationRows.filter(item => item.first_name.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase()));
          if (filter.by == 'assessment')
            this.filteredDisqualificationRows = this.filteredDisqualificationRows.filter(item => item.assessment.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase()));
        }
      })
    }
  }

  checkActivatedFilter(by, aegrotatDisqual){
    if(aegrotatDisqual == 0){
      const filter = this.aegrotatFilters.find(e => e.by == by);
      if(filter)
        return filter.activated
    }
    else if(aegrotatDisqual == 1){
      const filter = this.disqualFilters.find(e => e.by == by);
      if(filter)
        return filter.activated
    }else{
      const filter = this.fontsFilters.find(e => e.by == by);
      if(filter)
        return filter.activated
    }
    return false;
  }

  toggleFilter(by, aegrotatDisqual){
    if (aegrotatDisqual == 0) {
      const filter = this.aegrotatFilters.find(e => e.by == by);
      if(filter)
        filter.activated = !filter.activated;
      else
        this.aegrotatFilters.push({by: by, activated: true, search: ""})
    }
    else if(aegrotatDisqual == 1){ 
      const filter = this.disqualFilters.find(e => e.by == by);
      if(filter)
        filter.activated = !filter.activated;
      else
        this.disqualFilters.push({by: by, activated: true, search: ""})
    }else{
      const filter = this.fontsFilters.find(e => e.by == by);
      if(filter)
        filter.activated = !filter.activated;
      else
        this.fontsFilters.push({by: by, activated: true, search: ""})
    }
    return false;
  }

  isSortedBy(by, direction, type){
    if (type == 0) {
      if(by == this.currentAegrotatSort && direction == this.aegrotatSortingDirection)
        return true
    }else if(type == 1){ 
      if(by == this.currentDisqualSort && direction == this.disqualSortingDirection)
      return true
    }else if(type == 2){
      if(by == this.currentSpecialCasesSort && direction == this.specialCasesSortingDirection)
      return true
    }
    return false;
  }

  aegrotatDisqualCheck(rows, sortingDirection, currentSort, by) {
    let returningRows = rows;
    if (by == currentSort) {
      if (sortingDirection == "ASC") sortingDirection = "DESC";
      else sortingDirection = "ASC";
    }
    else {
      currentSort = by
      sortingDirection = "ASC";
    }
    return [returningRows, sortingDirection, currentSort]
  }


  changeOrderBy(by, type) {
    let setOfRows;
    let sortingDirection;
    
    if (type == 0) {
      let vals = this.aegrotatDisqualCheck(this.filteredAegrotatsRows, this.aegrotatSortingDirection, this.currentAegrotatSort, by);
      setOfRows = vals[0]; this.aegrotatSortingDirection = vals[1], this.currentAegrotatSort = vals[2];
      sortingDirection = this.aegrotatSortingDirection;
    }
    else if(type == 1){ 
      let vals =this.aegrotatDisqualCheck(this.filteredDisqualificationRows, this.disqualSortingDirection, this.currentDisqualSort, by);
      setOfRows = vals[0]; this.disqualSortingDirection = vals[1], this.currentDisqualSort = vals[2];
      sortingDirection = this.disqualSortingDirection;
    }
    else if(type == 2){ 
      let vals =this.aegrotatDisqualCheck(this.specialFormatRows, this.specialCasesSortingDirection, this.currentSpecialCasesSort, by);
      setOfRows = vals[0]; this.specialCasesSortingDirection = vals[1], this.currentSpecialCasesSort = vals[2];
      sortingDirection = this.specialCasesSortingDirection;
    }


    switch (by) {
      case ('pen'):
        if (sortingDirection == "ASC")
          setOfRows.sort((a, b) => a.pen < b.pen ? -1 : 1);
        else
          setOfRows.sort((a, b) => a.pen > b.pen ? -1 : 1);
        break;
      case ('last_name'):
        if (sortingDirection == "ASC")
          setOfRows.sort((a, b) => a.last_name < b.last_name ? -1 : 1);
        else
          setOfRows.sort((a, b) => a.last_name > b.last_name ? -1 : 1);
        break;
      case ('first_name'):
        if (sortingDirection == "ASC")
          setOfRows.sort((a, b) => a.first_name < b.first_name ? -1 : 1);
        else
          setOfRows.sort((a, b) => a.first_name > b.first_name ? -1 : 1);
        break;
      case ('assessment'):
        if (sortingDirection == "ASC")
          setOfRows.sort((a, b) => a.assessment < b.assessment ? -1 : 1);
        else
          setOfRows.sort((a, b) => a.assessment > b.assessment ? -1 : 1);
        break;
      case ('font'):
        if (sortingDirection == "ASC")
          setOfRows.sort((a, b) => a.content < b.content ? -1 : 1);
        else
          setOfRows.sort((a, b) => a.content > b.content ? -1 : 1);
        break;
    }

    if (type == 0) {
      this.filteredAegrotatsRows = setOfRows;
    }else if(type == 1){
      this.filteredDisqualificationRows = setOfRows;
    }else if(type == 2){
      this.specialFormatRows = setOfRows;
    } 
  }

  async onSelectedTestWindowChange() {
    this.selectedTestWindow = null;
    this.selectedTestWindow = await this.bcAssessments.getTestWindow(this.selectedTestWindowWithoutTestSession.id, AssessmentType.GRAD, false, true);
    // this.assessments = this.selectedTestWindow.assessments;
    this.selectedAssessment = null;
    this.loadData();
    this.onSelectedAssessmentChange(null);
  }

  getTestWindowTitle(testWindow: TestWindow): string {
    return this.bcAssessments.getTestWindowTitle(testWindow);
  }

  getAssessmentDisplayInSelect(assessment: AssessmentComponent): string {
    return assessment.code + ' - ' + assessment.name;
  }

  findAssessmentFromCodeAndName(assessmentCode: string): AssessmentComponent
  {
    // console.log(this.selectedTestWindow.assessments);
    const code = assessmentCode;
    return this.selectedTestWindow.assessments.
    find(assessment => assessment.code === assessmentCode);
  }

  onSelectedAssessmentChange(e) 
  {
    this.aegrotatOrDisqualificationError = "";
    this.noExistingAegrotatOrDisqualificationError = "";
    this.errorInSavingAddModal = false;
    this.errorInSavingRemoveModal = false;
    this.getTestAttemptsStatus();
    this.getAssessmentStatus();
  }

  async loadData() 
  {
    this.isLoading = true;
    this.aegrotatRows = await this.bcAccounts.findTestAttemptMetas(
      this.selectedTestWindow.id,
      "aegrotat",
    );

    this.gridOptionsAegrotat.rowData = this.aegrotatRows;
    this.setAegrotatData(this.aegrotatRows);

    this.disqualificationRows = await this.bcAccounts.findTestAttemptMetas(
      this.selectedTestWindow.id,
      "disqualification",
    );

    this.gridOptionsDisqualification.rowData = this.disqualificationRows;

    this.specialFormatRows = await this.bcAccounts.getSpecialFormats(this.selectedTestWindowWithoutTestSession);
    // console.log(this.specialFormatRows);
    this.gridOptionsSpecialFormats.rowData = this.specialFormatRows;

    this.isLoading = false;
  }

  initGridOptions = (): void => { // ag-grid
    this.gridOptionsAegrotat = {
      rowData: [],
      columnDefs: [
        { field: 'pen', headerName: "PEN", width: 120, filter: 'agTextColumnFilter', },
        { field: 'last_name', headerName: this.lang.tra("lbl_last_name"), width: 180, filter: 'agTextColumnFilter', },
        { field: 'first_name', headerName: this.lang.tra("lbl_first_name"), width: 180, filter: 'agTextColumnFilter',  },
        { field: 'assessment', headerName: "Assessment Code", width: 250, filter: 'agTextColumnFilter', },
      ],
      defaultColDef: {
        suppressMenu: true,
        floatingFilter: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      enableCellTextSelection: true,
      animateRows: true,
      pagination: true,
      paginationPageSize: 10,
      onGridReady: (event: GridReadyEvent) => {
        console.log('The grid is now ready')
      },
    };
    
    this.gridOptionsDisqualification = {
      rowData: [],
      columnDefs: [
        { field: 'pen', headerName: "PEN", width: 120, filter: 'agTextColumnFilter', },
        { field: 'last_name', headerName: this.lang.tra("lbl_last_name"), width: 180, filter: 'agTextColumnFilter', },
        { field: 'first_name', headerName: this.lang.tra("lbl_first_name"), width: 180, filter: 'agTextColumnFilter',  },
        { field: 'assessment', headerName: "Assessment Code", width: 250, filter: 'agTextColumnFilter', },
      ],
      defaultColDef: {
        suppressMenu: true,
        floatingFilter: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      enableCellTextSelection: true,
      animateRows: true,
      pagination: true,
      paginationPageSize: 10,
      onGridReady: (event: GridReadyEvent) => {
        console.log('The grid is now ready')
      },
    };
    
    this.gridOptionsSpecialFormats = {
      rowData: [],
      columnDefs: [
        { field: 'pen', headerName: "PEN", width: 120, filter: 'agTextColumnFilter', },
        { field: 'last_name', headerName: this.lang.tra("lbl_last_name"), width: 180, filter: 'agTextColumnFilter', },
        { field: 'first_name', headerName: this.lang.tra("lbl_first_name"), width: 180, filter: 'agTextColumnFilter',  },
        { field: 'content', headerName: "Content", width: 250, filter: 'agTextColumnFilter', },
        { field: 'assessment', headerName: "Assessment Code", width: 250, filter: 'agTextColumnFilter', },
      ],
      defaultColDef: {
        suppressMenu: true,
        floatingFilter: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      enableCellTextSelection: true,
      animateRows: true,
      pagination: true,
      paginationPageSize: 10,
      onGridReady: (event: GridReadyEvent) => {
        console.log('The grid is now ready')
      },
    };
  }

  async updateTable() {
    this.isLoading = true;
    await this.loadData();
    this.isLoading = false;
  }

  async onNewPage() {
    await this.updateTable();
  }

  async onSelectedDistrictChange() 
  {
    this.filteredSchools = await this.getSchools(this.selectedDistrict.groupId);
    this.selectedSchool = this.filteredSchools[0];
  }

  getDisplayDistrict(district: District): string 
  {
    return this.bcAccounts.getDistrictDisplay(district);
  }

  getDisplaySchool(school: School): string
  {
    return this.bcAccounts.getSchoolDisplay(school);
  }

  formatDistrict(district: number): string {
    return this.bcAccounts.formatDistrictCode(district);
  }

  formatSchool(school: number): string {
    return this.bcAccounts.formatSchoolCode(school);
  }

  public resetAddOrRemoveEditModal(): void
  {
    this.addOrRemoveCasePen = null;
    this.currentAction = "";
    delete this.addOrRemoveCaseErrors.pen;
    this.selectedCaseType.reset();
    this.selectedAssessment = null;
    this.addOrRemoveCaseUid = null;
    this.errorInSavingAddModal = false;
    this.errorInSavingRemoveModal = false;
    this.showExitModal = false;
    this.isExitModalForAddModal = false;
    this.resetEnrollmentMessage();
  }

  public openAddEditModal(): void
  {
    this.resetAddOrRemoveEditModal();
    this.showAddEditModal = true;
  }

  public closeAddEditModal(): void 
  {
    this.showAddEditModal = false;
  }

  public displayModalExitConfirmation(isForAddingModal: boolean): void
  {
    // if isForAddingModal == false, the funtion is called for removing aegrotat/disqualifications
    // only for when "save" is clicked after adding and removing aegrotat/disqualifications
    this.showExitModal = true;
    this.isExitModalForAddModal = isForAddingModal;
    this.currentAction = this.isExitModalForAddModal ? "adding" : "removing";
    this.closeAddEditModal();
    this.closeRemoveEditModal();
  }

  public closeAllModals(): void
  {
    this.showExitModal = false;
  }

  public closeExitModalAndKeepAddOrRemoveModal(): void
  {
    this.showExitModal = false;
    if (this.isExitModalForAddModal)
    {
      this.showAddEditModal = true;
    }

    else 
    {
      this.showRemoveEditModal = true;
    }
  }

  public closeSpecialFormatModal(): void 
  {
    this.resetStatesForSpecialFormatModal();
    this.showSpecialFormatModal = false;
  }

  public openSpecialFormatModal(action: string): void 
  {
    this.resetStatesForSpecialFormatModal();
    this.specialFormatAction = action;
    this.specialCaseType = SpecialCases.SPECIALFORMAT;
    this.showSpecialFormatModal = true;
  }

  public openRemoveEditModal(): void
  {
    this.resetAddOrRemoveEditModal();
    this.showRemoveEditModal = true;
  }

  public closeRemoveEditModal(): void 
  {
    this.showRemoveEditModal = false;
  }

  public isSelectedCaseTypeAegrotat(): boolean
  {
    return this.selectedCaseType.value === SpecialCases.AEGROTAT;
  }

  public formatDate(dateTime: moment.Moment): string
  {
    if (dateTime == null)
    {
      return "";
    }

    return dateTime.tz("America/Vancouver").format("MMMM Do, YYYY h:mm A") + " PST";
  }
  
  public formatSubmissionStatus(isSubmitted: boolean): string
  {
    return isSubmitted ? "Submitted" : "In Progress";
  }
  
  public setAegrotatWarningMessageAndFlag(studentTestAttempts: AegrotatTestAttempt[]): void
  {
    this.isAnyTestAttemptSubmitted = false;
    this.aegrotatWarningMessage = "";

    if (studentTestAttempts.length !== 0)
    { 
      for (let i = 0; i<studentTestAttempts.length; i++)
      {
        if (studentTestAttempts[i].isSubmitted)
        {
          this.isAnyTestAttemptSubmitted = true; 
          break;
        }
      }

      this.aegrotatWarningMessage = this.isAnyTestAttemptSubmitted ?
      `This student has completed one or more parts of the ${this.selectedAssessment.code}
        assessment and therefore is not eligible for aegrotat status.` :
      `This student has one or more parts of the ${this.selectedAssessment.code}
       assessment in progress, but is still elibile for aegrotat status.`;
    }

    else 
    {
      this.aegrotatWarningMessage = `This student has not started the ${this.selectedAssessment.code} assessment for the currently 
      selected assessment session. This student is eligible for aegrotat status.`
    }
  }

  public formatSchoolDisplay(schoolName: string, schoolCode: number | string): string
  {
    schoolName = schoolName == null ? "" : schoolName;
    schoolCode = schoolCode == null ? "" : schoolCode;

    return schoolName + " (" + schoolCode + ")";
  }

  public async getTestAttemptsStatus()
  {
    this.isStudentAssessmentInfoLoading = true;
    if (this.isSelectedCaseTypeAegrotat() && this.selectedAssessment != null)
    {
      this.studentTestAttempts = await this.bcAccounts.getTestAttempts(
      this.selectedTestWindow.id,
      this.addOrRemoveCaseUid,
      this.selectedAssessment.code
      );

      this.setAegrotatWarningMessageAndFlag(this.studentTestAttempts);
      console.log(this.studentTestAttempts);
    }
    this.isStudentAssessmentInfoLoading = false;
  }

  public async onSelectedCaseTypeChange(caseType: "aegrotat" | "disqualification", getTestAttempts: boolean)
  {
    this.aegrotatOrDisqualificationError = "";
    this.errorInSavingAddModal = false;
    this.errorInSavingRemoveModal = false;
    this.noExistingAegrotatOrDisqualificationError = "";

    // console.log(caseType);
    if (caseType === "aegrotat" && getTestAttempts)
    {
      this.getTestAttemptsStatus();
    }
  }

  async saveAddEditModal() 
  {
    this.isLoading = true;

    this.resetErrorMessages();
    if (this.isPenNotExistCase()) {
      this.addOrRemoveCaseUid = await this.enrollStudent();

      if (!(this.addOrRemoveCaseUid > 0)) return;
      this.addCaseLookupResult = LookupResult.EXISTS_IN_ANOTHER_SCHOOL;

    }

    // enrollment already happens in the API if needed when 
    // making the aegrotat/disqualifiaction, there is no need for this at all
    // if (this.isPenExistButNoEnrollCase()) {
    //   this.addOrRemoveCaseUid = await this.reEnrollStudent();

    //   if (!(this.addOrRemoveCaseUid > 0)) return;
    // }

    try
    {
      await this.bcAccounts.createSpecialCase({
        test_window_id: this.selectedTestWindow.id,
        uid: this.addOrRemoveCaseUid,
        assessment: this.selectedAssessment.code,
        caseType: this.selectedCaseType.value,
        school: this.selectedSchool?.groupId,
      });
      
      this.displayModalExitConfirmation(true);
      await this.updateTable();
    }

    catch(error)
    {
      console.error(error);
      if (error.message && error.message === "existingAegrotatOrDisqualification")
      {
        let errorType: string = "";
        let errorSlug: string = "";
        let testWindowTitle = this.getTestWindowTitle(this.selectedTestWindow);

        if (error.data.errorKey && error.data.errorSlug)
        {
          errorType = error.data.errorKey;
          errorSlug = error.data.errorSlug.slice(5); // remove "GRAD_"

          if (errorType === "aegrotat")
          {
            this.aegrotatOrDisqualificationError = "This student has already been granted aegrotat status for " + errorSlug
            + " for the " + testWindowTitle + " session.";
          }

          else if (errorType == "disqualification")
          {
            this.aegrotatOrDisqualificationError = "This student has already been disqualified for " + errorSlug
            + " for the " + testWindowTitle + " session.";
          }
          this.errorInSavingAddModal = true;     
        }
      }

      else
      {
        if (error.message != null)
        {
        this.aegrotatOrDisqualificationError = this.lang.tra(error.message);
        }

        else
        {
          this.aegrotatOrDisqualificationError = error;
        }

        this.errorInSavingAddModal = true;
      }
    }
    this.isLoading = false;
  }

  async saveRemoveEditModal()
  {
    this.isLoading = true;

    try
    {
      await this.bcAccounts.removeSpecialCase(this.selectedTestWindow.id, this.addOrRemoveCaseUid, this.selectedAssessment.code, this.selectedCaseType.value);
      this.displayModalExitConfirmation(false);
      await this.updateTable(); 
    }

    catch(error)
    {
      if (error.message && error.message === "noExistingAegrotatOrDisqualificationFound")
      {
        let testWindowTitle = this.getTestWindowTitle(this.selectedTestWindow);
        this.noExistingAegrotatOrDisqualificationError = "This student has no " + this.selectedCaseType.value + " status for " + this.selectedAssessment.code 
        + " for the " + testWindowTitle + " session."; 
        this.errorInSavingRemoveModal = true;
      }

      else
      {
        if (error.message != null)
        {
          this.noExistingAegrotatOrDisqualificationError = error.message;
        }

        else
        {
          this.noExistingAegrotatOrDisqualificationError = error;
        }
        
        this.errorInSavingRemoveModal = true;
      }
    }

    this.isLoading = false;
  }

  async lookupPen2() 
  {
    // only used for aegrotats and disqualifiactions and not for special formats

    delete this.addOrRemoveCaseErrors.pen;
    this.addOrRemoveCaseLoading = true;
    this.errorInSavingAddModal = false;
    this.errorInSavingRemoveModal = false;
    this.selectedCaseType.setValue(null);
    this.addOrRemoveCaseUid = null;
    this.resetErrorMessages();
    this.enrolledAssessments = [];
    this.selectedAssessment = null;
    this.studentTestAttempts = [];
    this.aegrotatWarningMessage = "";
    this.isAnyTestAttemptSubmitted = false;

    this.allSchools = await this.getAllSchools();

    if (this.addOrRemoveCasePen == "" || this.addOrRemoveCasePen == null)
    {
      this.addOrRemoveCaseErrors.pen = "Please provide a PEN.";
      this.addOrRemoveCaseLoading = false;
      return;
    }

    if (this.addOrRemoveCasePen.length !== 9)
    {
      this.addOrRemoveCaseErrors.pen = "PENs must be 9 digits long.";
      this.addOrRemoveCaseLoading = false;
      return;
    }

    if (!this.bcAccounts.validatePen(this.addOrRemoveCasePen))
    {
      this.addOrRemoveCaseErrors.pen = "This PEN is invalid.";
      this.addOrRemoveCaseLoading = false;
      return;
    }

    const { result, uid, school_group_id } = await this.bcAccounts.lookup2Find(this.addOrRemoveCasePen, this.selectedTestWindow.id, undefined, false, "", true);
    this.addCaseLookupResult = result;

    if (this.addCaseLookupResult === LookupResult.EXISTS_IN_ANOTHER_SCHOOL && school_group_id > 0)
    {
      this.addOrRemoveCaseUid = uid;
      await this.setupEnrolledAssessments(this.addOrRemoveCaseUid);
      await this.setupDefaultDistrictsAndSchools();
    }

    else if (this.addCaseLookupResult === LookupResult.NOT_EXISTS)
    {
      this.addOrRemoveCaseUid = -1;
      this.addOrRemoveCaseErrors.pen = 
      `
        No student found with PEN ${this.addOrRemoveCasePen}. This means this student is not currently registered to a school. 
        In order to track an Aegrotat or Disqualification (which will be transferred to TRAX), 
        please register this student in an Assessment Centre first.
      `;
      await this.setupDefaultDistrictsAndSchools();
    }
    
    else if (this.addCaseLookupResult === LookupResult.EXISTS_IN_ANOTHER_SCHOOL && school_group_id < 0)
    {
      // this is case LookupResult.EXISTS_BUT_NO_SCHOOL
      this.addCaseLookupResult = LookupResult.EXISTS_BUT_NO_SCHOOL;
      this.addOrRemoveCaseUid = uid;
      await this.setupEnrolledAssessments(this.addOrRemoveCaseUid);
      await this.setupDefaultDistrictsAndSchools();
    }

    else 
    {
      // case exists in LookupResult.EXISTS_IN_THIS_SCHOOL is:
      // unreachable since the school id is not provided with the lookup api call
    }
  
    this.addOrRemoveCaseLoading = false;
  }

  async lookupPenForSpecialFormat() {
    if (this.addOrRemoveCasePen.length !== 9) {
      this.addOrRemoveCaseErrors.pen = "PENs must be 9 digits long.";
      this.addOrRemoveCaseLoading = false;
      return;
    }

    if (!this.bcAccounts.validatePen(this.addOrRemoveCasePen)) {
      this.addOrRemoveCaseErrors.pen = "This PEN is invalid.";
      this.addOrRemoveCaseLoading = false;
      return;
    }

    this.resetStatesForLookupPen();
    // console.log(this.specialFormatAction);

    if (this.specialFormatAction === 'add') {
      const { result, uid } = await this.bcAccounts.lookup2Find(
        this.addOrRemoveCasePen, 
        this.selectedTestWindow.id, 
        undefined, 
        false, 
        "", 
        true
      );

      this.addCaseLookupResult = result;

      switch (this.addCaseLookupResult) {
        case LookupResult.EXISTS_IN_ANOTHER_SCHOOL:
          this.addOrRemoveCaseUid = uid;
          await this.setupEnrolledAssessments(this.addOrRemoveCaseUid);
          break;
        case LookupResult.NOT_EXISTS:
          this.addOrRemoveCaseUid = -1;
          this.addOrRemoveCaseErrors.pen = 
          `
            No student found with PEN ${this.addOrRemoveCasePen}. This means this student is not currently registered to a school. 
            In order to add a special format (which will be transferred to TRAX), 
            please register this student in an Assessment Centre first.
          `;
          break;
        case LookupResult.EXISTS_BUT_NO_SCHOOL:
          this.addOrRemoveCaseUid = uid;
          break;
        case LookupResult.EXISTS_IN_THIS_SCHOOL:
          // unreachable since the school id is not provided with the lookup api call
          break;
        default:
          break;
      }
    }

    if (this.specialFormatAction === 'remove') {
      this.specialFormats = this.specialFormatRows.filter(sfr => sfr.pen === this.addOrRemoveCasePen.trim());

      if (!(this.specialFormats.length > 0)) {
        this.addOrRemoveCaseErrors.pen = `No special format related to this PEN: ${this.addOrRemoveCasePen}`;
      }
    }
  
    this.addOrRemoveCaseLoading = false;
  }

  isNextStepEnabled() {
    // console.log(this.addOrRemoveCaseUid);
    // console.log(this.addOrRemoveCaseUid > 0 || this.addOrRemoveCaseUid === -1);
    return this.addOrRemoveCaseUid > 0 || this.addOrRemoveCaseUid === -1;
  }

  isPenNotExistCase(): boolean {
    return this.addOrRemoveCaseUid === -1 && this.addCaseLookupResult === LookupResult.NOT_EXISTS;
  }

  isPenExistButNoEnrollCase(): boolean {
    return this.addOrRemoveCaseUid > 0 && this.addCaseLookupResult === LookupResult.EXISTS_BUT_NO_SCHOOL;
  }

  isPenExistWithEnrollmentCase(): boolean {
    return this.addOrRemoveCaseUid > 0 && this.addCaseLookupResult === LookupResult.EXISTS_IN_ANOTHER_SCHOOL;
  }

  async setupDefaultDistrictsAndSchools() 
  {
    this.allDistricts = await this.getAllDistricts();
    this.selectedDistrict = this.allDistricts[0];
    this.filteredSchools = await this.getSchools();
    this.selectedSchool = this.filteredSchools[0];
    // console.log(this.selectedSchool);
  }

  isAllSchoolsSelected(): boolean
  {
    return this.selectedSchool === ALL_SCHOOLS;
  }

  async getAllDistricts(): Promise<District[]> 
  {
    return this.bcAccounts.findDistricts(AssessmentType.GRAD, true);
  }

  async getSchools(district?: number): Promise<School[]> 
  {
    const schools = await this.bcAccounts.findSchools(district, "grad", true, true);
    return schools;
  }

  async getAllSchools(): Promise<School[]> 
  {
    const schools = await this.bcAccounts.findSchools(undefined, "grad", true, true);
    return schools.filter(s => s.groupId > 0);
  }

  resetErrorMessages() {
    this.errorMessages = {
      firstName: null,
      lastName: null,
      school: null,
    }
  }

  async enrollStudent(): Promise<number> 
  {
    this.isLoading = true;

    this.validateEnrollStudentForm();
    if (!this.isEnrollStudentFormValid(true)) return -1;

    try {
      const response = await this.bcAccounts.enrollStudentToGradSchool(
        this.addOrRemoveCasePen,
        this.selectedTestWindowWithoutTestSession.id,
        this.selectedSchool.groupId,
        this.addOrRemoveCaseFirstName,
        this.addOrRemoveCaseLastName
      )

      return response.user?.id || -1;
    } 
    
    catch (e) 
    {
      console.error(e);
      if (e.message != null)
      {
        this.aegrotatOrDisqualificationError = this.lang.tra(e.message);
      }

      else
      {
        this.aegrotatOrDisqualificationError = e;
      }

      this.errorInSavingAddModal = true;
    }

    this.isLoading = false;
  }

  async reEnrollStudent(): Promise<number> {
    try {
      const response = await this.bcAccounts.registerStudentToGradAssessmentComponents(
        this.addOrRemoveCaseUid,
        this.selectedTestWindowWithoutTestSession.id,
        this.selectedSchool.groupId,
        `GRAD_${this.selectedAssessment.code}`
      )

      return response.created?.[0]?.uid || -1;
    } 
    
    catch (e) 
    {
      console.error(e);
      if (e.message != null)
      {
      this.aegrotatOrDisqualificationError = this.lang.tra(e.message);
      }

      else
      {
        this.aegrotatOrDisqualificationError = e;
      }

      this.errorInSavingAddModal = true;
    }
  }

  isEnrollStudentFormValid(nameCheckRequired: boolean): boolean 
  {
    if (nameCheckRequired)
    {
      const firstName = typeof this.addOrRemoveCaseFirstName === 'string' && this.addOrRemoveCaseFirstName.trim();
      const lastName = typeof this.addOrRemoveCaseLastName === 'string' && this.addOrRemoveCaseLastName.trim();

      if (!firstName || !lastName) 
      {
        return false;
      }
    }
    
    const schoolGroupId = this.selectedSchool?.groupId || -1;
    
    if (!(schoolGroupId > 0) || !this.selectedTestWindow.id
    || !this.selectedAssessment?.code || !this.selectedCaseType?.value)
    {
      return false;
    } 
    
    return true;
  }

  isCreateSpecialCaseFormValid(): boolean {
    return this.selectedTestWindow.id && this.addOrRemoveCaseUid > 0 && this.selectedAssessment?.code && this.selectedCaseType?.value;
  }

  validateEnrollStudentForm(): void {
    if (!this.addOrRemoveCaseFirstName) {
      this.errorMessages.firstName = 'Missing first name.';
    }

    if (!this.addOrRemoveCaseLastName) {
      this.errorMessages.lastName = 'Missing last name.';
    }

    if (!(this.selectedSchool?.groupId > 0)) {
      this.errorMessages.school = 'Please select a school to proceed.';
    }
  }

  isSaveBtnDisabled() 
  {
    console.log(this.addCaseLookupResult);
    if ((this.isSelectedCaseTypeAegrotat() && this.isAnyTestAttemptSubmitted) || 
    this.isLoading || this.isStudentAssessmentInfoLoading)
    {
      return true;
    }

    if (this.ifSchoolSelectionRequired() && this.isAllSchoolsSelected())
    {
      return true;
    }

    if (this.isPenNotExistCase()) 
    {
      return !this.isEnrollStudentFormValid(true);
    } 
    
    else if (this.isPenExistWithEnrollmentCase()) 
    {
      return !this.isEnrollStudentFormValid(false);
    }
    
    else if (this.isPenExistButNoEnrollCase()) 
    {
      return !this.isEnrollStudentFormValid(false);
    }

    return true;
  }

  isAssessmentsReady() {
    // console.log(this.selectedTestWindow);
    return this.selectedTestWindow?.assessments?.length > 0;
  }

  async setupEnrolledAssessments(uid: number): Promise<void> {
    this.enrolledAssessments = await this.bcAccounts.getStudentAssessmentEnrollStatusByTestWindow(uid, this.selectedTestWindow.id);
  }

  ifSchoolSelectionRequired() 
  {
    return [LookupResult.EXISTS_BUT_NO_SCHOOL, LookupResult.NOT_EXISTS, LookupResult.EXISTS_IN_ANOTHER_SCHOOL].includes(this.addCaseLookupResult); 
  }


  getAssessmentStatus(): void {
    if (!this.selectedAssessment) return this.resetEnrollmentMessage();
    if (this.isPenNotExistCase()) return this.resetEnrollmentMessage();

    let enrolledAssessment;
    let schoolDetail;

    if (this.enrolledAssessments?.length > 0) {
      enrolledAssessment = this.enrolledAssessments.find(ea => ea.test_session_slug === `GRAD_${this.selectedAssessment.code}`);
      schoolDetail = this.allSchools?.find(s => s.groupId === enrolledAssessment?.school_group_id);      
    }
  
    this.enrollmentStatus =  `This student is currently ${enrolledAssessment ? 'registered' : 'unregistered'} to ${this.selectedAssessment.code} ${enrolledAssessment ? 'at this Assessment centre:' : 'at any school.'}`;
    this.enrolledSchoolCode = `${ schoolDetail ? `School Code: ${schoolDetail.foreignId}` : '' }`;
    this.enrolledSchoolName = `${ schoolDetail ? `School Name: ${schoolDetail.name}` : '' }`;
    
  }

  resetEnrollmentMessage() {
    this.enrollmentStatus = null;
    this.enrolledSchoolCode = null;
    this.enrolledSchoolName = null;
  }

  private resetStatesForSpecialFormatModal(): void {
    this.showAddEditModal = false;
    this.showRemoveEditModal = false;

    this.addOrRemoveCasePen = null;
    this.addCaseLookupResult = null;
    this.addOrRemoveCaseUid = null;

    this.addOrRemoveCaseErrors.pen = null;

    this.specialFormats = [];
  }

  private resetStatesForLookupPen() {
    this.addCaseLookupResult = null;
    this.addOrRemoveCaseUid = null;

    this.addOrRemoveCaseErrors.pen = null;
  }

  public isNextStepEnabledForAddSpecialFormat(): boolean {
    return this.addCaseLookupResult === LookupResult.EXISTS_IN_ANOTHER_SCHOOL && this.addOrRemoveCaseUid > 0;
  }

  public isNextStepEnabledForRemoveSpecialFormat(): boolean {
    return this.specialFormats?.length > 0;
  }

  public isSaveSpecialFormatDisabled(): boolean 
  {
    if (this.specialFormatAction == 'remove')
    {
      return false;
    }
    // console.log(this.specialFormatContent);
    // console.log(this.selectedAssessment);
    // console.log(this.isSpecialFormatValid());
    if (this.isSpecialFormatValid()) return false;

    return true;
  }

  private isSpecialFormatValid(): boolean {
    return this.selectedAssessment?.code && this.specialFormatContent?.trim().length > 0;
  }

  public async saveSpecialFormat(): Promise<void> 
  {
    if (this.specialFormatAction == 'remove')
    {
      console.log("Already saved.");
      this.closeSpecialFormatModal();
      return;
    }

    console.log(this.specialFormatContent);
    try 
    {
      const response = await this.bcAccounts.createSpecialCase(
      {
        test_window_id: this.selectedTestWindow.id,
        uid: this.addOrRemoveCaseUid,
        assessment: this.selectedAssessment.code,
        caseType: SpecialCases.SPECIALFORMAT,
        content: this.specialFormatContent
      });

      console.log(response);

      this.triggerDataRefreshInLPTracker = true;
      this.closeSpecialFormatModal();
      await this.updateTable();
      this.triggerDataRefreshInLPTracker = false;
    } 
    
    catch(e) 
    {
      console.error(e);
      this.closeSpecialFormatModal();
      this.loginGuard.quickPopup(`There was an unexpected error in adding the special format, 
      and it has not been added. Please contact Vretta Support.`);
    }
  }

  async revokeSpecialFormat(specialFormat: any) 
  {
    // console.log(specialFormat);
    // test code to push
    try
    {
      await this.bcAccounts.removeSpecialFormats(specialFormat.id);
    }

    catch(e)
    {
      console.error(e);
      this.closeSpecialFormatModal();
      this.loginGuard.quickPopup(`There was an unexpected error in adding the special format, 
      and it has not been added. Please contact Vretta Support.`);
    }
    
    this.triggerDataRefreshInLPTracker = true;
    await this.updateTable();
    this.specialFormats = this.specialFormatRows.filter(sfr => sfr.pen === this.addOrRemoveCasePen.trim());
    this.triggerDataRefreshInLPTracker = false;
  }

  isViewTabSelected(tab: SpecialCases): boolean{
    return this.selectedViewTab == tab;
  }
  selectViewTab(tab: SpecialCases){
    this.selectedViewTab = tab;
  }

  downloadAsCsv(gridOptions: GridOptions){
    let params = {
      processCellCallback: (params: any) => {
        return params.value;
      }
    };

    if (gridOptions.api) {
      gridOptions.api.exportDataAsCsv(params);
    }

    return;
  }

  setAegrotatData(data: any): void {
    this.gridOptionsAegrotat?.api?.setRowData(data);
  }

  getEnrolledAssessments() {
    const enrolledAssessments = new Set();
    this.enrolledAssessments?.forEach?.(item => enrolledAssessments.add(item?.test_session_slug?.split?.("_")?.[1]));
    return this.selectedTestWindow.assessments.filter(item => enrolledAssessments.has(item.code));
  }
}
