<div [class.page-body]="isMarkingCoordView">
    <div class="content-container">
        <div *ngIf="isMarkingCoordView" id="pageHeader">
            <h4>
                <a [routerLink]="'/'+lang.c()+'/'+auth.myActiveMarkingRole(markingSessionId, true)+'/dashboard'"><i class="fas fa-home"></i></a> / <a (click)="navigateToFinalReview()">{{lang.tra('mrkg_fsa_marking_monitoring')}}</a> / {{districtReport ? 'Marking Monitoring Report' : 'Discrepancy Report'}} 
            </h4>     
        </div>
        <div class="body waiting" *ngIf="!isLoaded">
            <loading-indicator></loading-indicator>
        </div>
        <div *ngIf="isLoaded && !districtReport" class="body">
            <ng-container *ngIf="pageData.length < showReportThreshold">
                <tra slug="fsa_monitoring_report_no_enough_data"></tra>
            </ng-container>
            <ng-container *ngIf="pageData.length >= showReportThreshold">
                <div>
                    <h2 *ngIf="isMarkingCoordView">
                        <tra slug="mrkg_marking_session"></tra>
                    </h2>
                    <h1>
                        {{markingSessionName}}
                    </h1>
                </div>
                <div [class.big-card-admin]="(this.auth.isMinistryAdmin() || districtAdminMode)" [class.big-card]="isMarkingCoordView">
                    <div  *ngIf="isLoaded" class="main-container">
                        <div [ngStyle]="{ flexGrow: '2'}">
                            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                <div>
                                    <h3>Foundation Skills Assessment</h3>
                                </div>
                                <div>
                                    <button *ngIf="isMarkingCoordView" class="button" (click)="goBackToFinalResultsRoute()">Back to the 'Reports' table</button>
                                </div>
                            </div>
                            
                            <div id="to-print">
                                <div id="class-header">
                                    <!--<h4>Percentage of Score Differences</h4>-->
                                    <div style="display:flex;">
                                        <h5 style="margin-right:1em;">Grade<br><strong>{{this.selectedGrade}}</strong></h5>
                                        <h5 style="margin-right:1em;">Assessment<br><strong>{{this.selectedAssessment}}</strong></h5>
                                        <h5 style="margin-right:1em;">Item<br><strong>{{this.selectedQuestion}}</strong></h5>
                                    </div>
                                </div>
                                <div class="canvas-container" [style]="(this.auth.isMinistryAdmin() || districtAdminMode) ? 'max-width: 70%;' : 'max-width: 50%; margin-bottom: 4em;'">
                                    <canvas baseChart id='chartCanvas'
                                        [datasets]="barChartData"
                                        [labels]="barChartLabels"
                                        [options]="barChartOptions"
                                        [chartType]="barChartType">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                        <div class="toolbar" style="display: flex; flex-direction: row;">
                            <button style="margin-bottom: 1em" class="button is-small is-info" (click)="exportExcelFile()">Export</button>
                            <button class="button is-small is-info" (click)="printGraph()">Print Graph</button>
                        </div>
                        <div [ngStyle]="{ marginRight: '15px', flexGrow: '1' }">
                            <table *ngIf="false" class="info-table">
                                <th *ngFor="let header of studentTableHeaders">
                                    <div class="table-header-content">
                                        {{ header }}
                                    </div>
                                </th>
                                <tr 
                                [ngClass]="{ 'student-row': selectedStudent !== student, 'student-row-selected': selectedStudent === student }"
                                class="student-row"  
                                *ngFor="let student of pageData">
                                    <td>
                                        {{ maskPEN(student.pen) }}
                                        
                                    </td>
                                    <td>
                                        Grade {{ student.grade }}
                                    </td>
                                    <td>
                                        {{ student.assessment_code }}
                                    </td>
                                    <td>
                                        {{ student.item_name}}
                                    </td>
                                    <td>
                                        {{ student.language }}
                                    </td>
                                    <td>
                                        {{ student.local_score }}
                                    </td>
                                    <td>
                                        {{ student.monitoring_score }}
                                        &nbsp;&nbsp;
                                        <i *ngIf="student.is_exemplar" [matTooltip]="'Marked in exemplar selection'"class="fas fa-book"></i>
                                    </td>
                                </tr>
                            </table>
                            <ag-grid-angular
                                class="ag-theme-alpine ag-grid-fullpage info-table"
                                [rowData]="students"
                                [columnDefs]="studentColumnDefs"
                                [enableCellTextSelection]="true"
                                [suppressDragLeaveHidesColumns]="true"
                                [animateRows]=true
                                rowSelection="single"
                                
                                (modelUpdated)="cacheFilteredStudentRows()"
                                (gridReady)="onStudentGridReady($event)"
                            ></ag-grid-angular>
                            <span *ngIf="currStudents.length == 0">No students match this criteria</span>
                        </div>
                    </div>
                    <div *ngIf="!isLoaded"><loading-indicator></loading-indicator><br>
                        Loading discrepancy report...
                    </div>
                </div>
            </ng-container>
        </div>
        <div *ngIf="isLoaded && districtReport" class="body" id="FSAMonitoringReportPrint">
            <ng-container *ngIf="!shouldShowMonitoringReport()">
                <tra slug="fsa_monitoring_report_no_enough_data"></tra>
            </ng-container>

            <ng-container *ngFor="let district of selectedDistrictCodeForReport, index as district_i">
                <ng-container *ngIf="shouldShowDistrict(district)">
                <div [class.big-card-admin]="(this.auth.isMinistryAdmin() || districtAdminMode)" [class.big-card]="isMarkingCoordView" *ngFor="let grade of availableGrades, index as grade_i ">
                    <span class="back-button">
                        <span>
                            <button *ngIf="!(districtAdminMode)" class="button" (click)="handleEditButton()"><span *ngIf="!districtReportEditMode">Edit&nbsp;</span>
                                <span *ngIf="districtReportEditMode">Save&nbsp;</span> Report</button>
                            <button class="button" (click)="exportFSAReport()">Export Data</button>
                            <button class="button" (click)="printReport()">Print Report</button>
                        </span>
                        <button *ngIf="grade_i == 0 && district_i == 0 && isMarkingCoordView" class="button" (click)="goBackToFinalResultsRoute()">Back to the 'Reports' table</button>
                    </span>
                    <span class="header-area">
                        <span style="font-size: 1.5em; margin: .8em 0em;">
                            <span *ngIf="!districtReportEditMode">{{customizableReportSections.header}}:</span>
                            <input *ngIf="districtReportEditMode" type="text" class="EditingInput"
                                [(ngModel)]="customizableReportSections.header" />
                            Marking Monitoring Report
                        </span>
                        <ng-container *ngIf="district; else independent">
                            <span class="sub-header" *ngIf="selectedDistrictCodeForReport">
                                School District {{district}} <span>({{districtNameFromCode(district)}})</span>
                            </span>
                        </ng-container>
                        <ng-template #independent>
                            <span class="sub-header" *ngIf="selectedDistrictCodeForReport">
                                Independent Schools
                            </span>
                        </ng-template>
                    </span>
                    <div class="report-body">
                        <div class="report-body-text">
                            <span *ngIf="!districtReportEditMode" style="white-space: pre-wrap;">{{customizableReportSections.body}}</span>
                            <span style="width: 100%;" *ngIf="districtReportEditMode"><textarea rows="12" class="EditingInput text-area-input" [(ngModel)]="customizableReportSections.body" ></textarea></span>
                        </div>
                        <p class="sub-header grade-head">{{grade}} Summary</p>
                        <table style="width: 50%;">
                            <thead>
                                <tr>
                                    <th>Literacy Alignment: {{getData(district, grade, 1, null, false, true)}}%</th>
                                    <th>Numeracy Alignment: {{getData(district, grade, 1, null, false, false, true)}}%</th>
                                </tr>
                            </thead>
                        </table>
                        <p class="sub-header grade-head">{{grade}} Breakdown</p>
                        <table>
                            <thead>
                                <tr>
                                    <th [attr.colspan]="emptyCellColRowSpan" [attr.rowspan]="emptyCellColRowSpan"></th>
                                    <th [attr.colspan]="literacyTheme1Questions.length + literacyTheme2Questions.length">Literacy</th>
                                    <th [attr.colspan]="numeracyQuestions.length" [attr.rowspan]="numeracyRowSpan">Numeracy</th>
                                </tr>
                                <tr>
                                    <th [attr.colspan]="literacyTheme1Questions.length">Theme 1</th>
                                    <th [attr.colspan]="literacyTheme2Questions.length">Theme 2</th>
                                </tr>
                                <tr>
                                    <ng-container *ngFor="let headerSet of questionHeaders">
                                        <th *ngFor="let q of headerSet">{{q}}</th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let disc of discrepancyRange; index as disc_i">
                                    <tr *ngFor="let type of dataType; index as type_i" 
                                        [class.secondary-highlight]="disc == 1 || disc == -1"
                                        [class.main-highlight]="disc == 0"
                                    >
                                        <th *ngIf="disc_i == 0 && type_i == 0" [attr.rowspan]="negativeDiscRange.length * dataType.length">Local<br> Scores<br> Lower</th>
                                        <th *ngIf="disc_i == 3 && type_i == 0" [attr.rowspan]="dataType.length">Aligned</th>
                                        <th *ngIf="disc_i == 4 && type_i == 0" class="regular-cell" [attr.rowspan]="positiveDiscRange.length * dataType.length">Local<br> Scores<br> Higher</th>
                                        <td *ngIf="type_i == 0" [attr.rowspan]="dataType.length">{{disc}}</td>
                                        <th >{{type}}</th>
                                        <td *ngFor="let val of getData(district, grade, type_i, disc); let i = index">{{ getItemTotal(district, grade, i) < 10 ? 'Insufficient data' : val }}</td>
                                    </tr>
                                </ng-container>
                                <tr>
                                    <th [attr.colspan]="totalColSpan">Total</th>
                                    <th>Count</th>
                                    <td *ngFor="let val of getData(district, grade, 0, null); let i = index">{{ getItemTotal(district, grade, i) < 10 ? 'Insufficient data' : val }}</td>
                                </tr>
                                <tr>
                                    <td [attr.colspan]="alignmentColSpan"><b>Alignment %</b><br>
                                        (within one point, -1, 0, or +1)
                                    </td>
                                    <td *ngFor="let val of getData(district, grade, 1, null, true); let i = index">{{ getItemTotal(district, grade, i) < 10 ? 'Insufficient data' : val }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div [class]="isLastReport(district_i, grade_i) ? 'dont-break' : 'report-last-section'">
                            <p><em>Note:</em> Maximum score is 4.0</p><br>
                            <p>The FSA Provincial Marking Monitoring Report is posted on the FSA 
                                <a href="https://www2.gov.bc.ca/gov/content/education-training/k-12/administration/program-management/assessment/foundation-skills-assessment">website.</a>  
                                It provides evidence for the reliability, validity, and fairness of the FSA marking process.
                            </p>
                        </div>
                    </div>
                </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>