import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { IContentElementMoveableDragDrop, IEntryStateMoveableDragAndDrop, IContentElementDndDraggable, IContentElementTextLink, ScoringTypes, IContentElementDndTarget, ElementType, getElementWeight, GradingType } from '../models';
import { indexOf } from '../services/util';
import { HyperlinkService } from '../hyperlink.service';
import { IElementPos, renderDndElementStyle } from '../../ui-item-maker/element-config-grouping/element-config-grouping.component';
import {CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { generateDefaultElementDnd } from "../../ui-item-maker/item-set-editor/models/index"
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { TextToSpeechService } from '../text-to-speech.service';
import { PrintAltTextService } from 'src/app/ui-item-maker/print-alt-text.service';
import * as _ from 'lodash';
import { PubSubTypes } from '../element-render-frame/pubsub/types';



const SCORING_TYPE = ScoringTypes.AUTO;

interface IDraggables {
  targetContext: IContentElementDndTarget; 
  contents:IElementPos[];
  originalPosition:boolean
}
interface IKeyboardDrop{
  lastSrcId: string;
  source: IElementPos[];
}
@Component({
  selector: 'element-render-moveable-dnd',
  templateUrl: './element-render-moveable-dnd.component.html',
  styleUrls: ['./element-render-moveable-dnd.component.scss']
})
export class ElementRenderMoveableDndComponent implements OnInit, OnChanges {

  @Input() element:IContentElementMoveableDragDrop;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() changeCounter:number;
  @Input() questionState:any;
  @Input() questionPubSub?: QuestionPubSub;

  targets: IDraggables[] = [];
  isDragging:boolean;
  private _isDragstarted: boolean = false;

  constructor(public textToSpeech: TextToSpeechService, private renderer: Renderer2, private printAltText: PrintAltTextService) { }

  drag2Targ: Map<string, string[]> = new Map<string, string[]>()
  clickTriggers = new Map();

  ngOnInit() {
    if (this.element.pairMapping) {
      this.element.pairMapping.forEach((pair)=>{
        if (!this.drag2Targ.has(pair.optionID)) {
          this.drag2Targ.set(pair.optionID, [])
        }
        this.drag2Targ.get(pair.optionID).push(pair.targetID)
      })
    }

    this.element.draggables?.forEach((draggableEl, index) => {      
      if(draggableEl.isReusable == null){
        // console.log("isReusable is undefined, default is set to equal isOptionalReusable.")
        draggableEl.isReusable = this.element.isOptionsReusable;
      }
    })
    
    this.ensureState();
  }

  ngOnChanges(changes:SimpleChanges){
    // runs on every intialization
    if (changes.changeCounter) {
      this.updateDisplayEls();
    }
  }

  keyboardDrop:IKeyboardDrop ={
    lastSrcId: "",
    source: [],
  }
  
  private _resetlastSelection(){
    this.keyboardDrop.source = []
    this.keyboardDrop.lastSrcId = ""
  }
  
  isSelected(target){
    return this.keyboardDrop.source.length && target.contents === this.keyboardDrop.source
  }

  /**
   * Funcationality for accesibility
   * - unselect if selected same draggable again
   * - If we select another draggable and there is already one selected - unselect the previous and select the current
   * - If the current draggable is selected and we select container the draggable will be placed inside container
   * - If the current draggable is placed inside container and we try to place another draggable the 1st draggable should send itself home
   */

  onEnter(e, target:IDraggables, index) {
    //  index = index of this.targets
    //  console.log(this.targets, target, this.keyboardDrop);
    e.stopPropagation()
    if(/*e.srcElement.id === this.keyboardDrop.lastSrcId*/ index.toString()==this.keyboardDrop.lastSrcId){
      this._resetlastSelection();
      return;
    }

    if (target) {
      if (target.contents.length && (_.isEmpty(this.keyboardDrop.source) || target.originalPosition)) {
        this.keyboardDrop.source = target.contents;
        this.keyboardDrop.lastSrcId = index;
      } else {
        if (!_.isEmpty(this.keyboardDrop.source)) {
          const source = this.keyboardDrop.source;
          const draggable = source[0];
          const dest = this.targets[index].contents;
          const hasContent = dest.length > 0;
          const isDestHomeTarget = this.homeTargetContents.get(dest);
          const isSourceHomeTarget = this.homeTargetContents.get(source);
          const draggableHome = this.homeTargetMap.get(draggable) || {};
          const isSelfHome = dest === draggableHome.contents;
          const dropTarget = target;
          this._drop({ source, draggable, dest, hasContent, isDestHomeTarget, isSourceHomeTarget, isSelfHome, dropTarget }, {})
          this._resetlastSelection()
        }
      }
    }
  }

  drop(event: CdkDragDrop<string[]>, target) {
    const source = event.previousContainer.data;
    const draggable = source[0];
    const dest = event.container.data;
    const hasContent = (event.container.data.length > 0);
    const isDestHomeTarget = this.homeTargetContents.get(dest)
    const isSourceHomeTarget = this.homeTargetContents.get(source)
    const draggableHome = this.homeTargetMap.get(draggable) || {};
    const isSelfHome = (dest === draggableHome.contents);
    const dropTarget = target;
    this._drop({ source, draggable, dest, hasContent, isDestHomeTarget, isSourceHomeTarget, isSelfHome, dropTarget }, event)
    this._resetlastSelection()
  }

  sendHome = (draggable, sendSource) => {
    const swapElement = draggable;
    const homeTarget = this.homeTargetMap.get(swapElement) || {};
    const swapTargetList = homeTarget.contents;
    if (swapTargetList){
        transferArrayItem(sendSource, swapTargetList, 0, 0);
    }
  }

  private _drop(dropObj, event){
    // Event must be empty for keyboard drop
    const {source, draggable, dest, hasContent, isDestHomeTarget, isSourceHomeTarget, isSelfHome, dropTarget} = dropObj;
    const previousIndex = _.isEmpty(event) ? 0 : event.previousIndex
    const currentIndex =  _.isEmpty(event) ? 0 : event.currentIndex
    
    if (!_.isEmpty(event) && !event.isPointerOverContainer) {
      this.sendHome(draggable, source);
    } else if (!_.isEmpty(event) && event.previousContainer === event.container) {
      //moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      return;
    } else if (draggable.ref.category != dropTarget.targetContext.category){
      this.sendHome(draggable, source);
    } else {
      // console.log('will not allow drop into another draggables home slot', isDestHomeTarget, isSelfHome, dest, draggableHome.contents);
      if (isDestHomeTarget && !isSelfHome) {
        this.sendHome(draggable, source);
      } else {
        if (hasContent) {
          this.sendHome(dest[0], dest);
        }
        if (!this.element.isOptionsReusable || !draggable.ref.isReusable || !isSourceHomeTarget) {
          transferArrayItem(source, dest, previousIndex, currentIndex);
        } else {
          copyArrayItem(source, dest, previousIndex, currentIndex);
        }
      }
    }
    if (document.activeElement instanceof HTMLElement && document.activeElement.classList.contains('target-el')) document.activeElement.blur();
    this.updateState()
  }

  ensureState(){
    if (this.questionState) {
      if (!this.questionState[this.element.entryId]) {
        let entryState: IEntryStateMoveableDragAndDrop = {
          type: ElementType.MOVEABLE_DND,
          isCorrect: false,
          isStarted: false,
          isFilled: false,
          isResponded: false,
          score: 0, //this.targets.find((target:any) => target.groups.length) ? getElementWeight(this.element) : 0,
          weight: getElementWeight(this.element),
          scoring_type: ScoringTypes.AUTO,
          targets: this.targets
        };
        //console.log("initializing state")
        this.questionState[this.element.entryId] = entryState;
      }
      
    }
  }

  showAltText() {
    return true
    //return this.printAltText.getAltTextVisible();
  }

  drag2TargPairingExists(dragID:string, targetID: string) {
    if (this.drag2Targ.has(dragID) && this.drag2Targ.get(dragID).indexOf(targetID) != -1) {
      return true;
    }
    return false;
  }

  draggableLocation:Map<string,string[]>  // DraggableId => TargetID
  targetLocation:Map<string,string>       // TargetId => DraggableID
  updateState(){
    if (this.questionState) {
      const entryId = this.element.entryId;
      let weight = getElementWeight(this.element);
      let numCorrect = 0;
      let numWrong = 0;
      let numTargets = 0;
      let numDraggablesPlaced = 0; // 
      let isAllFilled = true;
      let isStarted = false;
      let countOnlyItems = []
      let numCountedDraggablesPlaced = 0
      
      if (this.element.isCountOnlyRequired && this.element.countOnly) {
        countOnlyItems = this.element.countOnly.split(',').map((id) => {return id.trim()})
      }

      this.draggableLocation = new Map() ;
      this.targetLocation = new Map()
      this.targets.forEach((target)=>{
        let context = target.targetContext;
        const isHomeTarget = this.homeTargetContents.has(target.contents)
        if (!isHomeTarget){
          const draggableEl = target.contents[0];
          numTargets ++;
          if (draggableEl){
            numDraggablesPlaced ++;
            isStarted = true;
            const draggable = <IContentElementDndDraggable> draggableEl.ref;
            
            //keep track of count item
            if (countOnlyItems.includes(draggable.id.toString())) {
              numCountedDraggablesPlaced++
            }
            // console.log('real target ', target.contents.length, draggable.targetId, context.id)
            if (this.element.isCustomValidataion) {
              const draggableId = draggable.id.toString();
              if (!this.draggableLocation.has(draggableId)) this.draggableLocation.set(draggableId, []);
              this.draggableLocation.get(draggableId).push(context.id.toString());
            }  
            if (this.element.isAcceptMultipleCombinations){
              const targetId = context.id.toString()
              // if(!this.targetLocation.has(targetId)) this.targetLocation.set(targetId, "")
              this.targetLocation.set(targetId, draggable.id.toString());
            }

            if ((!this.element.isMultipleOptionsRight && draggable.targetId == context.id) || (this.element.isMultipleOptionsRight && this.drag2TargPairingExists(draggable.id.toString(), context.id.toString()))) {
              numCorrect ++;
            } else {
              numWrong++;
            }
          }
          else{
            isAllFilled = false;
          }
        }
      })
      
      let score = 0;
      let isCorrect:boolean = false;
      let isFilled = true;
      //custom validation
      if (this.element.isCustomValidataion) {
        for (let i = 0; i < this.element.draggables.length; i++) {

          let draggable = this.element.draggables[i];
          let id = draggable.id.toString();
          const locations = this.draggableLocation.has(id) ? this.draggableLocation.get(id) : [];

          if (this.isExactDraggableRequired(draggable)) {
            isCorrect = locations.length == draggable.exactDraggableRequired;
            if (!isCorrect) break;
          }

          if (this.isRangeProvided(draggable)) {
            isCorrect = locations.length >= draggable.minRequired && locations.length <= draggable.maxRequired;
            if (!isCorrect) break;
          }

        }

        if (this.element.isAllTargetsToPlace) {
          if (!isAllFilled) isCorrect = false;
        }
        score = isCorrect ? 1 : 0
      } 
      //Multiple combo
      else if(this.element.isAcceptMultipleCombinations){
        if(this.element.multipleCombinations){
          let numCorrect = 0;
          isCorrect = this.element.multipleCombinations.some(combination => {
            let correctVal = 0;
            for (const comb in combination) {
              const placedId = this.targetLocation.get(comb);
              const expectedId = combination[comb];
              if (placedId == expectedId) {
                correctVal++
              }
            }
            if (correctVal>numCorrect) {
              numCorrect = correctVal
            }
            return correctVal === this.element.targets.length
          });
          if (isCorrect) score = weight;
          else if (this.element.enableProportionalScoring) {
            score = weight*numCorrect/this.element.targets.length
          }
        }
      } else if (this.element.gradingMode==GradingType.PLUS_MINUS) {
        isFilled = numDraggablesPlaced > 0
        isCorrect = (numCorrect==this.element.targets.length) ? true : false
        score = 2*numCorrect-numTargets
        if (score<0) {
          score = 0
        } 
        score = weight*score/numTargets
      } else if (this.element.isAllTargetsToPlace){
        isFilled = (numDraggablesPlaced >= this.element.draggables.length);
        if (numCorrect === numDraggablesPlaced){
          isCorrect = numDraggablesPlaced === this.element.targets.length
        }
        if (numDraggablesPlaced > 0){
          if (this.element.enableProportionalScoring) {
            score = weight*(numCorrect/this.element.targets.length)
          } else {
            score = isCorrect ? weight : 0
          }
        }
      }
      // if optionReusable and isAllFilledNotRequired is turned on 
      else if (this.element.isOptionsReusable && this.element.isAllFilledNotRequired && this.element.isCountOnlyRequired){
        isFilled = numDraggablesPlaced > 0

        if (numCountedDraggablesPlaced <= numCorrect && numCorrect > 0 && numCountedDraggablesPlaced > 0){
          isCorrect = true
          if (isCorrect) {
            score = weight;
          }
        } 
      }
      else {
        isFilled = isAllFilled || (!this.element.isOptionsReusable && numDraggablesPlaced == this.element.draggables.length);
        if (this.element.isAllFilledNotRequired) {
          isFilled = numDraggablesPlaced > 0
          if (numCorrect <= numTargets && numCorrect > 0 && numCorrect === numDraggablesPlaced){
            isCorrect = true;
          }
        }
        if (numCorrect === numTargets || (!this.element.isOptionsReusable && numCorrect == this.element.draggables.length)){
          isCorrect = true;
        }
        if (this.element.enableProportionalScoring) {
          if (numTargets > 0){
            console.log(!this.element.isOptionsReusable, numCorrect, this.element.draggables.length)
            if (!this.element.isOptionsReusable && this.element.draggables.length < this.element.targets.length){ //&& numCorrect == this.element.draggables.length
              score = weight*numCorrect/this.element.draggables.length;
            } else {
              score = weight*numCorrect/numTargets;
            }
            if (this.element.gradingMode==GradingType.REDUCTION) {
              isFilled = numDraggablesPlaced > 0
              isCorrect = (numCorrect==this.element.targets.length) ? true : false
              score = weight-(numTargets-numCorrect)
              if (score<0) {
                score = 0
              }
            }
          }
          
        }
        else{
          if (isCorrect){
            score = weight;
          }
        }
        
      }

      if (this.element.howManyToFill || this.element.howManyToFill==0) {
        isFilled = numDraggablesPlaced>=this.element.howManyToFill
      }

      let isResponded = this.questionState[this.element.entryId].isResponded || !!numDraggablesPlaced;
      // console.log(missed==0)
      let entryState: IEntryStateMoveableDragAndDrop = {
        type: ElementType.MOVEABLE_DND,
        isCorrect,
        isStarted,
        isFilled,
        isResponded,
        score,
        weight,
        scoring_type: ScoringTypes.AUTO,
        targets: this.targets, // this.textBlocks
      };
      this.questionState[this.element.entryId] = entryState;
      // console.log('dnd entryState', entryState)
      if(this.questionPubSub){
        this.questionPubSub.allPub({entryId: this.element.entryId, type: PubSubTypes.UPDATE_VALIDATOR, data: {}})
        if(this.element.countNumberOfDraggables && this.element.inputboxCounterEntryID){
          let count = 0;
          let res = entryState.targets.filter(item => this.element.targets.map(target => target.id).includes(item.targetContext.id));
          res.forEach(target => {
            count = count + target.contents.length;
          })
          this.questionPubSub.elementPub(this.element.inputboxCounterEntryID, PubSubTypes.INPUT, {isResponded: true, count: count, elementType: ElementType.MOVEABLE_DND})
        }
      }
    }
  }

 
  homeTargetMap;
  homeTargetContents:Map<any, boolean>;

  updateDisplayEls() {
    this.homeTargetMap = new Map();
    this.homeTargetContents = new Map();
    if (this.questionState &&  this.questionState[this.element.entryId]) {
      this.targets = this.questionState[this.element.entryId]["targets"];
      if(this.targets){
        for(let target of this.targets) {
          if(target.contents) {
            const draggable = target.contents[0];
            if (draggable){
              const homeTargetId = draggable.ref.id;
              const homeTarget = this.targets.filter(target => target.targetContext.id === homeTargetId)[0];
              this.homeTargetMap.set(draggable, homeTarget);
              this.homeTargetContents.set(homeTarget.contents, true);
            }
          }
        }
        return;
      }
    }
    this.targets = [];
    if (this.element.draggables) {
        this.element.draggables.forEach(element => {
            const arr = []
            const targetDraggable = this.addElementToList(element, arr);
            const target = {
              targetContext: element,
              contents: [targetDraggable],
              originalPosition: true
            }
            this.targets.push(target);
            this.homeTargetMap.set(targetDraggable, target);
            this.homeTargetContents.set(target.contents, true);
        });
    }
    if (this.element.targets) {
        this.element.targets.forEach(element=>{
            this.targets.push({
                targetContext: element,
                contents: [],
                originalPosition: false
            })
        })
    }
  }

  addElementToList(element: IContentElementDndDraggable, elementsToPosition: IElementPos[], isTarget: boolean= false) {
    let hasElement  = false;
    if ((<IContentElementDndDraggable> element).element) {
      hasElement = true;
    }
    const obj = {
        ref: element,
        originalX: element.x,
        originalY: element.y,
        isTarget,
        //style: renderDndElementStyle(element, hasElement, isTarget && this.element.customTargetDim, this.element.defaultTargetStyle),
        style: {}
      }
    elementsToPosition.push(obj);
    return obj;
  }

  getTargetStyle(target) {
    const ctx = target.targetContext;
    const content = target.contents;
    let style:any = {
      'min-width.em': ctx.width,
      'min-height.em': ctx.height,
      'left.em': ctx.x || 0,
      'top.em': ctx.y || 0,
    };

    if ((this.element.isTargetsInvisible && !target.originalPosition) || (this.element.isDraggablesInvisible && target.originalPosition) ){
      style["border"] = "dashed 2px rgba(0,0,0,0)";
      style["background-color"] = "none";
    }
    else {
      style["border"] = "dashed 2px black";
      style["background-color"] = ctx.containerBackgroundColor || "#ffffff";
    }

    if (this.element.isTargetColourSame){
      style["background-color"] = this.element.targetColour;
    }
    
    return style
  }

  getDraggableColour(drag:IContentElementDndDraggable) {
    if (!this.element.isDragsTransparent) {
      if (this.element.isDraggableColourSame) {
        return this.element.draggableColour;
      }
      if (drag.backgroundColor) return drag.backgroundColor;
      return "#ffffff"
    }
    return undefined;
  }

  isRangeProvided(draggable:IContentElementDndDraggable){
    return "minRequired" in draggable && "maxRequired" in draggable && draggable.minRequired !== null && draggable.maxRequired !== null
  }

  isExactDraggableRequired(draggable:IContentElementDndDraggable){
    return "exactDraggableRequired" in draggable && draggable.exactDraggableRequired !== null
  }

  getStyle() {
    const style = {}
    if (!this.element.isNoInvertOnHiContrast && this.textToSpeech.isHiContrast) {
      style["filter"] = "invert(1)"
    }
    return style
  }
  
  dragStarted(e){
    this._isDragstarted = true;
  }

  dragging(e){
    // #Imp: If condition to make sure block only fire once. 
    if(this._isDragstarted){
      // Reason: Drag preview and oiriginal element are opposite in high contrast mode.
      const preview = new ElementRef<HTMLElement>(document.querySelector('.cdk-drag.cdk-drag-preview'));

      if (preview.nativeElement){
        if (this.isHighContrastDragPreviewException()) this.renderer.addClass(preview.nativeElement, 'is-inverted-hi-contrast');

        const previewImageSubTextEl = preview.nativeElement.querySelector<HTMLElement>('.image-sub-text')
        if (previewImageSubTextEl) this.syncImageSubTextFontOnDragging(e.source?.element, previewImageSubTextEl)
      }
    }
  }

  // This method ensures the font inside the image sub text is matches the font set inside the image block.
  // To avoid font changes when the draggable is being dragged.
  syncImageSubTextFontOnDragging(draggableEl: ElementRef<HTMLElement>, previewImageSubTextEl: HTMLElement){
    if (!draggableEl) return;

    const subText = draggableEl.nativeElement.querySelector<HTMLElement>('.image-sub-text');
    if (subText && subText.style?.fontFamily && subText.style.fontFamily != ''){
      previewImageSubTextEl.style.fontFamily = subText.style.fontFamily;
    } else {
      previewImageSubTextEl.style.fontFamily = 'Source Sans Pro';
    }
  }

  isHighContrastDragPreviewException(){
    return this.element.isNoInvertDragPreview && this.textToSpeech.isHiContrast;
  }

  clickDrag(obj) {
    const trigger = this.clickTriggers.get(obj);
    if (trigger) {
      trigger.next(true);
    }
  }

  getClickTrigger(obj) {
    let trigger = this.clickTriggers.get(obj)
    if (!trigger) {
      trigger = new Subject();
      this.clickTriggers.set(obj, trigger)
    }
    return trigger
  }

  isVoiceOverEnabled(){
    return this.textToSpeech.isActive;
  }

  resetCount(){
    this.targets.forEach(target => {
      if(!target.originalPosition){
        target.contents = []
      }
    })
    this.questionState[this.element.entryId].isResponded = false
    this.updateDisplayEls()
    this.updateState()
  }
}