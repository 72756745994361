import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { IContentElementImage, IContentElementDynamicImage, QuestionState, ImageStates, ElementType, IContentElementImageSubText } from '../models';
import { generateDefaultElementImage, generateOldDefaultImage } from '../../ui-item-maker/item-set-editor/models/index'
import { DomSanitizer } from "@angular/platform-browser";
import { state } from '@angular/animations';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { TextToSpeechService } from '../text-to-speech.service';
import { LangService } from '../../core/lang.service';
import { StyleprofileService, processText } from 'src/app/core/styleprofile.service';



@Component({
  selector: 'element-render-image',
  templateUrl: './element-render-image.component.html',
  styleUrls: ['./element-render-image.component.scss']
})
export class ElementRenderImageComponent implements OnInit {

  @Input() element:IContentElementDynamicImage;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState; // not used
  @Input() isSelected?: boolean;
  @Input() isHovered?: boolean;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() draggable?: boolean = false;
  observer: MutationObserver;

  constructor(private sanitizer: DomSanitizer,
    private textToSpeech: TextToSpeechService,
    private lang: LangService,
    private profile: StyleprofileService) { }

  ngOnInit() {
    this.startObservingKURZWEIL();
  }

  ngOnDestroy(){
    this.observer.disconnect();
  }

  imgExists(img) {
    if (img && img.image && img.image.url && img.image.url != '') return true;
    return false;
  }

  getImage() {
    if (!this.element){
      return;
    }
    const imgs = this.element.images;
    if (!imgs)  {
      if (this.element.url) {
        return generateOldDefaultImage(this.element, ImageStates.DEFAULT);
      }
      return;
    }
    if (this.isSelected == true) {
      if (imgs[ImageStates.SELECTED] && this.imgExists(imgs[ImageStates.SELECTED])) return imgs[ImageStates.SELECTED];
    } else if (this.isHovered == true) {
      if (imgs[ImageStates.HOVERING] && this.imgExists(imgs[ImageStates.HOVERING])) return imgs[ImageStates.HOVERING];
    }
    if (imgs[ImageStates.DEFAULT]) return imgs[ImageStates.DEFAULT];
    else if (this.element.url) return generateOldDefaultImage(this.element, ImageStates.DEFAULT);
    return imgs[ImageStates.DEFAULT];
  }

  getFilter() {
    if (this.element.frameState) return this.sanitizer.bypassSecurityTrustStyle("drop-shadow( "+this.element.frameState.dropShadowX+"px "+this.element.frameState.dropShadowY+"px "+this.element.frameState.blurRadius+"px "+this.element.frameState.shadowColor+" )");
    else return '';
  }

  getSubTextColor(sub:IContentElementImageSubText){
    if (!sub.invisible){
      if (sub.colourScheme){
        return sub.colourScheme.textColor
      }
      return '#000000'
    }
  }
  getTransform(sub:IContentElementImageSubText) {
    if (sub.rotation){
      return `rotate(${sub.rotation}deg)`;
    }
    return '';
  }

  getPadding() {
    if (this.element.frameState) return this.element.frameState.padding;
    else return '';
  }


  // Method to handle the invert filter of the image and the subtext, and also when they are as drag previews
  getImageSubTextStyle(subText:IContentElementImageSubText) {
    if (!this.textToSpeech.isHiContrast) return;

    const style = {}
    const dragPreviewImageTextEl = new ElementRef<HTMLElement>(document.querySelector('.cdk-drag.cdk-drag-preview .image-sub-text markdown-inline'));

    if ((!this.element.isNoInvertOnHiContrast || subText.invertTextOnHightContrast)) {
      style["filter"] = "invert(1)"
      if (this.isDragPreviewElementPresent(dragPreviewImageTextEl, subText) && (this.element.isNoInvertOnHiContrast != subText.invertTextOnHightContrast)) {
        dragPreviewImageTextEl.nativeElement.setAttribute("style", "filter: invert(0);")
      } 
    } 
  
    else{
      if (this.isDragPreviewElementPresent(dragPreviewImageTextEl, subText)){
        dragPreviewImageTextEl.nativeElement.setAttribute("style", "filter: invert(1);")
      } 
    }

    return style
  }

  // We only want to apply any sort of style changes when the current drag preview matches the same instance
  // of the image, another way to say is "Is the draggable being dragged THE current image element"
  isDragPreviewElementPresent(dragPreviewImageTextEl: ElementRef, subText: IContentElementImageSubText){

    // Use the same logic to process the subtext in markdown inline so that it can be used to 
    // match the inner text of the captured drag preview element
    const input = subText.text;
    let output = input;
    const lang = this.lang.c();
    if (this.profile.getStyleProfile()) {
      output = processText(input, this.profile.getStyleProfile()[lang].renderStyling.plainText.transforms);
    } else {
      output = input
    }

    return dragPreviewImageTextEl && dragPreviewImageTextEl.nativeElement && dragPreviewImageTextEl.nativeElement.innerHTML.includes(output)
  }

  /*getOldUrl() {
    const image = { 
      elementType: ElementType.IMAGE,
      url: this.element.url, 
      fileType: this.element.fileType,
      altText: this.element.altText,
      scale: this.element.scale,
      outline: this.element.outline
    }
    return { condition: ImageStates.DEFAULT, image };
  }*/

  // For KURZWEIL 3000 screen reader: this reader does not change
  // the text color when it is reading a sentence and highlighting
  // it, since we're setting the invisible text to be transparent,
  // we need to manually set the color to black for the hi-lighted 
  // sentence. 
  startObservingKURZWEIL(){
    this.observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
          for (var i = 0; i < mutation.addedNodes.length; i++) {
              let a = mutation.addedNodes[i] as HTMLElement
              if (a.className == 'rtw-highlight-sentence'){         // the "rtw-highlight-sentence" class is used by KURZWEIL 3000 to highlight the text it's currently reading (through css prop)
                a.style.color = 'black'                             // supply the color prop for the highlighted text so they are not inheriting the transparent color from invisible text.
              }
          }
      });
    });
    this.observer.observe(document, { childList: true, subtree: true });
  }
}
