import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { RoutesService } from 'src/app/api/routes.service';
import { BcAccountsService } from 'src/app/bc-accounts/bc-accounts.service';
import { AssessmentType, BcAssessmentsService, TestWindow, TestWindowTabular } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { BreadcrumbsService } from 'src/app/core/breadcrumbs.service';
import { LangService } from 'src/app/core/lang.service';
import { mtz } from 'src/app/core/util/moment';
import { MySchoolService } from 'src/app/ui-schooladmin/my-school.service';
import { PageModalController, PageModalService } from '../page-modal.service';

interface SessionComponent {
  name: string;
  componentCode: string;
  accessCode: string;
  activityState: boolean;
}

@Component({
  selector: 'admin-session',
  templateUrl: './admin-session.component.html',
  styleUrls: ['./admin-session.component.scss']
})
export class AdminSessionComponent implements OnInit {

  components: SessionComponent[];
  public pageModal: PageModalController;
  schoolType = this.route.snapshot.data['schoolType'];
  CHANGE_ACCESS = 'CHANGE_ACCESS'
  newCode: string;
  newCodeError: string | null = null;
  @Input() breadcrumbProxy;
  testWindow: TestWindow;
  adminSessionStart: string;
  adminSessionEnd: string;

  mySchoolSubscription: Subscription;
  schoolDetials;

  routeSub: Subscription;
  passwordLang: string;
  is_offshore: boolean;
  displayDates: boolean = false;

  constructor(
    public lang: LangService,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private loginGuardService: LoginGuardService,
    private pageModalService: PageModalService,
    private bcAssessments: BcAssessmentsService,
    private bcAccounts: BcAccountsService,
    private mySchool: MySchoolService,
    private auth: AuthService,
    private routes: RoutesService,
  ) { }

  ngOnInit(): void {
    console.log("admin-session")
    if (this.auth.isSchoolAdmin()) {
      this.mySchoolSubscription = this.mySchool.sub().subscribe(groupRoleList => {
        if (!groupRoleList || groupRoleList.length === 0) return;
        const school_group_id = groupRoleList[0].group_id;
        this.bcAccounts.getSchoolDetail(school_group_id).then(schoolDetials => {
          if (schoolDetials) {
            this.schoolDetials = schoolDetials;
            this.is_offshore = schoolDetials.district.is_offshore;
            if (this.routeSub) this.routeSub.unsubscribe();
            this.routeSub = this.route.queryParams.subscribe(queryParams => {
              let lang = queryParams.lang || 'en';
              this.passwordLang = lang == 'en' ? 'English' : 'French';
              this.setupData();
            });

            this.updateBreadcrumb();
            this.pageModal = this.pageModalService.defineNewPageModal();
          }
        })
      })
    }else{
      this.setupData();
    }
    this.bcAssessments.showDates().then(res => this.displayDates = res)
  }

  ngOnDestroy() {
    if (this.mySchoolSubscription) this.mySchoolSubscription.unsubscribe();
    if (this.routeSub) this.routeSub.unsubscribe();
  }

  private setupData() {
    this.bcAssessments.findActiveTestWindowSimplified(AssessmentType.FSA, false, true, true).then(testWindow => {
      const componentCodeSet = new Set<string>();
      const components: TestWindowTabular = [];
      this.testWindow = testWindow;
      this.adminSessionStart = mtz(testWindow.dateStart).format(this.lang.tra('datefmt_long_date_line2'))
      this.adminSessionEnd = mtz(testWindow.dateEnd).format(this.lang.tra('datefmt_long_date_line2'))
      this.testWindow.tabular.map(row => {
        if (componentCodeSet.has(row.assessmentCode)) return;
        componentCodeSet.add(row.assessmentCode)
        components.push(row);
      });

      components.sort((a, b) => {
        if (a.grade != b.grade) return a.grade - b.grade;
        if (a.language != b.language) return a.language < b.language ? -1 : 1;
        if (a.assessmentCode != b.assessmentCode) return a.assessmentCode < b.assessmentCode ? -1 : 1;
        return 1;
      })

      this.components = components.filter(c => {
        if (this.auth.isMinistryAdmin()) return true;
        return c.language == this.passwordLang;
      }).map(c => ({
        name: c.title,
        componentCode: c.assessmentCode,
        accessCode: c.password,
        activityState: true,
      }));

      this.checkStatus();
    })

  }

  getBaseRoute() {
    return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}`;
  }

  getDashboardRoute() {
    if (this.schoolType === 'BC_FSA') {
      return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}/bc-fsa/dashboard`;
    }
  }

  getBaseViewRoute() {
    if (this.schoolType === 'BC_FSA') {
      return `${this.getBaseRoute()}/bc-fsa/assessment_setup1`;
    }
  }

  getAssessmentMaterialsViewRoute() {
    return `${this.getBaseRoute()}/bc-fsa/assessment_materials`;
  }

  getViewRoute() {
    return `${this.getBaseViewRoute()}/administration_session`;
  }

  isFSA(): boolean {
    return this.route.snapshot.data['schoolType'] === 'BC_FSA';
  }

  private updateBreadcrumb() {
    let admin = this.lang.tra('sa_grad_dashboard_school_admin');
    if (!this.isFSA()) admin += this.lang.tra('sa_grad_bcgrad');
    else admin += this.lang.tra('sa_fsa_bcfsa');
    this.breadcrumbProxy.clear();
    this.breadcrumbProxy.push(this.breadcrumbsService._CURRENT(admin, this.getDashboardRoute()));
    this.breadcrumbProxy.push(this.breadcrumbsService._CURRENT(this.lang.tra('sa_assessment_material_bc'), this.getAssessmentMaterialsViewRoute()));
    this.breadcrumbProxy.push(this.breadcrumbsService._CURRENT(this.lang.tra('da_a_admin_session'), this.getViewRoute()));
  }

  popupNotif = () => {
    this.loginGuardService.quickPopup("Please contact Vretta's System Coordinator to request changes.")
  }

  cModal() {
    return this.pageModal.getCurrentModal();
  }
  cmc() { return this.cModal().config; }

  change(e) {
    const newCode = e.target.value as string;
    if (newCode.length < 3 || newCode.length > 16) {
      this.newCodeError = 'Session password must be between 3-16 characters.';
    } else {
      this.newCodeError = null;
    }
    this.newCode = e.target.value
  }

  openChangeAccessCodeModal(i) {
    const config: any = {
      index: i
    };
    this.pageModal.newModal({
      type: 'CHANGE_ACCESS',
      config,
      finish: config => this.finishChangeAccessCodeModal(i)
    });
  }

  finishChangeAccessCodeModal = (i) => {
    if (!this.validateSessionPassword(this.newCode)) {
      return;
    }
    this.bcAssessments.modifyFsaSessionPassword(this.components[i].componentCode, this.testWindow, this.newCode).then(() => {
      this.components[i].accessCode = this.newCode;
      this.closeModal();
    })
  }

  closeModal() {
    this.newCodeError = null;
    this.pageModal.closeModal()
  }

  validateSessionPassword(code?: string): boolean {
    if (!code) return false;
    if (code.length < 3 || code.length > 16) return false;
    return true;
  }

  adminSessLinks;
  checkStatus = async () => {
    if(!this.testWindow) return;
    this.adminSessLinks = await this.auth.apiFind(this.routes.BC_ADMIN_ASSESSMENT_MATERIALS, {query: {
      test_window_id: this.testWindow.id, 
      assessment: this.bcAccounts.getIsSACurrentlyOnGrad()? AssessmentType.GRAD : AssessmentType.FSA,
      for_sa: this.auth.isMinistryAdmin() ? 0: 1,
      lang: this.lang.c(),
      sub_slug: 'admin_session',
    }});
  }

  async onPasswordSheetClick(){
    const url = await this.auth.apiFind(this.routes.PASSWORD_INFO_SHEET, {query: {test_window_id: this.testWindow.id, get_url: true, schoolAdmin: this.auth.isSchoolAdmin(), lang: this.lang.c()}} );
    const newlink = document.createElement('a');
    newlink.setAttribute('target', '_blank');
    newlink.setAttribute('href', url);
    document.body.appendChild(newlink);
    newlink.click();
    newlink.remove();
  }


}
