import { Component, OnInit, Input } from '@angular/core';
import { IContentElementFrame, TextParagraphStyle, QuestionState, ImageStates, countDecimals } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { PubSubTypes } from './pubsub/types';
import { TextToSpeechService } from '../text-to-speech.service';
import { HyperlinkService, ILinkRequest } from '../hyperlink.service';
import * as _ from 'lodash';
import { QuestionRunnerLayoutService } from '../question-runner-layout.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'element-render-frame',
  templateUrl: './element-render-frame.component.html',
  styleUrls: ['./element-render-frame.component.scss']
})
export class ElementRenderFrameComponent implements OnInit {

  @Input() element:IContentElementFrame;
  @Input() isLocked: boolean;
  @Input() questionState: any;
  @Input() isShowSolution?: boolean;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() allowSubtitles: boolean = false;
  @Input() allowTranscripts: boolean = false;
  @Input() allowAudioPlaybackSpeed: boolean = false;
  @Input() allowVideoPlaybackSpeed: boolean = false;

  constructor(
    private hyperLinkService: HyperlinkService,
    public text2Speech: TextToSpeechService,
    private questionRunnerLayout?: QuestionRunnerLayoutService,
  ) { }

  isToggledOn:boolean = true;
  rotation:number = 0;
  height: number
  activeBookMarkId:string;
  activeBookmarkState: Map<string, boolean> = new Map();
  pointerEvents:boolean = true;
  isResponded: boolean;
  linkRequestSecondSub: Subscription;

  ngOnInit() {
    if (this.element.isOff){
      if (!this.isLocked){
        this.isToggledOn = false;
      }
    }
    this.initElementSub(); 
    this.ensureState();
    this.handleNewState();

    this.linkRequestSecondSub = this.hyperLinkService.linkRequestSecond.subscribe(this.onBookmarkRequest);
  }

  ngOnDestroy(){
    this.linkRequestSecondSub.unsubscribe();
  }

  handleNewState() {
    this.rotation = this.getEntryState().rotation;
    this.height = this.getEntryState().currHeight;
    if(this.element.isStateEnabled && 'toggled' in this.getEntryState()){
      this.isToggledOn = this.getEntryState().toggled;
    }
  }

  setEntryState(config:any){
    if (this.element.isStateEnabled){
      this.questionState[this.element.entryId] = config;
    }
  }

  removeBookmarks() {
    const els = Array.from(document.getElementsByClassName('bookmark is-active'));
    if (els){
      els.forEach(e => e.classList.remove('is-active'));
    }
  }

  bookmarkTimeout = _.throttle((data:ILinkRequest)=>{
    setTimeout(()=> {
      const searchForClass = `bookmark id-${this.activeBookMarkId}`;
      const els = document.getElementsByClassName(searchForClass);
      if (els && els[0]){
        if(this.activeBookmarkState.get(data.bookmarkId)){
          els[0].classList.remove('is-active');
          this.activeBookmarkState.delete(data.bookmarkId);
        } else {
          els[0].classList.add('is-active');
          this.activeBookmarkState.set(data.bookmarkId, true);
        }
        els[0].scrollIntoView({behavior: 'smooth'});
      }
    }, 500);
  }, 300)

  onBookmarkRequest = (data:ILinkRequest) => {
    this.removeBookmarks()
    if(!this.activeBookmarkState.has(data.bookmarkId)) this.activeBookmarkState.set(data.bookmarkId, false);
    else this.activeBookmarkState.delete(data.bookmarkId)
    this.activeBookMarkId = data.bookmarkId;
    if (this.activeBookMarkId) {
      this.bookmarkTimeout(data)
    }
  }


  getEntryState(){
    if (this.element.isStateEnabled){
      return this.questionState[this.element.entryId]
    }
    else {
      return {};
    }
  }

  ensureState() {
    if(!this.getEntryState()) {
      this.updateState();
    }
  }

  updateState() {
    this.setEntryState({
      type: 'frame',
      rotation: this.rotation % 360,
      currHeight: this.height ? +this.height.toFixed(6) : this.height,
      toggled: this.element.isStateEnabled ? this.isToggledOn : true,
      isStarted: true,
      isFilled: !!this.height || !!this.rotation || !!this.element.isFilledNotRequired,
      isCorrect : false,
      isResponded: this.isResponded
    })
    this.questionPubSub.allPub({entryId: this.element.entryId, type: PubSubTypes.UPDATE_VALIDATOR, data: {}})
  }

  initElementSub(){
    const elementSub = this.questionPubSub.initElementSub(this.element.entryId);
    elementSub.subscribe(payload => {
      if (this.isLocked) {
        return;
      }
      if (payload.type in PubSubTypes && !this.isResponded) this.isResponded = true;
      switch(payload.type){
        case PubSubTypes.TOGGLE:     return this.handleToggle(!this.isToggledOn);
        case PubSubTypes.TOGGLE_OFF: return this.handleToggle(false);
        case PubSubTypes.TOGGLE_ON:  return this.handleToggle(true);
        case PubSubTypes.ROTATE:     return this.handleRotate(payload.data.angle);
        case PubSubTypes.INC_HEIGHT: return this.handleHeightChange(payload.data.heightChange, payload.data);
        case PubSubTypes.TOGGLE_CLICKABLE: return this.handleClickable(!this.pointerEvents);
        case PubSubTypes.CLICKABLE: return this.handleClickable(true);
        case PubSubTypes.NOT_CLICKABLE: return this.handleClickable(false);
      }
    });
  }

  getZIndex() {
    return this.questionRunnerLayout.id2ZIndex.get(this.element.entryId)
  }

  handleHeightChange(inc:number, {maxHeight, roundingTolerance}) {
    if (this.height != 0 && !this.height) this.height = this.element.height;
    if (this.height != 0 && !this.height) this.element.height = 0;
    this.height += inc;
    if (this.height<0) this.height = 0;
    if (maxHeight && 
      maxHeight != undefined && 
      this.height>maxHeight) this.height = maxHeight;
    if(roundingTolerance != undefined || roundingTolerance != null){
      this.height = parseFloat(this.height.toFixed(countDecimals(roundingTolerance)))
    } 
    this.updateState();
  }

  handleRotate(angle: number) {
    this.rotation += angle;
    this.updateState();
  }

  handleToggle(toggleState:boolean){
    this.isToggledOn = toggleState;
    if(this.element.isStateEnabled) this.updateState();
  }

  handleClickable(pointerEvents: boolean) {
    console.log(pointerEvents)
    this.pointerEvents = pointerEvents
  }

  onClick(){
    if (this.element.isClickable){
      this.questionPubSub.elementPub(
        this.element.toggleTargetId,
        this.getEventType(),
        this.element.eventData
      )
    }
  }

  getEventType() {
    return this.element.eventType || PubSubTypes.TOGGLE; //For backwards compatibility where all events were toggle
  }

  getHeight() {
    if (this.height || this.height==0) {
      return this.height
    }
    return undefined
  }

  renderRotation() {
    return `rotate(${this.rotation}deg)`;
  }

  getPointerEvents(){
    return this.pointerEvents ? 'auto' : 'none'
  }

  getStyle() {
    let style = this.element.styleRaw
    if (!style) {
      style = {}
    }
    style = JSON.parse(JSON.stringify(style))
    if (this.element.isNoInvertOnHiContrast && this.text2Speech.isHiContrast) {
      if (style["filter"]) {
        if (style["filter"].toString().indexOf("invert")==-1) {
          style["filter"] += " invert(1)"
        }
      } else {
        style["filter"] = "invert(1)"
      }
    } else {
      if (style["filter"]) {
        const index = style["filter"].toString().indexOf("invert(1)")
        if (index!=-1) {
          style["filter"] = style["filter"].toString().replace("invert(1)", '')
        }
      }
    }
    if (this.getZIndex()!=undefined) {
      style['position']='relative'
      style['z-index']=this.getZIndex()
    }
    return style
  }

}
