import { Component, Input, Output, OnChanges, OnInit, SimpleChanges, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BreadcrumbsService } from "../../core/breadcrumbs.service";
import { LangService } from 'src/app/core/lang.service';
import { BcAccountsService, DistrictDetail, Filter, FilterCondition, Pagination, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { BcAssessmentsService, IScoreProfile, ScoreEntryAssessmentData, ScoreEntryQuestion, ScoreEntryStudentRowV2, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';

import { AuthService } from 'src/app/api/auth.service';
import { DataGuardService } from 'src/app/core/data-guard.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { LoginGuardService } from "../../api/login-guard.service";
import { cloneDeep } from 'lodash';
import { BcScoreEntryService } from 'src/app/bc-score-entry/bc-score-entry.service';
import { IScoreEntryCell, IScoreEntryData, ScoreEntryStatus } from 'src/app/bc-score-entry/types';

// interface IScoringPanelQuestion {
//   title: string,
//   scoreEntryField: string,
//   profile: IScoreProfile,
//   onChange: (field: string, score?: string) => void,
//   getDisplay?: (slug: string) => string;
//   edisableOption?: (slug: string) => boolean;
// }
// interface IScoringPanel {
//   component: string,
//   questions: IScoringPanelQuestion[],
// }

// interface IScoreEntryPenOnlyRow {
//   uid: number,
//   pen: string,
// }

// interface IPageChangeRequest {
//   previousPagination: Pagination,
// }

export enum PageDirection
{
  NEXT = "next",
  PREVIOUS = "previous"
}

export interface PageSwitch
{
  idx: number, 
  direction: PageDirection
}

enum ScoreEntryThemes
{
  NO_THEME = 0
}

@Component({
  selector: 'score-entry-focus-table',
  templateUrl: './score-entry-focus-table.component.html',
  styleUrls: ['./score-entry-focus-table.component.scss']
})
export class ScoreEntryFocusTableComponent implements OnInit, OnChanges {

  // @Input() testWindow: TestWindow;
  // @Input() school: SchoolDetail;
  // @Input() district: DistrictDetail;
  // @Input() themeProfile: IScoreProfile;
  // @Input() scoreProfile: IScoreProfile;
  // @Input() uid: number;
  // @Input() breadcrumbProxy: any[];

  @Input() selectedScoreEntryRowV2: ScoreEntryStudentRowV2;
  @Input() totalNumStudents: number;
  @Input() currentStudentIdx: number;
  @Input() isNewStudentFocusModeLoading: boolean;

  @Input() onScoreInputV2: (row: ScoreEntryStudentRowV2, question: ScoreEntryQuestion, asmtType: ('literacy' | 'numeracy')) => void
  @Input() onSelectedThemeChangeV2: (row: ScoreEntryStudentRowV2, 
  previousTheme?: ScoreEntryStudentRowV2["literacy"]["selectedTheme"]) => Promise<void>
  @Input() markAsNotYetScoredClickedV2: (row: ScoreEntryStudentRowV2) => Promise<void>
  @Input() shouldDisableMarkAsNotYetScoredButtonV2: (row: ScoreEntryStudentRowV2) => boolean

  @Output() newStudentIdx = new EventEmitter<PageSwitch>();

  isLoading: boolean;
  scoreOptions: string[] = ['4', '3', '2', '1', '0', 'NR'];

  isScoreEntryOpen: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private lang: LangService,
    private whitelabel: WhitelabelService,
    private loginGuard: LoginGuardService,
    private bcAccounts: BcAccountsService,
    private bcAssessments: BcAssessmentsService,
    private bcScoreEntry: BcScoreEntryService,
    private breadcrumbsService: BreadcrumbsService,
    private auth: AuthService,
    private dataGuard: DataGuardService,
  ) {
    this.isLoading = false;
  }

  async ngOnInit(): Promise<void> {
    // console.log(this.selectedScoreEntryRowV2);
    this.isScoreEntryOpen = await this.bcScoreEntry.isScoreEntryOpen();
  }

  public get ScoreEntryThemes(): typeof ScoreEntryThemes
  {
    return ScoreEntryThemes;
  }

  displayStudentInfo(): string
  {
    if (this.selectedScoreEntryRowV2 != null)
    {
      return this.selectedScoreEntryRowV2.last_name + ", " + this.selectedScoreEntryRowV2.first_name
      + " (" + this.selectedScoreEntryRowV2.pen + ") - " + "Grade " + this.selectedScoreEntryRowV2.grade;
    }

    return "";
  }

  isPageChangeDisabled(goToPreviousPage: boolean)
  {
    // goToPreviousPage = false means go to next page 

    if (this.isNewStudentFocusModeLoading)
    {
      return true;
    }

    if (!goToPreviousPage && this.currentStudentIdx === this.totalNumStudents)
    {
      return true;
    }

    else if (goToPreviousPage && this.currentStudentIdx === 1)
    {
      return true;
    }

    return false;
  }

  changePage(goToPreviousPage: boolean)
  {
    // goToPreviousPage = false means go to next page
    if (goToPreviousPage)
    {
      this.newStudentIdx.emit(
      {
        idx: this.currentStudentIdx - 2,
        direction: PageDirection.PREVIOUS
      });
    }

    else 
    {
      this.newStudentIdx.emit(
      {
          idx: this.currentStudentIdx,
          direction: PageDirection.NEXT
      });
    }
  }

  public async markAsNotYetScored(): Promise<void>
  {
    try 
    {
      await this.markAsNotYetScoredClickedV2(this.selectedScoreEntryRowV2);
      this.loginGuard.quickPopup("This student has been successfully marked as \"not yet scored\".");
      // todo: needs translation
    }

    catch(e)
    {
      this.loginGuard.quickPopup("There was an error. Please try again.");
      // todo: needs translation
    }
  }

  disableMarkNotYetScoredBtn(): boolean
  {
    return this.shouldDisableMarkAsNotYetScoredButtonV2(this.selectedScoreEntryRowV2);
  }

  fakeSave(): void
  {
    // pure UI, fake save pop up to satisfy requirements; use auto-save for actual saving
    this.loginGuard.quickPopup("Save successful.");
  }

  saveScore(asmtType: "literacy" | "numeracy", scoreOption: string, questionOption: number)
  {
    // console.log(scoreOption, asmtType);
    const currQuestion: ScoreEntryQuestion = this.getQuestion(this.selectedScoreEntryRowV2[asmtType].questions,
    this.selectedScoreEntryRowV2[asmtType].selectedTheme, questionOption);
    currQuestion.response = scoreOption;
    this.onScoreInputV2(this.selectedScoreEntryRowV2, currQuestion, asmtType);
  }

  changeTheme(themeOption: number): void
  {
    // themeOption is 0, 1, or 2 
    // 0 = no theme

    // assuming, that this is always true:
    // this.selectedScoreEntryRowV2.literacy.selectedTheme === this.selectedScoreEntryRowV2.numeracy.selectedTheme

    const oldTheme = this.selectedScoreEntryRowV2.literacy.selectedTheme;
    this.selectedScoreEntryRowV2.literacy.selectedTheme = themeOption;
    this.onSelectedThemeChangeV2(this.selectedScoreEntryRowV2, oldTheme);
  }

  isThemeSelected(themeOption: number): boolean
  {
    // console.log(this.selectedScoreEntryRowV2);
    // assuming, that this is always true:
    // this.selectedScoreEntryRowV2.literacy.selectedTheme === this.selectedScoreEntryRowV2.numeracy.selectedTheme
    return this.selectedScoreEntryRowV2.literacy.selectedTheme == themeOption;
  }

  isScoreSelected(questionOption: number, scoreOption: string, asmtType: "literacy" | "numeracy"): boolean
  {
    return this.getQuestion(this.selectedScoreEntryRowV2[asmtType].questions, 
    this.selectedScoreEntryRowV2[asmtType].selectedTheme, questionOption).response === scoreOption;
  }

  ngOnDestroy() 
  {
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    // console.log(changes);
  }

  clear()
  {
    return;
  }

  createHeaderBarText(): String
  {
    return this.lang.tra("Student") + " " + this.currentStudentIdx
     + " " + this.lang.tra("of") + " " + this.totalNumStudents; 
  }

  getQuestion(allQuestions: ScoreEntryAssessmentData["questions"], selectedTheme: string | number, currQuestion: number): ScoreEntryQuestion
  {
    // console.log(allQuestions, selectedTheme, currQuestion);
    const foundQuestions = allQuestions.filter((question) =>
    {
      // console.log(question.theme, +selectedTheme, question.questionOrder, currQuestion);
      return +question.theme === +selectedTheme && +question.questionOrder === +currQuestion;
    }); 

    // console.log(foundQuestions);

    if (foundQuestions.length < 1)
    {
      console.error("No such questions found");
    }

    else if (foundQuestions.length > 1)
    {
      console.error("More than 1 question found for theme and question from DB, error.")
    }

    return foundQuestions[0];
  }

  

}
