import { CustomInteractionType, ElementType, getElementWeight, IContentElementRotateSprite, IEntryStateRotateSprite, QuestionState, ScoringTypes } from "src/app/ui-testrunner/models";
import { QuestionPubSub } from "src/app/ui-testrunner/question-runner/pubsub/question-pubsub";
import { CustomInteractionCtrl } from "./custom-interaction";
import * as PIXI from "pixi.js-legacy";
import * as _ from "lodash";
import { SpriteLoader } from "./sprite-loader";
interface AnchorPosition {
  TOP_LEFT: { x: number; y: number };
  BOTTOM_LEFT: { x: number; y: number };
  TOP_RIGHT: { x: number; y: number };
  BOTTOM_RIGHT: { x: number; y: number };
  LEFT_MIDDLE: { x: number; y: number };
  RIGHT_MIDDLE: { x: number; y: number };
  MIDDLE: { x: number; y: number };
  BOTTOM_MIDDLE: { x: number; y: number };
  TOP_MIDDLE: { x: number; y: number };
}

export class RotateSpriteCtrl extends CustomInteractionCtrl {
  element: IContentElementRotateSprite;
  spriteLoader: SpriteLoader
  stage: PIXI.Container;
  canvasGr: PIXI.Graphics;
  circleGr: PIXI.Graphics;
  backgroundSprite: PIXI.Sprite;
  rotateSprite: PIXI.Sprite;
  dragPosOffset: PIXI.Point;
  isDragging: boolean;
  value: number;
  rotation: number;
  speed: number;
  maxRadius: number;
  cX: number;
  cY: number;
  radius: number;
  dx: number;
  dy: number;
  isResponded: boolean;

  constructor(element: IContentElementRotateSprite, questionState: QuestionState, questionPubSub: QuestionPubSub, addGraphic, render, zoom, isLocked, textToSpeech) {
    super(questionState, questionPubSub, addGraphic, render, zoom, isLocked, textToSpeech);
    this.element = element;
    this.spriteLoader = new SpriteLoader();        

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    //setup stage
    this.stage = new PIXI.Container();
    this.addGraphic(this.stage);

    //Setup Dimensions
    this.element.scale = this.element.scale || 1;
    this.maxRadius = this.element.canvasWidth / 2 > this.element.canvasHeight / 2 ? this.element.canvasHeight / 2 : this.element.canvasWidth / 2;
    this.radius = this.radius || this.maxRadius / 2;
    this.cX = this.cY = this.maxRadius;

    //Draw a canvas and add it to the stage
    this.canvasGr = new PIXI.Graphics();
    this.canvasGr.interactive = true;
    this.drawCanvas();
    this.stage.addChild(this.canvasGr);

    //Draw a circle around the sprite
    this.circleGr = new PIXI.Graphics();
    this.circleGr.interactive = true;

    //Create a sprite
    this.rotateSprite = new PIXI.Sprite();
    this.rotateSprite.interactive = true;
    this.addMouseListener(this.rotateSprite);

    //create a Background Sprite
    this.backgroundSprite = new PIXI.Sprite();
    this.backgroundSprite.interactive = false;

    //load Sprites
    this.loadAssets().then(this.init)
    // this.loadSprite();

    this.drawCircleAround();
    this.canvasGr.addChild(this.circleGr);

    this.circleGr.addChild(this.backgroundSprite);
    this.circleGr.addChild(this.rotateSprite);

    //Render
    this.render();
  }

  init = ({resources}) => {
    this.rotateSprite.texture = resources.rotate.texture;
    if (resources['background']) this.backgroundSprite.texture = resources.background.texture;
    this.initialRotation();
    this.render();
  }

  addMouseListener(obj: PIXI.DisplayObject) {
    const mousedown = (event) => {
      this.isDragging = true;
      const mousePos = event.data.global;
      this.dragPosOffset = new PIXI.Point(mousePos.x - this.rotateSprite.x, mousePos.y - this.rotateSprite.y);
    }
    obj.on("mousedown", mousedown);
    obj.on("touchstart", mousedown)

    const mouseup = event => {
      if(this.isDragging) this.stopDragging();
    }
    obj.on("mouseup", mouseup);
    obj.on("touchend", mouseup);

    const mouseupoutside = event => {
      // console.log("mouseupoutside");
      if(this.isDragging) this.stopDragging();
    }
    obj.on("mouseupoutside", mouseupoutside);
    obj.on("touchendoutside", mouseupoutside);

    const mousemove = event => {
      if (this.isDragging && !this.isLocked) {
        const data = event.data.global;
        const dx = data.x - this.dragPosOffset.x;
        const dy = data.y - this.dragPosOffset.y;
        let dir = Math.atan2(dy, dx);
        if (dir < 0) dir = -dir;
        let rotateRadian = this.rotateSprite.rotation + (Math.PI / 2 - dir) * 0.01;
        if (rotateRadian < 0) rotateRadian = 0;
        this.rotateSprite.rotation += dir * 0.01; //0.785398;
        // console.log("direction", this.rotateSprite.rotation, dir);
        this.rotateSprite.rotation = this.rotateSprite.rotation >= 2 * Math.PI ? 0 : this.rotateSprite.rotation;
        this.render();

        //store rotation and last position
        this.rotation = this.rotateSprite.rotation;
        this.dx = data.x;
        this.dy = data.y;
        this.isResponded = true;
      }
    }
    obj.on("mousemove", mousemove);
    obj.on('touchmove', mousemove)

    const mouseup2 = () => {
      // console.log("mouseup dom");
      if(this.isDragging) this.stopDragging();
    }
    // #unncessary but deals  with a bug not registering mouseup events sometimes in PIXI
    document.addEventListener("mouseup", mouseup2);
    document.addEventListener("mouseup", mouseup2);
  }

  loadAssets = () => {
    let assets = []
    if (this.isRotateSpriteImg())  assets.push({name: "rotate", path: this.element.rotateSpriteImage.url})
    if (this.isRotateSpriteBgImg()) assets.push({name: "background", path: this.element.rotateSpriteBgImage.url})
    this.spriteLoader.addSpritestoLoader(assets);
    return this.spriteLoader.loadSprites()
  }


  // loadSprite() {
  //   if (this.isRotateSpriteImg() || this.isRotateSpriteBgImg()) {
  //     const loader = new PIXI.Loader();
  //     loader.add("rotate", this.element.rotateSpriteImage.url);
  //     if (this.isRotateSpriteBgImg) loader.add("background", this.element.rotateSpriteBgImage.url);
  //     loader.load((loader, resources) => {
  //       this.rotateSprite.texture = resources.rotate.texture;
  //       if (this.isRotateSpriteBgImg) this.backgroundSprite.texture = resources.background.texture;
  //       // this.rotateSprite.visible = true;
  //       this.initialRotation();
  //       this.render();
  //     });
  //   }
  // }

  initialRotation(): void {
    if (this.questionState && this.questionState[this.element.entryId]) {
      const { rotation } = this.questionState[this.element.entryId];
      if (rotation) {
        this.rotateSprite.rotation = rotation;
      }
      // console.log("saved rotation", rotation);
    }
  }

  stopDragging() {
    this.isDragging = false;
    this.dragPosOffset.x = this.dx;
    this.dragPosOffset.y = this.dy;
    this.getQuadrant(this.rotateSprite.rotation);
  }

  snapRadian: any[] = [Math.PI / 4, (3 * Math.PI) / 4, (5 * Math.PI) / 4, (7 * Math.PI) / 4];
  radians: Set<number> = new Set([0, Math.PI / 2, Math.PI, (3 / 2) * Math.PI, 2 * Math.PI]);

  getQuadrant(rotation): void {
    let value: number;
    if (this.isFirstQuadrant()) {
      this.snapResponse(this.snapRadian[0]);
      value = 1;
    } else if (this.isSecondQuadrant()) {
      this.snapResponse(this.snapRadian[1]);
      value = 2;
    } else if (this.isThirdQuadrant()) {
      this.snapResponse(this.snapRadian[2]);
      value = 3;
    } else if (this.isFourthQuadrant()) {
      this.snapResponse(this.snapRadian[3]);
      value = 4;
    }
    // console.log(value);
    this.render();
    this.setValue(value, this.rotateSprite.rotation);
  }

  snapResponse(snapAngle): void {
    if (this.radians.has(this.rotation)) {
      if (this.rotation == 2 * Math.PI || this.rotation == 0) this.rotateSprite.rotation += Math.PI / 4;
      else this.rotateSprite.rotation += Math.PI / 4;
    } else {
      if (this.rotation < snapAngle) {
        const offsetAngle = snapAngle - this.rotation;
        this.rotateSprite.rotation += offsetAngle;
      } else {
        const offsetAngle = this.rotation - snapAngle;
        this.rotateSprite.rotation -= offsetAngle;
      }
    }
  }

  initDefaultValues() {
    const defaults = {
      scale: 1,
      // spriteX: 0,
      // spriteY: 0,
      spriteHeight: 100,
      spriteWidth: 100,
      spriteAnchorPoint: "BOTTOM_MIDDLE",
      scaleBg: 0.5,
      spriteBgX: 0,
      spriteBgY: 0,
      spriteBgHeight: 100,
      spriteBgWidth: 100,
      spriteBgAnchorPoint: "MIDDLE"
    };

    for (let def of Object.keys(defaults)) {
      if (this.element[def] == null) {
        this.element[def] = defaults[def];
      }
    }
  }

  getUpdatedState(): Partial<IEntryStateRotateSprite> {
    const weight = getElementWeight(this.element);
    const isCorrect = this.element.rotateSpriteanswer == this.value;
    return {
      type: ElementType.CUSTOM_INTERACTION,
      subtype: CustomInteractionType.ROTATE_SPRITE,
      value: this.value,
      rotation: this.rotation,
      isStarted: this.value !== undefined,
      isFilled: this.rotation >= 0,
      isResponded: this.isResponded,
      isCorrect, //change it to provided answer
      score: isCorrect ? weight : 0,
      weight: weight,
      scoring_type: ScoringTypes.AUTO
    };
  }

  drawCanvas() {
    const width = this.maxRadius * 2;
    const canvas = new PIXI.Rectangle(0, 0, width, width);
    this.canvasGr.beginFill(0xffffff);
    this.canvasGr.drawShape(canvas);
    this.canvasGr.endFill();
  }

  drawCircleAround() {
    this.circleGr.clear();
    const sRadius = this.radius * this.element.scale;
    // this.circleGr.lineStyle(1, 0x000000);
    this.circleGr.drawCircle(this.cX, this.cY, sRadius);
    if (this.rotateSprite) {
      // this.rotateSprite.mask = this.circleGr;

      this.rotateSprite.anchor.set(0.5, 1);
      this.rotateSprite.scale.set(0.1 * this.element.scale);
      this.rotateSprite.height = this.element.spriteHeight || sRadius; //sRadius * 2;
      this.rotateSprite.width = this.element.spriteWidth; //sRadius * 2;
      this.rotateSprite.x = this.element.spriteX || this.cX; //this.cX;
      this.rotateSprite.y = this.element.spriteY || this.cY; //this.cY;
      this.rotateSprite.zIndex = 8;
    }
    if (this.isRotateSpriteBgImg()) {
      // this.backgroundSprite.mask = this.circleGr;
      // console.log(this.element.spriteBgHeight, this.element.spriteBgWidth);
      this.backgroundSprite.anchor.set(0.5);
      this.backgroundSprite.scale.set(this.element.scaleBg);
      this.backgroundSprite.height = this.element.spriteBgHeight; //sRadius * 2;
      this.backgroundSprite.width = this.element.spriteBgWidth; //sRadius * 2;
      this.backgroundSprite.x = this.element.spriteBgX;
      this.backgroundSprite.y = this.element.spriteBgY;
      this.backgroundSprite.zIndex = 7;
    }
    // this.circleGr.endFill();
    this.circleGr.zIndex = 6;
    // this.render();
  }

  setValue(val, rotation): void {
    this.value = val;
    this.rotation = rotation;
    this.updateState();
  }

  handleNewState(): void {
    this.value = this.questionState[this.element.entryId].value;
    this.rotation = this.questionState[this.element.entryId].rotation;
    this.isResponded = this.questionState[this.element.entryId].isResponded;
  }

  isFirstQuadrant() {
    return this.rotation > 0 && this.rotation < Math.PI / 2;
  }
  isSecondQuadrant() {
    return this.rotation > Math.PI / 2 && this.rotation < Math.PI;
  }
  isThirdQuadrant() {
    return this.rotation > Math.PI && this.rotation < (3 / 2) * Math.PI;
  }
  isFourthQuadrant() {
    return this.rotation > (3 / 2) * Math.PI && this.rotation < 2 * Math.PI;
  }
  isRotateSpriteImg() {
    return this.element.rotateSpriteImage && this.element.rotateSpriteImage.url;
  }
  isRotateSpriteBgImg() {
    return this.element.rotateSpriteBgImage && this.element.rotateSpriteBgImage.url;
  }
}
