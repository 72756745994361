import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { DistrictDetail, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcScoreEntryService } from 'src/app/bc-score-entry/bc-score-entry.service';
import { IScoreEntrySchoolAggregation, IScoreEntrySchoolAggregationPercentage } from 'src/app/bc-score-entry/types';
import { LangService } from 'src/app/core/lang.service';
import { ngHasInputChanged } from 'src/app/ui-schooladmin/sa-accounts-students/sa-accounts-students-enrolled/util';

@Component({
  selector: 'score-entry-aggregation-table',
  templateUrl: './score-entry-aggregation-table.component.html',
  styleUrls: ['./score-entry-aggregation-table.component.scss']
})
export class ScoreEntryAggregationTableComponent implements OnInit, OnChanges {

  @Input() district: DistrictDetail;
  @Input() school: SchoolDetail;
  @Input() testWindow: TestWindow;
  @Input() totalSlug: string;

  aggregation: IScoreEntrySchoolAggregation;
  aggregationPercentage: IScoreEntrySchoolAggregationPercentage;

  constructor(
    private auth: AuthService,
    private bcScoreEntry: BcScoreEntryService,
    private lang: LangService,
    private routes: RoutesService
  ) { }

  ngOnInit(): void {
    this.reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { school, district, testWindow } = changes;

    let updateTable = false;

    if (school || district || testWindow) {
      if (this.auth.isFsaDistrictAdmin()) {
        if (testWindow || school) {
          updateTable = true;
        }
      }

      if (this.auth.isFsaSchoolAdmin() || this.auth.isGradSchoolAdmin()) {
        const currentSchoolDistrictGroupId = this.school?.district?.groupId || 0;
        const currentDistrictGroupId = this.district?.groupId || 0;
        const isSchoolAndDistrictSelected = currentSchoolDistrictGroupId > 0 && currentDistrictGroupId > 0;
  
        if (isSchoolAndDistrictSelected && currentSchoolDistrictGroupId !== currentDistrictGroupId) {
          this.school = null;
        }

        // school admin can only query with the assigned school(s)
        // to do: move this check to API
        if (!this.school?.groupId) return;

        updateTable = true;
      }

      if (this.auth.isFsaMinistryAdmin()) {
        updateTable = true;
      }

      if (!this.testWindow?.id) return;
      if (!this.district?.groupId) return;

      if (updateTable) this.updateScoreEntrySummaryTableV2();
    }
    
    // if (ngHasInputChanged(changes.district, 'groupId')
    //   || ngHasInputChanged(changes.school, 'groupId')
    //   || ngHasInputChanged(changes.testWindow, 'id')) {
    //   this.update();
    // }
  }

  updateScoreEntrySummaryTableV2 = async () => {
    try {
      const data = await this.auth.apiGet(this.routes.BC_FSA_SCORE_ENTRY, String(this.testWindow.id), {
        query: {
          districtGroupId: this.district.groupId || 0,
          schoolGroupId: this.school?.groupId || 0
        }
      })

      this.aggregation = {
        g4: {
          enrollment: data[4]?.totalEnrolled || 0,
          sr_literacy: data[4]?.literacySrCompleted || 0,
          cr_literacy: data[4]?.literacyCrCompleted || 0,
          sr_numeracy: data[4]?.numeracySrCompleted || 0,
          cr_numeracy: data[4]?.numeracyCrCompleted || 0
        },
        g7: {
          enrollment: data[7]?.totalEnrolled || 0,
          sr_literacy: data[7]?.literacySrCompleted || 0,
          cr_literacy: data[7]?.literacyCrCompleted || 0,
          sr_numeracy: data[7]?.numeracySrCompleted || 0,
          cr_numeracy: data[7]?.numeracyCrCompleted || 0
        }
      }

      this.aggregationPercentage = this.calculateAggregationPercentage(this.aggregation);

    } catch(e) {
      console.log(e);
    }
  }

  reset() {
    this.aggregation = this.bcScoreEntry.getEmptyAggregatedSchoolInfo();
    this.aggregationPercentage = this.calculateAggregationPercentage(this.aggregation);
  }

  private update() {
    if (this.district === undefined || this.testWindow === undefined) return;

    if (this.auth.isSchoolAdmin()) {
      if (!this.school || this.school.groupId <= 0) return;
    }
    else if (this.auth.isDistrictAdmin()) {
      if (this.school === undefined) return;
    }
    else if (this.auth.isMinistryAdmin()) {
      if (!this.district || this.district.groupId <= 0) return;
    }

    this.reset();

    if (this.auth.isSchoolAdmin()) {
      this.bcScoreEntry.generateAggregatedSchoolInfo(this.testWindow, this.district.groupId, this.school.groupId).then(schoolAggregation => {
        this.aggregation = schoolAggregation;
        this.aggregationPercentage = this.calculateAggregationPercentage(schoolAggregation);
      });
    }

    if (this.auth.isDistrictAdmin()) {
      if (this.school === null) {
        this.bcScoreEntry.generateAggregatedSchoolInfo(this.testWindow, this.district.groupId).then(schoolAggregation => {
          this.aggregation = schoolAggregation;
          this.aggregationPercentage = this.calculateAggregationPercentage(schoolAggregation);
        });
      }

      else {
        this.bcScoreEntry.generateAggregatedSchoolInfo(this.testWindow, this.district.groupId, this.school.groupId).then(schoolAggregation => {
          this.aggregation = schoolAggregation;
          this.aggregationPercentage = this.calculateAggregationPercentage(schoolAggregation);
        });
      }
    }

    if (this.auth.isMinistryAdmin()) {
      this.bcScoreEntry.generateAggregatedSchoolInfo(
        this.testWindow,
        this.district.groupId,
        this.school && this.school.groupId > 0 ? this.school.groupId : undefined
      ).then(schoolAggregation => {
        this.aggregation = schoolAggregation;
        this.aggregationPercentage = this.calculateAggregationPercentage(schoolAggregation);
      });
    }
  }

  private calculateAggregationPercentage(schoolAggregation: IScoreEntrySchoolAggregation): IScoreEntrySchoolAggregationPercentage {
    const formatPercentage = (numerator: number, denominator: number): string => {
      if (numerator == 0 || denominator == 0 || numerator == null || denominator == null) return '0.00%';
      return this.formatFrenchPercent(`${(numerator / denominator * 100).toFixed(2)}%`);
    }
    return {
      g4: {
        enrollment: 0,
        cr_literacy: formatPercentage(schoolAggregation.g4.cr_literacy, schoolAggregation.g4.enrollment),
        cr_numeracy: formatPercentage(schoolAggregation.g4.cr_numeracy, schoolAggregation.g4.enrollment),
        sr_literacy: formatPercentage(schoolAggregation.g4.sr_literacy, schoolAggregation.g4.enrollment),
        sr_numeracy: formatPercentage(schoolAggregation.g4.sr_numeracy, schoolAggregation.g4.enrollment),
      },
      g7: {
        enrollment: 0,
        cr_literacy: formatPercentage(schoolAggregation.g7.cr_literacy, schoolAggregation.g7.enrollment),
        cr_numeracy: formatPercentage(schoolAggregation.g7.cr_numeracy, schoolAggregation.g7.enrollment),
        sr_literacy: formatPercentage(schoolAggregation.g7.sr_literacy, schoolAggregation.g7.enrollment),
        sr_numeracy: formatPercentage(schoolAggregation.g7.sr_numeracy, schoolAggregation.g7.enrollment),
      }
    }
  }

  formatFrenchPercent(percent){
    if(this.lang.c() == 'en') return percent;
    let newPercent: string = percent.replace("%", " %");
    newPercent = newPercent.replace(".", ",");
    return newPercent;
  }
}
