<div class="student-individual-report-bulk fit-print-to-page">
    <ng-container *ngIf="!isLoading">
        <div class="dont-print">
            <h1><tra slug="isr_bulk_view_header"></tra></h1>
            <!--NO PDFS AT ALL-->
            <ng-container *ngIf="!hasRecentReports(); else hasRecent">
                <p style="margin:1em 0; font-size:1.2em;">
                    <tra slug="isr_bulk_view_no_pdf_message"></tra>
                </p>
                <button (click)="generatePDF()" class="button is-large">
                    <tra slug="isr_bulk_view_generate_button"></tra>
                </button>
            </ng-container>

            <ng-template #hasRecent>
                    <!--RECENT IS COMPLETE-->
                <ng-container *ngIf="isValidRecentReport()"> 
                    <!-- "recentReports[0].is_waiting == 0 && recentReports[0].is_succeed == 1"> -->
                    <!-- <p style="margin:1em 0; font-size:1.2em;">The most recent PDF for this group was generated {{timeSince(recentReports[0].end)}} ago.</p> -->
                    <p style="margin:1em 0 1em 0; font-size:1.2em;">
                        <tra slug="isr_bulk_view_pdf_generated_date_message"></tra>
                        {{ formatDate(recentReports[0].end) }}
                    </p>

                    <div class="options-row">
                        <button (click)="generatePDF()" class="button is-large">
                            <tra slug="isr_bulk_view_generate_button"></tra>
                        </button>
                        <!-- <span style="font-weight:bold; margin:0 0.5em;">- or -</span>
                        <button  (click)="downloadPDF(recentReports[0].path)" class="button is-large">
                            Download PDF from {{timeSince(recentReports[0].end)}} ago
                        </button> -->
                    </div>
                    <a 
                        style="margin-top: 0.5em; display: block; margin-left: 0.1em;" 
                        (click)="downloadPDF(recentReports[0].path)"
                    >
                        <tra slug="isr_bulk_view_pdf_download_button"></tra>
                        {{ formatDate(recentReports[0].end) }}
                    </a>
                </ng-container>
                <!--RECENT IS INCOMPLETE-->
                <ng-container *ngIf="recentReports[0].is_waiting == 1 && recentReports[0].is_succeed == 0">
                    <p style="margin:1em 0; font-size:1.2em;">
                        <tra slug="isr_bulk_view_pdf_generated_message"></tra>
                    </p>
                    <ng-container *ngIf="recentReports[0].mine">
                        <input 
                            type="checkbox" 
                            id="EmailMe" 
                            [(ngModel)]="emailMe" 
                            (ngModelChange)="changeEmailPreference()"
                        >
                        &nbsp;&nbsp;
                        <label for="EmailMe">
                            <tra slug="isr_bulk_view_email_option"></tra>
                        </label>
                        <br>
                    </ng-container>

                    <div class="options-row"> 
                        <button disabled class="button is-large">
                            <tra slug="loading_message_1"></tra>
                            <span style="width: 1.5em;
                            position: relative;
                            margin-left: 0.5em;">
                                <div class="ball-pulse">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </button>
                        <!-- <ng-container  *ngIf="recentReports[1] && recentReports[1].end">
                            <span style="font-weight:bold; margin:0 0.5em;">- or -</span>
                            <button (click)="downloadPDF(recentReports[1].path)" class="button is-large">
                                Download PDF from {{timeSince(recentReports[1].end)}} ago
                            </button>
                        </ng-container> -->
                    </div>

                    <ng-container  *ngIf="getRecentSucceedReport()">
                        <a 
                            style="margin-top: 0.5em; display: block; margin-left: 0.1em;" 
                            (click)="downloadPDF(getRecentSucceedReport().path)"
                        >
                            <tra slug="isr_bulk_view_pdf_download_button"></tra>
                            {{ getRecentSucceedReport().end }}
                        </a>
                    </ng-container>
                </ng-container>
            </ng-template>
        
            <div class="select-sheet">
                <button 
                    *ngIf="isRawScoreAvailable()"
                    class="sheet-button"
                    [class.selected]="selectedScoreType == ScoreType.RAW_SCORE"
                    (click)="selectScoreType(ScoreType.RAW_SCORE)"
                >
                    <tra slug="raw_score_button"></tra>
                </button>

                <button
                    *ngIf="isScaledScoreAvailable()"
                    class="sheet-button"
                    [class.selected]="selectedScoreType == ScoreType.SCALED_SCORE"
                    (click)="selectScoreType(ScoreType.SCALED_SCORE)"
                >
                    <tra slug="scaled_score_button"></tra>
                </button>
            </div>
            <p style="margin:1em 0;">
                <i>
                    <tra slug="isr_bulk_view_note_message"></tra>
                </i>
            </p>
    
            <ng-container *ngIf="isFsaMinistryAdmin()">
                <button class="button" [class]="currentlang == 'en' ? 'selected' : 'unselected'"
                    (click)="handleLanguageToggle('en')"><tra slug="lbl_en"></tra></button>
                <button class="button" [class]="currentlang == 'fr' ? 'selected' : 'unselected'"
                    (click)="handleLanguageToggle('fr')"><tra slug="lbl_fr"></tra></button>
            </ng-container>
        </div>
    
        <div *ngIf="!languageRefresh" class="preview-container">
            <tra slug="ie_preview"></tra>
            <div class="one-report" *ngFor="let data of singleModeDataRows">
                <student-individual-report 
                    [singleModeData]="data" 
                    [isBulk]="true"
                    [districtCode]="districtDetail.foreignId" [schoolLang]="data.studentSchoolLang"
                    [isScaled]="isIrtReady"
                    [reportYear]="reportYear"
                    [testWindow]="testWindow"
                ></student-individual-report>
            </div>
        </div>
    </ng-container>

    <div *ngIf="isLoading || languageRefresh" class="loader-container dont-print">
        
        <div class="bcg-loader">
            <p style="text-align:center;">
                <tra slug="isr_bulk_view_loading_message"></tra>
            </p>&nbsp;&nbsp;&nbsp;
            <progress id="file" [value]="getLoadPercent()" max="100"> {{getLoadPercent()}}% </progress>
        </div>
        
    </div>
</div>
