import { Component, ElementRef, OnDestroy, OnInit, OnChanges, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { CellClickedEvent, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AuthService } from 'src/app/api/auth.service';
import { LangService } from 'src/app/core/lang.service';
import { students } from 'src/app/marking/data/accounts/students';
import { LeaderService } from 'src/app/marking/leader.service';
import { Location } from '@angular/common';
import { AccountType } from 'src/app/constants/account-types';
import { FSA_MONITORING_REPORT_THRESHOLD } from '../../types/fsa';

export enum DefaultFilterOptions {
  ALL_GRADES = "All Grades",
  ALL_ASSESSMENTS = "All Assessments",
  ALL_QUESTIONS = "All Items",
  ALL_LANGUAGES = "All Languages",
}
@Component({
  selector: 'view-discrepancy-monitoring-report',
  templateUrl: './view-discrepancy-monitoring-report.component.html',
  styleUrls: ['./view-discrepancy-monitoring-report.component.scss']
})
export class ViewDiscrepancyMonitoringReportComponent implements OnInit, OnDestroy {

  @Input() markingSessionId: number;
  @Input() monitoringOnLoad: boolean;
  @Input() districtDetail;
  @Input() isMarkingCoordView: boolean = false;
  
  public isLoaded: boolean = false;
  // public markingSessionId: number;
  public page = 1;

  public studentTableHeaders = ['PEN', 'Grade', 'Assessment', 'Item', 'Language', 'Local Score', 'Monitoring Score', 'Score Disc'];
  public students: any[] = []
  public currStudents: any [] = [];
  public selectedStudent: any = this.students[0];
  public fetchedStudents: any[] = [];

  public DefaultFilterOptions = DefaultFilterOptions;
  public gradeOptions : string[];
  public assessmentOptions: string[];
  public questionOptions: string[];
  public languageOptions: string[];

  public selectedGrade : string;
  public selectedAssessment: string;
  public selectedLanguage: string;
  public selectedQuestion: string;

  public maxDiscrepancyShown = 4;
  public showReportThreshold = FSA_MONITORING_REPORT_THRESHOLD;

  @ViewChild('DiscrepancyChart') discrepancyChart: ElementRef;

  constructor(
    private leaderService: LeaderService, 
    private route        : ActivatedRoute,
    private router       : Router,
    public lang          : LangService,
    public auth          : AuthService,
    private location     : Location) {
   }

  public barChartOptions;
  studentColumnDefs?:any[];
  mainColor;
  public barChartLabels;
  public barChartType;
  public barChartLegend;
  public barChartData;

  //monitoring report variables:
  districtReport: boolean = false;
  selectedDistrictCodeForReport = [];

  questionHeadersTemplate = ["Q1","Q2","Q3"];
  literacyTheme1Questions = this.questionHeadersTemplate;
  literacyTheme2Questions = this.questionHeadersTemplate;
  numeracyQuestions = this.questionHeadersTemplate;
  questionHeaders = [this.literacyTheme1Questions, this.literacyTheme2Questions, this.numeracyQuestions];

  availableGrades = ["Grade 4", "Grade 7"];
  negativeDiscRange = ["-3", "-2", "-1"];
  positiveDiscRange = ["1", "2", "3"];
  discrepancyRange = [...this.negativeDiscRange, "0", ...this.positiveDiscRange];
  dataType = ["Count", "%"];
  totalColSpan = 2; //for the specific cell of the total for each report.
  alignmentColSpan = 3 //for the specific cell of the alignment for each report.
  emptyCellColRowSpan = 3; //for the top left corner cell of each report.
  numeracyRowSpan = 2 //because the numeracy does not have theme sub-headers so the header takes two rows.
  emptySetOfDataTemplate = [0,0,0,0,0,0,0,0,0]; //field for each question.

  districtAdminMode: boolean = false;
  districtModeGId;

  exportCountHeaders   = ["LitTheme1Q1Count", "LitTheme1Q2Count", "LitTheme1Q3Count", 
                          "LitTheme2Q1Count", "LitTheme2Q2Count", "LitTheme2Q3Count",
                          "NumQ1Count"      , "NumQ2Count"      , "NumQ3Count"];
                  
  exportPercentHeaders = ["LitTheme1Q1Percent", "LitTheme1Q2Percent", "LitTheme1Q3Percent", 
                          "LitTheme2Q1Percent", "LitTheme2Q2Percent", "LitTheme2Q3Percent",
                          "NumQ1Percent"      , "NumQ2Percent"      , "NumQ3Percent"];
  ngOnInit(): void {
    if (this.auth.isDistrictAdmin() && this.districtDetail) {
      this.districtModeGId = this.districtDetail.groupId
      this.districtAdminMode = true;
    }
    this.initData().then(() => {
      this.initStudentsColDef();
      if (this.monitoringOnLoad) {
        this.handleProvincialReport();
      }
      this.isLoaded = true;
    })
    this.getCustomizableReportSections();
  }

  ngAfterViewInit() {
    //this.calculateChart();
  }

  getChartFontSize() {
    console.log('getChartFontSize')
    // get window width pixels
    const width = window.innerWidth;
    if(width < 1500) {
      // scale down the size, with 20 at 1380 and 0 at 0
      return 20 * (width / 1500);
    }
    return 20;
  }

  initializeChart(){
    this.barChartOptions = {
      scaleShowVerticalLines: false,
      responsive: true,
      tooltips: false,
      scales:{
        xAxes: [{
          scaleLabel: {
            display: true, 
            labelString: 'Differences between the district score and score assigned at the monitoring session',
            position: 'bottom',
            fontSize: this.getChartFontSize(), 
          },
        }],
        yAxes: [{
          ticks: {
            min: 0
          },
          scaleLabel: {
            display: true, 
            labelString: 'Percentage',
            position: 'left',
            fontSize: this.getChartFontSize(), 
          }
        }],
      },
      animation: {
        onComplete(){
          let chartInstance = this.chart,
          ctx = chartInstance.ctx;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          ctx.font = '18px Arial';
          ctx.fillStyle = '#000';
          this.data.datasets.forEach((dataset, i) => {
            let meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach((bar, index) => {
              let data = dataset.data[index];
              ctx.fillText(data + '%', bar._model.x, bar._model.y);
            });
          });
        }
      },
      title: {
        text: 'Score Discrepancies for ' + this.getSelectedQuestion(),
        fontSize: this.getChartFontSize(),
        display: true
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 0
        }
      },
    };

  // this.mainColor= '#3298dc';
  this.mainColor= '#4971b7';
  this.barChartLabels = ['0', '1', '2', '3', '4'];
  this.barChartType = 'bar';
  this.barChartLegend = true;
  this.barChartData = [
    {data: [0, 0, 0, 0, 0], label: '',
    hoverBackgroundColor: this.mainColor, 
    hoverBorderColor: this.mainColor,
    backgroundColor: this.mainColor,
    borderColor: this.mainColor,
    color: this.mainColor,
    borderWidth: 1,
    }];
  }

  handleProvincialReport(){
    for(let s of this.students){
      if(!this.selectedDistrictCodeForReport.includes(s.district))
        this.selectedDistrictCodeForReport.push(s.district);
    }
    this.calculateDistrictReport();
    this.districtReport = true;
  }

  getSelectedQuestion() {
    if(!this.questionOptions || !this.selectedQuestion) return 'All Items';
    const filteredQuestions = this.questionOptions.filter(q => q.toLowerCase().includes(this.selectedQuestion.toLowerCase()));
    if(filteredQuestions.length == 1) return filteredQuestions[0];
    return 'Applied Filter';
  }

  initStudentsColDef(){
    
    const cellStyle = {fontSize: '12px'};
    const smalles = 100;
    this.studentColumnDefs = 
    [
      this.genDefaultCol('pen', 'lbl_pen', {cellStyle}), 
      this.genDefaultCol('grade', 'school_grade', {cellStyle,
        cellRenderer: (params: ICellRendererParams) => 
        {
          const row = params.data;
          return row.grade;
          return this.lang.tra('mrkg_grade', null, {GRADE: row.grade});
        },
      }),
      this.genDefaultCol('assessment_code', 'mrkg_assessment', {cellStyle}),
      this.genDefaultCol('item_name', 'mrkg_session_items_col_item', {cellStyle}),
      this.genDefaultCol('language', 'mrkg_language', {cellStyle}),
      this.genDefaultCol('local_score', 'mrkg_local_score', {cellStyle}),
      this.genDefaultCol('monitoring_score', 'mrkg_monitoring_score', {cellStyle,
        cellRenderer: (params: ICellRendererParams) => 
        {
          const row = params.data;
          const title = this.lang.tra('mrkg_marked_exemplar');
          return `${row.monitoring_score || ''} &nbsp;&nbsp;
          ${row.is_exemplar ? `<i title="${title}" class="fas fa-book"></i>` : ``}`;
        },}),
      this.genDefaultCol('difference', 'mrkg_score_disc', {cellStyle}),
      this.genDefaultCol('district', 'sa_sr_district_code', {cellStyle, 
        cellRenderer: (params: ICellRendererParams) => 
        { return `<a>${params.data.district || 'Independent'}</a>`; },
        onCellClicked: async (event: CellClickedEvent) => {
          const row = event.data;
          this.selectedDistrictCodeForReport.push(row.district);
          this.calculateDistrictReport();
          this.districtReport = true;
        },
      }),
      this.genDefaultCol('school', 'sa_sr_school_code', {cellStyle}),
    ]
  }

  ngOnDestroy(){
    document.title = this.currentDocTitle;
  }

  districtNameFromCode(code) {
    let student = this.students.find(s => s.district == code);
    return student.district_name;
  }
  
  handleBackButton(){
    document.title = this.currentDocTitle;
    this.districtReport = false;
    this.selectedDistrictCodeForReport = [];
    this.districtReportData = [];
  }

  districtReportData = [];
  calculateDistrictReport(){ //this calculation happens only once, when a the report is first loaded.

    // remove null values from the selectedDistrictCodeForReport array and ensure elements are unique
    // (remerge uat)
    this.selectedDistrictCodeForReport = this.selectedDistrictCodeForReport.filter((districtCode, index, self) => districtCode != null && self.indexOf(districtCode) === index);

    for(let district of this.selectedDistrictCodeForReport){
      let eachObj = {district: null, grade: null, disc: null, count: [], percent: [], isTotal: false};
      eachObj.district = district;
      for(let grade of this.availableGrades){
        let gradeNum = parseInt(grade.split(" ")[1])
        eachObj.grade = gradeNum;
        for(let disc of this.discrepancyRange){
          let count;
          eachObj.disc = disc;
          let studentListRes = this.students.filter(s=> s.district == district && s.grade == gradeNum && s.difference == disc);
          count = 
          [studentListRes.filter(s => s.item_name.includes("Q1") && s.item_name.includes("T1")).length,
            studentListRes.filter(s => s.item_name.includes("Q2") && s.item_name.includes("T1")).length,
            studentListRes.filter(s => s.item_name.includes("Q3") && s.item_name.includes("T1")).length,
            studentListRes.filter(s => s.item_name.includes("Q1") && s.item_name.includes("T2")).length,
            studentListRes.filter(s => s.item_name.includes("Q2") && s.item_name.includes("T2")).length,
            studentListRes.filter(s => s.item_name.includes("Q3") && s.item_name.includes("T2")).length,
            studentListRes.filter(s => s.item_name.includes("Q1") && s.item_type == "numeracy").length,
            studentListRes.filter(s => s.item_name.includes("Q2") && s.item_type == "numeracy").length,
            studentListRes.filter(s => s.item_name.includes("Q3") && s.item_type == "numeracy").length];
          eachObj.count = count;
          this.districtReportData.push({...eachObj});
        }
        let filterForTotal = this.districtReportData.filter(d => d.district == district && d.grade == gradeNum);
        let total = {district: district, grade: gradeNum, disc: null, count: [0,0,0,0,0,0,0,0,0], percent: [], isTotal: true, overallLT: null, overallNUM: null};
        for(let i = 0; i < total.count.length; i++){
          for(let filtered of filterForTotal){
            total.count[i] += filtered.count[i];
          }
        }
        this.districtReportData.push({...total});
        for(let row of this.districtReportData){
          let newRow = JSON.parse(JSON.stringify(row));
          const total = this.districtReportData.find( d => d.district == row.district && d.grade == row.grade && d.disc == null);
          if(row.disc != null){
            for(let i = 0; i < newRow.count.length; i++){
              //if the total is 0 then dividing by it will cause NaN, so when the total is zero we simply don't calculate further
              if(total.count[i] == 0) newRow.percent[i] = (0).toFixed(1);
              else newRow.percent[i] = ((newRow.count[i] / total.count[i]) * 100).toFixed(1);
            }
          }else{
            let alignmentRows = this.districtReportData.filter( d => d.district == row.district && d.grade == row.grade && (d.disc == -1 || d.disc == 0 || d.disc == 1));
            let alignmentCount = [0,0,0,0,0,0,0,0,0];
            for(let i = 0; i < newRow.count.length; i++){
              for(let chosen of alignmentRows){
                alignmentCount[i] += chosen.count[i];
              }
            }
            let countLit = 0;
            let countNum = 0;
            for(let i = 0; i < alignmentCount.length; i++){
              //if the total is 0 then dividing by it will cause NaN, so when the total is zero we simply don't calculate further
              if(total.count[i] == 0) newRow.percent[i] = (0).toFixed(1);
              else newRow.percent[i] = ((alignmentCount[i] / total.count[i]) * 100).toFixed(1);
              if(i < 6) countLit += +newRow.percent[i];
              else countNum += +newRow.percent[i];
            }
            newRow.overallLT = (countLit/6).toFixed(1);
            newRow.overallNUM= (countNum/3).toFixed(1);
          }
          this.districtReportData[this.districtReportData.findIndex( d => d.district == row.district && d.grade == row.grade && d.disc == row.disc)] = {...newRow};
        }
      }
    }
    this.selectedDistrictCodeForReport = this.selectedDistrictCodeForReport.sort((a, b) => a - b);
    // console.log("districtReportData: ", this.districtReportData);
  }

  districtReportEditMode: boolean = false;
  customizableReportSections = {
    header: ``,
    body: ``}

  async getCustomizableReportSections(){
    this.customizableReportSections = await this.auth.apiFind('/public/mrkg-coord/fsa-monitoring-report-content');
  }

  navigateToFinalReview(){
    const route = `/${this.lang.c()}/${AccountType.MRKG_COORD}/${this.is_credentialing_session ? 'credentialing':'marking'}-session-details/${this.markingSessionId}/`;
    this.router.navigate([route],{
      queryParams:{
        tab: 'items',
        subtab: 'finalreview'
      }
    });
  }

  async handleEditButton(){
    if(this.districtReportEditMode){
      await this.auth.apiPatch('/public/mrkg-coord/fsa-monitoring-report-content', 1, {
          header: this.customizableReportSections.header,
          body: this.customizableReportSections.body,
        }
      );
    }
    this.districtReportEditMode = !this.districtReportEditMode;
  }

  trackByFn(index, item) {
    return index;  
  }

  currentDocTitle = document.title;
  printReport() {
    if(this.selectedDistrictCodeForReport.length == 1) document.title = 'School District ' + this.selectedDistrictCodeForReport[0] + ' Report'
    else document.title = 'District Monitoring Report'
    let url = this.router.url;
    this.location.go("");
    window.print()
    this.location.go(url);
  }

  printGraph(){
    const graph = document.getElementById('chartCanvas') as HTMLCanvasElement;
    const dataUri = graph.toDataURL('image/png');
    const img = document.createElement('img');
    img.src = dataUri;
    // const graphHeader = document.getElementById("class-header");

    const printWindow = window.open('', `blank`);
    // printWindow.document.body.innerHTML = graphHeader.outerHTML;
    printWindow.document.body.appendChild(img);
    
    window.requestAnimationFrame(() => {
      printWindow.print();
    });
  }

  // show the district if among all its item totals, there are 10 items with sufficient data
  shouldShowDistrict(district) {
    const totals = this.districtReportData.filter(d => d.district == district && d.isTotal == true);
    let count = 0;
    for(let t of totals){
      let i = 0;
      for(let item of t.count) {
        if(this.isSufficientData(district, t.grade, i)) count++;
        i++;
      }
    }

    return count >= this.showReportThreshold;
  }

  // The item has sufficient data if there are at least 10 students who have answered it
  isSufficientData(district, grade, index) {
    return this.getItemTotal(district, grade, index) >= this.showReportThreshold;
  }

  // get the total students who have answered the item
  getItemTotal(district, grade, index) {
    const totals = this.districtReportData.find(d => d.district == district && grade.toString().includes(d.grade) && d.isTotal == true);
    return totals?.count[index] || 0;
  }

  getData(district, grade, countOrPercent, discrepancy?, alignment?, overallLT?, overallNUM?){ // 0==count 1==percent, when discrepancy is null we get the total. alignment is the last row of %
    let find;
    if(overallLT || overallNUM){
      find = this.districtReportData.find(d => d.district == district && d.grade == grade.split(" ")[1] && d.isTotal == true);
      return overallNUM ? find.overallNUM : find.overallLT;
    }
    else find = this.districtReportData.find(d => d.district == district && d.grade == grade.split(" ")[1] && (discrepancy? d.disc == discrepancy: d.isTotal == true));

    if(countOrPercent == 0) {
      return find.count;
    }
    if(countOrPercent == 1) {
      return find.percent.map(p => p + '%');
    } 
  }

  genDefaultCol(field: string, headerTra:string, config:any, filter?, optionalFilterParams?){
    let filterType: boolean | string;
    if (filter != null) { filterType = filter; }
    else { filterType = true; }
    let filterParams = {
      newRowsAction: 'keep'
    };
    if (optionalFilterParams != null) {
      filterParams = {
         ...filterParams, ...optionalFilterParams 
      };
    }
    let floatingFilterComponentParams;
    if(this.lang.getCurrentLanguage() == 'fr'){
      floatingFilterComponentParams = {suppressFilterButton:true};
    }
    return {
      headerName: this.lang.tra(headerTra),
      field,
      filter: filterType,
      suppressMenu: true,
      floatingFilter: true,
      floatingFilterComponentParams,
      sortable: true,
      resizable: true,
      ... config
    }
  }

  currPage = 1;
  numPages;
  PAGE_SIZE = 20;
  pageData:any[];
  goToPage(p) {
    this.currPage = p;
    this.pageData = this.currStudents.slice((this.currPage - 1)*this.PAGE_SIZE, (this.currPage)*this.PAGE_SIZE);
  }

  prevPage() {
    this.goToPage(Math.max(1, this.currPage-1));
  }
  nextPage() {
    this.goToPage(Math.min(this.numPages, this.currPage+1));
  }

  initPages() {
    this.numPages = Math.ceil(this.currStudents.length / this.PAGE_SIZE);
  }

  markingSessionName;
  is_credentialing_session;

  async initData(refresh = true) {
    this.initializeChart();
    if (refresh) {
      if (!this.markingSessionId) {
        throw new Error('Cannot `initData`. Undefined `this.markingSessionId`');
      }
      let ms = await this.auth.apiGet('public/mrkg-lead/assigned-marking-session', this.markingSessionId);
      this.markingSessionName = ms.title;
      this.is_credentialing_session = ms.is_credentialing_session;
      this.students = await this.leaderService.getDiscrepancyReportData(this.markingSessionId);

      if (this.districtAdminMode) {
        this.students = this.students.filter(student => student.district == this.districtDetail.foreignId);
      }
      this.setDefaultFilterOptions();
      this.populateFilterOptions();
      
    }
    this.calculatePointDifferences();
    this.currStudents = this.filterStudents(this.students);
    this.setGraphData();
    // this.updateTableAndGraph();
    this.initPages();
    this.goToPage(1);
    
  }

  goBackToFinalResultsRoute() {
    this.router.navigate([this.lang.c(), this.auth.myActiveMarkingRole(this.markingSessionId, true), `${this.is_credentialing_session ? 'credentialing' : 'marking'}-session-details`, this.markingSessionId], {
      queryParams: {
        tab: 'items',
        subtab: 'finalreview'
      }
    })
  }

  studentGridApi;
  onStudentGridReady(params: GridReadyEvent) {
    this.studentGridApi = params.api;
    this.cacheFilteredStudentRows();
  }

  cacheFilteredStudentRows(){
    if (this.studentGridApi != null){
      this.checkFilter('assessment_code', 'selectedAssessment');
      this.checkFilter('grade', 'selectedGrade');
      this.checkFilter('item_name', 'selectedQuestion');
      this.checkFilter('language', 'selectedLanguage');
    }
  }

  checkFilter(key, selected){
    const modal = this.studentGridApi.getFilterModel();
    if(modal && modal[key]){
      this[selected] = modal[key].filter;
    }else{
      if(selected == 'selectedAssessment') this[selected] = DefaultFilterOptions.ALL_ASSESSMENTS; 
      if(selected == 'selectedGrade') this[selected] = DefaultFilterOptions.ALL_GRADES;
      if(selected == 'selectedQuestion') this[selected] = DefaultFilterOptions.ALL_QUESTIONS;
      if(selected == 'selectedLanguage') this[selected] = DefaultFilterOptions.ALL_LANGUAGES;
    }
    this.currStudents = [];
    this.studentGridApi.forEachNodeAfterFilterAndSort((node) => {
      this.currStudents.push(node.data);
    });
    if (selected == 'selectedQuestion') this.initializeChart();
    this.setGraphData();
  }

  formatThreshold(number) {
    if (number % 10 === 0) {

      if (number === 100) {
        return '1.00';

      } else if (number === 0) {
        return '0.00';
      }

      return `${number / 100}0`;

    }
    return number / 100;
  }

  getFinalResultsRoute() {
    return `/${this.lang.c()}/${this.auth.myActiveMarkingRole(this.markingSessionId, true)}/${this.is_credentialing_session ? 'credentialing' : 'marking'}-session-details/${this.markingSessionId}`;
  }

  getScore(item, student) {
    const scoreObj = student.scores.find((score) => {
      return score.item === item;
    });

    if (!scoreObj) {
      return '';
    }
    return `${scoreObj.studentScore}/${scoreObj.fullScore}`;
  }

  getScoreObj(item, student) {
    const scoreObj = student.scores.find((score) => {
      return score.item === item;
    });

    if (!scoreObj) {
      return null;
    }
    return scoreObj;
  }

  goToAllResponses() {
    this.router.navigate([this.lang.c(), this.auth.myActiveMarkingRole(this.markingSessionId, true), `${this.is_credentialing_session ? 'credentialing' : 'marking'}-session-details`, this.markingSessionId, 'correlation-report', this.selectedStudent.PEN])
  }

  selectNextStudent() {

    const currIndex = this.currStudents.findIndex((student) => {
      return student === this.selectedStudent;
    });

    const nextIndex = currIndex === this.currStudents.length - 1 ? currIndex : currIndex + 1;

    //this.selectStudent(this.currStudents[nextIndex]);

  }

  getCurrIndex() {
    return this.currStudents.findIndex((student) => {
      return student === this.selectedStudent;
    });
  }

  selectPrevStudent() {
    const currIndex = this.currStudents.findIndex((student) => {
      return student === this.selectedStudent;
    });

    const prevIndex = currIndex === 0 ? currIndex : currIndex - 1;

    //this.selectStudent(this.currStudents[prevIndex]);
  }






  markAsInspected() {
    this.selectedStudent.isInspected = true;
  }

  filterStudents(students) {
    return students.filter((student) => {
      return (this.isSelectedAssessment(student.assessment_code)
        && this.isSelectedGrade(student.grade)
        && this.isSelectedQuestion(student.item_name)
        && this.isSelectedLanguage(student.language)
      );
    });

  }


  getAverage(scores) {

    const sum = scores.reduce((acc, score) => {
      const percent = score.studentScore / score.fullScore;

      return acc + percent;
    }, 0);

    return sum / scores.length;
  }

  getPercent(number) {
    return number * 100;
  }

  maskPEN(pen) {
    if(this.auth.isCoordinator()) return pen;
    return '******'+pen[pen.length-3]+pen[pen.length-2]+pen[pen.length-1];
  }

  populateFilterOptions() {
    const options = {
      grade: {},
      assessment_code: {},
      item_name: {},
      language: {},
    }

    for (let student of this.students) {
      if (!(student.grade in options.grade)) {
        options.grade[student.grade] = null;
      }
      if (!(student.assessment_code in options.assessment_code)) {
        options.assessment_code[student.assessment_code] = null;
      }
      if (!(student.item_name in options.item_name)) {
        options.item_name[student.item_name] = null;
      }
      if (!(student.language in options.language)) {
        options.language[student.language] = null;
      }
    }

    this.gradeOptions.push(...Object.keys(options.grade));
    this.assessmentOptions.push(...Object.keys(options.assessment_code));
    this.filteredAssessmentOptions = this.assessmentOptions;
    this.questionOptions.push(...Object.keys(options.item_name));
    this.languageOptions.push(...Object.keys(options.language));

    // console.log(this.gradeOptions);
    // console.log(this.assessmentOptions);
    // console.log(this.questionOptions);
  }


  isSelectedGrade(grade) {
    if (this.selectedGrade == DefaultFilterOptions.ALL_GRADES) {
      return true;
    }
    return grade == this.selectedGrade;
  }

  isSelectedAssessment(assessment) {
    if (this.selectedAssessment == DefaultFilterOptions.ALL_ASSESSMENTS) {
      return true;
    }
    return assessment == this.selectedAssessment;
  }

  isSelectedQuestion(question) {
    if (this.selectedQuestion == DefaultFilterOptions.ALL_QUESTIONS) {
      return true;
    }
    return question == this.selectedQuestion;
  }

  isSelectedLanguage(language) {
    if (this.selectedLanguage == DefaultFilterOptions.ALL_LANGUAGES) {
      return true;
    }
    return language == this.selectedLanguage;
  }

  setDefaultFilterOptions() {
    this.selectedQuestion = DefaultFilterOptions.ALL_QUESTIONS;
    this.selectedAssessment = DefaultFilterOptions.ALL_ASSESSMENTS;
    this.selectedGrade = DefaultFilterOptions.ALL_GRADES;
    this.selectedLanguage = DefaultFilterOptions.ALL_LANGUAGES;

    this.gradeOptions = [DefaultFilterOptions.ALL_GRADES];
    this.assessmentOptions = [DefaultFilterOptions.ALL_ASSESSMENTS];
    this.questionOptions = [DefaultFilterOptions.ALL_QUESTIONS];
    this.languageOptions = [DefaultFilterOptions.ALL_LANGUAGES];
  }

  calculatePointDifferences() {
    this.students = this.students.map((student) => {
      if(student.monitoring_score != undefined && !isNaN(student.monitoring_score) && student.local_score != undefined && !isNaN(student.local_score))
        student.difference = Number(student.monitoring_score) - Number(student.local_score);
      return student;
    });
  }

  setGraphData() {
    // if (this.currStudents.length == 0) {
    //   return;
    // }

    const differenceFrequencies = {};
    const percentages : any[] = [];

    let studentCount = 0;

    for (let student of this.currStudents) {
      if(!student.monitoring_score) continue;
      if(student.local_score === null) continue;
      if(student.monitoring_score == 'NR') continue;
      if(student.local_score == 'NR') continue;

      const pointDifference = Math.abs(student['difference']);
      if ((pointDifference in differenceFrequencies)) {
          differenceFrequencies[pointDifference] += 1
      } else {
        differenceFrequencies[pointDifference] = 1
      }

      studentCount++;
    }

    for (let i = 0; i <= this.maxDiscrepancyShown; i++) {
      let percentageForDiff = "0";
      if (i in differenceFrequencies) {
        percentageForDiff =  ((differenceFrequencies[i] / studentCount) * 100).toFixed(1);
      }

      percentages.push(percentageForDiff);
    }

    // console.log('perc', percentages);
    this.barChartData[0]['data'] = percentages;
  }

  changeSelectedAssessment(e) {
    this.selectedAssessment = e.target.value;
    this.updateTableAndGraph();
  }

  filteredAssessmentOptions: any[];
  changeSelectedGrade(e) {
    this.selectedGrade = e.target.value;
    this.filteredAssessmentOptions = this.assessmentOptions;
    if(this.selectedGrade != DefaultFilterOptions.ALL_GRADES){
      let newAssessmentSet = [];
      for(let opt of this.filteredAssessmentOptions){
        if(opt.includes(this.selectedGrade) || opt == DefaultFilterOptions.ALL_ASSESSMENTS) newAssessmentSet.push(opt);
      }
      this.filteredAssessmentOptions = newAssessmentSet;  
      if(this.filteredAssessmentOptions.find(opt => opt != this.selectedAssessment)) this.selectedAssessment = this.filteredAssessmentOptions[0];
    }
    this.updateTableAndGraph();
  }

  changeSelectedQuestion(e) {
    this.selectedQuestion = e.target.value;
    this.updateTableAndGraph();
  }

  changeSelectedLanguage(e) {
    this.selectedLanguage = e.target.value;
    this.updateTableAndGraph();
  }

  updateTableAndGraph() {
    this.initData(false);
  }

  generateExportFileName(monitoring?) {
    return (monitoring? `FSAMonitoringReport_` : `FSADiscrepancyReport_`) + Date.now();
  } 

  exportExcelFile() {
    this.auth.jsonToExcel(this.formatStudentsForExcel(), this.generateExportFileName()).then((res: any)=> {
      this.downloadFile(res.url);
    });
  }

  exportFSAReport(){
    this.auth.jsonToExcel(this.formatStudentsForExcel(true), this.generateExportFileName(true)).then((res: any)=> {
      this.downloadFile(res.url);
    });
  }

  downloadFile(file) {
    var a = document.createElement("a");
    a.href = file;
    a.click();
  }

  processExportRow(district, grade, disc, counts, percents){
    let row = {
      District:    district,
      Grade:       grade,
      Discrepancy: disc,
    }
    for(let i = 0; i < counts.length; i++){
      row[this.exportCountHeaders[i]]   = counts[i];
      row[this.exportPercentHeaders[i]] = percents[i]+'%';
    }
    return row;
  }

  formatStudentsForExcel(monitoring?) {
    if(monitoring){
      let toExport: any[] = [];
      for(let dist of this.selectedDistrictCodeForReport){
        for(let grade of this.availableGrades){
          for(let disc of this.discrepancyRange){
            let counts:   any[] = [];
            let percents: any[] = [];
            for(let type_i = 0; type_i < this.dataType.length; type_i++){
              const rowValues          = this.getData(dist, grade, type_i, disc);
              if(type_i == 0) counts   = rowValues;
              else            percents = rowValues;
            }
            toExport.push(this.processExportRow(dist, grade, disc, counts, percents));
          }
          const totaRowCount   = this.getData(dist, grade, 0, null);
          const totaRowPercent = this.getData(dist, grade, 1, null);
          toExport.push(this.processExportRow(dist, grade, "Totals", totaRowCount, totaRowPercent));
        }
      }
      return toExport;
    }else{
      let studentList = this.currStudents.slice();
      studentList.map(s => {
        delete s.is_exemplar
        delete s.district_id
      });
  
      return studentList.map(student => {
        if(!student.monitoring_score) {
          student.difference = '';
        }
        return student;
      })
    }
  }

  isLastReport(district_i, grade_i){
    return district_i == (this.selectedDistrictCodeForReport.length - 1) && grade_i == (this.availableGrades.length - 1);
  }

  // show the monitoring report if any of the districts should be shown
  public shouldShowMonitoringReport(): boolean {
    for (const districtGroupId of this.selectedDistrictCodeForReport) {
      if (this.shouldShowDistrict(districtGroupId)) return true;
    }

    return false;
  }
}
