import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { BcAccountsService, DistrictDetail, School, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { AssessmentType, BcAssessmentsService, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { BreadcrumbsService, IBreadcrumbRoute } from 'src/app/core/breadcrumbs.service';
import { LangService } from 'src/app/core/lang.service';
import { SidepanelService } from 'src/app/core/sidepanel.service';
import { MyBoardService } from 'src/app/ui-dist-admin/my-board.service';
import { BCFSAAssessmentSession, BCFSAProgressReportsView, BC_FSA_PROGRESS_REPORTS_VIEWS, IView } from 'src/app/ui-ministryadmin/ma-progress-reports/data/views';
import { G9DemoDataService } from 'src/app/ui-schooladmin/g9-demo-data.service';
import { MySchoolService } from 'src/app/ui-schooladmin/my-school.service';
import { memo } from 'src/app/ui-testrunner/element-render-video/element-render-video.component';
import { BcHeaderLayoutComponent } from '../bc-header-layout/bc-header-layout.component';
import { LoginGuardService } from "../../api/login-guard.service";
import { WhitelabelService } from '../../domain/whitelabel.service';

interface IAssessmentSession {
  code: string;
  name: string;
  description: string,
  reports: IView<BCFSAProgressReportsView>[];
}

@Component({
  selector: 'progress-report',
  templateUrl: './progress-report.component.html',
  styleUrls: ['./progress-report.component.scss']
})
export class ProgressReportComponent implements OnInit {

  @ViewChild(BcHeaderLayoutComponent) headerLayout: BcHeaderLayoutComponent;

  accountType: AccountType = this.route.snapshot.data['accountType'];

  public breadcrumb: IBreadcrumbRoute[] = [];
  public schoolData;
  public assessmentSessions: IAssessmentSession[] = [];
  public selectedAssessmentSession: IAssessmentSession;
  isSelectedAdministrationSession: boolean = true;
  showSelectAssessmentModal: boolean = false;

  selectedTestWindow: TestWindow;

  selectedSchool: SchoolDetail;

  districtDetail: DistrictDetail;
  schools: School[];


  // temp
  rollUpReportTitleSlug = 'sc_drur_title';
  rollUpReportDescriptionSlug = 'sc_drur_desc';
  rollUpReportFilename = 'Roll-Up-Report';

  constructor(
    private bcAccounts: BcAccountsService,
    public lang: LangService,
    private loginGuard: LoginGuardService,
    private whitelabel: WhitelabelService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    private sidePanel: SidepanelService,
    private g9DemoData: G9DemoDataService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private bcAssessments: BcAssessmentsService,
    private auth: AuthService,
  ) {
  }
  BCFSAProgressReportsView = BCFSAProgressReportsView;
  selectedView: BCFSAProgressReportsView;
  routeSub: Subscription;
  subs: Subscription[] = [];
  sanitizedUrls = new Map();
  isInited: boolean;
  noRole = false;

  ngOnInit() {
    console.log('progress-report.comp.ts');
    if (this.whitelabel.getSiteFlag('IS_BCED')) {
      this.loginGuard.forceEnglish()
    }
    this.loginGuard.activate();
    this.sidePanel.activate();
    this.sidePanel.unexpand();
    this.initRouteSub();
    this.updateSchoolInfo(true);
    this.loadData();

    const sessionCode = this.route.snapshot.queryParams.session_code || BCFSAAssessmentSession._2020_2021_FUNDAMENTAL_SKILLS_ASSESSMENT;
    this.selectedAssessmentSession = this.assessmentSessions.filter(session => session.code === sessionCode)[0];

    this.updateBreadcrumb();

    if (!this.isSchoolAdmin()) {
      this.bcAssessments.findActiveTestWindow(AccountType.MINISTRY_ADMIN, AssessmentType.FSA, false).then(testWindow => this.selectedTestWindow = testWindow);
    }
  }

  private loadData() {

    let reports = [BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.DAILY_DISTRICT_SUBMISSION),
    BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.DAILY_SUBMISSION_SUMMARY),
    BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.DISTRICT_ROLL_UP),
    BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.ENROLMENT_EXTRACT_1300C),
    BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.ENROLMENT_EXTRACT_1300D),
    BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.NOT_STARTED_SR_CR),
    BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.SCHOOL_COMPLETION),
    BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.STUDENT_LOGIN_INFORMATION),
    BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.SCHOOL_LEVEL_DATA_REPORT),
    BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.STUDENT_RESPONSE_TIME_REPORT),
    BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.CONFIRMATION_CODE_REPORT),
    ]

    if (this.isDistrictAdmin()) {
      reports = [
        BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.NOT_STARTED_SR_CR),
        BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.SCHOOL_COMPLETION),
        BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.SCHOOL_LEVEL_DATA_REPORT),
        BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.CONFIRMATION_CODE_REPORT),
        BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.DISTRICT_ROLL_UP),
      ]
    }
    if (this.isSchoolAdmin()) {
      reports = [
        BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.SCHOOL_COMPLETION),
        BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.SCHOOL_LEVEL_DATA_REPORT),
        BC_FSA_PROGRESS_REPORTS_VIEWS.get(BCFSAProgressReportsView.CONFIRMATION_CODE_REPORT),
      ]
    }

    for (let report of reports) {
      if (report.id === 'school_level_data_report') {
        if (this.auth.isFsaSchoolAdmin() || this.auth.isFsaDistrictAdmin()) {
          report.description = 'sc_sldr_desc_no_scaled';
        }
      }

      report.disabled = false;
    }
    this.assessmentSessions = [{
      code: BCFSAAssessmentSession._2020_2021_FUNDAMENTAL_SKILLS_ASSESSMENT,
      name: this.lang.tra('sa_2021_FSA'),
      description: this.lang.tra(`sa_2021_FSA_desc`),
      reports: reports
    }];
  }

  onRoleFail = (failed: boolean) => {
    if (failed) {
      this.isInited = false;
      this.noRole = true;
    }
  }

  updateSchoolInfo = (schoolInfo: any) => {
    if (schoolInfo && !this.isInited) {
      this.isInited = true;
      this.schoolData = this.g9DemoData.schoolData;
      this.initRouteSub();
    }
    else if (!schoolInfo) {
      this.isInited = false;
    }
  }

  initRouteSub() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    this.routeSub = this.route.params.subscribe(e => {
      this.onRouteChange(e)

    });
  }

  getBaseRoute() {
    if (this.isDistrictAdmin()) {
      return `/${this.lang.c()}/${AccountType.DIST_ADMIN}`;
    }
    if (this.isSchoolAdmin()) {
      return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}`;
    }
    if (this.isMinistryAdmin()) {
      return `/${this.lang.c()}/${AccountType.MINISTRY_ADMIN}`;
    }
  }

  getViewRoute(viewSlug: BCFSAProgressReportsView) {
    const view = (viewSlug === BCFSAProgressReportsView.REPORTS_LIST ? '' : `/${viewSlug}`);
    return `${this.getBaseRoute()}/bc-fsa/${BCFSAProgressReportsView.REPORTS_LIST}${view}`;
  }

  getDashboardRoute() {
    if (this.isDistrictAdmin()) {
      return `/${this.lang.c()}/${AccountType.DIST_ADMIN}/bc-fsa/dashboard`;
    }
    if (this.isSchoolAdmin()) {
      return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}/bc-fsa/dashboard`;
    }
    if (this.isMinistryAdmin()) {
      return `/${this.lang.c()}/${AccountType.MINISTRY_ADMIN}/bc-fsa/dashboard`;
    }
  }

  isFSA(): boolean {
    let type = this.route.snapshot.data.accountType + "";

    return type.includes('bcfsa');
  }

  updateBreadcrumb() {

    if (this.isDistrictAdmin()) {
      this.breadcrumb = [
        this.breadcrumbsService._CURRENT(this.lang.tra('da_dashboard_district_admin_bc'), this.getDashboardRoute()),
        this.breadcrumbsService._CURRENT(this.lang.tra('sa_session_reports_bc'), this.getViewRoute(BCFSAProgressReportsView.REPORTS_LIST), 'merge')
      ];
    }
    if (this.isSchoolAdmin()) {
      let schoolAdmin = this.lang.tra('sa_grad_dashboard_school_admin');
      if (this.isFSA()) schoolAdmin += this.lang.tra('sa_fsa_bcfsa');
      else schoolAdmin += this.lang.tra('sa_grad_bcgrad');

      this.breadcrumb = [
        this.breadcrumbsService._CURRENT(schoolAdmin, this.getDashboardRoute()),
        this.breadcrumbsService._CURRENT(this.lang.tra('sa_session_reports_bc'), this.getViewRoute(BCFSAProgressReportsView.REPORTS_LIST), 'merge')
      ];
    }
    if (this.isMinistryAdmin()) {
      this.breadcrumb = [
        this.breadcrumbsService._CURRENT(this.lang.tra('Ministry Administration Coordinator'), this.getDashboardRoute()),
        this.breadcrumbsService._CURRENT(this.lang.tra('sa_session_reports_bc'), this.getViewRoute(BCFSAProgressReportsView.REPORTS_LIST), 'merge')
      ];

    }
    if (this.selectedView) {
      const viewCaption = this.selectedAssessmentSession.reports.find(view => view.id === this.selectedView).caption;
      this.breadcrumb.push(
        this.breadcrumbsService._CURRENT(this.lang.tra(viewCaption), this.getViewRoute(this.selectedView), 'merge'),
      )
    }

  }

  getUrl(url: string) {
    return memo(this.sanitizedUrls, url, url => this.sanitizer.bypassSecurityTrustResourceUrl(url));
  }

  onRouteChange(routeParams: any) {

    window.scrollTo(0, 0);
    const targetView: BCFSAProgressReportsView = routeParams['view'];
    if (targetView) {
      this.selectedView = targetView;
    }
    else {
      this.selectedView = null;
    }
  }

  selectView(id: BCFSAProgressReportsView) {
    this.router.navigate([this.getViewRoute(id)], {
      queryParams: this.headerLayout.getQueryParams(),
    });
  }

  onAssessmentSessionChange(e: Event) {
    (e.target as HTMLElement).blur();
  }

  pageUnavailable() {
    this.loginGuard.quickPopup(this.lang.tra('ma_am_current_pageUnavailable'))
  }

  selectAdministrationSample(a: 'administration' | 'sample') {
    this.isSelectedAdministrationSession = a === 'administration';
  }

  showAssessmentSessionModal() {
    this.showSelectAssessmentModal = true;
  }

  closeAssessmentSessionModal() {
    this.showSelectAssessmentModal = false;
  }

  selectAssessmentSession() {
    this.closeAssessmentSessionModal();
  }

  isSchoolAdmin() {
    return this.auth.isSchoolAdmin(this.accountType);
  }

  isDistrictAdmin() {
    return this.auth.isDistrictAdmin(this.accountType);
  }

  isMinistryAdmin() {
    return this.auth.isMinistryAdmin(this.accountType);
  }

  onSelectedSchoolChange(school: School) {
    if (school && school.groupId > 0) {
      this.bcAccounts.getSchoolDetail(school.groupId).then(schoolDetail => {
        this.selectedSchool = schoolDetail;
      })
    } else {
      this.selectedSchool = null;
    }

  }

  onSchoolsChange(schools: School[]) {
    this.schools = schools;
  }

  onSelectedTestWindowChange(testWindow: TestWindow) {
    this.selectedTestWindow = testWindow;
  }

  onSelectedDistrictChange(districtDetail: DistrictDetail) {
    this.districtDetail = districtDetail;
  }


  backToOptions() {
    this.router.navigate([`${this.getBaseRoute()}/bc-fsa/${BCFSAProgressReportsView.REPORTS_LIST}`], {
      queryParams: this.headerLayout.getQueryParams(),
    });
  }

  getViewCaption(view: BCFSAProgressReportsView): string {
    let _view = BC_FSA_PROGRESS_REPORTS_VIEWS.get(view);
    if (_view) {
      return this.lang.tra(_view.caption);
    }
    return '';
  }

  getViewDescription(view: BCFSAProgressReportsView): string {
    let _view = BC_FSA_PROGRESS_REPORTS_VIEWS.get(view);
    if (_view) {
      return _view.description;
    }
    return '';
  }
}
