import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { BcAccountsService, FilterCondition, LookupResult, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { AssessmentType, TestWindow, AssessmentComponent } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { GRADE_OPTIONS, IGradeOption } from './data';
import { AuthService } from 'src/app/api/auth.service';
import { ActivatedRoute } from "@angular/router";
import { BcHeaderLayoutComponent } from 'src/app/ui-partial/bc-header-layout/bc-header-layout.component';
import { DataGuardService } from 'src/app/core/data-guard.service';
import { cloneDeep } from 'lodash';
import { RoutesService } from 'src/app/api/routes.service';

interface EnrollRow {
  pen: string,
  last_name: string,
  first_name: string,
  successMessages?: string[],
  failureMessages?: string[],
  failed?: boolean,
  cleared?: boolean,
  errors: {
    pen?: string,
    warning?: boolean, //when warning is true, the color of the text is blue instead of red.
  },

  // FSA-only
  grade?: IGradeOption,

  // GRAD-only
  assessments?: AssessmentComponent[],
  selection?: Record<string, boolean>,
  uid?: number,

  [key: string]: any,
}

enum Mode {
  EDIT,
  CONFIRMATION,
}

enum DisplayMode {
  ENROLLED = 'enrolled',
  ENROLL = 'enroll',
}

interface IAlertMessageProps {
  NUM_ENROLLED: number,
  NUM_FAILED: number,
  NUM_CLEARED: number,
}

const LONG_NAME_LENGTH = 20;

@Component({
  selector: 'bc-accounts-students-enroll',
  templateUrl: './bc-accounts-students-enroll.component.html',
  styleUrls: ['./bc-accounts-students-enroll.component.scss']
})
export class BcAccountsStudentsEnrollComponent implements OnInit, OnChanges {

  @Input() schoolDetail: SchoolDetail;
  @Input() testWindow: TestWindow;
  @Input() isFSA: boolean = true;
  @Input() isCancelButtonVisible: boolean = true;
  @Input() isBackButtonVisisble: boolean = true;
  @Input() isSubHeaderVisible: boolean = true;
  @Input() readOnly: boolean = false;

  initialNumRows = 5;
  rows: EnrollRow[] = [];

  numRows: number;

  grades = GRADE_OPTIONS.slice(0, 2);

  DisplayMode = DisplayMode;

  ShowStudentsAddedModal: boolean = false;
  ShowNoMoreRowsModal: boolean = false;

  Mode = Mode;
  mode = Mode.EDIT;
  hasFailedRows: boolean = false;

  isLoading: boolean = false;

  numProcessed: number = 0;
  totalToProcess: number = 0;
  showProcessingPensModal: boolean = false;

  shouldShowAlertMessage: boolean = false;
  alertMessageProps: IAlertMessageProps;
  alertMessageSlug: string;

  assessments: AssessmentComponent[];

  shouldOpenNMF_NME_ConflictModal: boolean = false;
  nmf_nme_conflict_component: AssessmentComponent;
  nmf_nme_conflict_other_component: AssessmentComponent;
  nme_nmf_conflict_props: Record<string, string>;

  shouldShowMovingStudentMessage: boolean = false;
  moveStudentMessageProps: Record<string, string>;

  shouldShowInvalidPenMessage: boolean = false;

  isFrSchool = false;

  constructor(
    private lang: LangService,
    private bcAccounts: BcAccountsService,
    private router: Router,
    private loginGuard: LoginGuardService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private dataGuard: DataGuardService,
    private routes: RoutesService,
  ) { }

  ngOnInit(): void {
    this.numRows = this.initialNumRows;
    this.initializeRows();
  }

  async ngOnChanges(changes: SimpleChanges) {
    
    if (changes.testWindow || changes.schoolDetail) {
      for (let i = 0; i < this.rows.length; i++) {
        if (!(this.rows[i].pen == '' || this.rows[i].pen == null)) {
          this.onPenChange(this.rows[i]);
        }
      }
    }

    if (changes.schoolDetail) {
      if (this.schoolDetail){
        await this.checkIfFrancophoneSchool();
        this.fillAssessments();
      }
    }

    if (changes.testWindow) {
      this.fillAssessments();
    }
  }

  ngOnDestroy() {
    this.dataGuard.deactivate();
  }

  backToStudents() 
  {
    this.dataGuard.deactivate();
    if (this.isFSA) {
      this.router.navigate([this.getBaseRoute(), 'students', DisplayMode.ENROLLED], {
        relativeTo: this.route,
      });
    } else 
    {
      if (this.auth.isSchoolAdmin())
      {
        this.backToOptions();
      }
      
      else
      {
        // ministry admin
        if (window)
        {
          window.location.reload();
        }
      }
      
    }
  }

  addMoreStudentsFromAdded() {
    this.dataGuard.deactivate();
    this.resetRows();
    this.hideAlertMessage();
    this.ShowStudentsAddedModal = false;
  }

  addMoreStudentsFromNoRow() {
    this.hideAlertMessage();
    this.ShowNoMoreRowsModal = false;
  }
  showStudenstsAddedModel() {
    this.ShowStudentsAddedModal = true;
  }

  getBaseRoute() {
    const type = this.route.snapshot.data['accountType'];
    const schoolType = this.route.snapshot.data['schoolType']
    const schoolTypeURL = schoolType === 'BC_FSA' ? 'bc-fsa' : 'bc-grad';
    if (this.auth.isSchoolAdmin(type)) {
      return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}/${schoolTypeURL}`;
    } else if (this.auth.isDistrictAdmin(type)) {
      return `/${this.lang.c()}/${AccountType.DIST_ADMIN}/${schoolTypeURL}`;
    } else {
      return `/${this.lang.c()}/${AccountType.MINISTRY_ADMIN}/${schoolTypeURL}`;
    }
  }

  getStudentRoute() {
    if (this.isFSA) {
      return `${this.getBaseRoute()}/students`;
    } else {
      return `${this.getBaseRoute()}/bc_grad_students`;
    }
  }

  private async checkIfFrancophoneSchool() {
    const sch = await this.bcAccounts.getSchoolDetail(this.schoolDetail.groupId);
    this.isFrSchool = sch.lang === 'FR';
  }

  private fillAssessments() {
    if (!this.isFSA && this.schoolDetail && this.testWindow && this.testWindow.assessments) {
      this.assessments = this.testWindow.assessments;

      if (this.isFrSchool) {
        this.assessments = this.assessments.filter(assessment => !assessment.req_sd_lang_not);
      }

      for (let assessment of this.assessments) {
        assessment.selectAll = false;
        if (assessment.name.length > LONG_NAME_LENGTH) {
          assessment.longName = true;
        } else {
          assessment.longName = false;
        }
      }
      for (let row of this.rows) {
        // if (!this.isRowEmpty(row)) {
        row.assessments = cloneDeep(this.assessments);
        // }
      }
    }
  }

  private makeDefaultRow(): EnrollRow {
    if (this.isFSA) {
      return {
        pen: '',
        last_name: '',
        first_name: '',
        grade: null,
        errors: {},
        successMessages: [],
        failureMessages: [],
      };
    } else {
      let row = {
        pen: '',
        last_name: '',
        first_name: '',
        errors: {},
        assessments: [],
        selection: {},
        successMessages: [],
        failureMessages: [],
      };
      if (this.assessments) {
        row.assessments = cloneDeep(this.assessments);
      }
      return row;
    }

  }

  async onPenChange(row: EnrollRow) {
    this.dataGuard.activate();
    delete row.errors.pen;
    row.cleared = undefined;

    // check if the PEN is numerical
    if (row.pen !== "" && !(/^\d+$/.test(row.pen))) {
      row.errors.pen = this.lang.tra('sa_pen_digit_only_error');
      return;
    }

    // check if the PEN is 9 characters long
    if (row.pen !== "" && row.pen.length !== 9) {
      row.errors.pen = this.lang.tra('sa_pen_too_few_digits_error');
      return;
    }

    else if (!row.pen || row.pen == "" || row.pen === "") {
      delete row.errors.pen;
      return;
    }

    if (!this.bcAccounts.validatePen(row.pen)) {
      if (this.isFSA) {
        row.errors.pen = this.lang.tra('sa_pen_digit_check_error');
      } else {
        row.errors.pen = this.lang.tra('sa_pen_invalid_error');
      }
    }

    const nonEmptyRows = this.rows.filter(row => !this.isRowEmpty(row));
    const hasInvalidPenInRows = nonEmptyRows.some(row => !this.bcAccounts.validatePen(row.pen));

    if (hasInvalidPenInRows) {
      this.shouldShowInvalidPenMessage = true;
    }

    else {
      this.shouldShowInvalidPenMessage = false;
    }

    // FSA
    if (this.isFSA) {
      let school;

      try {
        const { data } = await this.auth.apiFind(this.routes.BCED_FSA_STUDENTS, {
          query: {
            pen: row.pen,
            testWindowId: this.testWindow.id,
          }
        });

        school = data.school;
      } catch (error) {
        // we can safely ignore student_not_found failed API call since it's just PEN look up
        if (error.message !== 'student_not_found') {
          throw error;
        }
      }
      
      if (school?.groupId === this.schoolDetail.groupId) {
        row.errors.pen = this.lang.tra('sa_pen_exists_in_this_school_error');
      } 

      if (school && school.groupId !== this.schoolDetail.groupId) {
        row.errors.pen = this.lang.tra('sa_pen_exists_in_another_school_error', undefined, {
          schoolName: school.name
        });
        row.errors.warning = true;
      }
    }

    // GRAD
    else {
      // lookup the pen
      const { uid, school_group_id } = await this.auth.apiFind(this.routes.SCHOOL_ADMIN_GRAD_REGISTRATION, {
        query: {
          pen: row.pen,
        },
      });
      // const { result, uid, school_group_id } = await this.bcAccounts.lookup2Find(
      //   row.pen,
      //   this.testWindow.id,
      // );

      // student exists
      if (uid && school_group_id) {
        const { assessments } = await this.auth.apiGet(this.routes.SCHOOL_ADMIN_GRAD_REGISTRATION, uid, {
          query: {
            test_window_id: this.testWindow.id,
          }
        });
        const registered_at_other_schools = assessments.filter(a => a.school_group_id != this.schoolDetail.groupId);
        if (registered_at_other_schools.length > 0) {
          const traProps = { 
            assessments: registered_at_other_schools.map(a => this.getNameFromSlug(a.test_session_slug)).join(', ')
          };
          row.errors.pen = this.lang.tra('sa_pen_registered_assessments_in_other_school_error', undefined, traProps);
        }
        row.is_moving = true;
        row.uid = uid;
      } else {
        row.uid = undefined;
      }

      // if (result == LookupResult.EXISTS_IN_ANOTHER_SCHOOL) {
      //   const schoolDetail = await this.bcAccounts.getSchoolDetail(school_group_id);
      //   row.errors.pen = `This PEN is currently registered to ${components.map(c => c.code).join(', ')} in ${schoolDetail.name}.`;
      //   row.is_moving = true;
      // }

      // else if (result == LookupResult.EXISTS_IN_THIS_SCHOOL) {
      //   const components = await this.bcAccounts.lookup2Get(uid, school_group_id, this.testWindow);
      //   let overlap = await this.checkOverlap(uid, school_group_id, row.selection, components);
      //   if (overlap.length > 0) {
      //     row.errors.pen = `This PEN is already registered to ${overlap.map(a => a.code).join(', ')}.`;
      //   }

      //   const option1 = 'NME10';
      //   const option2 = 'NMF10';
      //   let selected: string;
      //   let already: string;

      //   if (components.find(c => c.code === option1) && row.selection[option2]) {
      //     already = option1;
      //     selected = option2;
      //   }

      //   if (components.find(c => c.code === option2) && row.selection[option1]) {
      //     already = option2;
      //     selected = option1;
      //   }

      //   if (already && selected) {
      //     row.selection[selected] = false;
      //     this.loginGuard.quickPopup(`You selected ${selected} for this student. However, this student is already registered to ${already} and so cannot be registered to ${selected} at the same time.\n\n${selected} has been unselected.`);
      //   }

      //   row.uid = uid;
      //   row.is_moving = false;
      // }

      // else if (result == LookupResult.NOT_EXISTS) {
      //   row.is_moving = false;
      // }

      if (this.rows.find(row => row.is_moving)) {
        this.moveStudentMessageProps =
        {
          SCHOOL_NAME: this.schoolDetail.name,
        };
        this.shouldShowMovingStudentMessage = true;
      }

      else {
        this.moveStudentMessageProps = null;
        this.shouldShowMovingStudentMessage = false;
      }
    }

    return;
  }

  private getNameFromSlug(slug: string): string {
    return slug.substring('GRAD_'.length);
  }

  private async checkOverlap(uid: number, school_group_id: number, selection: Record<string, boolean>, components?: AssessmentComponent[]): Promise<AssessmentComponent[]> {
    let _components: AssessmentComponent[] = components;
    if (!_components) {
      _components = await this.bcAccounts.lookup2Get(uid, school_group_id, this.testWindow);
    }
    let overlap: AssessmentComponent[] = [];
    for (let component of _components) {
      if (selection[component.code]) {
        overlap.push(component);
      }
    }
    return overlap;
  }

  clearRow(row: EnrollRow) {
    if (!this.isRowEmpty(row)) {
      row.cleared = true;
    } else {
      row.cleared = undefined;
    }
    row.pen = "";
    row.last_name = "";
    row.first_name = "";
    row.grade = null;
    row.errors.pen = "";
    row.successMessages = [];
    row.failureMessages = [];
    row.assessments = cloneDeep(this.assessments);
    row.selection = {};
    row.uid = undefined;
    this.mode = Mode.EDIT;

  }

  resetRows() {
    this.initializeRows();
    this.hasFailedRows = false;
    this.mode = Mode.EDIT;
    this.isLoading = false;
  }

  private validateRow(row: EnrollRow): boolean {
    if (row.errors.pen == this.lang.tra('sa_pen_digit_only_error') || row.errors.pen == this.lang.tra('sa_pen_too_few_digits_error')) {
      // this.hasFailedRows = true;
      // row.failed = true
      // this.mode = Mode.CONFIRMATION;
      // this.isLoading = false;
      // this.showProcessingPensModal = false;
      // row.confirmation = row.errors.pen;
      return false;
    }

    if (row.pen || row.last_name || row.first_name || (row.grade && ['4', '7'].includes(row.grade.grade))) {
      return true;
    }

    return false;
  }

  moreRowsClicked() {
    for (let i = 0; i < 10; i++) {
      this.rows.push(this.makeDefaultRow());
      this.numRows++;
    }
  }

  private getNumNonEmptyRows(): number {
    let nonEmptyRows = 0;
    for (let i = 0; i < this.rows.length; i++) {
      if (!this.isRowEmpty(this.rows[i])) {
        nonEmptyRows += 1;
      }
    }
    return nonEmptyRows;
  }

  private isRowEmpty(row: EnrollRow): boolean {
    return !(row.pen || row.last_name || row.first_name || (row.grade && ['4', '7'].includes(row.grade.grade)));
  }

  onRowInput(row: EnrollRow) {
    row.cleared = undefined;
    this.dataGuard.activate();
  }

  async saveClicked() {
    if (this.readOnly) return;
    
    // if all rows are empty, you just want to return right away without doing anything and pop up an error modal
    for (let i = 0; i < this.rows.length; i++) {

      if (this.rows[i].pen || this.rows[i].last_name || this.rows[i].first_name || (this.isFSA && this.rows[i].grade && ['4', '7'].includes(this.rows[i].grade.grade))) {
        break;
      }

      if (i === this.rows.length - 1) {
        this.ShowNoMoreRowsModal = true;
        return;
      }
    }

    let nonEmptyRows = this.rows.filter(row => !this.isRowEmpty(row) || row.cleared);

    // check duplicate PENs
    let hasDuplicatePens = false;
    let penSet = new Set<string>();
    for (let row of nonEmptyRows) {
      if (row.pen) {
        if (penSet.has(row.pen)) {
          hasDuplicatePens = true;
          break;
        } else {
          penSet.add(row.pen);
        }
      }
    }

    if (hasDuplicatePens) {
      this.loginGuard.quickPopup("sa_enroll_duplicate_pen");
      return;
    }

    const clearRowMessages = (row: EnrollRow) => {
      row.failureMessages = [];
      row.successMessages = [];
    }

    const handleError = (row: EnrollRow, err: any) => {
      if (err.code == 400 && err.message) {
        if(row.failureMessages.findIndex(m => m == this.lang.tra(err.message)) == -1) row.failureMessages.push(this.lang.tra(err.message));
      } else {
        if(row.failureMessages.findIndex(m => m == this.lang.tra('ma_grad_reg_unknown_error')) == -1) row.failureMessages.push(this.lang.tra('ma_grad_reg_unknown_error'));
      }
      numFailed++;
    }

    this.isLoading = true;

    this.numProcessed = 0;
    this.totalToProcess = nonEmptyRows.length;
    this.showProcessingPensModal = true;

    let numEnrolled = 0;
    let numFailed = 0;

    for (let row of nonEmptyRows) {
      if (row.cleared) {
        continue;
      }
      
      clearRowMessages(row);

      try {
        let uid: number;

        if (!this.bcAccounts.validatePen(row.pen)) throw {
          code: 400,
          message: 'grad-enroll-invalid-pen-err-msg'
        };

        if (this.isFSA) {
          const res = await this.bcAccounts.enrollStudentToSchool(
            row.pen,
            this.testWindow.id,
            this.schoolDetail.groupId,
            row.first_name,
            row.last_name,
            this.getGradeFromOption(row.grade),
            this.isFSA ? AssessmentType.FSA : AssessmentType.GRAD,
          );
          uid = res.uid;

          row.successMessages.push(res.message);

          await this.bcAccounts.registerStudentToFsaAssessmentComponents(
            uid,
            this.schoolDetail.groupId,
            this.testWindow,
          );
        }

        if (!this.isFSA) {
          const assessments: AssessmentComponent[] = [];
          for (let assessment of row.assessments) {
            if (row.selection[assessment.code]) {
              assessments.push(assessment);
            }
          }

          if (assessments.length === 0)
          {
            const err =
            {
              code: 400,
              message: "grad_enrollment_no_assessment_selected_err" 
            };

            throw err;
          }

          else 
          {
            if (row.uid == null) {
              const { user } = await this.bcAccounts.enrollStudentToGradSchool(
                row.pen,
                this.testWindow.id,
                this.schoolDetail.groupId,
                row.first_name,
                row.last_name,
              );
              uid = user.id;
            } else {
              uid = row.uid;
            }
  
            for (const assessment of assessments) {
              await this.bcAccounts.registerStudentToGradAssessmentComponents(
                uid,
                this.testWindow.id,
                this.schoolDetail.groupId,
                'GRAD_' + assessment.code,
              )
              .then(res => {
                let message = res.message;
                let propStarti = message.indexOf("[");
                if(propStarti > -1){
                  let slug = message.slice(0, propStarti);
                  let propCounter = 0;
                  let propValue = "";
                  let props = {};
                  for(let i = (propStarti + 1); i < message.length; i++){
                    let char = message.charAt(i);
                    if(char == ','){
                      propCounter++;
                      props['prop0'+(propCounter)] = propValue;
                      propValue = "";
                    }else if(char == ']') props['prop0'+(++propCounter)] = propValue;
                    else propValue += char;
                  }
                  message = this.lang.tra(slug, null, props);
                }else message = this.lang.tra(message);
  
                row.successMessages.push(message)
              })
              .catch(err => handleError(row, err));
            }
          }          
        }

        numEnrolled++;
      } catch (err) {
        handleError(row, err);
      }

      this.numProcessed++;

      // await this.bcAccounts.enrollStudentToSchool(
      //   row.pen,
      //   this.testWindow.id,
      //   this.schoolDetail.groupId,
      //   row.first_name,
      //   row.last_name,
      //   this.getGradeFromOption(row.grade),
      //   this.isFSA ? AssessmentType.FSA : AssessmentType.GRAD,
      // ).then(({ uid, message }) => {
      //   if (this.isFSA) {
      //     return this.bcAccounts.registerStudentToFsaAssessmentComponents(
      //       uid,
      //       this.schoolDetail.groupId,
      //       this.testWindow
      //     ).then(() => {
      //       row.confirmation = message;
      //       row.failed = false;
      //       numEnrolled++;
      //     }).catch(err => {
      //       handleError(row, err);
      //       numFailed++;
      //     });
      //   } else {

      //     return this.bcAccounts.registerStudentToGradAssessmentComponents(
      //       uid,
      //       this.schoolDetail.groupId,
      //       this.testWindow,
      //       assessments,
      //     ).then(() => {
      //       row.confirmation = message;
      //       row.failed = false;
      //       numEnrolled++;
      //     }).catch(err => {
      //       handleError(row, err);
      //       numFailed++;
      //     });
      //   }

      // }).catch(err => {
      //   handleError(row, err);
      //   numFailed++;
      // })

    }

    this.hasFailedRows = numFailed > 0;

    // if (!this.hasFailedRows) {
    //   this.showStudenstsAddedModel(); //if we processed anything valid show the added students modal
    // }

    if (this.hasFailedRows) {
      this.showAlertMessage(numEnrolled, numFailed, nonEmptyRows.length - numEnrolled - numFailed);
    }

    setTimeout(() => {
      this.mode = Mode.CONFIRMATION;
      this.isLoading = false;
      this.showProcessingPensModal = false;

      if (!this.hasFailedRows) {
        this.dataGuard.deactivate();
      }
    }, 1000);


    if (this.numProcessed == 0) {
      //if nothing was processed then show the no more rows modal
      // this.ShowNoMoreRowsModal = true;
    }

    if (this.shouldShowMovingStudentMessage) {
      this.shouldShowMovingStudentMessage = false;
    }
  }

  apiSlugTranslator(message){
    let res = "";
    let propStarti = message.indexOf("[");
    console.log("message: ", message);
    if(propStarti > -1){
      let slug = message.slice(0, propStarti);
      let propCounter = 0;
      let propValue = "";
      let props = {};
      for(let i = (propStarti + 1); i < message.length; i++){
        let char = message.charAt(i);
        if(char == ','){
          propCounter++;
          console.log("propval: ", propValue)
          props['prop0'+(propCounter)] = propValue;
          propValue = "";
        }else if(char == ']') props['prop0'+(++propCounter)] = propValue;
        else propValue += char;
      }
      console.log("props: ", this.lang.tra(slug, null, props))
      res = this.lang.tra(slug, null, props);
    }else res = this.lang.tra(message);
    return res;
  }
  

  fixClicked() {
    this.rows = this.rows.filter(r => r.failureMessages.length > 0);

    this.hideAlertMessage();

    this.mode = Mode.EDIT;
    this.isLoading = false;
  }

  okClicked() {
    this.dataGuard.deactivate();
    this.showStudenstsAddedModel();
  }

  private initializeRows() {
    this.rows = [];
    this.numRows = this.initialNumRows;
    for (let i = 0; i < this.numRows; i++) {
      this.rows.push(this.makeDefaultRow());
    }
  }

  private getGradeFromOption(option: IGradeOption): number {
    if (!option) return null;
    if (option.grade == '4') return 4;
    if (option.grade == '7') return 7;
    else throw new Error("4 & 7 cannot be parsed into a single grade.");
  }

  backToOptions() {
    let queryParams = this.route.snapshot.queryParams;

    this.router.navigate([this.getStudentRoute()], {
      relativeTo: this.route,
      queryParams,
    });
  }

  cancelClicked() {
    this.dataGuard.deactivate();
    let queryParams = this.route.snapshot.queryParams;
    this.router.navigate([this.getStudentRoute()], {
      relativeTo: this.route,
      queryParams
    });
  }

  showAlertMessage(enrolled: number, failed: number, cleared: number): void {
    if (enrolled === 0) {
      this.alertMessageSlug = 'sa_enroll_alert_no_success';
    } else {
      this.alertMessageSlug = 'sa_enroll_alert';
    }

    this.alertMessageProps = {
      NUM_ENROLLED: enrolled,
      NUM_FAILED: failed,
      NUM_CLEARED: cleared,
    }
    this.shouldShowAlertMessage = true;
  }

  hideAlertMessage(): void {
    this.alertMessageProps = {
      NUM_ENROLLED: 0,
      NUM_FAILED: 0,
      NUM_CLEARED: 0,
    }
    this.shouldShowAlertMessage = false;
  }

  formatAssessment(assessment: AssessmentComponent): string {
    return `${assessment.code} - ${assessment.name}`;
  }

  onAssessmentSelectionChange(row: EnrollRow, code: string) {

    if (!row.assessments || !row.selection) return;

    const selected = row.selection[code] === true;

    if (code == 'NME10') {
      if (selected) {
        row.assessments.find(a => a.code === 'NMF10').disabled = true;
      } else {
        row.assessments.find(a => a.code === 'NMF10').disabled = false;
      }
    }
    else if (code == 'NMF10') {
      if (selected) {
        row.assessments.find(a => a.code === 'NME10').disabled = true;
      } else {
        row.assessments.find(a => a.code === 'NME10').disabled = false;
      }
    }

    this.onPenChange(row);
  }

  closeNMF_NME_ConflictModal() {
    this.shouldOpenNMF_NME_ConflictModal = false;
  }

  confirmSelectAll(assessment: AssessmentComponent, unselect?) {
    if (assessment.code == 'NMF10' || assessment.code == 'NME10') {

      let other = assessment.code == 'NMF10' ? 'NME10' : 'NMF10';
      for (let row of this.rows) {
        if (!this.isRowEmpty(row)) {
          if (row.components.find(c => c.code === other)) {
            continue;
          } else {
            const a = row.assessments.find(a => a.code === assessment.code);
            if (!a || !a.disabled) {
              if(unselect) row.selection[assessment.code] = false;
              else row.selection[assessment.code] = true;
              this.onAssessmentSelectionChange(row, assessment.code);
            }
          }
        }
      }

      this.closeNMF_NME_ConflictModal();
    } else {
      for (let row of this.rows) {
        if (!this.isRowEmpty(row)) {
          const a = row.assessments.find(a => a.code === assessment.code);
          if (!a || !a.disabled) {
            if(unselect) row.selection[assessment.code] = false;
            else row.selection[assessment.code] = true;
            this.onAssessmentSelectionChange(row, assessment.code);
          }
        }
      }
    }


  }

  onSelectAllToggle(assessment: AssessmentComponent) {
    if(!assessment.selectAll) this.unSelectAll(assessment)
    else this.selectAll(assessment);   
  }

  unSelectAll(assessment: AssessmentComponent){
    this.confirmSelectAll(assessment, true);
  }

  async selectAll(assessment: AssessmentComponent) {

    if (assessment.code == 'NMF10' || assessment.code == 'NME10') {
      let promises: Promise<void>[] = [];
      for (let row of this.rows) {
        if (!this.isRowEmpty(row)) {
          if (row.uid) {
            promises.push(this.bcAccounts.lookup2Get(row.uid, this.schoolDetail.groupId, this.testWindow).then(components => {
              row.components = components;
            }));
          } else {
            row.components = [];
          }

        }
      }
      await Promise.all(promises);


      let other = assessment.code == 'NMF10' ? 'NME10' : 'NMF10';
      let hasConflict = false;
      for (let row of this.rows) {
        if (!this.isRowEmpty(row)) {
          if (row.components.find(c => c.code === other)) {
            hasConflict = true;
            break;
          }
        }
      }

      if (hasConflict) {
        this.nmf_nme_conflict_component = this.assessments.find(a => a.code === assessment.code);
        this.nmf_nme_conflict_other_component = this.assessments.find(a => a.code === other);
        this.nme_nmf_conflict_props = {
          COMPONENT: this.nmf_nme_conflict_component.name,
          COMPONENT_LANG: this.nmf_nme_conflict_component.code == 'NMF10' ? 'French' : 'English',
          OTHER_COMPONENT: this.nmf_nme_conflict_other_component.name,
          OTHER_COMPONENT_LANG: this.nmf_nme_conflict_other_component.code == 'NME10' ? 'English' : 'French',
        };
        this.shouldOpenNMF_NME_ConflictModal = true;
      } else {
        this.confirmSelectAll(assessment);
      }

    } else {
      this.confirmSelectAll(assessment);
    }
  }
}
