import { Injectable } from '@angular/core';
import { IUserInfo, AuthService } from '../api/auth.service';
import { BehaviorSubject } from 'rxjs';
import { RoutesService } from '../api/routes.service';
import { ClassFilterId } from '../ui-schooladmin/my-school.service';
import { IFilterToggle } from '../ui-partial/filter-toggles/filter-toggles.component';
import { G9DemoDataService } from '../ui-schooladmin/g9-demo-data.service';
import { AccountType } from "../constants/account-types";

const SCHOOL_DIST_ADMIN_ROLE = 'schl_dist_admin';

type GroupRolesList = Array<{
  role_type: string,
  group_id: number,
}>;

@Injectable({
  providedIn: 'root'
})
export class MyBoardService {

  // services + init
  constructor(
    private auth: AuthService,
    private routes: RoutesService,
    private g9DemoData: G9DemoDataService, // temp
  ) {
    this.auth.user().subscribe(v => {
      this._userInfo = v;
      if (v !== null) {
        // temp change to avoid API error
        if ([AccountType.BC_GRAD_MINISTRY_ADMIN, AccountType.BC_FSA_MINISTRY_ADMIN].includes(this._userInfo.accountType)) {
          return;
        }
        this.loadMyRoles();
      }
    });
  }

  // vars (these should never be public)
  private _userInfo: IUserInfo;
  private _roles: GroupRolesList;
  private info: BehaviorSubject<GroupRolesList> = new BehaviorSubject(null);
  fail: BehaviorSubject<boolean> = new BehaviorSubject(null);

  private currentSchoolGroupId: number;

  // public methods
  public sub() {
    return this.info;
  }

  private hasDistAdminRole(roles: GroupRolesList) {
    return roles.find(role => [SCHOOL_DIST_ADMIN_ROLE, 'bc_fsa_admin'].includes(role.role_type));
  }

  private loadMyRoles() {
    return this.auth
      .apiFind(this.routes.DIST_ADMIN_ROLES)
      .then((roles: GroupRolesList) => {
        this._roles = roles;
        if (roles.length > 0 && this.hasDistAdminRole(roles)) {
          const activeRole = roles[0]; // arbitrary... assumption is that there is not more than one district per district admin right now
          this.currentSchoolGroupId = activeRole.group_id;
          return { dist_group_id: this.currentSchoolGroupId };
        }
        else {
          this.fail.next(true);
        }
      })
      .then(districtsData => {
        if (districtsData) {
          this.info.next(this._roles);
        }
      })
  }

  getCurrentSchoolGroupId() {
    return this.currentSchoolGroupId;
  }

  fetchSecureLink(route: string, schoolDistrictGroupId) {
    return this.auth.apiGet(route, schoolDistrictGroupId, this.configureQueryParams(schoolDistrictGroupId))
  }
  configureQueryParams(schoolDistrictGroupId) {
    //const schoolData: any = this.g9DemoData.schoolData
    if (schoolDistrictGroupId) {
      return {
        query: {
          schl_dist_group_id: schoolDistrictGroupId
        }
      }
    }
    return null;
  }
  loadChecklistStates(schoolDistrictGroupId: number) {
    let checklistPromise;
    if (schoolDistrictGroupId) {
      return this.auth
        .apiGet(
          this.routes.DIST_ADMIN_CHECKLIST,
          schoolDistrictGroupId,
          this.configureQueryParams(schoolDistrictGroupId)
        )
        .then((checklistStates: any) => {
          const state = { };
          checklistStates.forEach(checkItem => {
            state[checkItem.slug] = checkItem.value;
          })
          return state;
        });
    }
  }


  public classFilterToggles: IFilterToggle[] = [
    {
      id: ClassFilterId.G9,
      caption: 'txt_g9_math',
      value: false,
    },
    {
      id: ClassFilterId.OSSLT,
      caption: 'lbl_osslt',
      value: false,
    },
  ]


  getSessions(schoolDistrictGroupId) {
    return this.auth
      .apiFind(this.routes.DIST_ADMIN_SESSIONS, this.configureQueryParams(schoolDistrictGroupId))
  }

  toggleTRChecklist(slug: string, value: boolean, schoolDistrictGroupId: number) {
    return this.auth
      .apiUpdate(
        this.routes.DIST_ADMIN_CHECKLIST,
        schoolDistrictGroupId,
        {
          slug,
          value,
        },
        this.configureQueryParams(schoolDistrictGroupId)
      )
  }


  // to do
  constructPermissionsParams(query?: any) {
    // if (!this._info){ console.warn('myInst constructPermissionsParams early exit'); return; }
    const instit_group_id = 0; // this._info.groupId;
    return {
      query: {
        ...query,
        instit_group_id,
      }
    }
  }



}
