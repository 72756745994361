import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { BcAccountsService } from 'src/app/bc-accounts/bc-accounts.service';
import { AssessmentType, BcAssessmentsService, TestWindow } from '../../bc-assessments/bc-assessments.service';
import { LangService } from '../../core/lang.service';
import { SECURE_LOGIN_SYS_FLAG } from '../../ui-whitelabel-bc-landing/view-secure-login/model';

interface IDocument {
  title: string,
  link: string,
  isDisabled?:boolean,
}

type IDocumentGroup = IDocument[];

@Component({
  selector: 'score-entry-documents',
  templateUrl: './score-entry-documents.component.html',
  styleUrls: ['./score-entry-documents.component.scss']
})
export class ScoreEntryDocumentsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() grade: number;
  @Input() large: boolean = false;
  @Input() testWindow: TestWindow;


  documentGroups: IDocumentGroup[];

  private asmtAvailabilitySubcription: Subscription;

  constructor(
    private lang: LangService,
    private bcAssessments: BcAssessmentsService,
    private auth: AuthService,
    private routes: RoutesService,
    private bcAccounts: BcAccountsService,
  ) { }

  ngOnInit(): void {
    this.getDocumentGroups(this.grade);
  }

  isEnabled(doc:IDocument){
    if (doc.isDisabled){ return false; }
    return this.bcAssessments.isFsaAdminAvailable();
  }

  ngOnDestroy() {
    if (this.asmtAvailabilitySubcription) this.asmtAvailabilitySubcription.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.grade || this.testWindow) {
      this.getDocumentGroups(this.grade);
    }
  }

  private async getDocumentGroups(grade: number) {
    if(!this.testWindow) return;
    this.documentGroups = await this.auth.apiFind(this.routes.BC_ADMIN_ASSESSMENT_MATERIALS, {query: {
      test_window_id: this.testWindow.id, 
      assessment: this.bcAccounts.getIsSACurrentlyOnGrad()? AssessmentType.GRAD : AssessmentType.FSA,
      for_sa: this.auth.isMinistryAdmin() ? 0: 1,
      lang: this.lang.c(),
      sub_slug: 'score_entry',
      grade
    }});
  }

}
