import moment, { Moment } from "moment";
import { FsaDataLoad, PaginatedRows, Pagination } from "../bc-accounts/bc-accounts.service";


export enum ReportType {
    SCHOOL_COMPLETION = 'school completion',
    DISTRICT_COMPLETION = 'district completion',
    LOGIN_BY_STUDENT = 'login by student',
    LOGIN_BY_SCHOOL = 'login by school',
    SCANS_BY_SCHOOL = 'scans by school',
    LOGIN_BY_DISTRICT = 'login by district',
    SCANS_BY_DISTRICT = 'scans by district',
    LOGIN_BY_PROVINCE = 'login by province',
    SCANS_BY_PROVINCE = 'scans by province',
    REGISTRATION_BY_SCHOOL = 'registration by school',
    REGISTRATION_BY_DISTRICT = 'registration by district',
    REGISTRATION_BY_PROVINCE = 'registration by province',
    SUBMISSION_BY_SCHOOL = 'submission by school',
    SUBMISSION_BY_DISTRICT = 'submission by district',
    SUBMISSION_BY_PROVINCE = 'submission by province',
    FILTERED_BY_STUDENT = 'filtered by student',
    REGISTERED_STUDENTS = 'registered students',
    LOGGED_IN_STUDENTS = 'logged in students',
    SUBMITTED_STUDENTS = 'submitted students',
    STAGE_ONE = 'stage one',
    STAGE_TWO = 'stage two',
    STAGE_THREE = 'stage three',
    STAGE_FOUR = 'stage four',
    STUDENT_ASSESSMENT_RESULT = 'student assessment result',
    SCHOOL_LEVEL_DATA_REPORT = 'school_level_data_report',
    INDIVIDUAL_RAW_SCORES = 'individual raw scores',
    INDIVIDUAL_SCALED_SCORES = 'individual scaled scores',
    COGNITIVE_LEVEL_SCORES = 'cognitive level scores',
    STUDENT_SELF_REFLECTION = 'student self reflection',
    ITEM_DATE_REPORT = 'item data report',
    DAILY_SUBMISSION_SUMMARY = 'daily submission summary',
    DISTRICT_ROLL_UP = 'district roll up report',
    ENROLLMENT_EXTRACT_1300_C = 'enrollment extract 1300 c',
    ENROLLMENT_EXTRACT_1300_D = 'enrollment extract 1300 d',
    SCHOOLS_NOT_STARTED = 'schools not started',
    STUDENT_LOGIN = 'student login',
    STUDENT_RESPONSE_TIME = 'student response times',
    DAILY_DISTRICT_SUBMISSION = 'daily district submission',
    CONFIRMATION_CODEs = 'confirmation codes',
    FSA_SAMPLE_ASSESSMENT_LOGINS = 'fsa sample assessment logins',
}

export interface DOARReturnData
{
  rowType?: UIRowTypes,
  calculations?: DOARData,
  calculationsCreatedOn?: string,
  updateInProgress: boolean
};

export enum UIRowTypes
{
  province = "Province",
  school = "School",
  district = "District",
  public = "All Public",
  independent = "All Independent",
};

export interface DOAR_PL
{
    proficiency_level: number | string,
    count: number
}

// for DOARs
export interface UpdateInfo
{
    lastUpdatedOn: moment.Moment,
    updateInProgress: boolean
}

export interface DOARSchoolLevelUpdateInfo extends Partial<UpdateInfo>
{
    level: UIRowTypes,
    schoolOrDistNotSelected?: boolean
}

export interface DOARData
{
  proficiencyLevelValues: any[],
  rawScores: any,
  extraConfi: any,
  pipelineLastUpdatedOn: any, 
  pipelineExclusiveAegrotatRecords: any[],
  pipelineExclusiveDisqualificationRecords: any[],
}

export interface DailySubmissionSummaryRow {
    total_submissions: number,
    total_logins?: number,
    total_enrollment?: number,
    login_not_submitted?: number,
    grade?: number,
}

export interface DailySubmissionSummaryAllDistrictRow extends DailySubmissionSummaryRow {
    district_group_id: number,
    district_name: string,
    district_foreign_id: number,
    district_code?: string,
    grade?: number
}

export interface DailySubmissionSummaryReport {
    districts: DailySubmissionSummaryAllDistrictRow[],
    public: DailySubmissionSummaryRow[],
    independent: DailySubmissionSummaryRow[],
    yukon: DailySubmissionSummaryRow[],
    offshore: DailySubmissionSummaryRow[],
}

export interface DailySubmissionSummaryReportParams {
    start_date: Moment
    end_date: Moment
    test_window_id: number,
}

export interface RollUpReportRow {
    grade: number,
    enrollment: number,
    sr_literacy: number,
    sr_numeracy: number,
    cr_literacy: number,
    cr_numeracy: number
}

export interface DistrictRollUpReportRow extends RollUpReportRow {
    district_group_id: number,
    district_name: string,
    district_foreign_id: number,
    _isInfo?: any,
    _isOdd?: any,
}

export interface DistrictRollUpReport {
    districts: DistrictRollUpReportRow[],
    provincial: { [key: string]: RollUpReportRow },
}

export interface DistrictRollUpReportParams {
    test_window_id: number,
    grades: (4 | 7)[],
    start_date?,
    end_date?
}

export interface EnrollmentExtract1300CReportRow {
    school_type: string,
    facility_type: string,
    english_g4: number,
    english_g7: number,
    french_g4: number,
    french_g7: number,
    total_schools: number,
    participating_schools: number,
}

export interface EnrollmentExtract1300CReportParams {
    load: 'sept' | 'feb',
    test_window_id: number;
}

export interface EnrollmentExtract1300DReportRow {
    district_group_id: number,
    district_foreign_id: number,

    school_group_id: number,
    school_name: string,
    school_foreign_id: number,
    is_participating: boolean,

    english_g4: number,
    english_g7: number,

    french_g4: number,
    french_g7: number,

    total_g4: number,
    total_g7: number,
}

export interface EnrollmentExtract1300DReportParams {
    load: FsaDataLoad,
    district_group_id: number,
    testWindowId?: number,
}

export interface EnrollmentExtract1300DReportExportParams {
    district_group_id: number,
    district_name: string,
    testWindowId?: number,
}


export interface CompletionReportRow {
    grade: number,
    enrollment: number,
    sr_literacy: number,
    sr_numeracy: number,
    cr_literacy: number,
    cr_numeracy: number,
}

export interface SchoolCompletionReportRow extends CompletionReportRow {
    school_group_id: number,
    school_foreign_id: number,
    school_name: string,
    school_type: string,
    lang: string,
    _isInfo?: any,
    _isOdd?: any,
}

export interface SchoolCompletionReport {
    schools: PaginatedRows<SchoolCompletionReportRow>,
    district: { [key: string]: CompletionReportRow }
}

export interface SchoolCompletionReportParams {
    district_group_id: number,
    school_group_id?: number,
    test_window_id: number,
    grades: (4 | 7)[],
    no_independent?: boolean,
}

export interface SchoolCompletionReportExportParams {
    district_group_id: number,
    district_name: string,
    test_window_id: number,
    school_group_id?: number,
    no_independent?: boolean
    grades: string,
    pagination?: Pagination,
    isSchoolAdmin?:boolean
}

export interface SchoolCompletionReport {
    schools: PaginatedRows<SchoolCompletionReportRow>,
    district: { [key: string]: CompletionReportRow }
}

export interface SchoolsNotStartedReportRow {
    school_group_id: number,
    school_foreign_id: number,
    school_name: string,
    school_type: string,
    grade: number,
    enrollment: number,
    sr_literacy: number,
    sr_numeracy: number,
    cr_score_entry: number,
    _isInfo?: any,
    _isOdd?: any,
}

export interface SchoolsNotStartedReportExportParams {
    district_group_id: number,
    district_name: string,
    school_group_id?: number,
    school_name?: string,
    test_window_id: number,
    grades: string,
    pagination?: Pagination,
}

export interface SchoolsNotStartedReportParams {
    grades: (4 | 7)[],
    district_group_id: number,
    school_group_id?: number,
    test_window_id: number,
}


export enum IndividualRawScoresProficiencyLevel {
    PLU = 'PLU',
    EMERGING = 'EM',
    ON_TRACK = 'OT',
    EXTENDING = 'EX',
}

export interface IndividualRawScoresRow {
    last_name: string,
    first_name: string,
    pen: string,
    grade: number,

    li_sr_attempted: number,
    li_sr_score: number,
    li_1_score: number,
    li_2_score: number,
    li_3_score: number,
    li_proficiency_level: IndividualRawScoresProficiencyLevel,

    nu_sr_attempted: number,
    nu_sr_score: number,
    nu_1_score: number,
    nu_2_score: number,
    nu_3_score: number,
    nu_proficiency_level: IndividualRawScoresProficiencyLevel,

    [key: string]: any,
}

export interface IndividualRawScoresReport {
    school_group_id: number,
    school_code: number,
    school_name: string,
    is_paginated: boolean,
    data: PaginatedRows<IndividualRawScoresRow>,
}

export interface IndividualRawScoresReportParams {
    school_group_id: number,
    test_window_id: number,
    school_name: string,
    school_code: number,
}

export interface IndividualScaledScoresReportParams {
    school_group_id: number,
    test_window_id: number,
    school_name: string,
    school_code: number,
}


export interface CognitiveLevelScoresRow {
    uid: number,
    last_name: string,
    first_name: string,
    pen: string,
    grade: number,

    l1_score: number,
    l1_weight: number,
    l2_score: number,
    l2_weight: number
    l3_score: number,
    l3_weight: number
    n1_score: number,
    n1_weight: number
    n2_score: number,
    n2_weight: number,
    n3_score: number,
    n3_weight: number
}

export interface CognitiveLevelScoresReport {
    school_group_id: number,
    school_code: number,
    school_name: string,
    is_paginated: boolean,
    data: PaginatedRows<CognitiveLevelScoresRow>,
}

export interface CognitiveLevelScoresReportParams {
    school_group_id: number,
    test_window_id: number,
    school_name: string,
    school_code: number,
}


export interface StudentSelfReflectionRow {
    uid: number,
    last_name: string,
    first_name: string,
    pen: string,
    grade: number,

    literacy: string,
    numeracy: string,
}

export interface StudentSelfReflectionReport {
    school_group_id: number,
    school_code: number,
    school_name: string,
    is_paginated: boolean,
    data: PaginatedRows<StudentSelfReflectionRow>,
}

export interface StudentSelfReflectionReportParams {
    school_group_id: number,
    test_window_id: number,
    school_name: string,
    school_code: number,
}

export interface StudentLoginReportRow {
    district_group_id: number,
    school_group_id: number,
    school_foreign_id: number,
    school_name: string,

    uid: number,
    first_name: string,
    last_name: string,
    pen: string,
    grade: string,
    test_session_slug: string,

    sr_total: number,
    sr_completed: number,
    is_submitted: number,
    started_on: string,
    completed_on: string

    confirmation_code?: string,
    has_unsubmissions: number,
}

export interface StudentLoginReportParams {
    district_group_id: number,
    school_group_id?: number,
    test_window_id: number
}

export interface StudentLoginReportExportParams {
    test_window_id: number,
    district_group_id?: number,
    school_group_id?: number,
    pagination?: Pagination,
}

export interface StudentResponseTimesReportRow {
    school_name: string,
    school_code: number,
    uid: number,
    first_name: string,
    last_name: string,
    pen: string,
    grade: string,
    test_session_slug: string,
    test_attempt_id: number,
    total_questions: number,
    time_spent: Record<number, number>,
}

export interface StudentResponseTimesReportParams {
    district_group_id: number,
    school_group_id?: number,
    test_window_id: number
}

export interface StudentResponseTimesReportExportParams {
    test_window_id: number,
    district_group_id: number,
    school_group_id?: number,
}

export interface DailySubmissionReportRow {
    enrollment: number,
    expected_tests: number,
    submissions: number,
    logins: number
}

export interface DistrictReportRow extends DailySubmissionReportRow {
    district_group_id: number,
    district_foreign_id: number,
    district_name: string,
    district_code: string,
    grade: number
}

export interface PubIndReportRow extends DailySubmissionReportRow {
    is_independent: number,
    grade?: number,
}

export interface DailyDistrictSubmissionReport {
    districts: DistrictReportRow[],
    public: PubIndReportRow[],
    independent: PubIndReportRow[],
    yukon: PubIndReportRow[],
    offshore: PubIndReportRow[],
}

export interface DailyDistrictSubmissionReportParams {
    test_window_id: number,
    start_date: string,
    end_date: string,
}

export interface DailyDistrictSubmissionReportExportParams {
    test_window_id: number,
    start_date: string,
    end_date: string,
    pagination: Pagination,
}

export interface ConfirmationCodeReportRow {
    district_group_id: number,
    district_foreign_id: number,
    school_group_id: number,
    school_foreign_id: number,
    school_name: string,

    uid: number,
    last_name: string,
    first_name: string,
    pen: string,
    grade: number,
    slug: string,
    confirmation_code: string,
    created_on: string,
    has_unsubmissions: number,

}

export interface ConfirmationCodeReportParams {
    test_window_id: number,
    district_group_id?: number,
    school_group_id?: number,
    pagination?: Pagination,
}

export interface IGradLoginReportRow {
    pen: string,
    first_name: string,
    last_name: string,
    slug: string,
    confirmation_code: string,
    datetime: string,
    date: string,
    time: string,
    districtName: string,
    schoolName: string,
    question_position?: string,
}

export interface IGradLoginReportParams {
    test_window_id: number,
    district_group_id?: number,
    school_group_id?: number,
    order?: number, 
    assessment?: string,
    pagination: Pagination,
    splitByComponent?: 0 | 1
}

export interface ILoginByDistrictCounts {
    login_count: number,
    submission_count: number,
    registration: number,
}

export interface ILoginByDistrictReportRow {
    district_group_id: number,
    district_name: string,
    district_code: number,
    slugs: Record<string, ILoginByDistrictCounts>,
    total?: number,
}

export interface ILoginByDistrictReportParams {
    test_window_id: number,
    start_date: string,
    end_date: string,
}

export interface IScansByDistrictReportRow {
    district_group_id: number,
    district_name: string,
    district_code: number,
    test_session_slug: string,
    resolved_scans: number,
}

export interface IScansByDistrictReportParams {
    test_window_id: number,
    test_session_slug: string,
    start_date: string,
    end_date: string,
}

export interface ILoginBySchoolReportRow {
    school_group_id: number,
    school_name: string,
    school_code: number,
    slugs: Record<string, ILoginByDistrictCounts>,
    total?: number,
}

export interface ILoginBySchoolReportParams {
    test_window_id: number,
    start_date: string,
    end_date: string,
    pagination: Pagination,
    district_group_id?: number,
}

export interface IScansBySchoolReportRow {
    school_group_id: number,
    school_name: string,
    school_code: number,
    test_session_slug: string,
    resolved_scans: number,
}

export interface IScansBySchoolReportParams {
    test_window_id: number,
    test_session_slug: string,
    start_date: string,
    end_date: string,
    district_group_id: number,
}

interface ILoginScanByProvinceReportRawRow {
    test_session_slug: string,
    login_count: number,
    submission_count: number,
    registration: number,
}

interface ILoginScanByProvinceCounts {
    login_count: number,
    submission_count: number,
    registration: number,
}

export interface ILoginScanByProvinceReport {
    slugs: Record<string, ILoginScanByProvinceCounts>,
}

export interface ILoginScanByProvinceReportParams {
    test_window_id: number,
    date_start: string,
    date_end: string,
}

export interface IScansByProvinceReportRow {
    resolved_scans: number,
}

export interface IScansByProvinceReportParams {
    test_window_id: number,
    test_session_slug: string,
    start_date: string,
    end_date: string,
}

export interface IGradSRScoreParams 
{
    testWindowId: number,
    assessment: string, // also known as test_session_slug
    PEN: number,
    reviewRequests?: boolean,
}

export interface gradSRScores
{
    total: number,
    score: number,
    taskCode: string,
}

export interface gradCRScores
{
    total: number,
    score: number | "NR",
    title: string,
    questionNumber?: number, // only needed for part A LTE10 and LTE12
}
export enum HeaderType {
    NO = 0,
    MAIN = 1,
    MINOR = 2,
}
export interface IDirectReportElement {
id: number,
caption: string,
is_header: HeaderType,
item_filter: string, // json 
itemsIncluded: {test_question_id: string, taqr_id: number, score: number, scoreMax: number}[]
order: number,
score: number,
scoreMax: number,
sub_index?: number,
}

export interface IDirectReportFooter {
    text1: string,
    text2: string,
    link: string
}
export interface IGradLTESRScoreRow 
{
    partAScores?: gradSRScores,
    partBScores?: gradSRScores
    directReport?: {
        reportElements:IDirectReportElement[]
    }
}

export interface IGradCRScoreParams 
{
    testWindowId: number,
    assessment: string, // also known as test_session_slug
    PEN: number
}

export interface IUnfilteredGradCRScoreRow
{
    max_mark: number,
    final_mark: number | "NR",
    scale_factor: number,
    item_name: string,
    assessment_section: string,
    task_code: string,
}

export interface IGradLTECRScoreRow
{
    partAScores: gradCRScores[],

    partBScores: gradCRScores[]
}

export interface IGradNMECRScoreRow
{
    total: number,
    score: number | "NR",
    title: string,
    taskCode: string,
    taskCodeTitle: string,
    taskCodeDescription: string,
}
export interface IFsaSampleAssessmentLoginReportRow 
{
    assessment: string,
    language: string,
    logins: Record<string, number>,
    dates: string[],
    order: number,
}

export interface IFsaSampleAssessmentLoginReportParams 
{
    start_date: string,
    end_date: string,
    time_zone: string,
}



export interface IDoARReportParams
{
    testWindowId: number,
    assessment: string, // also known as test_session_slug,
    schoolGroupId?: number,
    schoolDistGroupId?: number,
    isAllPublic?: number,
    isAllIndependent?: number,
    isProvincialView: number,
    dataUpdate?: number
}

export interface DOARTQRMap
{
  assessment: string,
  numStudents: number,
  rows: DOARRow[]
}
export interface DOARRow 
{
    title: string,
    columns: DOARColumn[]
}

export interface LoginBySchool
{
    schoolGroupId: number,
    schoolDistrictGroupId: number,
    count: number
}

export interface LoginByDistrict
{
    schoolDistrictGroupId: number,
    count: number
}

export interface DOARLoginBreakdowns
{
    schoolLevel: LoginBySchool[]
    districtLevel: LoginByDistrict[]
}
interface DOARColumn
{ 
    title: string,
    tqrParams: TQRIterable[], 
    // if more than one tqrParams, all of them have to be matched for a match and multiTQRParam = true
    // else, multiTQRParam = false and there is only 1 tqrParams
    multiTQRParam: boolean,
    score: number,
    total: number,
    percent: null | number
}

enum DOARFilterableTQRParams
{
    taskCode = "task_code",
    claimCode = "claim_code",
    cognitiveLevel = "cognitive_level",
    reportComponent = "report_component",
    conceptsCode = "concepts_code",
    pathId = "path_id",
    part = "part"
}

interface TQRIterable
{
    // if more than one values, any of them can be matched for a match
    // if multiTQRParam = true, values can only have 1 element
    param: DOARFilterableTQRParams,
    values: any[] 
}

export interface DLLoginReportParams
{
    testWindowId: number,
    homeSchoolGroupId: number, 
    pagination: Pagination,
    assessment?: string,
    order?: number
}

export enum ScanStatus {
    MISSING_SCAN = 'missing',
    PROBLEMATIC_SCAN = 'problem',
    OK = 'ok'
}
export interface DLLoginReportRow 
{
    gradLoginReport: 
    {
        uid: number,
        pen: string,
        first_name: string,
        last_name: string,
        slug: string,
        confirmation_code: string,
        datetime: string,
        date: string,
        time: string,
        schoolName: string,
        group_id: number,
        q13p1?: ScanStatus,
        q13p2?: ScanStatus,
        q26p1?: ScanStatus,
        q26p2?: ScanStatus,
        lastUpload?: Moment
    },
    uids: number[]
}

export interface gradAssessmentSummary
{
    order: number,
    assessmentWithComponent: string,
    assessmentWithoutComponent: string,
}