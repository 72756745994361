import { Injectable } from "@angular/core";
import { AuthService } from "../api/auth.service";
import { ASSESSMENT } from 'src/app/ui-teacher/data/types';
import moment, { Moment } from "moment";
import { RoutesService } from "../api/routes.service";
import { AccountType } from "../constants/account-types";
import { LangService } from "../core/lang.service";
import { isEmpty } from "lodash";
import { District, Pagination, School, PaginatedRows, ConfirmationCode, EDFMCFilesReturnType, StaticPDFs, AcceptableDomainsBC, PackingSlipPDF, SchoolSeparatorFormPDF, StudentListPDF, ALL_SCHOOLS, ALL_DISTRICT, BcAccountsService } from "../bc-accounts/bc-accounts.service";
import { ITestAttempt } from "../ui-testadmin/view-invig/view-invig.component";
import { SECURE_LOGIN_SYS_FLAG } from "../ui-whitelabel-bc-landing/view-secure-login/model";
import { BehaviorSubject, Subject } from "rxjs";
import { IndividualRawScoresRow } from "../bc-reports/types";

export enum AcceptablePDFTypes
{
  schoolSeparatorForm = "school_separator",
  packingSlip = "packing_slip",
  studentList = "student_list"
}

export interface CreateNewSessionConfig {
    isScheduled: boolean,
    scheduled_time: string | null,
    school_class_id: number,
    slug: ASSESSMENT,
}

export interface SRDuplicateStudentTestAttempts extends TestAttemptStudentInfo
{
    attempt_count: number,
    invalid_attempts_count: number,
    unique_assessment_subject_count: number,
    are_all_cases_resolved: 0 | 1,
}
export interface DuplicateStudentTestAttempts extends TestAttemptStudentInfo
{
    SR_attempt_count: number,
    CR_attempt_count: number,
    SR_invalid_attempts_count: number,
    SR_unique_assessment_subject_count: number,
    CR_invalid_attempts_count: number,
    CR_unique_assessment_subject_count: number,
    SR_are_all_cases_resolved: 0 | 1,
    CR_are_all_cases_resolved: 0 | 1,
    overall_are_all_cases_resolved: 0 | 1
}

export interface TestAttemptStudentInfo
{
    uid: number
    PEN: number,
    grade: number,
    first_name: string,
    last_name: string,
    enrollment_school: string,
    enrollment_school_code: string,
    enrollment_district: string,
    enrollment_district_code: string
}

interface CRDuplicateStudentTestAttempts extends SRDuplicateStudentTestAttempts
{
  cr_scores: number,
  taqr_count: number,
  twtar_count: number,
  lang_count: number,
  grade_count: number,
}

export interface AssessmentComponent {
    id: number,
    code: string,
    component: string,
    type: string,
    grade: number,
    language: string,
    assessment: string,
    name: string,
    [key: string]: any,
    order: number,
    assessmentNameWithoutComponent?: string
}

export interface TestWindow {
    id: number,
    dateStart: Date,
    dateEnd: Date,
    scoreEntryDateStart: Date,
    scoreEntryDateEnd: Date,
    title?: {
        en?: string,
        fr?: string,
    },
    is_active: boolean,
    is_qa?: boolean | number,
    is_bg?: boolean,
    is_allow_new_bookings?: boolean | number,
    is_closed: boolean,
    is_archived: boolean,
    durationInMin: number,
    numAttemptsAllowed: number,
    allowInvigilatorUnsubmit: boolean,
    allowInvigilatorTakeTest: boolean,
    tabular: TestWindowTabular,
    assessments?: AssessmentComponent[],
    allAssessments?: string[],
    allocRules?: any[],
    window_code?: string,
    isIrtReady: boolean;
    month_title?: {
        en?: string,
        fr?: string,
    },
}

export interface TestSessionInfo {
    subSessions?: {
        studentStates?: Record<number, {
            uid: number,
            question_index: number,
            [key: string]: any,
        }>
    },
    activeSubSessions: any[],
    completedSubSessions: any[],
    [key: string]: any,
}

export interface TestWindowTabularRow {
    assessmentId: number,
    assessmentCode: string,
    title: string,
    subtitle?: string,
    grade: number,
    language: string,
    password: string,
    validOn: Moment,
    testSessionId?: number,
    schoolClassId?: number,
    schoolClassGroupId?: number,
    testSessionInfo?: TestSessionInfo,
}

export type TestWindowTabular = TestWindowTabularRow[];

export interface NewAssessmentConfig {
    name: string,
    startDate: string,
    endDate: string,
    components: string[],
}

export type NewTestWindowConfig = NewAssessmentConfig;

export interface SrResult {
    partA: {
        score: number,
        weight: number,
    },
    partB: {
        score: number,
        weight: number,
    },
}

export enum PROFICIENCY_LEVEL 
{
    EMERGING = 'Emerging',
    DEVELOPING = 'Developing',
    PROFICIENT = 'Proficient',
    EXTENDING = 'Extending',
    ON_TRACK = 'On Track',
}

export interface ProfDistData
{
    proficiencyLevelRows: ProficiencyLevelRow[],
    forms: string[],
    proficiencyLevelFrequencyTables: Record<string, ProficiencyLevelFrequencyTable>,
}

export interface ProficiencyLevelFrequencyTable 
{
    table: ProficiencyLevelFrequencyRow[],
    totalFrequency: number,
    totalPercent: number
}

export interface ProficiencyLevelRow 
{
    form: string,
    levelCount: Record<PROFICIENCY_LEVEL, number>,
}

export interface ProficiencyLevelFrequencyRow 
{
    level: PROFICIENCY_LEVEL,
    frequency: number,
    percent: number,
    cumulative: number,
}

export interface CutScoreRow {
    assessmentCode: string,
    assessmentName: string,
    form: string,
    emergingLow: number,
    developingLow: number,
    proficientLow: number,
    extendingLow: number
}

export interface StudentIrtRow {
    uid: number,
    pen: string,
    slug: string,
    irt_score: number,
}

export interface StudentIrtRow2 {
    uid: number,
    first_name: string,
    last_name: string,
    pen: string,
    assessment_code?: string,
    score?: number,
    lang: string,
    grade: number,
    school_name?: string,
    district_code?: number,
}

export enum AssessmentType {
    FSA = 'fsa',
    GRAD = 'grad',
}

export interface assessmentInfo {
    assessmentType: AssessmentType,
    learningType: LearningType,
    grade: string,
    langSlug: string
}

export enum LearningType {
    LITERACY = 'Literacy',
    NUMERACY = 'Numeracy'
}

export interface IndividualStudentReportRow {
    assessmentYear: number,
    firstName: string,
    lastName: string,
    schoolName: string,
    studentPENNumber: number,
    studentGrade: number,
    literacyLevel: string,
    numeracyLevel: string,
    submissionDateTimeNumSR: Moment,
    submissionDateTimeLitSR: Moment,
    submissionDateTimeCR: Moment,
    assessment: string,
}

export interface ScoreEntryStudentRow {
    uid: number,
    pen: string,
    first_name: string,
    last_name: string,
    grade: number,
    school_group_id: number,
    is_marked_not_yet_scored: boolean,
    test_attempt_id?: number,
    theme: number,
    theme_taqr_id: number,
    LI_1: string,
    LI_1_taqr_id: number,
    LI_2: string,
    LI_2_taqr_id: number,
    LI_3: string,
    LI_3_taqr_id: number,
    NU_1: string,
    NU_1_taqr_id: number,
    NU_2: string,
    NU_2_taqr_id: number,
    NU_3: string,
    NU_3_taqr_id: number,

    _is_saved?: boolean

    [key: string]: any,
}

export type ScoreEntryQuestion = {
    twtarId: number
    questionId: number
    questionOrder: number
    taId: number
    taqrId: number
    response: string
    theme: number
    isSaved: boolean
}

export type ScoreEntryAssessmentData = {
    fstrId: number
    twtarId: number
    isComplete: boolean
    questionOptions: number[]
    themeOptions: number[]
    selectedTheme: string | number
    notYetScored: boolean
    isSaving: boolean
    questions: ScoreEntryQuestion[]
}
  
  export interface ScoreEntryStudentRowV2 {
    uid: number,
    pen: string,
    first_name: string,
    last_name: string,
    grade: number,
    schoolGroupId: number,
    districtGroupId: number,
    literacy: ScoreEntryAssessmentData,
    numeracy: ScoreEntryAssessmentData,
    secondaryStatus: ScoreEntryStatusMessage
  }

export enum ScoreEntryStatusMessage {
    INVALID_ENTRY = 'Error: invalid entry'
}

export interface ScoreEntryTwtarConfig {
    
}

export interface SubmissionProgress {
    uid: number,
    progress: number,
    test_attempt_id: number,
    attempt_key: string,
    created_on: Moment,
    confirmationCode?: ConfirmationCode,
    hasUnsubmissions?: boolean,
}

export const ALL_ASSESSMENTS: AssessmentComponent = {
    id: -1,
    code: '',
    component: '',
    type: '',
    grade: -1,
    language: '',
    assessment: '',
    name: 'All assessments',
    order: 0
};

export interface SpreadSheetScoreEntryRow {
    pen: string,
    school_code: number,
    theme?: string,
    LI_1?: string,
    LI_2?: string,
    LI_3?: string,
    NU_1?: string,
    NU_2?: string,
    NU_3?: string,
    first_name: string,
    last_name: string,
    grade: 4 | 7,
}

export enum ScoreProfileType {
    THEME = 'Theme',
    SCORE = 'LI/NM',
}


export interface IScoreOption {
    slug: string,
    value: number,
    order: number,
}

export interface IScoreProfile {
    type: ScoreProfileType,
    options: IScoreOption[],
}

export type IThemeProfile = IScoreProfile;

export interface MarkNotYetScoredParams {
    uid: number,
    test_window_id: number,
    school_group_id: number,
    taqr_id: number,
}

export type AsmtAvailability = Record<SECURE_LOGIN_SYS_FLAG, boolean>;

export interface IPath {
    name: string,
    num_questions: number,
}

export interface ITestDesign {
    id: number,
    framework: string,
    source_item_set_id: number,
    [key: string]: any,
}

export interface ITestDesignWithAllocRule extends ITestDesign {
    type_slug: string,
    form_code: string,
    test_window_id: number,
}

export interface ITestDesignWithPath extends ITestDesignWithAllocRule {
    paths: IPath[],
}

export interface ITestQuestionRegister {
    id: number
    component: string,
    subcomponent: string,
    question_label: string,
    lang: 'en' | 'fr',
    expected_answer: string,
    question_id: number,
    test_design_id: number,
    is_theme: number,
    is_reflection: number,
    trax_order: number,
    student_question: number,
    item_type: string,
    mark: number,
    cognitive_level: number,
    task_code: string,
    claim_code: string,
    context_code: string,
    concepts_code: string,
    topic_type: string,
    scale_factor: number,
    question_origin: string,
    question_origin_fr: string,
    irt: string,
    assessment_section: string,
    report_component: string,
    curriculum_context: string,
    curriculum_context_fr: string,
    text_type: string,
    text_type_fr: string,
    multiple_choice_question_type: string,
    form_of_response: string,
    question_type: string,
    item_description: string,
    item_description_fr: string,
    a: number,
    b: number,
    common: number,
    cr_item_name: string,
    cr_item_name_fr: string,
    partial_mark: number,
    partial_mark_strategy: number,
    is_partial_mark_configured: number,
    condition_on_item: number,
    condition_on_option: string,
}

export type ComponentFormMapping = Record<string, string[]>;

export type ICutScoreProficiencyLevelMap = Record<string, number>;
export type ICutScoreFormMap = Record<string, ICutScoreProficiencyLevelMap>;
export type ICutScoreMap = Record<string, ICutScoreFormMap>;


@Injectable({
    providedIn: 'root'
})
export class BcAssessmentsService {

    constructor(
        private auth: AuthService,
        private routes: RoutesService,
        private lang: LangService,
        private bcAccounts: BcAccountsService
    ) {
        this._asmtAvailabilitySub = new BehaviorSubject(this.makeConstAsmtAvailabilityObj());
        this.initAvailability()
    }

    private requesterUid = () => this.auth.getUid();
    private wrapRequesterUid = (params?: any) => {
        params = params || {};
        params.query = params.query || {};
        params.query.requester_uid = this.requesterUid();
        return params;
    };
    private makeConstAsmtAvailabilityObj = (): AsmtAvailability => ({
        [SECURE_LOGIN_SYS_FLAG.FSA]: this.asmtAvailability.get(SECURE_LOGIN_SYS_FLAG.FSA) || false,
        [SECURE_LOGIN_SYS_FLAG.GRAD]: this.asmtAvailability.get(SECURE_LOGIN_SYS_FLAG.GRAD) || false,
    });
    private _asmtAvailabilitySub: BehaviorSubject<AsmtAvailability>;

    private asmtAvailability: Map<SECURE_LOGIN_SYS_FLAG, boolean> = new Map();
    async initAvailability() {
        console.log('initAvailability', this.auth.earlyAccessCode)
        const loginSysFlags = [
            SECURE_LOGIN_SYS_FLAG.FSA,
            SECURE_LOGIN_SYS_FLAG.GRAD,
        ];
        this.asmtAvailability.set(SECURE_LOGIN_SYS_FLAG.FSA, true);
        // this.asmtAvailability.set(SECURE_LOGIN_SYS_FLAG.GRAD, false);
        const checkStatus = async (flag: SECURE_LOGIN_SYS_FLAG) => {
            const res = await this.auth.apiGet(this.routes.LANDING_BCED_SECURE_LOGIN, flag, this.wrapRequesterUid({ query: { earlyAccessCode: this.auth.earlyAccessCode } }));
            this.asmtAvailability.set(flag, !!res.isOpen);
        }
        await checkStatus(SECURE_LOGIN_SYS_FLAG.GRAD);
        this._asmtAvailabilitySub.next(this.makeConstAsmtAvailabilityObj());
    }
    isFsaAdminAvailable = () => this.asmtAvailability.get(SECURE_LOGIN_SYS_FLAG.FSA);
    isGradAdminAvailable = () => this.asmtAvailability.get(SECURE_LOGIN_SYS_FLAG.GRAD);

    asmtAvailabilitySub = () => this._asmtAvailabilitySub;


    async getTestWindow(id: number, assessment: AssessmentType, withTestSession: boolean = true, withAssessments: boolean = true, withTabular: boolean = false, splitComponents: boolean = false): Promise<TestWindow | null> {
        const testWindow = await this.auth.apiGet(this.routes.MINISTRY_ADMIN_TEST_WINDOW, id, {
            query: {
                assessment,
                splitComponents: splitComponents ? 1 : 0,
                withTestSession: withTestSession ? 1 : 0,
                withAssessments: withAssessments ? 1 : 0,
                withTabular: withTabular ? 1 : 0,
                requester_uid: this.requesterUid(),
            }
        });
        if (isEmpty(testWindow)) return null;
        return this.makeTestWindowFromData(testWindow);
    }

    async createNewTestWindow(config: NewTestWindowConfig, assessment: AssessmentType): Promise<void> {
        await this.auth.apiCreate(this.routes.MINISTRY_ADMIN_TEST_WINDOW, config, {
            query: {
                assessment,
                requester_uid: this.requesterUid(),
            }
        });
    }

    async modifyTestWindowDates(id: number, newStartDate: string, newEndDate: string): Promise<void> {
        let assessmentConfig: NewTestWindowConfig = this.getInitialNewAssessmentConfig();
        assessmentConfig.startDate = newStartDate;
        assessmentConfig.endDate = newEndDate;

        await this.auth.apiPatch(this.routes.MINISTRY_ADMIN_TEST_WINDOW, id, assessmentConfig, this.wrapRequesterUid());
    }

    private makeTestWindowFromData(data: any): TestWindow {
        const testWindow: TestWindow = {
            id: data.id,
            dateStart: new Date(data.date_start),
            dateEnd: new Date(data.date_end),
            scoreEntryDateStart: data.score_entry_date_start == null? null: new Date(data.score_entry_date_start), //new Date(data.score_entry_date_start),
            scoreEntryDateEnd: data.score_entry_date_end == null? null: new Date(data.score_entry_date_end),//new Date(data.score_entry_date_end),
            title: data.title ? JSON.parse(data.title) : null,
            durationInMin: data.duration_m,
            numAttemptsAllowed: data.is_multi_attempt == 1 ? data.num_attempts || 0 : 1,
            allowInvigilatorUnsubmit: data.is_invig_unsubmit == 1,
            allowInvigilatorTakeTest: data.is_invig_taketest == 1,
            is_active: data.is_active,
            is_qa: data.is_qa === 1,
            is_bg: data.is_bg === 1,
            is_allow_new_bookings: data.is_allow_new_bookings == 1,
            is_closed: data.is_closed === 1,
            is_archived: data.is_archived == 1 ? true : false,
            isIrtReady: data.irt_ready === 1,
            assessments: data.assessments ? data.assessments.map(a => ({
                ...a,
                code: a.assessment_code,
            })) : undefined,
            allAssessments: data.assessments ? data.assessments.map(a => a.assessment_code) : undefined,
            allocRules: data.alloc_rules ? data.alloc_rules : undefined,
            window_code: data.window_code,
            month_title: data.month_title ? JSON.parse(data.month_title) : '',
            tabular: data.tabular ? data.tabular.map((t: any) => {
                const titleComponents = t.name.split(',')
                const row: TestWindowTabularRow = {
                    assessmentId: t.id,
                    assessmentCode: t.assessment_code,
                    title: titleComponents[0],
                    grade: t.grade,
                    subtitle: titleComponents.length > 1 ? titleComponents[1] : undefined,
                    language: t.language,
                    password: t.password,
                    validOn: moment.utc(t.valid_on),
                    testSessionId: t.test_session_id,
                    schoolClassId: t.school_class_id,
                    schoolClassGroupId: t.school_class_group_id,
                    testSessionInfo: t.sessionInfo,
                }
                return row;
            }) : undefined,
        };
        return testWindow;
    }

    async findPastTestWindows(assessment: AssessmentType): Promise<TestWindow[]> {
        const data = <any[]>await this.auth.apiFind(this.routes.MINISTRY_ADMIN_TEST_WINDOW, {
            query: {
                assessment,
                isPast: 1,
                withTestSession: 1,
                requester_uid: this.requesterUid(),
            }
        });
        return data.map(d => this.makeTestWindowFromData(d));
    }

    async findUpcomingTestWindows(assessment: AssessmentType): Promise<TestWindow[]> {
        const data = <any[]>await this.auth.apiFind(this.routes.MINISTRY_ADMIN_TEST_WINDOW, {
            query: {
                assessment,
                isUpcoming: 1,
                withTestSession: 1,
                requester_uid: this.requesterUid(),
            }
        });
        return data.map(d => this.makeTestWindowFromData(d));
    }

    async findActiveTestWindow(accountType: AccountType, assessment: AssessmentType, withTestSession: boolean = true, withTabular: boolean = false, schoolGroupId?: number): Promise<TestWindow | null> {
        accountType === AccountType.MINISTRY_ADMIN ? this.routes.MINISTRY_ADMIN_TEST_WINDOW : this.routes.SCHOOL_ADMIN_TEST_WINDOW;
        let route: string;
        let extraQuery = {};
        if (accountType === AccountType.MINISTRY_ADMIN) {
            route = this.routes.MINISTRY_ADMIN_TEST_WINDOW;
        } else {
            route = this.routes.SCHOOL_ADMIN_TEST_WINDOW;
            extraQuery = {
                schl_group_id: schoolGroupId,
            };
        }

        const data = <any[]>await this.auth.apiFind(route, {
            query: {
                assessment,
                isActive: 1,
                isNotStartedIncluded: 1,
                withTestSession: withTestSession ? 1 : 0,
                withTabular: withTabular ? 1 : 0,
                withTDAllocRules: 1,
                withAssessments: 1,
                requester_uid: this.requesterUid(),
                ...extraQuery,
            }
        });
        if (data.length === 0) return null;
        return this.makeTestWindowFromData(data[data.length - 1]);
    }

    async findTestWindowsInRange(assessment: AssessmentType, range: { startDate: Moment, endDate: Moment }, withTestSession: boolean = true, withTabular: boolean = false): Promise<TestWindow[]> {
        const data = <any[]>await this.auth.apiFind(this.routes.MINISTRY_ADMIN_TEST_WINDOW, {
            query: {
                assessment,
                range: {
                    startDate: range.startDate.utc().toISOString(),
                    endDate: range.endDate.utc().toISOString(),
                },
                withTestSession: withTestSession ? 1 : 0,
                withTabular: withTabular ? 1 : 0,
                requester_uid: this.requesterUid(),
            }
        });
        return data.map(d => this.makeTestWindowFromData(d));
    }

    async findTestWindowsByName(assessment: AssessmentType, name: string): Promise<TestWindow[]> {
        const data = <any[]>await this.auth.apiFind(this.routes.MINISTRY_ADMIN_TEST_WINDOW, {
            query: {
                assessment,
                name,
                withTestSession: 1,
                requester_uid: this.requesterUid(),
            }
        });
        return data.map(d => this.makeTestWindowFromData(d));
    }

    async findAllTestWindows(assessment: AssessmentType, withTestSession: boolean = true, 
    withTabular: boolean = false, withAssessments: boolean = false, splitComponents: boolean = false,
    short: boolean = false, includeQATWs: boolean = false, is_force_show_asmt_materials: boolean = false): Promise<TestWindow[]> 
    {
        const data = <any[]>await this.auth.apiFind(this.routes.MINISTRY_ADMIN_TEST_WINDOW, {
            query: {
                assessment,
                is_force_show_asmt_materials: is_force_show_asmt_materials ? 1 : 0,
                includeQATWs: includeQATWs ? 1 : 0,
                splitComponents: splitComponents ? 1 : 0,
                withTestSession: withTestSession ? 1 : 0,
                withAssessments: withAssessments ? 1 : 0,
                withTabular: withTabular ? 1 : 0,
                requester_uid: this.requesterUid(),
                short
            },
        });
        return data.map(d => this.makeTestWindowFromData(d));
    }

    async findActiveTestWindowSimplified(assessment: AssessmentType, withTestSession: boolean = true, withTabular: boolean = false, short: boolean = false): Promise<TestWindow> {
        const testWindows = await this.findAllTestWindows(assessment, withTestSession, withTabular, false, false, true);
        let activeTestWindow
        if (this.auth.isMinistryAdmin()) {
            if (this.auth.getUid() == 318572) {
                // temp 78 for testing ministry admin
                activeTestWindow = testWindows.find(tw => tw.id == 78);
            } else {
                activeTestWindow = testWindows.find(tw => tw.is_active && !tw.is_qa);
                // activeTestWindow = testWindows.find(tw => tw.is_active);
            }
        }
        else {
            activeTestWindow = testWindows.find(tw => tw.is_active);
        }
        return activeTestWindow;
    }

    async getTestWindowById(id: number, assessment: AssessmentType, withTestSession: boolean = false, withTabular: boolean = false): Promise<TestWindow | null> {
        const data = <any>await this.auth.apiGet(this.routes.STUDENT_TEST_WINDOW, id, {
            query: {
                assessment,
                withTestSession: withTestSession ? 1 : 0,
                withTabular: withTabular ? 1 : 0,
                requester_uid: this.requesterUid(),
            }
        });
        return this.makeTestWindowFromData(data);
    }

    async exportTestWindowSessionPasswords(testWindow: TestWindow, assessmentId: number) {
        let title = '-';
        if (testWindow.title && testWindow.title.en) {
            title += testWindow.title.en;
        }
        window.open(this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_SESSION_PASSWORDS, JSON.stringify({
            query: {
                testWindowId: testWindow.id,
                assessmentId,
            }
        }), `Session-Passwords${title}-${testWindow.tabular.find(r => r.assessmentId === assessmentId).assessmentCode}`.split(' ').join('-')), '_blank');
    }

    async getSessionInfo(sessionId: number, classGroupId: number, classId: number, schoolGroupId?: number): Promise<TestSessionInfo> {
        const sessionInfo = await this.auth
            .apiGet(this.routes.SCHOOL_ADMIN_SESSION, sessionId, {
                query: {
                    school_class_group_id: classGroupId,
                    school_class_id: classId,
                    schl_group_id: schoolGroupId,
                    requester_uid: this.requesterUid(),
                },
            })

        return sessionInfo;
    }

    getInitialNewAssessmentConfig(): NewAssessmentConfig {
        return {
            name: '',
            startDate: this.toDateString(moment().toDate()),
            endDate: this.toDateString(moment().add({ month: 1 }).toDate()),
            components: [],
        }
    }

    private toDateString(date: Date): string {
        return (date.getFullYear().toString() + '-'
            + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
            + ("0" + (date.getDate())).slice(-2))
            + 'T' + date.toTimeString().slice(0, 5);
    }

    getTestWindowTitle(testWindow: TestWindow, isStudent?:boolean): string {
        const fallbackName = 'Loading Assessment Session...';
        let title, studentTitle
        const langCode = this.lang.c();
        try { 
            title = testWindow.title;
            studentTitle = (<any> title || {}).studentTitle;
        }
        catch (e){
            console.log('getTestWindowTitle', 'testWindow malformed')
        }
        
        if ( !testWindow || !title ){
            if (!isStudent){
                return fallbackName
            }
            title = {};
        };
        
        // console.log('getTestWindowTitle', 'testWindow malformed')

        if (isStudent && !studentTitle){ // temporary
            studentTitle = {
                "en": this.lang.tra('student_assessment_welcome_text_title'), //June 2022 Graduation Assessments Administration
                "fr": this.lang.tra('student_assessment_welcome_text_title'), // la séance de juin 2022
            }
            
        }

        if (isStudent && studentTitle){
            switch (langCode){
                case 'en': return studentTitle.en || fallbackName;
                case 'fr': return studentTitle.fr || fallbackName;
            }
        }

        // default to main window name (if not student)
        if (this.lang.c() == 'en') {
            return testWindow.title.en || fallbackName;
        }
        if (this.lang.c() == 'fr') {
            return testWindow.title.fr || testWindow.title.en || fallbackName;
        }
        return fallbackName;
    }

    async getAllAssessmentComponents(assessment: 'grad' | 'fsa'): Promise<AssessmentComponent[]> {
        const components = <any[]>await this.auth.apiFind(this.routes.MINISTRY_ADMIN_ASSESSMENT_COMPONENTS, {
            query: {
                assessment,
                requester_uid: this.requesterUid(),
            }
        });
        return components.map(c => ({
            ...c,
            code: c.assessment_code,
        }))
    }

    async getSrResultForTestAttempt(testAttemptId: number): Promise<SrResult> {
        const srResult = await this.auth.apiGet(this.routes.MINISTRY_ADMIN_BCGRAD_SR_RESULT, testAttemptId);
        return srResult;
    }

    isTestWindowUpcoming(testWindow: TestWindow): boolean {
        const dateStart = moment(testWindow.dateStart).utc();
        const now = moment().utc();
        return now.isBefore(dateStart);
    }

    async getStudentIrtScores(pagination: Pagination, testWindow: TestWindow, assessmentType: AssessmentType, district?: District, school?: School, assessment?: AssessmentComponent): Promise<{
        count: number,
        data: StudentIrtRow[],
    }> {
        const irtScores = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_IRT_SCORE, {
            query: {
                pagination,
                testWindowId: testWindow.id,
                assessmentType: assessmentType,
                district: district ? district.groupId : undefined,
                school: school ? school.groupId : undefined,
                assessment: assessment ? assessment.code : undefined,
                requester_uid: this.requesterUid(),
            },
        });
        return irtScores;
    }

    async isAdminSessionOpen(test_window_id: number): Promise<boolean> {
        const { is_admin_session_open } = await this.auth.apiGet(this.routes.SCHOOL_ADMIN_TEST_WINDOW_ADMIN_SESSION_OPEN, test_window_id, this.wrapRequesterUid());
        return is_admin_session_open as boolean;
    }

    exportStudentIrtScores(assessmentType: AssessmentType, district?: District, school?: School, assessment?: AssessmentComponent) {
        let filename = 'Student-IRT-Scores-' + assessmentType;
        if (district) {
            filename += '-' + district.name;
        }
        if (school) {
            filename += '-' + school.name;
        }
        if (assessment) {
            filename += '-' + assessment.code;
        }
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_IRT_SCORE, JSON.stringify({
            query: {
                assessmentType: assessmentType,
                district: district ? district.groupId : undefined,
                school: school ? school.groupId : undefined,
                assessment: assessment ? assessment.code : undefined,
                requester_uid: this.requesterUid(),
            }
        }), filename);
        window.open(url, '_blank');
    }

    async getIndividualStudentReportScores(pagination: Pagination, assessmentType: AssessmentType, testWindow: TestWindow, district?: District, school?: School, assessment?: AssessmentComponent, studentPEN?: number): Promise<{
        count: number,
        data: IndividualStudentReportRow[],
    }> {
        let irtScores = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_IRT_SCORE, {
            query: {
                pagination,
                assessmentType: assessmentType,
                testWindowId: testWindow.id,
                isStudentResult: true,
                pen: studentPEN ? studentPEN : undefined,
                district: district ? district.groupId : undefined,
                school: school ? school.groupId : undefined,
                assessment: assessment ? assessment.code : undefined,
                requester_uid: this.requesterUid(),
            },
        });

        const rows: Map<number, IndividualStudentReportRow> = new Map();
        irtScores.data.map(rawRow => {
            let existingRow = rows.get(rawRow.pen);
            if (existingRow) {
                if (rawRow.slug.startsWith('LI')) {
                    existingRow.literacyLevel = rawRow.prof_level;
                    if (rawRow.slug.includes('SR')) {
                        existingRow.submissionDateTimeLitSR = moment(rawRow.submission_dt);
                    }
                    else {
                        existingRow.submissionDateTimeCR = moment(rawRow.submission_dt);
                    }
                }
                else if (rawRow.slug.startsWith('NU')) {
                    existingRow.numeracyLevel = rawRow.prof_level;
                    if (rawRow.slug.includes('SR')) {
                        existingRow.submissionDateTimeNumSR = moment(rawRow.submission_dt);
                    }
                    else {
                        existingRow.submissionDateTimeCR = moment(rawRow.submission_dt);
                    }
                }
            } else {
                existingRow = {
                    assessmentYear: testWindow.dateEnd.getFullYear(),
                    firstName: rawRow.first_name,
                    lastName: rawRow.last_name,
                    schoolName: rawRow.school_name,
                    studentPENNumber: rawRow.pen,
                    studentGrade: rawRow.slug[3],
                    literacyLevel: undefined,
                    numeracyLevel: undefined,
                    assessment: rawRow.slug,
                    submissionDateTimeLitSR: undefined,
                    submissionDateTimeNumSR: undefined,
                    submissionDateTimeCR: undefined,
                };
                if (rawRow.slug.startsWith('LI')) {
                    existingRow.literacyLevel = rawRow.prof_level;
                    if (rawRow.slug.includes('SR')) {
                        existingRow.submissionDateTimeLitSR = moment(rawRow.submission_dt);
                    }
                    else {
                        existingRow.submissionDateTimeCR = moment(rawRow.submission_dt);
                    }
                }
                else if (rawRow.slug.startsWith('NU')) {
                    existingRow.numeracyLevel = rawRow.prof_level;
                    if (rawRow.slug.includes('SR')) {
                        existingRow.submissionDateTimeNumSR = moment(rawRow.submission_dt);
                    }
                    else {
                        existingRow.submissionDateTimeCR = moment(rawRow.submission_dt);
                    }
                }
                rows.set(rawRow.pen, existingRow);
            }
        });
        return {
            data: [...rows.values()].sort((a, b) => a.studentPENNumber < b.studentPENNumber ? -1 : 1),
            count: irtScores.count,
        }
    }

    async getStudentIrtScore__new(pagination: Pagination, assessment: AssessmentType, testWindow: TestWindow, group_by_student: boolean, district?: number, school?: number, byAssessment?: boolean): Promise<PaginatedRows<StudentIrtRow2>> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_IRT_SCORE, {
            query: {
                new: 1,
                pagination,
                district_group_id: district,
                school_group_id: school,
                test_window_id: testWindow.id,
                assessment_type: assessment,
                group_by_student: group_by_student ? 1 : 0,
                byAssessment: byAssessment ? 1 : undefined,
                requester_uid: this.requesterUid(),
            }
        });
    }

    async getIndividualStudentReports(payload: {
        pagination: Pagination,
        testWindow: TestWindow,
        assessmentType: AssessmentType,
        schoolGroupId: number,
        isScaled: boolean,
        withScores: boolean,
    }) {
        const { assessmentType, pagination, testWindow, schoolGroupId, isScaled, withScores } = payload;

        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_STUDENT_ISR, {
            query: {
                pagination,
                testWindowId: testWindow.id,
                assessmentType,
                schoolGroupId,
                withScores: withScores ? 1 : 0,
                isScaled: isScaled ? 1 : 0,
            }
        })
    }

    async getStudentPreIrtScore__new(pagination: Pagination, assessment: AssessmentType, testWindow: TestWindow, byAssessment?: boolean, district?: number, school?: number, pen?: string, isScaled: boolean = false): Promise<PaginatedRows<StudentIrtRow2> | IndividualRawScoresRow[]> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_PRE_IRT_SCORE, { //No await since bulk is expecting a promise.
            query: {
                pagination,
                district_group_id: district,
                school_group_id: school,
                test_window_id: testWindow.id,
                assessment_type: assessment,
                by_assessment: byAssessment ? 1 : 0,
                pen,
                requester_uid: this.requesterUid(),
                isScaled: isScaled ? 1 : undefined,
            }
        });


    }

    exportStudentIrtScore__new(assessment: AssessmentType, testWindow: TestWindow, district?: number, school?: number) {
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_IRT_SCORE, JSON.stringify({
            query: {
                new: 1,
                district_group_id: district,
                school_group_id: school,
                test_window_id: testWindow.id,
                assessment_type: assessment,
            }
        }), 'Student-IRT-Scores');
        window.open(url, '_blank');
    }

    async getStudentIrtScore__penOnly(pagination: Pagination, assessment: AssessmentType, testWindow: TestWindow, district?: number, school?: number): Promise<{
        pen: string,
        lang: string,
        grade: number,
    }[]> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_IRT_SCORE, {
            query: {
                new: 1,
                pagination,
                district_group_id: district,
                school_group_id: school,
                test_window_id: testWindow.id,
                assessment_type: assessment,
                pen_only: 1,
                group_by_student: 1,
                requester_uid: this.requesterUid(),
            }
        });
    }

    async getValidationCodes(studentPen: number, testWindowId: number) {
        const codes = await this.auth.apiFind(this.routes.SCHOOL_ADMIN_BCG_STUDENTS_LOOKUP, {
            query: {
                action: 'find student codes',
                pen: studentPen,
                testWindowId,
                requester_uid: this.requesterUid(),
            },
        });

        let validation = [];
        let logged = [];
        let special = [];

        for (const code of codes) {
            if (code.code_type == 'log') {
                logged.push(code);
            }
            else if (code.code_type == 'validation') {
                validation.push(code);
            }
            else if (code.code_type == 'special') {
                special.push(code);
            }
        }

        return { logged, validation, special };
    }

    async modifyFsaSessionPassword(assessmentCode: string, testWindow: TestWindow, password: string): Promise<void> {
        await this.auth.apiPatch(this.routes.MINISTRY_ADMIN_SESSION_PASSWORDS, 0, {
            password,
        }, {
            query: {
                assessmentCode,
                testWindowId: testWindow.id,
                assessmentType: AssessmentType.FSA,
                requester_uid: this.requesterUid(),
            }
        });
    }

    async findStudentsForScoreEntry(pagination: Pagination, testWindow: TestWindow, districtGroupId: number, incompleteOnly: boolean = false, completeOnly: boolean = false, grade: -1 | 4 | 7, schoolGroupId?: number, penOnly: boolean = false): Promise<PaginatedRows<ScoreEntryStudentRow>> {
        return await this.auth.apiFind(this.routes.DIST_ADMIN_SCORE_ENTRY, {
            query: {
                district_group_id: districtGroupId,
                school_group_id: schoolGroupId,
                pagination,
                test_window_id: testWindow.id,
                incomplete: incompleteOnly ? 1 : 0,
                complete: completeOnly ? 1 : 0,
                grade: grade == -1 ? undefined : grade,
                pen_only: penOnly ? 1 : 0,
                requester_uid: this.requesterUid(),
            },
        }).then((result: PaginatedRows<any>) => {
            result.data.map(d => {
                d.grade = +d.grade;
                d.is_marked_not_yet_scored = d.is_marked_not_yet_scored == 1 ? true : false;
            });
            return result;
        })
    }

    async markScoreEntryNotYetScored(params: Partial<MarkNotYetScoredParams>, not_yet_scored: boolean): Promise<ITestAttempt> {
        return await this.auth.apiCreate(this.routes.DIST_ADMIN_SCORE_ENTRY_NOT_YET_SCORED, {
            is_marked_as_not_yet_scored: not_yet_scored ? 1 : 0,
        }, {
            query: {
                requester_uid: this.requesterUid(),
                ...params,
            }
        });
    }

    async countStudentsCompletedSr(pagination: Pagination, testWindow: TestWindow, districtGroupId: number, grade: 4 | 7, schoolGroupId?: number): Promise<number> {
        const { count } = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_ACCOUNTS, {
            query: {
                action: 'count',
                count: 'student count',
                sr_completed: 1,
                district: districtGroupId,
                school: schoolGroupId,
                pagination,
                grade,
                testWindowId: testWindow.id,
                requester_uid: this.requesterUid(),
            }
        });
        return count;
    }

    async countStudentsForScoreEntry(pagination: Pagination, testWindow: TestWindow, districtGroupId: number, incompleteOnly: boolean = false, completeOnly: boolean = false, grade: -1 | 4 | 7, schoolGroupId?: number): Promise<number> {
        const { count } = await this.auth.apiFind(this.routes.DIST_ADMIN_SCORE_ENTRY, {
            query: {
                action: 'count',
                district_group_id: districtGroupId,
                school_group_id: schoolGroupId,
                pagination,
                test_window_id: testWindow.id,
                incomplete: incompleteOnly ? 1 : 0,
                complete: completeOnly ? 1 : 0,
                grade: grade == -1 ? undefined : grade,
                requester_uid: this.requesterUid(),
            },
        });
        return count as number;
    }

    async getStudentForScoreEntry(studentUid: number, testWindow: TestWindow): Promise<ScoreEntryStudentRow[]> {
        return await this.auth.apiGet(this.routes.DIST_ADMIN_SCORE_ENTRY, studentUid, {
            query: {
                test_window_id: testWindow.id,
                requester_uid: this.requesterUid(),
            },
        });
    }

    async changeScoreEntryThemeByUid(uid: number, schoolGroupId: number, testWindow: TestWindow, theme: any) {
        await this.auth.apiPatch(this.routes.DIST_ADMIN_SCORE_ENTRY, uid, {
            theme,
        }, {
            query: {
                school_group_id: schoolGroupId,
                test_window_id: testWindow.id,
                requester_uid: this.requesterUid(),
            },
        });
    }

    async changeScoreEntryScoreByUid(uid: number, schoolGroupId: number, testWindow: TestWindow, slug: 'LI_1' | 'LI_2' | 'NU_1' | 'NU_2', score: string | number) {
        const component = ['LI_1', 'LI_2', 'LI_3'].includes(slug) ? 'literacy' : 'numeracy';
        const question = ['LI_1', 'NU_1'].includes(slug) ? 1 : (['LI_2', 'NU_2'].includes(slug) ? 2 : 3);
        if (score == "") {
            // delete score
            // await this.auth.apiRemove(this.routes.DIST_ADMIN_SCORE_ENTRY, {
            // score,
            // }, {
            // query: {
            // uid: uid,
            // school_group_id: schoolGroupId,
            // test_window_id: testWindow.id,
            // component: component,
            // question: question,
            // },
            // });
        }
        else {
            await this.auth.apiCreate(this.routes.DIST_ADMIN_SCORE_ENTRY, {
                score,
            }, {
                query: {
                    uid: uid,
                    school_group_id: schoolGroupId,
                    test_window_id: testWindow.id,
                    component: component,
                    question: question,
                    requester_uid: this.requesterUid(),
                },
            });
        }
    }

    async getStudentLogForScoreEntry(studentUid: number, taqr_ids: number[]): Promise<ScoreEntryStudentRow[]> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_SCORE_ENTRY_LOGS, {
            query: {
                taqr_ids: taqr_ids,
                requester_uid: this.requesterUid(),
            },
        });
    }

    async getStudentSubmissionProgress(uid: number, schoolGroupId: number, testWindow: TestWindow, assessmentSlug): Promise<SubmissionProgress | null> {
        const { progress } = await this.auth.apiGet(this.routes.MINISTRY_ADMIN_SUBMISSION_PROGRESS, uid, {
            query: {
                school_group_id: schoolGroupId,
                test_window_id: testWindow.id,
                assessment_slug: assessmentSlug,
                requester_uid: this.requesterUid(),
            },
        });
        if (progress) {
            return {
                ...progress,
                created_on: moment(progress.created_on),
            };
        }
        return null
    }

    async unsubmitStudentAttempt(uid: number, testWindow: TestWindow, slug: string) {
        return await this.auth.apiPatch(this.routes.MINISTRY_ADMIN_UNSUBMISSIONS, uid, {}, {
            query: {
                test_window_id: testWindow.id,
                test_session_slug: slug,
                requester_uid: this.requesterUid(),
            }
        });
    }

    async resetUnsubmission(uid: number, testWindow: TestWindow, slug: string) {
        return await this.auth.apiPatch(this.routes.MINISTRY_ADMIN_UNSUBMISSIONS, uid, {}, {
            query: {
                test_window_id: testWindow.id,
                test_session_slug: slug,
                reset: 1,
                requester_uid: this.requesterUid(),
            }
        });
    }

    async isIrtReadyForTestWindow(testWindowId: number): Promise<boolean> {
        let data = await this.auth.apiGet(this.routes.MINISTRY_ADMIN_IRT_READY, testWindowId, this.wrapRequesterUid());
        return data.irt_ready == 1 ? true : false;
    }

    async getIrtFlags(testWindowId: number): Promise<{ irt_ready: boolean, is_irt_sld_disabled: boolean }> {
        let data = await this.auth.apiGet(this.routes.MINISTRY_ADMIN_IRT_READY, testWindowId, this.wrapRequesterUid());

        return {
            irt_ready: data.irt_ready === 1,
            is_irt_sld_disabled: data.is_irt_sld_disabled === 1,
        }
    }

    async uploadScoreEntry(test_window_id: number, rows: SpreadSheetScoreEntryRow[]): Promise<any> {
        return await this.auth.apiCreate(this.routes.DIST_ADMIN_SCORE_ENTRY, {
            upload: rows,
        }, {
            query: {
                upload: 1,
                test_window_id,
                requester_uid: this.requesterUid(),
            },
        });
    }

    async getScoreProfile(type: ScoreProfileType): Promise<IScoreProfile> {
        return this.auth.apiGet(this.routes.SCHOOL_ADMIN_SCORE_PROFILE, type, this.wrapRequesterUid());
    }

    async getTestDesignPathsForTestWindow(test_window_id: number): Promise<ITestDesignWithPath[]> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_TEST_DESIGNS_NUM_QUESTIONS, {
            query: {
                test_window_id,
            },
        });
    }

    async getAssessmentKeysForTestWindow(test_window_id: number, type_slug: string, form: string): Promise<ITestQuestionRegister[]> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_ASSESSMENT_KEYS, {
            query: {
                test_window_id,
                type_slug,
                form,
            },
        });
    }

    async getComponentFormMappingForTestWindow(test_window_id: number): Promise<ComponentFormMapping> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_ALLOC_RULES, {
            query: {
                test_window_id,
            },
        });
    }

    async getAssessmentComponentFromCode(code: string): Promise<AssessmentComponent[]> {
        const components = await this.auth.apiGet(this.routes.MINISTRY_ADMIN_ASSESSMENT_COMPONENTS, code);
        return components.map(c => ({
            ...c,
            code: c.assessment_code,
        }))
    }

    async getCutScoresForTestWindow(test_window_id: number): Promise<ICutScoreMap> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_CUT_SCORES, {
            query: {
                test_window_id,
            }
        });
    }


    async getFinalReports(testWindowId: number): Promise<any> {
        return this.auth.apiGet(this.routes.BC_ADMIN_FINAL_REPORTS, testWindowId, {});
    }

    async findAvailableTestWindowsByUserRole(params: {
        assessment: AssessmentType
    }) {
        const { assessment } = params;
        
        const data = <any[]>await this.auth.apiFind(this.routes.MINISTRY_ADMIN_TEST_WINDOW, {
            query: {
                assessment,
                byRole: 1,
                requester_uid: this.requesterUid(),
            }
        });
        return data.map(d => this.makeTestWindowFromData(d));
    }
    async generateEDFMCFiles(testWindowId: number, isSupplementaryMaterialsRun: boolean, domain: AcceptableDomainsBC, specificPrintOrder?: number): Promise<EDFMCFilesReturnType>
    {   
        // TODO: actually use test window to affect print preview data in the API
        return this.auth.apiCreate("public/bc-admin-coordinator/edfmc-v-2", [], 
        {
            query: 
            {
                testWindowId,
                isSupplementaryMaterialsRun,
                specificPrintOrder,
                domain
            }
        }); 
    }

    async retrieveEDFMCFiles(testWindowId: number, domain: AcceptableDomainsBC): Promise<EDFMCFilesReturnType>
    {   
        return await this.auth.apiGet("public/bc-admin-coordinator/edfmc-v-2", -1, 
        {
            query: 
            {
                testWindowId,
                domain
            }
        }); 
    }

    async getStaticPDFs(testWindowId: number): Promise<StaticPDFs>
    {
        const params = 
        {
            query: 
            {
                testWindowId,
                isDynamicPDFReq: 0
            }
        };

        return await this.auth.apiFind(this.routes.BC_MINISTRY_ADMIN_PDF_PREVIEWS_FSA, params);
    }

    async getDynamicPDF(testWindowId: number, schoolGroupId: number, printOrder: number, PDFType: AcceptablePDFTypes): 
    Promise<PackingSlipPDF | SchoolSeparatorFormPDF | StudentListPDF>
    {
        const params = 
        {
            query: 
            {
                testWindowId,
                schoolGroupId,
                printOrder,
                isDynamicPDFReq: 1, 
                PDFType
            }
        };

        return await this.auth.apiFind(this.routes.BC_MINISTRY_ADMIN_PDF_PREVIEWS_FSA, params);
    }

    async getAllTwtdars(testWindowId: number): Promise<any[]>
    {
    // this function returns all the columns from `test_window_td_alloc_rules`, as the type.
        const params =
        {
            query:
            {
                isExport: 0,
                testWindowId,
                getAssessments: 1
            }
        };

        return await this.auth.apiFind(this.routes.MINISTRY_TQR_EXTRACT, params);
    }

    async getTQRParams(testDesignId: number, isGrad: boolean): Promise<any[]>
    {
    // this function returns certain columns from `test_window_td_alloc_rules`, as the type
    // depending on if grad or FSA.
        const params =
        {
            query:
            {
                isGrad: isGrad ? 1 : 0,
                isExport: 0,
                testDesignId,
                getTQR: 1
            }
        };

        return await this.auth.apiFind(this.routes.MINISTRY_TQR_EXTRACT, params);
    }

    async getTqrParamsByTwtar(twtarId: number, isGrad: boolean): Promise<any[]> {
        const params =
        {
            query:
            {
                isGrad: isGrad ? 1 : 0,
                isExport: 0,
                twtarId,
                getTQR: 1
            }
        };

        return await this.auth.apiFind(this.routes.MINISTRY_TQR_EXTRACT, params);
    }
    
    async exportTQRParams(testWindowName: string, assessment: string,
    formOrComponent: string, testDesignId: number, isGrad: boolean)
    {
        let fileName = "Full-Assessment-Keys";
        fileName += "; " + testWindowName + " - " + assessment;
        fileName += (isGrad ? " - Form " : " - ") + formOrComponent;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_TQR_EXTRACT, JSON.stringify(
        {
            isGrad: isGrad ? 1 : 0,
            isExport: 1,
            testDesignId,
            getTQR: 1,
        }), fileName);
        // console.log(url, fileName);
        window.open(url, '_blank');
    } 

    async exportProficiencyLevelsReport(testWindowName: string, assessmentCode: string, 
    assessmentName: string, testWindowId: number, school: School, district: District,
    numDecimalPointsInData: number): Promise<void>
    {
        // console.log(school, district);
        let fileName = "Proficiency-Levels" + " - " + assessmentCode;
        
        // file name too long to have testWindowName
        // fileName += "; " + testWindowName;
        if (school != null && school.groupId != ALL_SCHOOLS.groupId && school.foreignId != ALL_SCHOOLS.foreignId)
        {
            fileName += "; School - " + school.name + " (" + school.foreignId + ")";
        }

        if (district != null && district.groupId != ALL_DISTRICT.groupId && district.foreignId != ALL_DISTRICT.foreignId)
        {
            fileName += "; District - " + district.name + " (" + district.foreignId + ")";
        }
        
        fileName += "; " + this.formatDateForBC(moment());
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_IRT_SCORE, JSON.stringify(
        {
            isExport: 1,
            testWindowId,
            assessmentCode,
            assessmentName,
            action: "get-proficiency-distribution-data",
            schoolGroupId: school.groupId,
            districtGroupId: district.groupId,
            numDecimalPointsInData,
            useQueryDirectly: 1
        }), fileName);
        window.open(url, '_blank');
    }

    public formatDateForBC(dateTime: moment.Moment): string
    {
      if (dateTime == null)
      {
        return "";
      }
  
      return dateTime.tz("America/Vancouver").format("MMMM Do, YYYY h:mm A") + " PST";
    }

    async flagDuplicateFSATestAttempts(testWindowId: number): Promise<void>
    {
        await this.auth.apiUpdate(this.routes.FSA_MINISTRY_ADMIN_DUPLICATE_ATTEMPTS_ANALYSIS,
        null, 
        {},
        {
            query: 
            {
                markDuplicateAttempts: 1,
                testWindowId
            }
        });
    }

    async getDuplicateFSATestAttemptsStudents(testWindowId: number): Promise<DuplicateStudentTestAttempts[]>
    {
        let students = await this.auth.apiGet(this.routes.FSA_MINISTRY_ADMIN_DUPLICATE_ATTEMPTS_ANALYSIS, testWindowId, 
        {
            query: 
            {
                getStudents: 1,
            }
        }) as DuplicateStudentTestAttempts[];
        
        students.forEach((row) => 
        {
            // enrollment_district and enrollment_school are the names of the dist/school from the API
            row.enrollment_district = this.formatDistrictOrSchool(row.enrollment_district, row.enrollment_district_code, true);
            row.enrollment_school = this.formatDistrictOrSchool(row.enrollment_school, row.enrollment_school_code, true);
        });

        return students;
    }

    formatDistrictOrSchool(name: string, code: string, forRegistration: boolean): string
    {
        if (code == null)
        {
            return forRegistration ? "Not Registered" : "None";
        }

        return name + " (" + code + ")";
    }

    async getDuplicateFSATestAttempts(testWindowId: number, uid: number): Promise<
    {
        SRAttempts: any[],
        CRAttempts: any[]
    }>
    {
        let 
        { 
            SRAttempts,
            CRAttempts
        } = await this.auth.apiGet(this.routes.FSA_MINISTRY_ADMIN_DUPLICATE_ATTEMPTS_ANALYSIS, testWindowId, 
        {
            query: 
            {
                getAttempts: 1,
                uid
            }
        });    
        
        SRAttempts.forEach((row) => 
        {
            // enrollment_district and enrollment_school are the names of the dist/school from the API
            row.test_attempt_district = this.formatDistrictOrSchool(row.schl_district_name, row.schl_dist_foreign_id, false);
            row.test_attempt_school = this.formatDistrictOrSchool(row.schl_name, row.schl_foreign_id, false);
            row.closed_on = this.formatDateForBC(row.closed_on == null ? null : moment(row.closed_on));
            row.created_on = this.formatDateForBC(row.created_on == null ? null : moment(row.created_on));
        });

        CRAttempts.forEach((row) => 
        {
            // enrollment_district and enrollment_school are the names of the dist/school from the API
            row.test_attempt_district = this.formatDistrictOrSchool(row.schl_district_name, row.schl_dist_foreign_id, false);
            row.test_attempt_school = this.formatDistrictOrSchool(row.schl_name, row.schl_foreign_id, false);
            row.closed_on = this.formatDateForBC(row.closed_on == null ? null : moment(row.closed_on));
            row.created_on = this.formatDateForBC(row.created_on == null ? null : moment(row.created_on));
        });

        return {
            CRAttempts, 
            SRAttempts
        };

        // expected rows to be used by the code that calls this function (in each CRAttempts and SRAttempts)
        // row.ta_id,
        // row.is_invalid
        // row.is_closed
        // row.closed_on
        // row.assessment
        // row.form
        // row.test_attempt_district
        // row.test_attempt_school
    }    

    async toggleTestAttemptIsInvalid(testAttemptId: number, studentTestAttempts: any[], isSR: boolean): Promise<void>
    {
        await this.auth.apiUpdate(this.routes.FSA_MINISTRY_ADMIN_DUPLICATE_ATTEMPTS_ANALYSIS,
        null, 
        studentTestAttempts,
        {
            query: 
            {
                changeTestAttemptStatus: 1,
                testAttemptId,
                isSR: isSR ? 1 : 0
            }
        });
    }

    async showDates(): Promise<boolean>{
        const record = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
          query: {
            action: 'check_sys_flag',
            flag: 'BCED_SHOW_DATES'
          }
        });
        if(record[0]){
          return record[0].value;
        }
      }


}

