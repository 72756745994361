import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AdminRole, BcAccountsService, CreateDistrictAdminData, CreateSchoolAdminData, District, DistrictAdminRow, DistrictDetail, FilterCondition, PaginatedRows, Pagination, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { AssessmentType, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { BcPaginatedTableComponent, IColumnHeading, Toggle } from '../bc-paginated-table/bc-paginated-table.component';
import { isEmpty, cloneDeep } from 'lodash';
import { CreateSchoolAdminErrors, ModalCreateSchoolAdminData } from '../bc-accounts-admins-school/bc-accounts-admins-school.component';
import moment from 'cypress/node_modules/moment';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { AuthService } from 'src/app/api/auth.service';

interface SaAccountsDistrictAdminRow {
  uid: number,
  first_name: string,
  last_name: string,
  contact_email: string,
  role: AdminRole,
  editedRole: AdminRole
  status: string,
  selected: boolean,
  invited: boolean,
  revoked: boolean,
}

export type CreateDistrictAdminErrors = CreateSchoolAdminErrors;

export interface ModalCreateDistrictAdminData extends CreateDistrictAdminData {
  district: District,
  district_group_id: number,
}

@Component({
  selector: 'bc-accounts-admins-district',
  templateUrl: './bc-accounts-admins-district.component.html',
  styleUrls: ['./bc-accounts-admins-district.component.scss']
})
export class BcAccountsAdminsDistrictComponent implements OnInit, OnChanges {

  @Input() testWindow: TestWindow;
  @Input() districtDetail: DistrictDetail;
  @Input() schoolDetail: SchoolDetail;
  @Input() accountType: AccountType;

  isLoading: boolean = false;

  pagination: Pagination;
  rows: SaAccountsDistrictAdminRow[] = [];
  filteredRows: SaAccountsDistrictAdminRow[];
  selectAll: boolean = false;

  columnHeadings: IColumnHeading[];
  tableColumnWidths: number[];
  showAdminInfo: boolean = false;
  showAdminInfoToggle: Toggle;
  roles: AdminRole[] = [AdminRole.SCORE_ENTRY];

  FilterCondition = FilterCondition;

  nameSearch = "";
  nameSearchPlaceHolder: string;

  // invitation
  modalCreateDistrictAdminData: ModalCreateDistrictAdminData;
  createDistrictAdminErrors: CreateDistrictAdminErrors;
  shouldOpenNewAdminModal: boolean = false;

  constructor(
    private auth: AuthService,
    private bcAccounts: BcAccountsService,
    private lang: LangService,
    private loginGuard: LoginGuardService,
  ) {
    this.pagination = this.bcAccounts.getInitialPagination();
    this.showAdminInfoToggle = {
      tra: 'sa_dist_admin_show_admin_info_toggle',
    };
    this.nameSearchPlaceHolder = this.lang.tra("da_sa_searchAccountName");
  }

  ngOnInit() 
  {
    console.log("src/app/ui-partial/bc-accounts-admins-district/bc-accounts-admins-district.component.ts");
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.testWindow || changes.schoolDetail || changes.districtDetail || changes.accountType) {
      this.updateTable();
    }
  }

  private updateTable() {
    if (!this.districtDetail || !this.pagination || !this.accountType) return;
    this.isLoading = true;
    this.bcAccounts.findDistrictAdmins(
      this.pagination,
      this.districtDetail.groupId,
      AssessmentType.FSA,
      true,
      true,
    ).then(({ data, count }) => {
      this.rows = data.map(d => {
        let status: string;
        let is_pending: boolean = false;
        let is_revoked: boolean = false;
        if (d.is_revoked) {
          status = this.lang.tra('da_sa_status_revoked');
          is_revoked = true;
        } else if (d.invite_used_by_uid && d.invite_used_on) {
          let translated = this.lang.tra('da_sa_status_access_updated');
          status = translated + ` ${moment(d.invite_used_on).format('MMM. D, YYYY')}`;
        } else if (d.invite_created_on) {
          let translated = this.lang.tra('da_sa_status_pending');
          status = translated + ` ${moment(d.invite_created_on).format('MMM. D, YYYY')}`
          is_pending = true;
        } else {
          status = this.lang.tra('da_sa_status_default');
        }
        let role = this.getRoleForAccountType(d.account_type as AccountType);
        return {
          uid: d.uid,
          first_name: d.first_name,
          last_name: d.last_name,
          contact_email: d.contact_email,
          status,
          role,
          editedRole: role,
          is_pending,
          selected: false,
          invited: d.invite_created_on !== null && d.invite_created_on !== undefined,
          revoked: is_revoked,
        };
      });
      this.pagination.count = count;
      this.filteredRows = this.removeNullRow(this.rows);
      this.isLoading = false;
    })
  }

  private removeNullRow(rows) 
  {
    return rows.filter((eachRow) => 
    { 
      return eachRow.contact_email != null 
    });
  }

  newInviteClicked() {
    this.openNewInviteModal();
  }

  async revokeAccessClicked() {
    let selectedRows = this.rows.filter(r => r.selected);
    const revokeCheckText = this.lang.tra('sa_aa_revoke_warning');
    let caption = revokeCheckText + `\n\n`;
    for (let r of selectedRows) {
      caption += `${r.first_name} ${r.last_name} (${r.contact_email}).\n\n`;
    }
    const revokeConfirmText = this.lang.tra('sa_aa_revoke_confirm');
    caption += revokeConfirmText + '\n\n';
    this.loginGuard.confirmationReqActivate({
      caption,
      confirm: async () => {
        let succMessage = '';
        let errMessage = '';

        let promises = this.rows.filter(r => r.selected).map(r => {
          return this.bcAccounts.revokeDistrictAdminAccess(r.uid, this.districtDetail.groupId).then(() => {
            succMessage += this.lang.tra('sa_aa_revoke_success') + ` ${r.first_name} ${r.last_name} (${r.contact_email}).\n\n`;
          }).catch(err => {
            errMessage += this.lang.tra('sa_aa_revoke_failed') + ` ${r.first_name} ${r.last_name} (${r.contact_email}):\n\n${err.message}\n\n`;
          });
        });
        await Promise.all(promises);

        this.loginGuard.quickPopup(succMessage + errMessage);
        this.updateTable();
      },
    });

  }

  async grantAccessClicked() {
    let promises = this.rows.filter(r => r.selected).map(r => {
      return this.bcAccounts.grantDistrictAdminAccess(r.uid, this.districtDetail.groupId);
    });

    await Promise.all(promises);
    this.updateTable();
  }

  openNewInviteModal() {
    this.createDistrictAdminErrors = {};
    this.modalCreateDistrictAdminData = {
      first_name: '',
      last_name: '',
      role: AdminRole.SCORE_ENTRY,
      email: '',
      district: cloneDeep(this.districtDetail),
      district_group_id: this.districtDetail.groupId,
    };
    this.shouldOpenNewAdminModal = true;
  }

  closeNewInviteModal() {
    this.shouldOpenNewAdminModal = false;
  }

  saveNewInviteModal() {
    this.createDistrictAdminErrors = this.validateCreateDistrictAdminData(this.modalCreateDistrictAdminData);
    if (!isEmpty(this.createDistrictAdminErrors)) {
      return;
    }

    const createDistrictAdminData = this.makeCreateDistrictAdminData(this.modalCreateDistrictAdminData)
    this.bcAccounts.createDistrictAdmin(createDistrictAdminData).then(data => {
      this.loginGuard.quickPopup(this.lang.tra('sa_aa_invitation_sent', null, {'email': createDistrictAdminData.email}));
      this.closeNewInviteModal();
      this.updateTable();
    }).catch(err => {
      this.loginGuard.quickPopup(this.lang.tra('sa_aa_already_exists_error'));
    });
  }

  makeCreateDistrictAdminData(data: ModalCreateDistrictAdminData): CreateDistrictAdminData {
    const {
      first_name,
      last_name,
      email,
      role,
      district_group_id,
    } = data;

    return {
      role,
      first_name,
      last_name,
      email,
      district_group_id: district_group_id,
    };
  }

  validateCreateDistrictAdminData(data: ModalCreateDistrictAdminData): CreateDistrictAdminErrors {
    const {
      first_name,
      last_name,
      email,
      role,
      district_group_id,
    } = data;

    const errors: CreateSchoolAdminErrors = {};

    if (first_name === '') {
      errors.first_name = 'Please provide a first name.';
    }

    if (last_name === '') {
      errors.last_name = 'Please provide a last name.';
    }

    // using rfc2822 email regex
    const rfc5322EmailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    if (email === '') {
      errors.email = 'Please provide an email.';
    }
    else if (rfc5322EmailRegex.test(email.toLocaleLowerCase()) === false) {
      errors.email = "This email is invalid.";
    }

    if (!role || role !== AdminRole.SCORE_ENTRY) {
      errors.role = 'This role is invalid.'
    };

    if (!district_group_id || district_group_id == -1) {
      errors.district = 'This district is invalid';
    };

    return errors;
  }

  shouldDisableNewInviteSave(): boolean 
  {
    return !isEmpty(this.validateCreateDistrictAdminData(this.modalCreateDistrictAdminData));
  }

  shouldDisableRevokeButton() {
    return this.rows.filter(r => r.selected && !r.revoked).length === 0 || this.rows.filter(r => r.selected && r.revoked).length > 0;
  }

  shouldDisableGrantButton() {
    return this.rows.filter(r => r.selected && r.revoked).length === 0 || this.rows.filter(r => r.selected && !r.revoked).length > 0;
  }

  onSelectAllChange() {
    this.rows.map(r => r.selected = this.selectAll);
  }

  onSelectChange(row: SaAccountsDistrictAdminRow) {
    if (this.rows.filter(r => r.selected).length === this.rows.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  getNameDisplay(row: SaAccountsDistrictAdminRow): string {
    return `${row.first_name} ${row.last_name}`;
  }

  searchByName(e) { //
    const value = e.target.value.toLocaleLowerCase();
    if (value == "") {
      this.filteredRows = this.rows;
    } else {
      this.filteredRows = this.rows.filter((row) => (row.last_name.toLocaleLowerCase().includes(value) || row.first_name.toLocaleLowerCase().includes(value)));
    }
  }

  onPaginationChange() {
    this.updateTable();
  }

  getDistrictDisplay = (district: DistrictDetail): string => {
    if (!district) return '';
    return this.bcAccounts.getDistrictDisplay({
      groupId: district.groupId,
      foreignId: district.foreignId,
      name: district.name,
    });
  }

  getRoleForAccountType(accountType: AccountType): AdminRole {
    if (accountType === AccountType.BC_FSA_DIST_ADMIN_SCORE_ENTRY) {
      return AdminRole.SCORE_ENTRY;
    } else {
      return AdminRole.ADMINISTRATOR;
    }
  }

  displayRole(role: AdminRole): string {
    if (!role) return '';
    if (role == AdminRole.ADMINISTRATOR) return this.lang.tra('District Administrator');
    return this.lang.tra(role);
  }


  onSelectedRoleChange(row: SaAccountsDistrictAdminRow) {

  }

  onSelectedSchoolForInviteChange(event) {

  }

  isScoreEntryDistrictAdmin(): boolean {
    return this.auth.isScoreEntryDistrictAdmin(this.accountType);
  }
}
